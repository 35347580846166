import React      from 'react';
import Display    from '@cComponents/displayIf';

import 'font-awesome/css/font-awesome.min.css';

import './header.css';

const Header = (props) => {
  const { pushed } = props;
  return(
    <div className={"bs-button bs-button-header" + (pushed ? " bs-selected" : "")}>
        { props.children }
    </div>
  )
}
Header.Standart = (props) => {
  const { icon, textLeft, children, pushed } = props;
  const iconSpan = <span className={ `fa fa-${icon}` }/>;
  const textSpan = <span className="bs-text">{children}</span>; 
  return (
    <Header pushed={ pushed }>
      <Display.If condition={ iconSpan && !textLeft}>
        { iconSpan }
      </Display.If>
      <Display.If condition={ children }>
        { textSpan }
      </Display.If>
      <Display.If condition={ iconSpan && textLeft}>
        { iconSpan }
      </Display.If>
    </Header>
  )
}
export default Header;