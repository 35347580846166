import Path from "../types/Path";

type PathParameter = {
  name: string;
  optional: boolean;
};

type PathComponent = Path | PathParameter;

const isPath = (pathComponent: PathComponent): pathComponent is Path => {
  return typeof pathComponent === 'string';
}

const isParamsPath = (pathComponent: PathComponent): pathComponent is PathParameter => {
  return (pathComponent as PathParameter).name !== undefined;
}

export const pathToRegexp = (path: Path, strict: boolean): RegExp => {
  const pathComponents = path.split('/').slice(1).map<PathComponent>(pathPart => {
    if(!pathPart.startsWith(':')){
      return pathPart;
    }
    const pathParameter: PathParameter = {
      name: pathPart.substring(1),
      optional: false
    };
    if(pathParameter.name.startsWith('?')){
      pathParameter.optional = true;
      pathParameter.name = pathParameter.name.substring(1);
    }
    return pathParameter;
  });

  const regExpString: string = pathComponents.reduce<string>((path, pathComponent) => {
    if(isPath(pathComponent)){
      return `${path}\\/${pathComponent}`;
    } else if(isParamsPath(pathComponent)){
      if(pathComponent.optional){
        return `${path}(\\/(?<${pathComponent.name}>[^\\/]+))?`;
      } else {
        return `${path}\\/(?<${pathComponent.name}>[^\\/]+)`;
      }
    } else {
      throw new Error('Invalid path component');
    }
  }, "");

  const completeRegExpString = `^${regExpString}${strict ? '$' : ''}`;
  
  return new RegExp(completeRegExpString);
};

export const matcher = (regExpPath: RegExp, currentPath: Path) => {
  const match = regExpPath.exec(currentPath);
  if(!match){
    return {
      match: false,
      params: {}
    };
  }
  
  return {
    match: true,
    params: match.groups as Record<string, string>
  };
}