import React, { FunctionComponent } from "react";
import Numeric  from "./numeric";
import Select   from "./select";
import T        from "@uBehaviour/i18n";

import "./duration.css";

interface DurationInputProps {
  value: String;
  onChange: (value: String) => void
}

const regexp = /P(?<quantity>[0-9].*)(?<type>[DMY])/;

const DurationInput: FunctionComponent<DurationInputProps> = ({ value, onChange }) => {
  let numericValue = 1;
  let typeValue = null;

  if (value) {
    const match = value.match(regexp);
    numericValue = parseInt(match.groups.quantity);
    typeValue = match.groups.type;
  }

  const _onNumeriqueChange = React.useCallback((value) => {
    if(isNaN(value) || value < 1) {
      value = 1;
    }
    onChange(`P${value}${typeValue}`);
  }, [ typeValue, onChange ]);

  const _onTypeChange = React.useCallback((value) => {
    onChange(value ? `P${numericValue}${value}` : null);
}, [ numericValue, onChange ]);

  return (
    <div className="bs-input-duration">
      <Numeric  value={ numericValue } onChange={ _onNumeriqueChange } numericType="integer" min={ 1 } max={ 100 } disabled={ !typeValue } />
      <Select   value={ typeValue }    onChange={ _onTypeChange }>
        <Select.Value value="D"><T>input_duration_day</T></Select.Value>
        <Select.Value value="M"><T>input_duration_month</T></Select.Value>
        <Select.Value value="Y"><T>input_duration_year</T></Select.Value>
        <Select.Value value={null}><T>input_duration_undefined</T></Select.Value>
      </Select>
    </div>
  )
}

export default DurationInput;