import { Service } from "@uLib/application";
export default class AclService extends Service{
  constructor(){
    super("users", ["api", "session", "message"]);
  }
  sendInvitation(user){
    const api     = this.application.getService("api");
    const message = this.application.getService("message");
    api.service("users", "invite").execute(user._id.toString()).then(() => {
      message.send("info", `Message d'invitation envoyé à ${user.fullname}`);
    });
  }
  isAllowToChangePasswordOn(user){
    const configuration = this.application.getService("configuration");
    return (!user.email || configuration.get("env") === "development") && user.authentificationProviders.some(ap => ap.discriminator === "username");
  }
  getUserManagedTenant(user){
    const session = this.application.getService("session");
    const acl     = this.application.getService("acl");
    return user.discriminator === "collaborator"
      ? []
      : user.tenants.filter(t => {
        return acl.isAllow("users", "manage", session.user, t.tenant._id)
      });
  }
  updatePassword(userId, password){
    const session = this.application.getService("session");
    const api     = this.application.getService("api");
    if (session.user._id === userId) {
      return session.updateMyPassword(password);
    } else {
      return api.service("users", "resetPassword").execute(userId, password);
    }
  }
}