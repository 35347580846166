// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.bs-form-layout.bs-form-standart-layout.bs-dispose-row  {
    display: flex;
    justify-content: space-between;
}

.bs-form-layout-row {
    margin-bottom: 15px;
}

.bs-form-layout-row.bs-disabled {
    pointer-events: none;
    opacity: 0.6;
}

.bs-form-layout-row .bs-form-layout-label {
    margin-bottom: 5px;
    color: #2c3e50;
}*/`, "",{"version":3,"sources":["webpack://./../common/components/layout/form/standart.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;EAiBE","sourcesContent":["/*.bs-form-layout.bs-form-standart-layout.bs-dispose-row  {\n    display: flex;\n    justify-content: space-between;\n}\n\n.bs-form-layout-row {\n    margin-bottom: 15px;\n}\n\n.bs-form-layout-row.bs-disabled {\n    pointer-events: none;\n    opacity: 0.6;\n}\n\n.bs-form-layout-row .bs-form-layout-label {\n    margin-bottom: 5px;\n    color: #2c3e50;\n}*/"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
