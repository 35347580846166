export default class Event {
  constructor() {
    this._listeners = [];
  }
  hasListener(listener){
    return this._listeners.indexOf(listener) !== -1;
  }
  addListener(listener) {
    if (!listener || !listener.handleEvent) {
      throw new Error("Listener must have a handleEvent method");
    }
    if (this.hasListener(listener)) 
      return false;
    
    this._listeners.push(listener);
    return true;
  }
  removeListener(listener) {
    const idx = this._listeners.indexOf(listener);
    if (idx === -1) 
      return false;
    
    this._listeners.splice(idx, 1);
    return true;
  }
  trigger(...args) {
    this._listeners.slice().forEach(listener => {
      listener.handleEvent.apply(listener, args);
    });
  }
}
class Listener {
  constructor(handler, context) {
    this._handler = handler;
    this._context = context;
  }
  handleEvent() {
    const args = Array.prototype.slice.call(arguments);
    return this._handler.apply(this._context, args);
  }
}
export {
  Listener
};
