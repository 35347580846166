import React        from "react";
import md5          from "md5";
import Text         from "./text";
import Select       from "./select";
import _            from "lodash";
import Slot         from "@uComponents/slot";

import './autocomplete.css';

const unsensitiveCaseBolder = (splitted, splitter) => {
  const ucSplitted    = splitted.toLowerCase();
  const ucSplitter    = splitter.toLowerCase();
  const sl            = splitter.length;
  let idx             = 0;
  
  const result = ucSplitted.split(ucSplitter).reduce((acc, val, index) => {
    acc.push(splitted.substr(idx, val.length));
    idx += val.length;
    const text = splitted.substr(idx, sl);
    acc.push(React.createElement('b', { key: index }, text));
    idx += sl;
    return acc;
  }, []);
  return result;
};
let id = 1;
export default class Autocomplete extends React.Component {
  static Item = Slot();
  static Placeholder = Slot();
  constructor(props){
    super(props);
    this._id        = md5(++id + "");
    this._inputText = React.createRef();
    this._select    = React.createRef();
    this._searched  = props.searched ? props.searched : null;
    this._values    = [];
    this._search(null);
    this._onTextChange    = this._onTextChange.bind(this);
    this._onSelectChange  = this._onSelectChange.bind(this);
    if(this.props.delay){
      this._search = _.debounce(this._search.bind(this), this.props.delay);
    }
  }
  get value(){
    return this._select.current.value;
  }
  set value(value){
    this._inputText.current.value = value;
    this._select.current.value = value;
  }
  get searched(){
    return this._searched;
  }

  focus(){
    this._inputText.current.input.focus();
    const length = this._inputText.current.input.value.length;
    this._inputText.current.input.setSelectionRange(length, length);
  }

  clear = () => {
    if(this.props.clear){
      this.props.clear();
    }
  }
  _onTextChange(value){
    this._searched = value;
    this.forceUpdate();
    if(value){
      this._search(value);
      this._select.current.open();
    }else{
      this._select.current.close();
    }
  }
  _onSelectChange(value){
    if(this.props.onChange){
      this.props.onChange(value);
    }
    this._searched = value.label;
    this._values  = [];
    this.forceUpdate();
  }

  _initialize(id) {
    if (!id) this._values = [];
    this.props.initialize(id)
    .then(value => {
      this._values = [value];
      this.forceUpdate();
    })
  }

  _search(value){
    if(value && value.trim() === ""){
      value = null;
    }
    this.props.search(value)
      .then(values => {
        this._values = values;
        this.forceUpdate();
      });
  }
  shouldComponentUpdate(nextProps){
    if(this.props.value !== nextProps.value){
      this._searched = nextProps.value ? nextProps.value.label : "";
    }
    return true;
  }
  
  componentDidMount() {
    this._onTextChange(this._searched);
    if(this.props.focusOnMount){
      this.focus();
    }
  }

  render(){
    const placeholder = Autocomplete.Placeholder.get(this);
    let item          = Autocomplete.Item.get(this);
    if(!item){
      item = (value, label) => {
        return React.createElement('div', null, label);
      }
    }
    const children =  
      [React.createElement(Select.Display, { key: `${this._id}_display` }, (value, isOpen) => {
        return (
          <span className="bs-input-autocomplete-display">
            <Text ref={ this._inputText } onChange={ this._onTextChange } onKeyUp={ this.props.onKeyUp } value={ this._searched }>{ placeholder }</Text>
            { this.props.clear ? <div className="bs-input-autocomplete-display-clear"><span className="fa fa-times" onClick={ this.clear } /></div> : null }
          </span>
        );
      })].concat(this._values.map(value => (value => 
        !this._searched
          ? React.createElement(Select.Value, value, item(value, value.label))
          : React.createElement(Select.Value, value,
              item(value, unsensitiveCaseBolder(value.label, this._searched))
            )
      )({ ...value, key: `${this._id}_value_${md5(JSON.stringify(value.value))}` })
    ));
    return React.createElement(Select, {
      name: this.props.name,
      required: this.props.required,
      value: this.props.value,
      onChange: this._onSelectChange,
      disabled: this.props.disabled,
      postSelect: this.props.postSelect ? this.props.postSelect : null,
      comparableValue: this.props.comparableValue ? this.props.comparableValue : null,
      initialize: this._initialize.bind(this),
      fluid: this.props.fluid,
      ref: this._select 
    }, ...children);
  }
}

Autocomplete.Model = undefined;
