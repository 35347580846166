/**
 * This file contains the implementation of the SubscribeForm component and its related sub-components.
 */

import React, { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react';

import Slot from '@uComponents/slot2';
import { Channels, NotificationSubscription } from '@uTypes/business/User';
import T from '@universal/behaviour/i18n';
import Input from '@common/components/input';
import Form from '@universal/behaviour/form';
import Button from '@common/components/button';
import { Checkbox } from 'semantic-ui-react';


import './subscribe.css';

/**
 * Props for the Group component.
 */
interface GroupProps {
  endLine?: ReactNode
}

/**
 * A component that represents a group of elements.
 */
const Group: FunctionComponent<PropsWithChildren<GroupProps>> = ({ endLine, children }) => {
  return (
    <div className="bs-subscribe-group">
      <div>{ children }</div>
      { endLine }
    </div>
  );
}

/**
 * Props for the Subscription component.
 */
interface SubcriptionProps {
  setter: ReactNode
}

/**
 * A component that represents a subscription.
 */
const Subscription: FunctionComponent<PropsWithChildren<SubcriptionProps>> = ({ setter, children }) => (
  <div className="bs-subscribe-subscription">
    <div>{ children }</div>
    { setter }
  </div>
);

interface ChannelSetterHeaderProps {
  globalsChannels: Channels
}

/**
 * A component that represents the header of the ChannelSetter component.
 */
const ChannelSetterHeader: FunctionComponent<ChannelSetterHeaderProps> = ({ globalsChannels }) => (
  <div className="bs-subscribe-channelSetterHeader">
  {
    globalsChannels.map(channel => {
      return (
        <div key={ channel }><T>{ `subscription_channelSetterHeader_${ channel }` }</T></div>
      );
    })
  }
  </div>
);

/**
 * Props for the ChannelSetter component.
 */
interface ChannelSetterProps { 
  name: string,  
  value: Channels,
  onChange: (value: { name: string, channels: Channels }) => void,
  allowedChannels?: Channels,
  globalsChannels?: Channels
}

const empty = (<Input.Checkbox.Value value={ null }>{(selected: boolean) => (<div onClick={e => e.stopPropagation() } />)}</Input.Checkbox.Value>);

const allChannels = ["email", "push"];

/**
 * A component that allows setting the channels for a subscription.
 */
const ChannelSetter: FunctionComponent<ChannelSetterProps> = ({ name, value, onChange, allowedChannels = allChannels, globalsChannels = allChannels }) => {
  
  const sendChannelWithName = React.useCallback((channels: Channels) => {
    onChange({ name, channels });
  }, [onChange, name]);

  const values = React.useMemo(() => globalsChannels.map(channel => {
    if(allowedChannels.includes(channel)){
      return (
        <Input.Checkbox.Value key={ channel } value={ channel }>{(selected: boolean) => (<Checkbox checked={ selected }/>)}</Input.Checkbox.Value>
      );
    }
    return React.cloneElement(empty);
  }), [value]);
  
  return (
    <Input.Checkbox value={ value } onChange={ sendChannelWithName } inline noFrame>
    { values }
    </Input.Checkbox> 
  );
}

/**
 * Props for the SubscriptionFacade component.
 */
interface SubcriptionFacadeProps {
  name: string,
  withEmail?: boolean,
  withPush?: boolean,
}

/**
 * A slot component that represents a facade for a subscription.
 */
const SubscriptionFacade = Slot<ReactNode, PropsWithChildren<SubcriptionFacadeProps>>();

/**
 * A slot component that represents a facade for a group.
 */
const GroupFacade = Slot<ReactNode, PropsWithChildren>();

/**
 * Props for the Subscribe component.
 */
interface SubscribeProps {
  value: NotificationSubscription[];
  onChange: (value: NotificationSubscription[]) => void;
  withPush?: boolean;
  withEmail?: boolean;
  children: ReactElement<typeof GroupFacade> | Iterable<ReactElement<typeof GroupFacade>>;
}

/**
 * A component that represents a subscription form.
 */
const Subscribe: FunctionComponent<SubscribeProps>= ({ value, onChange, withPush: gWithPush = true, withEmail: gWithEmail = true, children }) => {
  
  const groupsProps = GroupFacade.props(children, true);

  const onSubscriptionChange = React.useCallback((subscription: NotificationSubscription) => {
    const newValue: NotificationSubscription[] = [...value];
    const index = newValue.findIndex(({ name }) => name === subscription.name);
    if(index === -1){
      newValue.push(subscription);
    } else {
      newValue[index] = subscription;
    }
    onChange(newValue);
  }, [value, onChange]);

  const globalsChannels: Channels = [];
  if(gWithEmail){
    globalsChannels.push("email");
  }

  if(gWithPush){
    globalsChannels.push("push");
  }

  const groups = React.useMemo<Iterable<ReactNode>>(() => {
    return groupsProps.reduce<ReactNode[]>((els, group, grIndex) => {

      const notSubscription = SubscriptionFacade.not(group.children);

      const groupEndLine = grIndex === 0 
        ? <ChannelSetterHeader globalsChannels={ globalsChannels }/> 
        : null;

      els.push(<Group key={ `group-${ grIndex }` } endLine={ groupEndLine }>{ notSubscription }</Group>);

      const subscriptions = SubscriptionFacade.props(group.children, true);
      subscriptions.forEach(({ name, withEmail = true, withPush = true, children }, subIndex) => {
        
        withEmail = withEmail && gWithEmail;
        withPush = withPush && gWithPush;

        const subscriptionValue = value.find(({ name: valueName }) => valueName === name);
        let subValue: Channels = [];
        if(subscriptionValue){
          subValue = subscriptionValue.channels;
        }

        const allowedChannels: Channels = [];
        if(withEmail){
          allowedChannels.push("email");
        }
        if(withPush){
          allowedChannels.push("push");
        }

        const setter = (
          <ChannelSetter
            name={ name }
            value={ subValue }
            onChange={ onSubscriptionChange }
            allowedChannels={ allowedChannels }
            globalsChannels={ globalsChannels }
          />
        );

        els.push(
          <Subscription key={ `group-${grIndex}-subscription-${subIndex}` } setter={ setter }>
          { children }
          </Subscription>
        );
        return els;
      });

      return els;
    }, [] as ReactNode[]);
  }, [value, children]);

  return (
    <div className="bs-subscribe">
    { groups }
    </div>
  );
};

/**
 * Props for the SubscribeForm component.
 */
interface SubscribeFormProps {
  value: NotificationSubscription[];
  submit: (value: NotificationSubscription[]) => void;
  withPush?: boolean;
  withEmail?: boolean;
  children: ReactElement<typeof GroupFacade> | Iterable<ReactElement<typeof GroupFacade>>;
}

type FormValue = { value: NotificationSubscription[] };

/**
 * A component that represents a subscription form with a submit button.
 */
const SubscribeForm: FunctionComponent<SubscribeFormProps> & { Group: typeof GroupFacade, Subscription: typeof SubscriptionFacade } = (props) => {
  const { value, submit, withPush = true, withEmail = true, children } = props;

  const formValue = React.useMemo<FormValue>(() => ({ value }), [value]);
  const submitForm = React.useCallback((form: typeof Form.Simple, { value }: FormValue) => {
    return submit(value);
  }, [ submit ]);
  return (
    <Form.Simple value={ formValue } submit={ submitForm }>
    {(ctx: any, value: FormValue, errors: [], form: any, submit: () => void) => (
      <div className='bs-subscribe-form'>
        <div className='bs-subscribe-form-header'>
          <div className='bs-subscribe-form-header-title'><T>subscription_parameters</T></div>
          <div><T>subscription_info</T></div>
        </div>
        <div className="bs-subscribe-form-content">
          <Form.Simple.InputAdapter name="value">
          {(value: NotificationSubscription[], onChange: (value: NotificationSubscription[]) => void) => (
            <Subscribe value={ value } onChange={ onChange } withPush={ withPush } withEmail={ withEmail }>
            { children }
            </Subscribe>
          )}
          </Form.Simple.InputAdapter>
        </div>
        <div className='bs-subscribe-form-footer'>
          <Button.Text onClick={ submit }>
            <T>subscription_subscriptionForm_submit</T>
            <span className='fa fa-arrow-right' />
          </Button.Text>
        </div>
      </div>
    )}
    </Form.Simple>
  );

}

SubscribeForm.Group = GroupFacade;
SubscribeForm.Subscription = SubscriptionFacade;

export default SubscribeForm;