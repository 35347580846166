import React          from "react";
import _              from "lodash";

class Pager extends React.Component {
  constructor(props) {
    super(props);
    this._configurePager();
    this._pager.stateChange.addListener(this);
    if (this._pager.loadedLength < this._pageSize) {
      this.loadNext();
    }
    this._firstLoad = true;
    this.internalForceUpdate = _.debounce(this.internalForceUpdate.bind(this), 0);
  }

  get pager(){
    return this._pager;
  }

  _configurePager() {
    if(this.props.pager){
      this._pageSize  = JSON.parse(JSON.stringify(this.props.pageSize ? this.props.pageSize : 20));
      this._pager     = this.props.pager;
      this.props.pagerService.register(this._pager.id);
    } else {
      let query       = JSON.parse(JSON.stringify(this.props.query ? this.props.query : {}));
      let sort        = JSON.parse(JSON.stringify(this.props.sort ? this.props.sort : {}));
      this._pageSize  = JSON.parse(JSON.stringify(this.props.pageSize ? this.props.pageSize : 20));
      this._pager     = this.props.pagerService.create(this.props.model, query, sort, this.props.load);
    }
    if(this.props.debugPager){
      this._pager.debugPager = true;
    }
  }

  handleEvent(event) {
    switch (event) {
      case "loading":

        break;
      case "loaded":
        this._onLoaded();
        if(this._firstLoad){
          this._firstLoad = false;
          this._onFirstLoaded();
        }else{
          this._onUpdated();
        }
        break;
      case "entity-set-updated":
        this.internalForceUpdate();
        if(!this._firstLoad){
          this._onUpdated();
        }
        break;
    }
  }

  internalForceUpdate(){
    this.setState(Object.assign({}, this.state));
  }

  forceReload() {
    this._pager.reload(this._pageSize);
  }

  _onFirstLoaded(){
    if (this.props.onFirstLoaded) {
      this.props.onFirstLoaded();
    }
  }
  _onUpdated(){
    if (this.props.onUpdated) {
      this.props.onUpdated();
    }
  }
  _onLoaded() {
    if (this.props.onLoaded) {
      this.props.onLoaded();
    }
  }

  get loaded() {
    return this._pager.hasLoaded();
  }
  isFinishedLoading() {
    return this._pager.isFinishedLoading();
  }
  loadNext() {
    if (!this._pager.isFinishedLoading()) {
      return this._pager.next(this._pageSize);
    }
    return Promise.resolve({ alreadyLoad: true });
  }
  isEmpty() {
    return this.isFinishedLoading() && this._pager.loadedLength === 0;
  }

  componentDidUpdate() {
    const cpPager = this._pager;
    this._configurePager();
    if (this._pager !== cpPager) {
      this._pager.stateChange.addListener(this);
      cpPager.stateChange.removeListener(this);
      this.props.pagerService.unregister(cpPager.id);
      if (this._pager.loadedLength < this._pageSize) {
        this.loadNext();
      }
      if(this.props.onDefinitionChange){
        this.props.onDefinitionChange(this);
      }
    } else {
      this.props.pagerService.unregister(this._pager.id);
    }
  }

  componentWillUnmount() {
    this._pager.stateChange.removeListener(this);
    this.props.pagerService.unregister(this._pager.id);
  }
}


export default Pager;