import React        from "react";
import Release      from "@cFeatures/release";
import Modal        from "@cComponents/modal";


const ReleaseList = (props) => (
  <Modal.Show key="release" userCanClose={false} style={{ width: "40vw", height: "90vh" }}>
  {() => (
    <Release.List application={props.application} close={ props.close }/>
  )}
  </Modal.Show>
);

export default ReleaseList;