import React from "react";
import Event from "@uLib/event";

const Context = React.createContext();

class FocusBlurContext extends React.Component {
  constructor(props){
    super(props);
    this._onClickEvent  = new Event();
    this._onMouseDown   = this._onMouseDown.bind(this);
  }
  get onClick(){
    return this._onClickEvent;
  }
  _onMouseDown(ev){
    this._onClickEvent.trigger();
    if(this.props.onClick){
      this.props.onClick(ev);
    }
  }
  render(){
    const props = Object.assign({}, this.props, {
      onMouseDown: this._onMouseDown
    });
    return React.createElement(Context.Provider, { value: this }, React.createElement("div", props, this.props.children));
  }
}

class FocusBlur extends React.Component {
  static contextType = Context;
  constructor(props){
    super(props);
    this._onMouseDown   = this._onMouseDown.bind(this);
    this.handleEvent    = this.handleEvent.bind(this);
    this._hasFocus      = !!this.props.hasFocus;
    this._ref           = React.createRef();
    this._clickOnMe     = false;
  }
  _onMouseDown(ev){
    this._clickOnMe = true;
    if(!this._hasFocus){
      this._hasFocus = true;
      if(this.props.onFocus){
        this.props.onFocus();
      }
    }
    if(this.props.onMouseDown){
      this.props.onMouseDown(ev);
    }
  }
  get hasFocus(){
    return this._hasFocus;
  }
  handleEvent(){
    if(this._hasFocus && !this._clickOnMe){
      this._hasFocus = false;
      if(this.props.onBlur){
        this.props.onBlur();
      }
    }
    this._clickOnMe = false;
  }
  componentDidMount(){
    this.context.onClick.addListener(this);
  }
  componentWillUnmount(){
    this.context.onClick.removeListener(this);
  }
  render(){
    const props = Object.assign({}, this.props, { onMouseDown: this._onMouseDown, onBlur: null, onFocus: null, ref: this._ref });
    return React.createElement(this.props.el ? this.props.el : "div", props, this.props.children);
  }
}
FocusBlur.Context = FocusBlurContext;

export default FocusBlur;