
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {

  return ModelBuilder.create("File", Key.create("_id"), stdRepositoryConnector(application, "files"), {})
    .add(p => p("_id", "string"))
    .add(p => p("name", "string"))
    .add(p => p("hash", "string"))
    .add(p => p("type", "string")) //virtuel, elle n'existe pas en BDD, uniquement en local
    .add(p => p("storageId", "string"))
    .add(p => p("mimeType", "string"))
    .add(p => p("uri", "string"))
    .add(p => p("$creator", "boolean"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("meta", "object")
      .add(p => p("rotate", "number"))
    )
    .build();
}