import React, { CSSProperties, ComponentType, FunctionComponent, MouseEvent, PropsWithChildren, ReactElement, ReactNode }                        from "react";

import Display                      from './displayIf';
import Keyboard                     from "@cLib/keyboard";
import FocusBlur                    from "@cComponents/focusBlur";
import Tooltip                      from '@cComponents/tooltip';
import useService                   from "@uBehaviour/hooks/useService";
import UrlService                   from "@cServices/url";
import _ from 'lodash';

import "./modal.css";
const Context = React.createContext<Manager | null>(null);
class Manager extends React.Component<PropsWithChildren, { managerChildActive: boolean }> {
  static Context = Context;
  static contextType = Context;

  private stacks: Array<Show>;

  constructor(props: any) {
    super(props);
    this.stacks  = [];
    this.state = {
      managerChildActive: false
    };
  }
  componentDidMount() {
    Keyboard.onKeyDown.addListener(this);
  }
  componentWillUnmount() {
    Keyboard.onKeyDown.removeListener(this);
  }
  handleEvent = (event, value) => {
    if (event === "onKeyDown" && value.code === "Escape" && this.stacks[this.stacks.length - 1] && this.stacks[this.stacks.length - 1].userCanClose) {
      this.stacks[this.stacks.length - 1].close();
    }
  }
  push(modal: Show) {
    this.stacks.push(modal);
    this.forceUpdate();
    _.defer(() => {
      if(this.stacks.length > 1){
        this.stacks[this.stacks.length - 2].triggerHide();
      }
      this.stacks[this.stacks.length - 1].triggerOpen();
      if(this.context){
        (this.context as Manager).withManagerChild(true);
      }
    });
  }
  pop() {
    this.stacks.pop();
    this.forceUpdate();
    if(this.stacks.length){
      this.stacks[this.stacks.length - 1].triggerShow();
    } else if(this.context){
      (this.context as Manager).withManagerChild(false);
    }
  }

  withManagerChild = (active: boolean) => {
    if(this.state.managerChildActive !== active){
      this.setState({ managerChildActive: active });
    }
  }

  get hasModal(): boolean {
    return !!this.stacks.length;
  }

  render() {
    return (
      <Manager.Context.Provider value={this}>
        { this.props.children}
        <Display.If condition={ this.hasModal }>
          <div className="bs-modal">
            { this.stacks.map((modal, index) => (
              <div key={ index } className="bs-modal-parent-content">
                <div className="bs-modal-content">
                { modal.renderModal(!this.state.managerChildActive) }
                </div>
              </div>
            ))}
          </div>
        </Display.If>
      </Manager.Context.Provider>
    )
  }
}

interface ContainerProps{
  children: () => ReactNode;
}

class ModalContainer extends React.Component<ContainerProps> {
  render(){
    return this.props.children();
  }
}

interface ShowProps {
  onOpen?: () => void;
  onShow?: () => void;
  onHide?: () => void;
  onClose?: () => void;
  close?: () => void;
  style?: CSSProperties;
  userCanClose?: boolean;
  children: ((close: () => void) => ReactNode) | ReactNode
}

class Show extends React.Component<ShowProps> {
  static contextType = Context;
  private closed : boolean;
  private container: React.RefObject<ModalContainer>;
  constructor(props: PropsWithChildren<ShowProps>) {
    super(props);
    this.closed = false;
    this.container = React.createRef();
  }
  triggerOpen(){
    if(this.props.onOpen){
      this.props.onOpen();
    }
  }
  triggerShow(){
    if(this.props.onShow){
      this.props.onShow();
    }
  }
  triggerHide(){
    if(this.props.onHide){
      this.props.onHide();
    }
  }
  get userCanClose() {
    return this.props.userCanClose !== false;
  }
  componentDidMount() {
    (this.context as Manager).push(this);
  }
  componentDidUpdate(): void {
    this.container.current?.forceUpdate();
  }
  componentWillUnmount() {
    this.internalClose();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  _eventStop = (ev: MouseEvent) => {
    ev.stopPropagation();
  }

  private internalClose() {
    if (!this.closed) {
      this.closed = true;
      (this.context as Manager).pop();
      return true;
    }
    return false;
  }

  close = () => {
    if (this.internalClose() && this.props.close) {
      this.props.close();
    }
  }
  renderContent = (): ReactNode => {
    const { children } = this.props;
    if(!children || (typeof (children.type) === 'string')){
      return this.props.children as ReactNode;
    }
    if(children instanceof Function){
      return children(this.close);
    }
    return React.cloneElement(children as ReactElement, {
      close: this.close
    });
  }
  renderModal(withCloseTrigger: boolean = true) {
    return (
      <FocusBlur.Context style={this.props.style ? this.props.style : {}} onClick={ this._eventStop }>
        <Tooltip.Manager>
          <ModalContainer ref={ this.container }>
          { this.renderContent }
          </ModalContainer>
          <Display.If condition={this.props.userCanClose !== false && withCloseTrigger} >
            <div className="bs-modal-close" onClick={ this.close }>
              <span className="fa fa-times" />
            </div>
          </Display.If>
        </Tooltip.Manager>
      </FocusBlur.Context>
    );
  }

  
  render(): React.ReactNode {
    return null;
  }
}

type UrlRequestProps = {
  displayModalValue: string;
  onOpen?: () => void;
  onShow?: () => void;
  onHide?: () => void;
  onClose?: () => void;
  style?: CSSProperties;
  userCanClose?: boolean;
  children: ((close: () => void) => ReactNode) | ReactNode
}

const UrlRequest: FunctionComponent<UrlRequestProps> = ({ displayModalValue, onOpen, onShow, onHide, onClose, style, userCanClose, children }) => {
  const urlService = useService<UrlService>("url", ["onParamsUpdated"]);

  const closeModal = React.useCallback(() => {
    urlService.remove("displayModal");
  }, [urlService]);

  if(urlService.has("displayModal") && urlService.get("displayModal") === displayModalValue) {
    return (
      <Show
        onOpen={ onOpen }
        onShow={ onShow }
        onHide={ onHide }
        onClose={ onClose }
        style={ style }
        userCanClose={ userCanClose }
        close={ closeModal }
      >
        { children }
      </Show>
    );
  }

  return null;
}

type UrlRequestButtonPropsWaited = {
  onClick: () => void;
  children?: ReactNode;
};

type UrlRequestButtonPropsGenerated<Props> = Omit<Props, "onClick"> & { displayModalValue: string }

const withUrlRequestButton = <ButtonProps extends UrlRequestButtonPropsWaited>(Button: ComponentType<ButtonProps>): FunctionComponent<UrlRequestButtonPropsGenerated<ButtonProps>> => {
  return (props) => {
    const urlService = useService<UrlService>("url");

    const setDisplayModal = React.useCallback(() => {
      urlService.set("displayModal", props.displayModalValue);
    }, [urlService, props.displayModalValue]);

    return (
      <Button { ...props as unknown as ButtonProps } onClick={ setDisplayModal }>
      { props.children }
      </Button>
    );
  };
}

export default {
  Manager,
  Show,
  UrlRequest,
  withUrlRequestButton
}