import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from '@uLib/classNames';
import Event, { Listener } from '@uLib/event';

import './scrollable.css';

interface ScrolledElement {
  init(): void;
  dispose(): void;
  scrollTo(scroll: number): void;
  readonly containerHeight: number;
  readonly viewPortHeigh: number;
  readonly scrollTop: number;
}

class MultiManager {
  private _scrolledElements: ScrolledElement[];
  private container: HTMLElement | null;
  private speed: number;
  private _onElementsChange: Event;
  private observer: MutationObserver;

  constructor(speed = 25){
    this.speed = speed;
    this.container = null;
    this._scrolledElements = [];
    this._onElementsChange = new Event();
    this.observer = new MutationObserver(this.triggerOnElementsChange);
  }

  get onElementsChange(): Event {
    return this._onElementsChange;
  }

  get scrolledElements(): ScrolledElement[] {
    return this._scrolledElements.slice();
  }

  private triggerOnElementsChange = () => {
    this._onElementsChange.trigger(this._scrolledElements.slice());
  }

  private wheelListener = (e: WheelEvent) => {
    const toAdd = this.speed * (e.deltaY > 0 ? 1 : -1);
    let scrollTo = 0;
    this._scrolledElements.forEach((el) => {
      scrollTo = Math.max(scrollTo, el.scrollTop + toAdd);
    });
    this.scrollTo(scrollTo);
  }

  public scrollTo(scroll: number){
    this._scrolledElements.forEach((el) => {
      el.scrollTo(scroll);
    });
  }

  init(container: HTMLElement){
    this.container = container;
    this.container.addEventListener('wheel', this.wheelListener);
    this.observer.observe(container, {
      childList: true,
      subtree: true
    });
  }

  dispose(){
    if(this.container){
      this.container.removeEventListener('wheel', this.wheelListener);
    }
  }

  register(scolledElement: ScrolledElement){
    this._scrolledElements.push(scolledElement);
    this.triggerOnElementsChange();
  }

  unregister(scolledElement: ScrolledElement){
    this._scrolledElements = this._scrolledElements.filter((el) => el !== scolledElement);
    this.triggerOnElementsChange();
  }
}

class FakeScrollBar implements ScrolledElement{
  private manager: MultiManager;
  private container: HTMLElement;
  private content: HTMLElement;
  private updateFakeHeight: Listener;
  private allowScrollTo: boolean;

  constructor(manager: MultiManager, container: HTMLElement, content: HTMLElement){
    this.manager = manager;
    this.container = container;
    this.content = content;
    this.updateFakeHeight = new Listener(this.updateFakeHeightHandler);
    this.allowScrollTo = true;
    this.init();
  }

  private synchroniseScroll = () => {
    this.allowScrollTo = false;
    this.manager.scrollTo(this.container.scrollTop);
    this.allowScrollTo = true;
  }

  init(){
    this.manager.onElementsChange.addListener(this.updateFakeHeight);
    this.container.addEventListener('scroll', this.synchroniseScroll);
  }

  dispose(){
    this.container.removeEventListener('scroll', this.synchroniseScroll);
    this.manager.onElementsChange.removeListener(this.updateFakeHeight);
  }

  scrollTo(scroll: number): void {
    if(this.allowScrollTo){
      this.container.scrollTo(0, scroll);
    }
  }

  get containerHeight() {
    return this.container.clientHeight;  
  }

  get viewPortHeigh()  {
    return this.content.clientHeight; 
  }

  get scrollTop() {
    return this.container.scrollTop;
  }

  private updateFakeHeightHandler = (elements: ScrolledElement[]) => {
    if(this.container && this.content){
      let max = 0;
      elements.filter(element => element !== this).forEach((el) => {
        max = Math.max(max, el.viewPortHeigh);
      });
      this.content.style.height = `${max}px`;
    }
  }
}

class ScrolledElementImpl implements ScrolledElement {
  private _viewPort: HTMLElement;
  private _container: HTMLElement;

  constructor(viewPort: HTMLElement, container: HTMLElement) {
    this._viewPort = viewPort;
    this._container = container;
  }

  init(){
    window.addEventListener('resize', this.evaluateScrollTop);
  }

  dispose(){
    window.removeEventListener('resize', this.evaluateScrollTop);
  }

  private evaluateScrollTop = () => {
    this.scrollTo(this.scrollTop);
  }

  public get containerHeight(){
    return this._container.clientHeight;  
  }

  public get scrollTop(){
    return this._viewPort.offsetTop;
  }

  public get viewPortHeigh(){
    return this._viewPort.clientHeight;
  }

  public scrollTo(scroll: number){
    scroll *= -1;
    
    if(scroll < (this.viewPortHeigh - this.containerHeight) * -1){
      scroll = (this.viewPortHeigh - this.containerHeight) * -1;
    }

    if(scroll > 0){
      scroll = 0;
    }
    
    this._viewPort.style.top = `${scroll}px`;
  }
}
const Context = React.createContext<MultiManager | null>(null);


const Scrollable: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const manager = React.useContext(Context);
  const viewPortRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if(manager && viewPortRef.current && containerRef.current){
      const scrolledElement = new ScrolledElementImpl(viewPortRef.current, containerRef.current);
      scrolledElement.init();
      manager.register(scrolledElement);
      return () => {
        scrolledElement.dispose();
        manager.unregister(scrolledElement);
      }
    }
  }, [manager]);

  return (
    <div ref={ containerRef } className={ classNames("bs-scrollBar-scrollable-container") }>
      <div ref={ viewPortRef } className={ classNames("bs-scrollBar-scrollable-viewPort") }>
        <div className={ classNames("bs-scrollBar-scrollable-viewPort-container") }>
          { children }
        </div>
      </div>
    </div>
  );
}

type ManagerProps = PropsWithChildren & {
  className?: string;
};
const Manager: FunctionComponent<PropsWithChildren<ManagerProps>> & { Scrollable: typeof Scrollable } = ({ children, className }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const fakeContainerRef = React.useRef<HTMLDivElement>(null);
  const fakeContentRef = React.useRef<HTMLDivElement>(null);
  const manager = React.useMemo(() => new MultiManager(), []);

  React.useEffect(() => {
    if(containerRef.current && fakeContainerRef.current && fakeContentRef.current){
      manager.init(containerRef.current);
      const fakeScrollBar = new FakeScrollBar(manager, fakeContainerRef.current, fakeContentRef.current);
      manager.register(fakeScrollBar);
      return () => {
        fakeScrollBar.dispose();
        manager.unregister(fakeScrollBar);
        manager.dispose();
      }
    }
  }, [containerRef.current, fakeContainerRef.current, fakeContentRef.current]);

  return (
    <Context.Provider value={ manager }>
      <div ref={ containerRef } className={ classNames("bs-scrollBar-scrollable-manager") }>
        <div className={ classNames("bs-scrollBar-scrollable-manager-content").addNotEmpty(className) }>
        { children }
        </div>
        <div ref={ fakeContainerRef } className={ classNames("bs-scrollBar-scrollable-manager-scrollbar") }>
          <div ref={ fakeContentRef } className={ classNames("bs-scrollBar-scrollable-manager-scrollbar-fake") }>&nbsp;</div>
        </div>
      </div>
    </Context.Provider>
  );
}

Manager.Scrollable = Scrollable;

export default Manager;