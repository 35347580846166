import React    from 'react';
import Slot     from '@cComponents/slot';
import Display  from '@cComponents/displayIf';

import './standart.css';

const getOptionalClassName = function(className){
  return className ? className : "";
};

export default class Standart extends React.Component {  
  static Header  = Slot();
  static Content = Slot();
  static Footer  = Slot();
  render(){
    const header  = Standart.Header.get(this);
    const content = Standart.Content.get(this);
    const footer  = Standart.Footer.get(this);    
    return (
      <div className={"bs-layout-standart-container " + (this.props.stretch ? "bs-layout-standart-stretch " : "") + getOptionalClassName(this.props.className)}>
        <Display.If condition={ header }>
          <div className={"bs-layout-standart-header " + getOptionalClassName(Standart.Header.props(this).className)}>{ header }</div>
        </Display.If>
        <div className={"bs-layout-standart-content "+ getOptionalClassName(Standart.Content.props(this).className)}>{ content }</div>
        <Display.If condition={ footer }>
          <div className={"bs-layout-standart-footer " + getOptionalClassName(Standart.Footer.props(this).className)}>{ footer }</div>
        </Display.If>
      </div>
    );
  }
}