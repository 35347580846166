import React from 'react';

import RouterContext from '../context/router';
import Path from '../types/Path';

type UseLocationType = () => [
  Path,
  (path: Path) => void,
  () => void
];

const useLocation: UseLocationType = () => {
  const { current, navigator } = React.useContext(RouterContext);
  
  const goTo = React.useCallback((path: Path) => {
    navigator.goTo(path);
  }, [navigator]);

  const goBack = React.useCallback(() => {
    navigator.goBack();
  }, [navigator]);

  return [
    current,
    goTo,
    goBack
  ];
}

export default useLocation;