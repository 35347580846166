import React        from "react";
import Application  from "@uBehaviour/application";

class Provider extends React.Component {
  constructor(props) {
    super(props);
    this._ready = false;
  }
  _buildStore(lifeCycleManager) {
    let p = Promise.resolve();
    if (!this._lifeCycleManager) {
      this._lifeCycleManager = lifeCycleManager;
    }
    if (this._name && this._name !== this.props.name) {
      this._unregisterStore();
    }
    if (!this._name) {
      p = this._createStore();
    }
    return p;
  }
  _createStore() {
    let p = Promise.resolve();
    this._ready = false;
    if (!this._lifeCycleManager.has(this.props.name)) {
      p = Promise.resolve(this.props.build()).then(store => {
        if (!this._lifeCycleManager.has(this.props.name)) {
          this._lifeCycleManager.create(this.props.name, store);
        }
        return;
      });
    }
    return p.then(() => {
      this._lifeCycleManager.register(this.props.name);
      this._name = this.props.name;
      this._ready = true;
      this.forceUpdate();
    });
  }
  _unregisterStore() {
    this._lifeCycleManager.unregister(this._name);
    this._name = null;
  }
  componentWillUnmount() {
    if (!this.props.persist && this._name) {
      this._lifeCycleManager.unregister(this._name);
    }
  }
  render() {
    if (!this.props.name) {
      throw new Error("Provider must have a name");
    }
    return React.createElement(Application.Service, { name: "redux-store" }, (lifeCycleManager) => {
      this._buildStore(lifeCycleManager);
      if (this._ready) {
        return this.props.children;
      } else {
        return null;
      }
    }
    );
  }
}

class Accessor extends React.Component {
  constructor(props) {
    super(props);
    this._stores = {};
  }
  _registerStore(lifeCycleManager) {
    if (!this._lifeCycleManager) {
      this._lifeCycleManager = lifeCycleManager;
    }
    const names = Array.isArray(this.props.name) ? this.props.name : [this.props.name];
    Object.keys(this._stores).forEach(name => {
      if (names.indexOf(name) === -1) {
        this._unregisterStore(name);
      }
    });

    names.forEach(name => {
      if (!this._stores[name]) {
        this._stores[name] = this._lifeCycleManager.register(name);
        this._stores[name].onStoreUpdated.addListener(this);
      }
    });
  }
  _unregisterStore(name) {
    this._stores[name].onStoreUpdated.removeListener(this);
    this._lifeCycleManager.unregister(name);
    delete this._stores[name];
  }
  handleEvent() {
    this.forceUpdate();
  }
  componentWillUnmount() {
    Object.keys(this._stores).forEach(name => this._unregisterStore(name));
  }
  render() {
    if (!this.props.name) {
      throw new Error("Accessor must have a name");
    }
    return React.createElement(Application.Service, { name: "redux-store" }, (lifeCycleManager) => {
      this._registerStore(lifeCycleManager);
      const props = Object.keys(this._stores).reduce((props, name) => {
        props[name] = this._stores[name];
        return props;
      }, {});
      props.stores = this._stores;
      return this.props.children instanceof Function
        ? this.props.children(!Array.isArray(this.props.name) ? this._stores[this.props.name] : this.props.name.map(name => this._stores[name]))
        : React.cloneElement(this.props.children, props);
    });
  }
}
export default {
  Provider,
  Accessor
}