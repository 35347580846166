import { Service }    from "@uLib/application";
import md5            from "js-md5";
import FileCollection from "@cLib/fileCollection";
import base64         from "@cLib/base64";
import { Listener } from "@universal/lib/event";

export default class FileService extends Service{
  constructor(bufferSize = 1024 * 10){
    super("file", ["api", "repository", "i18n", "networking", "storage", "message"]);
    this._bufferSize  = bufferSize;
    this._nbr         = 0;
    this._promise     = Promise.resolve();
    this._updateLimitationsListener = new Listener(this._updateLimitations, this);
    this._readyState  = null;
    this._maxSize     = Math.pow(1024, 2) * 5;
    this._acceptedMimeType = {
      displayable: [
        "image/jpeg",
        "image/png",
        "image/webP",
        "image/gif",
        "image/avif",
        "image/tiff",
        // "image/svg+xml"
      ],
      uploadable: [
        "image/jpeg",
        "image/png",
        "image/webP",
        "image/gif",
        "image/avif",
        "image/tiff",
        // "image/svg+xml"
      ]
    }
  }
  start(){
    return this.waitReady(['api', "repository", "session", "networking", "storage"]).then(([api, repository, session, networking, storage]) => {
      session.onServiceUpdated.addListener(this._updateLimitationsListener);
      if (networking.isConnected()) {
        return this._getLimitations();
      } else {
        networking.onConnect.addListener((this._updateLimitationsListener));
        return storage.has("file_limitations").then(has => {
          if (has) {
            return storage.get("file_limitations", true).then(limitations => {
              this._maxSize = limitations.maxSize;
              this._acceptedMimeType = limitations.mimeTypes;
            })
          }
          return true;
        })
      }
    });
  }

  async _updateLimitations() {
    await this._getLimitations();
    this.triggerUpdate(); 
  }
  
  _getLimitations() {
    const api = this.application.getService("api");
    return api.service("files", "limitations").execute().then(limitations => {
      this._maxSize = limitations.maxSize;
      this._acceptedMimeType = limitations.mimeTypes
      this.application.getService("storage").set("file_limitations", limitations, true);
    });
  }
  get displayableMimeTypes(){
    return this._acceptedMimeType.displayable.slice();
  }

  get uploadableMimeTypes(){
    return this._acceptedMimeType.uploadable.slice();
  }

  _save(entity, buffer){
    const file    = entity.toPlainText();
    file.uploaded = null;
    const toStore = {
      _id: file._id,
      file,
      buffer: buffer
    };
    return Promise.resolve(toStore);
  }
  _update(file, buffer){
    return Promise.resolve();
  }
  _remove(file){
    return Promise.resolve();
  }
  _prepare(file, buffer){
    let pSend = null;
    if(file.uploaded === null){
      pSend = this.application.getService("api").service("files", "prepare").execute(file._id, buffer.byteLength).then(() => 0);
    }else{
      pSend = Promise.resolve(file.uploaded);
    }
    return pSend;
  }
  _upload(file, buffer, index, bufferSize){
    return this.application.getService("api").service("files", "upload").execute(file._id, buffer.slice(index, index + bufferSize), index)
            .then(() => {
              file.uploaded += bufferSize;
              return this._update(file, buffer);
            });
  }
  _finalize(file){
    return this.application.getService("api").service("files", "finalize").execute(file._id)
      .then(apiFile => this._remove(file).then(() => apiFile));
  }
  _threatQueue(storageFile){
    const { buffer, file } = storageFile;
    return this._promise = this._promise.then(() => true, () => Promise.resolve())
      .then(() => this._prepare(file, buffer)
        .then(uploaded => {
          let p = Promise.resolve();
          for(let i = uploaded; i < buffer.byteLength; i += this._bufferSize)(uploaded => {
            p = p.then(() => this._upload(file, buffer, uploaded, this._bufferSize));
          })(i);
          return p;
        })
        .then(() => this._finalize(file))
      )
      .catch((e) => {
        if (file.onError) {
          file.onError(e);
        }
        return Promise.reject(e);
      });
  }

  _threatEntityQueue(entity, buffer, mimeType){
    return this._save(entity, buffer)
      .then(storageFile => this._threatQueue(storageFile))
      .then(apiFile => entity.update(apiFile));
  }
  _isAllow(file){
    return this._acceptedMimeType.uploadable.includes(file.type);
  }
  async highLevelAdd(files) {
    try {
      const addedFiles = await this.add(files);
      return addedFiles;
    }
    catch(error) {
      this.application.getService("message").send("warning", error.message);
    };
  }
  add(files){
    if(files instanceof FileCollection){
      const fs = [];
      for(let i = 0; i < files.length(); ++i){
        fs.push(files.get(i));
      }
      files = fs;
    }
    if(!Array.isArray(files)){
      files = [files];
    }
    for(let i = 0; i < files.length; ++i){
      if(!this._isAllow(files[i])){
        return Promise.reject(new Error(this.application.getService("i18n").translateString("file_service_error_file_format", { name: files[i].name ? files[i].name : files[i]._data.name }, true)));
      }
    }
    return Promise.all(files.map(file => new Promise((resolve, reject) => {
      var reader = new FileReader();
      let entity;
      reader.onload = (r) => {
        const buffer = new Uint8Array(r.srcElement.result); 
        if(buffer.length > this._maxSize){
          return reject(new Error(this.application.getService("i18n").translate("file_service_error_file_size", { name: file.name ? file.name : file._data.name })));
        } else {
          entity = this.application.getService("repository").get("File").create({
            _id: ++this._nbr,
            name: file.name ? file.name : file._data.name,
            mimeType: "image/uri",
            $creator: true,
            uri: `data:${file.type};base64,${base64.btoa(buffer)}`
          });
          resolve(entity);
          this._enqueue(entity, buffer, file.type);
        }
      };
      reader.readAsArrayBuffer(file);
    })));
  }

  _enqueue(entity, buffer, mimeType){
    this.application.getService("repository").get("File").repository.create({
      name: entity.name,
      mimeType: mimeType,
      hash: md5(buffer)
    }).then((result) => {
      const datas = Object.assign({}, result);
      if(!result.storageId){
        datas.$creator   = entity.$creator;
        datas.mimeType   = entity.mimeType;
        datas.uri        = entity.uri;
      }
      entity.update(datas);
      if(!result.storageId){
        this._threatEntityQueue(
          entity,
          buffer,
          mimeType
        );
      }
    });
  }
}