import { Key, ModelBuilder }      from "@uLib/model";

const notificationModel = (application) => {
  const connector = {
    find(query, sort, offset, limit){
      //On autorise qu'un filtrage sur le champ "type" en mode "$in"
      //On autorise que le trie sur les champs "_id" et "createdAt"
      //Le paginateur de notifications a besoin de récupérer les objets ayant un discriminant (discriminator)
      // mais ils ne sont pas compatible avec l'api du serveur
      // Voir: web/admin/src/universal/services/businessNotification.ts      
      if(query?.$or?.length && Object.keys(query.$or[0]).filter(p => p !== 'type' || !query.$or[0][p].$in?.length).length) {
        throw new Error('Not possible');
      }
      
      if(query?.$or?.length){
        query = query.$or[0];
      }

      if(sort && Object.keys(sort).filter(p => !['_id', 'createdAt'].includes(p)).length){
        throw new Error('Not possible');
      }
      return application.getService("api").service('notifications', "get").execute(query, offset, limit);
    },
    count(){ throw new Error('Not possible'); },
    findOne(){ throw new Error('Not possible') },
    create(){ throw new Error('Not possible') },
    update(){ throw new Error('Not possible') },
    partial(){ throw new Error('Not possible') },
    remove(){ throw new Error('Not possible') }
  };
  return ModelBuilder.create("Notification", Key.create("_id"), connector,  {})
    .add(p => p("_id", "string"))
    .add(p => p("discriminator", "string"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("issue", "entity").type("Issue"))
    .add(p => p("createdAt", "date"))
    .add(p => p("diff", "mixed"))
    .add(p => p("type", "string"))
    .add(p => p("message", "string"))
    .add(p => p("all", "bool"))
    .add(p => p("subject", "object")
      .add(p => p("id", "string"))
    )
    .build();
}

export default notificationModel;