import { Service }      from "@uLib/application";
import { de } from "date-fns/locale";
import { ReactElement } from "react";
import { v4 as uuid }   from "uuid";


export type MessageType = "info" | "success" | "warning" | "error";
export type PositionType = "top" | "bottom" | "left" | "right" | "center" | "top-left" | "top-right" | "bottom-left" | "bottom-right";

type MessageContent = string | ReactElement | (() => ReactElement) | (() => string);

export type Message = {
  id: string;
  type: MessageType;
  content: MessageContent;
  delay: number;
  position: PositionType;
  close: () => void;
}

type TechnicMessage = Message & {
  timeoutId: number | null;
}

class MessageService extends Service {
  private _messages: TechnicMessage[];

  constructor() {
    super("message");
    this._messages = [];
  }

  get messages(): Message[]{
    return this._messages.map(({ id, type, content, delay, position, close }) => ({ id, type, content, delay, position, close }));
  }

  close(id: string){
    const indexMessage = this._messages.findIndex(message => message.id === id);
    if(indexMessage === -1) {
      return;
    }

    const completeMessage = this._messages[indexMessage];
    if(completeMessage.timeoutId !== null){
      window.clearTimeout(completeMessage.timeoutId);
    }

    this._messages.splice(indexMessage, 1);

    this.triggerUpdate();
  }

  send(type: MessageType, content: MessageContent, delay: number = 10000, position: PositionType = "bottom"){
    let id: string = uuid();

    const completeMessage: TechnicMessage = { 
      id,
      type,
      content,
      delay: type !== "error" ? delay : 0,
      position,
      close: () => {
        this.close(id);
      },
      timeoutId: null,
    };

    this._messages.push(completeMessage);

    if(completeMessage.delay){
      completeMessage.timeoutId = window.setTimeout(() => {
        this.close(id);
      }, delay);
    }

    this.triggerUpdate();

    return () => { this.close(id) }
  }
}

export default MessageService;