import React from 'react';
import Navigator from '../types/Navigator';
import Event from '../../../lib/event';

const RouterContext = React.createContext<{ current: string, navigator: Navigator }>({ 
  current: "",
  navigator: { 
    current: "",
    state: {},
    goTo: () => { throw new Error('Router not defined') },
    goBack: () => { throw new Error('Router not defined') },
    onChange: new Event()
  }
});

export default RouterContext;