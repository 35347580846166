
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultMapLayerValue = {
    type: "xyz",
    transparent: true
  }

  return ModelBuilder.create("MapLayer", Key.create("_id"), stdRepositoryConnector(application, "mapLayers"), defaultMapLayerValue)
    .add(p => p("_id", "string"))
    .add(p => p("name", "string"))
    .add(p => p("type", "string"))
    .add(p => p("serviceUrl", "string"))
    .add(p => p("iconUrl", "string"))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("transparent", "bool"))
    .build();
}