
class Geometry {
    static buildFromGeoJSON(geoJSON){
        if(Array.isArray(geoJSON)){
            return new Collection(geoJSON.map(g => Geometry.buildFromGeoJSON(g)));
        }
        switch(geoJSON.type){
            case "Point":
                return new Point(geoJSON.coordinates[0], geoJSON.coordinates[1]);
            case "Polygon":
                return new Polygon(geoJSON.coordinates[0].map(coord => new Point(coord[0], coord[1])));
            case "GeometryCollection":
                return new Collection(geoJSON.geometries.map(geometry => Geometry.buildFromGeoJSON(geometry)));
            case "FeatureCollection":
                return new Collection(geoJSON.features.map(feature => Geometry.buildFromGeoJSON(feature.geometry)));
            default: 
            throw new Error("Unknow type");
        }
    }
    
    get barycentre(){
        throw new Error("Must be override");
    }

    toGeoJSON() {
        throw new Error("Must be override");
    }

    clone(){
        throw new Error("Must be override");
    }

    toJSON(){
        return this.toGeoJSON();
    }
}

class Point extends Geometry{
    constructor(longitude, latitude){
        super();
        this._longitude = longitude;
        this._latitude = latitude;
    }
    get longitude(){
        return this._longitude;
    }
    get latitude(){
        return this._latitude;
    }
    get barycentre(){
        return this.clone();
    }

    toGeoJSON() {
        return {
            type:"Point",
            coordinates: this.toArray()
        }
    }

    toArray(){
        return [
            this.longitude,
            this.latitude
        ];
    }

    distance(point){
        return Math.sqrt(Math.pow(this.longitude - point.longitude, 2) + Math.pow(this.latitude - point.latitude, 2));
    }
    
    clone(){
        return new Point(this.longitude, this.latitude);
    }
}

class Polygon extends Geometry{
    constructor(points = []){
        super();
        this._points = points.map(point => point.barycentre);
    }
    add(point){
        this._points.push(point);
    }

    get barycentre(){
        const sum = this._points.reduce((sum, p) => {
            sum.lng = p.longitude;
            sum.lat = p.latitude;
            return sum;
        }, { lng: 0, lat: 0 });
        return new Point(sum.lng / this._points.length, sum.lat / this._points.length);
    }

    toGeoJSON(){
        return {
            type: "Polygon",
            coordinates: this._points.map(point => point.toArray())
        };
    }

    clone(){
        return new Polygon(this._points.slice());
    }
}

class Collection extends Geometry {
    constructor(geometries = []){
        super();
        this._geometries = geometries;
    }

    add(geometry){
        this._geometries.push(geometry);
    }

    get geometries(){
      return this._geometries;
    }

    get barycentre(){
        const sum = this._geometries.reduce((sum, g) => {
            const p = g.barycentre;
            sum.lng = p.longitude;
            sum.lat = p.latitude;
            return sum;
        }, { lng: 0, lat: 0 });
        return new Point(sum.lng / this._geometries.length, sum.lat / this._geometries.length);
    }

    toGeoJSON(){
        return {
            type: "GeometryCollection",
            geometries: this.geometries.map(g => g.toGeoJSON())
        };
    }
}

Geometry.Point              = Point;
Geometry.Polygon            = Polygon;
Geometry.Collection         = Collection;

export default Geometry;