import { Service }  from '@uLib/application';
import Event        from '@uLib/event';

export default class Networking extends Service {
  constructor() {
    super('networking');
    this._onConnect     = new Event();
    this._onDisconnect  = new Event();
    this._onStateChange = new Event();
  }
  start() {
    
  }

  get onConnect() {
    return this._onConnect;
  }

  get onDisconnect() {
    return this._onDisconnect;
  }

  get onStateChange() {
    return this._onStateChange;
  }

  isConnected() {
    return true;
  }
}