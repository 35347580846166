import React                            from 'react';
import Input                            from "@cComponents/old/input/input";
import Map                              from '@cComponents/map';
import Application                      from "@uBehaviour/application";


export default class InputMap extends Input {
  constructor(props) {
    super(props);
    this._onMapClick                    = this._onMapClick.bind(this);
    this._centerPoint_onDragEnd         = this._centerPoint_onDragEnd.bind(this);
    this._bounding_onDragEndOrOnZoomEnd = this._bounding_onDragEndOrOnZoomEnd.bind(this);
    this._updateDefaultZoom = this._updateDefaultZoom.bind(this);
  }
  
  getErrors(){
    if (this.props.required && !this._getShapes().length){
      return [{
        path:this.props.name,
        error: "required_input"
      }];
    }
    return [];
  }

  get type(){
    return this.props.type 
      ? this.props.type
      : "unique";
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
  }

  _onMapClick(map, point) {
    this._addOrSet(point);
    this._geolocation.updateLastUse(point, map.zoom);
  }

  _centerPoint_onDragEnd(map){
    this._set(map.center);
  }

  _bounding_onDragEndOrOnZoomEnd(map){
    this._setBounding(map.center, map.zoom);
    this._geolocation.updateLastUse(map.center, map.zoom);
  }

  _updateDefaultZoom(map){
    this._geolocation.updateLastUse(map.center, map.zoom);
  }

  _addOrSet(point){
    if(this.props.type === "multi"){
      this._value.push(point);
      this._triggerChange();
    } else {
      this._set(point);
    }
  }

  _set(point){
    this._value = point;
    this._triggerChange();
  }

  _setBounding(center, zoom){
    this._value = {
      position: center,
      zoomLevel: zoom
    };
    this._triggerChange();
  }

  _getZoom(geolocation){
    if(this.props.type === "mapposition" && this._value && this._value.zoomLevel){
      return this._value && this._value.zoomLevel;
    }
    return geolocation.defaultZoom;
  }

  _getCenter(geolocation){
    if(this.props.type === "mapposition" && this._value && this._value.position){
      return this._value && this._value.position;
    }
    return geolocation.current;
  }

  _getShapes(){
    let points = []
    if(this.type === "mutli"){
      points = this._value;
    } else if(this.type === "unique" && this._value){
      points = [this._value];
    }
    return points.map(point => React.createElement(
      Map.Shape, {
        definition: point,
        options: {
          icon: this.props.icon
        }
      }
    ));
  }

  __render() {
    return React.createElement(Application.Service, { name: ["geolocation"] }, ([geolocation]) => {
      this._geolocation = geolocation;
      let values = this._value ? this._value : [];
      if(!Array.isArray(values)){
        values = [values];
      }
      const mapProps = {
        default: this._getCenter(geolocation),
        zoom: this._getZoom(geolocation),
        zoomOnScroll: this.props.zoomOnScroll
      };

      const type = this.props.type
        ? this.props.type
        : "unique";
        
      switch(type){
        case "unique":
          if(this.props.select === "center"){
            mapProps.onDragEnd  = this._centerPoint_onDragEnd;
          } else {
            mapProps.onClick    = this._onMapClick;
          }
          mapProps.onZoomChanged = this._updateDefaultZoom;
          mapProps.cursor = "pointer";
        break;
        case "mutli":
          mapProps.onClick       = this._onMapClick;
          mapProps.onZoomChanged = this._updateDefaultZoom;
        break;
        case "mapposition":
          mapProps.gestureHandling  = "cooperative";
          mapProps.onDragEnd        = this._bounding_onDragEndOrOnZoomEnd;
          mapProps.onZoomChanged    = this._bounding_onDragEndOrOnZoomEnd;
        break;
        default:
          console.log("Type must be defined")
      }
      return React.createElement(
        Map, 
        mapProps,
        this._getShapes()
      );
    });
  }
}