import React, { useContext } from 'react';
import Path from '../types/Path';
import RouteContext from '../context/route';

type UseRouteType = (path: Path) => Path;

const useRoute: UseRouteType = (path) => {
  const stringBuilder = useContext(RouteContext);
  return React.useMemo(() => stringBuilder.add(path).build(), [stringBuilder]);
}

export default useRoute;