import Title from "./title";
import Header from "./header";
import Footer from "./footer";
import Section from "./section";
import Content from "./content";

export {
  Title,
  Header,
  Footer,
  Section,
  Content
};

export default {
  Title,
  Header,
  Footer,
  Section,
  Content
};