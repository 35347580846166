import Model    from "./model";
import Observer from "./observer";
import Simple   from "./simple";

export default {

  /**
   * @deprecated
   */
    Model,

    /**
     * @deprecated
     */
    Observer,

    /**
     * @deprecated
     */
    Simple
}