import Event      from './event';
import md5        from "md5";
import Criterion  from './filter';
import Query      from "@uLib/query";
import _          from 'lodash';

const compare = (val1, val2) => {
  if(val1 === val2){
    return 0;
  }
  if(val1 === null || val1 < val2){
    return -1;
  }
  return 1;
}

class Pager {
  static getId(model, query, sort, load) {
    return `Pager::${model.name}#${md5("query=" + JSON.stringify(query) + "&sort=" + JSON.stringify(sort) + "&load=" + JSON.stringify(load))}`;
  }
  constructor(model, query, sort, load) {
    this._model              = model;
    this._query              = query;
    this._keySort            = sort;
    this._sort               = JSON.parse(JSON.stringify(sort));
    if (!this._sort._id) {
      this._sort._id = 1;
    }
    this._load               = load;

    this._stateChange        = new Event();
    this._filters            = Criterion.factory(this._model, Query.getQueryIfOptimized(this._query ? this._query : {}));
    this._waitLoadingPromise = Promise.resolve(true);

    this._firstSearch        = true;
    this._loading            = false;
    this._finishLoading      = false;

    this._entities           = [];
    this._toLoadJoin         = [];
    this._loadJoin           = _.debounce(this._loadJoin.bind(this), 0);
    this._onRemoveTrigger    = _.debounce(this._onRemoveTrigger.bind(this), 0);
    this.debugPager          = false;
  }
  informChange(model, entity, change) {
    if(this.debugPager){
      debugger;
    }
    if (model === this._model) {
      switch (change) {
        case "create":
          if (this._isIncludedInLoadedSet(entity)) {
            this._add(entity);
          }
          break;
        case "remove":
          if (this._has(entity)) {
            this._remove(entity);
          }
          break;
        case "update":
          if (this._has(entity)) {
            if (this._isIncludedInLoadedSet(entity)) {
              this._update(entity);
            } else {
              this._remove(entity);
            }
          } else if (this._isIncludedInLoadedSet(entity)) {
            this._add(entity);
          }
          break;
        default:
          break;
      }
    }
  }
  _isIncludedInLoadedSet(entity) {
    if(entity.toPlainText){
      entity = entity.toPlainText();
    }
    return this._isIncludedInSetDefinition(entity) && this._isIncludedInCurrentLimitOfSet(entity);
  }
  _isIncludedInCurrentLimitOfSet(entity) {
    return this._finishLoading || this._compare(entity, this._entities[this._entities.length - 1]) <= 0;
  }
  _isIncludedInSetDefinition(entity) {
    return this._filters.match(entity, this._model)
  }
  _has(entity) {
    const hash = this._model.key.hash(entity);
    return this._entities.findIndex(e => this._model.key.hash(e) === hash) !== -1;
  }
  get(entity){

  }
  _loadJoin(){
    const loadJoin    = this._toLoadJoin;
    this._toLoadJoin  = [];
    if(!loadJoin.length){
      this._sortCollection();
    }else{
      this._model.repository.updateDependancies(loadJoin, this._load)
        .then(() => {        
          this._sortCollection();
        });
    }
  }
  _addLoadJoin(entity){
    const hash  = this._model.key.hash(entity);
    if(this._toLoadJoin.findIndex(e => this._model.key.hash(e) === hash) === -1){
      this._toLoadJoin.push(entity);
      this._loadJoin();
    }
  }
  _add(entity) {
    this._model.entityManager.register(entity);
    this._entities.push(entity);
    this._addLoadJoin(entity);
  }
  _update(entity){
    const hash  = this._model.key.hash(entity);
    this._addLoadJoin(this._entities.find(e => this._model.key.hash(e) === hash));
  }
  _onRemoveTrigger(){
    this._stateChange.trigger("entity-set-updated");
    this._stateChange.trigger("entity-removed");
  }
  _remove(entity) {
    const hash  = this._model.key.hash(entity);
    const idx   = this._entities.findIndex(e => this._model.key.hash(e) === hash);
    if (idx !== -1) {
      this._entities.splice(idx, 1);
      entity.dispose();
      this._onRemoveTrigger();
    }
  }
  _sortCollection() {
    this._entities = this._entities.sort(this._compare.bind(this));
    this._stateChange.trigger("entity-set-updated");
    this._stateChange.trigger("entities-sorted");
  }
  _compare(entity1, entity2) {
    const extract = (value, path) => path.split('.').reduce((value, prop) => value ? value[prop] : null, value);
    return Object.keys(this._sort).reduce((cmp, key) => {
      if (cmp) 
        return cmp;
      
      const val1 = extract(entity1, key);
      const val2 = extract(entity2, key);
      return this._sort[key] * compare(val1, val2);
    }, 0);
  }
  get id() {
    return Pager.getId(this._model, this._query, this._keySort, this._load);
  }
  get query(){
    return this._query;
  }
  get model(){
    return this._model;
  }
  get stateChange() {
    return this._stateChange;
  }
  get loaded(){
    return this._entities.slice();
  }
  get loadedLength() {
    return this._entities.length;
  }
  get loading() {
    return this._loading;
  }
  getLoadedByIndex(index){
    if(index >= this.loadedLength){
      throw new Error("Index out of bounds");
    }
    return this._entities[index];
  }
  hasLoaded() {
    return !this.isFinishedLoading() && !!this._entities.length;
  }
  isFinishedLoading() {
    return this._finishLoading;
  }
  _destroy() {
    this._entities.forEach(entity => {
      entity.dispose();
    });
  }
  next(pageSize = 10) {
    if (this.isFinishedLoading() || this._loading) 
      return this._waitLoadingPromise;
    
    this._loading            = true;
    this._stateChange.trigger("loading");
    this._waitLoadingPromise = this._waitLoadingPromise.then(() => this._model.repository.find(this._query, this._sort, this._entities.length, pageSize, this._load)).then(results => {
      let ret       = true;
      if (results.length !== pageSize) {
        this._finishLoading = true;
        ret                 = false;
      }
      if (results.length || this._firstSearch) {
        this._firstSearch = false;
        this._entities    = this._entities.concat(results);
        this._stateChange.trigger("entity-set-updated");
      }
      this._loading = false;
      this._stateChange.trigger("loaded", results);
      return ret;
    }, (err) => { 
      console.error(err);
      return false;
    });
    return this._waitLoadingPromise;
  }
  forEachLoaded(handler) {
    this._entities.forEach(handler);
  }
  mapLoaded(handler) {
    return this._entities.map(handler);
  }
  clear() {
    this._waitLoadingPromise = this._waitLoadingPromise.then(() => {
      this._firstSearch   = true;
      this._loading       = false;
      this._finishLoading = false;
      this.mapLoaded((entity) => {
        entity.dispose();
      });
      this._entities      = [];
      this._stateChange.trigger("entity-set-updated");
    });
    return this._waitLoadingPromise;
  }
  reload(pageSize) {
    return this.clear().then(() => this.next(pageSize));
  }
}
export default Pager;