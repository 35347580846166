import Address                    from "./address";
import Radio                      from './radio';
import Text                       from './text';
import Textarea                   from './textarea';
import Password                   from './password';
import Number                     from './number';
import Email                      from './email'; 
import Url                        from './url'; 
import Object                     from "./object";
import Checkbox                   from './checkbox';
import Array                      from "./array";
import Hidden                     from "./hidden";
import Select                     from './select';
import Autocomplete               from "./autocomplete";
import AutocompleteModel          from "./autocompleteModel";
import Selectable                 from "./selectable";
import Boolean                    from "./boolean";
import Map                        from "./map";
import File                       from "./file";
import Location                   from "./location";
import NewToOld                   from "./newToOld";

Autocomplete.Model = AutocompleteModel;

export default {
  Address,
  Autocomplete,
  Radio,
  Text,
  Textarea,
  Password,
  Number,
  Email,
  Url,
  Object,
  Checkbox,
  Array,
  Hidden,
  Select,
  Selectable,
  Boolean,
  Map,
  File,
  Location,
  NewToOld
}