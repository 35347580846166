import React          from "react";
import Input          from "./inputBase";
import Data           from "@uBehaviour/data";
import DisplayFile    from '@cComponents/file';
import Application    from "@uBehaviour/application";
import FileCollection from '@cLib/fileCollection';

import "./file.css";

class FileDropArea extends React.Component {
  constructor(props) {
    super(props);
    this._preventDefault = this._preventDefault.bind(this);
    this._onDrop = this._onDrop.bind(this);
    this._droparea = React.createRef();
  }
  _preventDefault(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }
  _addFiles(files){
    if(files.length){
      this.props.onFiles(files);
    }
  }
  _onDrop(ev) {
    if (ev.dataTransfer && ev.dataTransfer.files) {
      this._addFiles(ev.dataTransfer.files);
    }
  }
  _onChange(ev) {
    if (ev.currentTarget.files) {
      this._addFiles(ev.currentTarget.files);
    }
  }
  componentDidMount() {
    this._droparea.current.addEventListener("dragenter", this._preventDefault, false);
    this._droparea.current.addEventListener("dragleave", this._preventDefault, false);
    this._droparea.current.addEventListener("dragover", this._preventDefault, false);
    this._droparea.current.addEventListener("drop", this._preventDefault, false);
    this._droparea.current.addEventListener("drop", this._onDrop, false);
  }
  render() {
    return (
      <label>
        <div className="bs-old-input-old-file-thumb bs-old-input-old-file-add" ref={this._droparea} style={this.props.style}>
          <form>
            <input type="file" onChange={(ev) => this._onChange(ev)} accept={ this.props.acceptedMimeType } />
            <i className="fa fa-file-image-o"></i>
            <i className="fa fa-file-pdf-o"></i>
          </form>
        </div>
      </label>
    )
  }
}

class File extends Input {
  constructor(props) {
    super(props);
    this._onFiles = this._onFiles.bind(this);
    this._newFiles = {};
  }
  get value() {
    return this._value;
  }
  _onFiles(files) {
    this.props.fileService.add(new FileCollection(files))
      .then(files => {
        files.forEach(file => {
          const listener = {
            handleEvent: () => {
              file.removeListener(listener);
              this._newFiles[file._id] = file;
              this._value.push(file._id);
              this._triggerChange();
            }
          };
          file.addListener(listener);
        });
      }, error => {
        this.props.message.send("error", error.message);
      });
  }
  _remove(id) {
    if (this._newFiles[id]) {
      delete this._newFiles[id];
    }
    this._value = this._value.filter(i => i !== id);
    this._triggerChange();
  }
  _render() {
    let ret = [];
    const height = this.props.height ? this.props.height : 120;
    const width = this.props.width ? this.props.width : 120;
    const style = {
      height: height,
      width: width
    };
    if (this._value && this._value.length) {
      ret.push(React.createElement(Data.List, {
        key: "first_file",
        model: "File",
        query: { _id: { $in: this._value.filter(v => !this._newFiles[v]) } }
      }, (file => (
        <div key={file._id} className="bs-old-input-old-file-thumb" style={style}>
          <div className="bs-old-input-old-file-remove" onClick={(e) => { e.preventDefault(); this._remove(file._id); }}><span className="fa fa-times" /></div>
          <DisplayFile file={file} height={height} width={width} fit={this.props.fit} openable/>
        </div>
      ))));
    }
    ret = ret.concat(this._value.filter(v => this._newFiles[v]).map(fileId => {
      const file = this._newFiles[fileId];
      return (
        <Data.Synchronizer key={fileId} entity={file}>
          { file => (
            <div className="bs-old-input-old-file-thumb" style={style}>
              <div className="bs-old-input-old-file-remove" onClick={(e) => { e.preventDefault(); this._remove(file._id); }}><span className="fa fa-times" /></div>
              <DisplayFile file={file} height={height} width={width} fit={this.props.fit} />
            </div>
          )}
        </Data.Synchronizer>
      );
    }));
    if (!this.props.limit || this._value.length < this.props.limit) {
      ret.push(React.createElement(FileDropArea, { 
        key: "drop-area",
        onFiles: (files => this._onFiles(files)),
        style: style,
        acceptedMimeType: this.props.fileService.uploadableMimeTypes.join(", ")
      }));
    }
    return React.createElement("div", { className: "bs-old-input-file" }, ret)
  }
}
export default Application.Service.forward([["file", "fileService"], "message"], File);