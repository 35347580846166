import { Navigator, Path } from '@uFeatures/router';
import Event from '@uLib/event';

class WebNavigator implements Navigator {
  private _onChange: Event;
  private _history: History;
  private counter: number;
  constructor(){
    this._onChange = new Event();
    this._history = window.history;
    this.counter = 0;
  }
  _triggerChange = () => {
    this._onChange.trigger(this);
  }
  initialize(){
    window.addEventListener('popstate', this._triggerChange);
  }
  dipose(){
    window.removeEventListener('popstate', this._triggerChange);
  }
  get current(): string { 
    return window.location.pathname;
  }
  get state(): Object {
    return this._history.state;
  }
  get onChange(): Event {
    return this._onChange;
  }
  goTo(location: Path, state: Object = {}, keepExistinginHistory: boolean = true): void {
    if(keepExistinginHistory){
      this._history.pushState(state, "", location);
    }
    this._history.replaceState(state, "", location);
    ++this.counter;
    this._triggerChange();
  }
  goBack(): void {
    if(this.counter > 0){
      this._history.back();
      --this.counter;
    }else{
      this.goTo(this.current.split('/').slice(0, -1).join('/'), this.state, false);
    }
  }  
}

export default WebNavigator;