import { Service } from "@uLib/application";
export default class ConfigurationService extends Service{
  constructor(configuration){
    super("configuration");
    this._configuration = configuration;
  }
  has(name){
    return !!this._configuration[name];
  }
  get(name){
    if(!this.has(name)){
      throw new Error(`Unknow property "${name}"`);
    }
    return this._configuration[name];
  }
}