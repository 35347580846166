import React    from 'react';
import Slot     from '@cComponents/slot';
import Display  from '@cComponents/displayIf';

import './inline.css';

const getOptionalClassName = function(className){
  return className ? className : "";
};

export default class Inline extends React.Component {  
  static Header  = Slot();
  static Content = Slot();
  static Footer  = Slot();
  render(){
    const header  = Inline.Header.get(this);
    const content = Inline.Content.get(this);
    const footer  = Inline.Footer.get(this);    
    return (
      <div className={"bs-layout-inline-container " + getOptionalClassName(this.props.className)}>
        <Display.If condition={ header }>
        <div className={"bs-layout-inline-header " + getOptionalClassName(Inline.Header.props(this).className)}>{ header }</div>
        </Display.If>        
        <div className={"bs-layout-inline-content "+ getOptionalClassName(Inline.Content.props(this).className)}>{ content }</div>
        <Display.If condition={ footer }>
          <div className={"bs-layout-inline-footer " + getOptionalClassName(Inline.Footer.props(this).className)}>{ footer }</div>
        </Display.If>
      </div>
    );
  }
}