import React from "react";
const collectChildren = (componentChildren) => {
  if(!Array.isArray(componentChildren)){
    componentChildren = [componentChildren];
  }
  return componentChildren.reduce((children, child) => {
    if(Array.isArray(child)){
      child.forEach(child => children.push(child));
    }else{
      children.push(child);
    }
    return children;
  }, [])
}
export default () => {
    const fn = () => null;
    fn.props =(component, all = false, _default = undefined) => {
        if(!_default) _default = all ? [] : {};
        if(!component.props.children) return _default;
        const children = collectChildren(component.props.children);
        if(all){
            const childrenComponent = children.filter(child => child && child.type === fn);
            if(_default && ! childrenComponent.length){
              return _default;
            }
            return childrenComponent.map(childComponent => childComponent.props);
        }else{
            const childComponent = children.find(child => child && child.type === fn);
            return childComponent ? childComponent.props : _default;
        }
    }
    fn.get = (component, all = false, _default = null) => {
        const props = fn.props(component, all);
        if(all){
            const components = props.map(prop => prop.children).filter(c => !!c);
            if(_default && !components.length){
              return _default;
            }
            return components;
        }else{
            return props.children ? props.children : _default;
        }
    }
    fn.not = (component) => {
      const children = collectChildren(component.props.children);
        return children.filter(child => child.type !== fn);
    }
    return fn;
};