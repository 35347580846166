import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {

  const defaultEquipmentValue = {
    disabled: false,
    location:null
  }

  return ModelBuilder.create("Equipment", Key.create("_id"), stdRepositoryConnector(application, "equipments"), defaultEquipmentValue)
    .add(p => p("_id", "string"))
    .add(p => p("name", "string"))
    .add(p => p("disabled", "bool"))
    .add(p => p("type", "string"))
    .add(p => p("$hasValorization", "bool"))
    .add(p => p("valorization", "object")
      .add(p => p("type", "string"))
      .add(p => p("cost", "int"))
    )
    .add(p => p("location", "object")
      .add(p => p("address", "object")
        .add(p => p("streetNumber", "string"))
        .add(p => p("street", "string"))
        .add(p => p("locality", "string"))
        .add(p => p("zip", "string"))
      )
      .add(p => p("building", "entity").type("Building"))
      .add(p => p("position", "object")
        .add(p => p("type", "string"))
        .add(p => p("coordinates", "array").of(t => t("float")))
      )
    )
    .add(p => p("files", "array").of(t => t("entity").type("File")))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .build();
}