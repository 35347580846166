
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultCategoryValue = {
    type:"publicSpace",
    disabled: false,
    visibleByCitizen: true,
    publicByDefault: true,
    issuesValorizationMandatoryByDefault: false,
    label: {
      fr: "",
      en: "",
      nl: "",
      de: ""
    },
    redirectRule: {
      discriminator:"manager"
    },
    followers: [],
    tags: [],
    deadlineReminder: 15,
    deadlineReminderEmailAlert: false
  }

  return ModelBuilder.create("Category", Key.create("_id"), stdRepositoryConnector(application, "categories"), defaultCategoryValue)
      .add(p => p("_id", "string"))
      .add(p => p("disabled", "bool"))
      .add(p => p("issuesValorizationMandatoryByDefault", "bool"))
      .add(p => p("label", "object")
        .add(p => p("fr", "string"))
        .add(p => p("en", "string"))
        .add(p => p("nl", "string"))
        .add(p => p("de", "string"))
      )
      .add(p => p("key", "string"))
      .add(p => p("type", "string"))
      .add(p => p("visibleByCitizen", "bool"))
      .add(p => p("publicByDefault", "bool"))
      .add(p => p("backOffice", "bool"))    
      .add(p => p("redirectRule", "object")
        .add(p => p("discriminator", "string"))
        .add(p => p("mail", "string"))
        .add(p => p("manager", "entity").type("User"))
        .add(p => p("defaultTransfer", "entity").type("Contact"))
        .add(p => p("category", "entity").type("Category"))
        .add(p => p("categories", "array").of(t => t("entity").type("Category")))
      )
      .add(p => p("type", "string"))
      .add(p => p("tenant", "entity").type("Tenant"))
      .add(p => p("followers", "array").of(t => t("entity").type("User")))
      .add(p => p("tags", "array").of(t => t("string")))
      .add(p => p("createdAt", "date"))
      .add(p => p("createdBy", "entity").type("User"))
    .build(); 
}