// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-layout-standart-container {
  display:flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.bs-layout-standart-stretch>.bs-layout-standart-footer,
.bs-layout-standart-stretch>.bs-layout-standart-header{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}
.bs-layout-standart-stretch>.bs-layout-standart-content{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow-y: hidden;
}
`, "",{"version":3,"sources":["webpack://./../common/components/layout/standart.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;AACA;;EAEE,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".bs-layout-standart-container {\n  display:flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: hidden;\n}\n.bs-layout-standart-stretch>.bs-layout-standart-footer,\n.bs-layout-standart-stretch>.bs-layout-standart-header{\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n}\n.bs-layout-standart-stretch>.bs-layout-standart-content{\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: 0;\n  overflow-y: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
