import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from '@uLib/classNames';
import './content.css';

interface ContentProps {
  className?: string
}

type ContentType = FunctionComponent<PropsWithChildren<ContentProps>> & {

};

const Content: ContentType = ({ children, className }) => (
  <div className={ classNames('bs-section-content').addNotEmpty(className) }>
  { children }
  </div>
);

export default Content;