import { Service }        from "@uLib/application";
import jwt                from "jsonwebtoken";
import LZString           from "lz-string"


export default class JwtService extends Service {
  constructor() {
    super("jwt");
  }

  decode(token) {
    const uncompressedToken = /.*\..*\..*/.test(token) ? token : LZString.decompressFromEncodedURIComponent(token);
    return jwt.decode(uncompressedToken);
  }
}