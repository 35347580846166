class StringBuilder {
  private strings: string[];

  static create(): StringBuilder {
    return new StringBuilder();
  }

  constructor(strings: string[] = []) {
    this.strings = strings;
  }

  add(value: string): StringBuilder {
    return new StringBuilder(this.strings.concat([value]));
  }

  build(separator = ''): string {
    return this.strings.join(separator);
  }
}

export default StringBuilder;
