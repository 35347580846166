import React, { FunctionComponent, PropsWithChildren } from 'react';
import './timeline.css';
import 'font-awesome/css/font-awesome.min.css';

const Timeline: FunctionComponent<PropsWithChildren> = ({ children }) => { 
  return (
    <div className="timeline-component">
      <div className={ "timeline "  + (children.length % 2  ? "timeline-odd" : "timeline-even")}>
          {children.map((el, idx) => {
            return (
              <div className="timeline-row">
                <div className="timeline-icon"><div className="bg-primary"><i className={ "icon-plus-sign fa " + (idx < children.length - 1 ? "fa-repeat" : "fa-plus-circle")}></i></div></div>
                <div className="timeline-content">
                  {el}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  );
}

export default Timeline;