import React        from "react";
import InputBase    from "./inputBase";

import "./boolean.css";


export default class Boolean extends InputBase{
  constructor(props){
    super(props);
    this._input         = React.createRef();
    this._triggerChange = this._triggerChange.bind(this);
  }
  get value(){
    return !!this._input.current.checked;
  }
  componentDidMount(){
    super.componentDidMount();
    if(this._input.current){
      const value = this._value !== undefined ? this._value : this.props.default;
      this._input.current.checked = value;
    }
  }
  _render(){
    if(this._input.current){
      const value = this._value !== undefined ? this._value : this.props.default;
      this._input.current.checked = value;
    }
    return (
      <span className={`bs-old-input-boolean${this.props.className ? (" " + this.props.className) : ""}`}>
        <input type="checkbox" name={ this.props.name } ref={ this._input } onClick={ this._triggerChange }/>
        <span>{ this.props.children }</span>
      </span>
    )
  }
}