import React, { FunctionComponent, MouseEvent, PropsWithChildren } from 'react';

import { withLink, Path } from '@uFeatures/router';

interface LinkProps {
  to: Path;
  onClick: () => void;
  'data-testid'?: string;
}

const Link: FunctionComponent<PropsWithChildren<LinkProps>> = ({ to, onClick, 'data-testid': dataTestId, children }) => {
  const preventDefaultAndGoTo = React.useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    onClick();
  }, [onClick]);

  return (
    <a className='bs-link' href={ to } onClick={ preventDefaultAndGoTo } data-testid={ dataTestId }>
    { children }
    </a>
  );
}

const LinkedLink = withLink(Link);

export default LinkedLink;