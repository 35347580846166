import { Helper } from '@uLib/application';
import UrlBuilder from '@uLib/urlBuilder';
import moment     from 'moment';

export default class AssistanceHelper extends Helper {
  constructor() {
    super('assistance', ["session", "api", "configuration"]);
  }
  useJvsAssistance(){
    const currentTenantService = this.application.hasService("currentTenant")
      ? this.application.getService("currentTenant")
      : null;
    if(!currentTenantService){
      return false;
    }
    return currentTenantService.isSelected() && currentTenantService.isClient() && currentTenantService.current.clientIdJvs;
  };

  async getSupportUri(email){
    const sessionService = this.application.getService('session');
    const configuration = this.application.getService("configuration");
    const currentTenantService = this.application.hasService("currentTenant")
      ? this.application.getService("currentTenant")
      : null;

  
    if(this.useJvsAssistance()) {
      const token  = await this.application.getService("api").service("sessions", "getTokenAssistance").execute();
      const tenant = currentTenantService.current;

      return UrlBuilder.create("http://updateweb.jvsonline.fr/OL_WEB_ZEND")
        .addParam("IdKfc", "BES")
        .addParam("Name", sessionService.user.fullname)
        .addParam("eMail", email || sessionService.user.email)
        .addParam("IdKnote", tenant.clientIdJvs)
        .addParam("Name_Client", tenant.name.fr)
        .addParam("token", token)
        .build();
    }

    if(currentTenantService && sessionService.isLogged() && ["lu", "be"].includes(currentTenantService.current?.country)){
      return Promise.resolve(`mailto:${ configuration.get("supportEmailBenelux") }`);
    }

    return Promise.resolve(`mailto:${ configuration.get("supportEmailDefault") }`);
  };

  async open(email) {
    const url = await this.getSupportUri(email);

    window.open(url);
  }
}
