import React from 'react';
import Slot from './slot';

class If extends React.Component{
  render(){
    let _then = If.Then.get(this);
    let _else = If.Else.get(this);
    if(!_then){
        _then = this.props.children;
        _else = null;
    }
    return this.props.condition
        ? _then instanceof Function ? _then() : _then
        : _else instanceof Function ? _else() : _else;
  } 
};
If.Then = Slot();
If.Else = Slot();

class Switch extends React.Component{
    render(){
        const c = Switch.Case.props(this, true).find(c => c.condition);
        const d = Switch.Default.get(this);
        if(!c) return d instanceof Function ? d() : d;
        return c.children instanceof Function ? c.children() : c.children;
    }
}
Switch.Case     = Slot();
Switch.Default  = Slot();
export default {
    If: If,
    Then: If.Then,
    Else: If.Else,
    Switch: Switch,
    Case: Switch.Case,
    Default: Switch.Default
};