import React          from "react";
import ScrollBar      from './scrollBar';
import CError         from "./error";
import Display        from "./displayIf";

import "./formError.css";

export default (props) => {
  return (
    <div className="bs-form-error">
      <Display.If condition={ props.errors.length && props.position && props.position.includes("up") }>
        <CError errors={ props.errors }/>
      </Display.If>
      <ScrollBar>
        { props.children }     
      </ScrollBar>
      <Display.If condition={ props.errors.length && (!props.position || (props.position && props.position.includes("down"))) }>
        <CError errors={ props.errors }/>
      </Display.If>
    </div>
  )
}