import React        from "react";
import T            from "@cBehaviour/i18n"
import Collapsable  from "@cComponents/collapsable";
import Filter       from "./filter";

import "./bulk.css";

export default () => React.createElement(Filter.Context.Consumer, {}, context => {
  if(!context.aggregator.isReady()){
    return null;
  }
  const values = context.aggregator.values;
  if(!values.length){
    return null;
  }
  return (
    <div className="bs-filters-bulk">
    <Collapsable defaultOpen={true}>
      <Collapsable.Title>
        <div className="bs-filters-bulk-title">
          <span className="bs-filters-bulk-title-text"><T>filter_bulk_active_filter</T></span>
          <span className="fa fa-trash" onClick={ () => context.aggregator.clear() }/>
        </div>
      </Collapsable.Title>
      <Collapsable.Content>
        <div className="bs-filters-bulk-content">
          {
            values.map(v => (
              <div key={v.key()} className="bs-filters-bulk-content-item"><div>{ v.stringify() }</div><span className="fa fa-times bs-filters-bulk-content-item-drop" onClick={ v.drop } /></div>
            ))
          }
        </div>
      </Collapsable.Content>
    </Collapsable>
    </div>
  )
});