import React        from "react";

import "./standart.css";

export default function Standart(props) {
        return (
            <div className="bs-layout-file-standart">
                {props.children}
            </div>
        )
}