import Leaflet from 'leaflet';

import 'leaflet.vectorgrid';
import 'leaflet.gridlayer.googlemutant';
import '@geoman-io/leaflet-geoman-free';
import 'leaflet.markercluster';
import 'leaflet-fullscreen';


import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
export * from 'leaflet';

Leaflet.Icon.Default.imagePath = '/images/';
Leaflet.Icon.Default.prototype.options.iconUrl = 'leaflet/marker-icon.png';
Leaflet.Icon.Default.prototype.options.iconRetinaUrl = 'leaflet/marker-icon-2x.png';
Leaflet.Icon.Default.prototype.options.shadowUrl = 'leaflet/marker-shadow.png';

export default Leaflet;