import React, { FunctionComponent } from 'react';

type MetaProps = {
  property: string;
  content: string;
};

const Meta: FunctionComponent<MetaProps> = ({ property, content }) => {

  React.useEffect(() => {
    let value = null;
    let meta = document.head.querySelector(`meta[property="${property}"]`);

    if(!meta) {
      meta = document.createElement('meta');
      meta.setAttribute('property', property);
      document.head.appendChild(meta);
    } else {
      value = meta.getAttribute('content');
    }
    meta.setAttribute('content', content);

    return () => {
      if(value) {
        meta.setAttribute('content', value);
      } else {
        document.head.removeChild(meta);
      }
    };
  }, [property, content]);
  
  return  null;
};

export default Meta;