import React                  from 'react';
import Text                   from "./text";
import Location               from "@cLib/location";
import Application            from "@uBehaviour/application";


class Address extends React.Component {
  constructor(props) {
    super(props);
    this._input           = React.createRef();
    this._onPlaceSelected = this._onPlaceSelected.bind(this);
    this._onInputChange   = this._onInputChange.bind(this);
  }
  _onPlaceSelected(place) {
    const addressObject = this._autocomplete.getPlace();
    const value = this.props.google.getAddressObjectFromGMPlace(addressObject);
    if(this.props.onChange){
      this.props.onChange(value);
    }
    if(this.props.onCoordinateChange){
      this.props.onCoordinateChange({
        type: "Point",
        coordinates: [addressObject.geometry.location.lng(), addressObject.geometry.location.lat()]
      });
    }
  }

  _onInputChange(value) {
    if(this.props.onInputTextChange){
      this.props.onInputTextChange(value);
    }
  }
  componentDidMount() {
    this._autocomplete = new this.props.google.lib.maps.places.Autocomplete(
      this._input.current.input,
      {
        types: ['geocode'],
        fields: ["formatted_address", "address_components", "geometry.location"],
        componentRestrictions: { country: this.props.country }
      }
    )
    this._autocomplete.addListener("place_changed", this._onPlaceSelected);
  }

  render() {    
    const value = this.props.value
        ? Location.getLabelFromAddress(this.props.value)
        : "";    
    return <Text value={ value } ref={ this._input } onChange={ this._onInputChange } />;
  }
}
export default Application.Service.forward([["google-map", "google"]], Address);