import React    from 'react';
import ReactDOM from "react-dom";

export default class InnerTextContent extends React.Component {
  componentDidMount() {
    this.props.onReady(this._d.innerText)
  }
  componentDidUpdate() {
    this.props.onReady(this._d.innerText)
  }
  render() {
    this._d = document.createElement("div");
    return ReactDOM.createPortal(
      this.props.children,
      this._d
    );
  }
}