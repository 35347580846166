// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-layout-inline-container {
  display:flex;
  flex-direction: row;
  overflow: hidden;
}

.bs-layout-inline-content {
  flex-basis: auto;
  flex-grow: 2;
  flex-shrink: 2;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./../common/components/layout/inline.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".bs-layout-inline-container {\n  display:flex;\n  flex-direction: row;\n  overflow: hidden;\n}\n\n.bs-layout-inline-content {\n  flex-basis: auto;\n  flex-grow: 2;\n  flex-shrink: 2;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
