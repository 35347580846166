import Application, { Helper } from '../../lib/application';
import React from 'react';
import { ApplicationContext } from '../application';

function useHelper<T extends Helper>(helperName: string): T {
  const application = React.useContext(ApplicationContext);
  if(!application){
    throw new Error("Unknown Application");
  }
  return React.useMemo(() => (application as Application).getHelper(helperName), [helperName]) as T;
};

export default useHelper;