import React        from 'react';
import InputBase    from './inputBase';
import ChildToProps from "@cBehaviour/childToProps";

export default class Text extends InputBase {
  constructor(props){
    super(props);
    this._input = React.createRef();
    this._pos   = null;
  }
  getErrors() {
    const errors = super.getErrors();
    
    return errors;
  }

  componentDidUpdate(){
    if(this._pos){
      this._input.current.setSelectionRange(this._pos.start, this._pos.end);
      this._pos = null;
    }
  }

  _render() {
    const iProps = {
      ref: this._input,
      type: "text",
      className: "bs-old-input-text bs-old-input-text-like",
      onChange: () => {
        this._pos = { start: this._input.current.selectionStart, end: this._input.current.selectionEnd };
        this._value = this._input.current.value;
        if(this.props.maxLength){
          this._value = this._value.substring(0, this.props.maxLength);
        }
        this._triggerChange(); 
      },
      value: this._value ? this._value : "",
      placeholder: this.props.children,
      "data-testid": this.props["data-testid"],
    };
    if(!this.props.autocomplete){
      iProps.autoComplete = "off";
    }
    return React.createElement("input", iProps);      
  }
}