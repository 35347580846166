import React                  from 'react';
import md5                    from "md5";
import Autocomplete           from "react-google-autocomplete";
import InputBase              from "./inputBase";
import Location               from "@cLib/location";
import Application            from "@uBehaviour/application";

import "./address.css";


class Address extends InputBase {
  constructor(props) {
    super(props);
    this._onPlaceSelected = this._onPlaceSelected.bind(this);
    this._onInputChange   = this._onInputChange.bind(this);
    this.state            = { inputValue: null };
  }
  get hash(){
    return md5(JSON.stringify(this._value ? this._value : null));
  }
  _onPlaceSelected(place) {
    this._value           = this.props.googleMapService.getAddressObjectFromGMPlace(place);
    this.state.inputValue = Location.getLabelFromAddress(this._value);
    this._triggerChange();
  }

  _onAddressChange(){
    if(this._value){
      this.state.inputValue = Location.getLabelFromAddress(this._value);
    }
  }

  _onInputChange() {
    this.setState({
      inputValue: this._input.refs.input.value
    });
  }

  _render() {
    if(this._lasthash !== this.hash){
      this._onAddressChange();
    }
    this._lasthash = this.hash;
    
    const value = this.state.inputValue !== undefined && this.state.inputValue !== null
      ? this.state.inputValue
      : this._value
        ? Location.getLabelFromAddress(this._value)
        : "";
        
    return React.createElement(Autocomplete, {
      ref: ref => this._input = ref,
      className: "bs-old-input-address",
      onPlaceSelected: this._onPlaceSelected,
      placeholder: this.props.placeholder,
      types: ['address'],
      type: "text",
      value: value,
      onChange: this._onInputChange,
      componentRestrictions: {
        country: this.props.country
      }
    });   
  }
}
export default React.forwardRef((props, ref) => React.createElement(Application.Service, { name: "google-map" }, googleMapService => 
  React.createElement(Address, Object.assign({}, props, { ref: ref, googleMapService: googleMapService }), props.children)
));