import Event, { Listener }  from "@uLib/event";
import Logger               from "@uLib/logger";
import _                    from "lodash";


class Container<Type> {
  private _type: string;
  private _objects: Record<string, { object: Type, alias: boolean }>;

  constructor(type: string){
    this._type    = type;
    this._objects = {};
  }

  get objects(): { object: Type, alias: boolean }[]{
    return Object.values(this._objects);
  }

  has(name: string){
    return !!this._objects[name];
  }

  get<T = Type>(name: string): T{
    if(!this.has(name)){
      throw new Error(`Unknow ${this._type} "${name}"`);
    }
    return this._objects[name].object as unknown as T;
  }

  set<T extends Type>(name: string, service: T){
    if(this.has(name)){
      throw new Error(`${this._type} "${name}" already defined`);
    }
    this._objects[name] = { object: service, alias: false };
  }

  remove(name: string){
    if(!this.has(name)){
      throw new Error(`Unknow ${this._type} "${name}"`);
    }
    delete this._objects[name];
  }

  isAlias(name: string): boolean {
    if(!this.has(name)){
      throw new Error(`Unknow ${this._type} "${name}"`);
    }
    return this._objects[name].alias;
  }

  setAlias(alias: string, name: string){
    if(this.has(alias)){
      throw new Error(`${this._type} "${alias}" already defined`);
    }
    if(!this.has(name)){
      throw new Error(`Unknow ${this._type} "${name}"`);
    }
    this._objects[alias] = {
      object: this._objects[name].object,
      alias: true
    };
  }
}


class ServicesContainer extends Container<Service> {
  constructor(){
    super("service");
  }

  get services(): Service[]{
    return this.objects.map(({ object }) => object);
  }

  override<T extends Service = Service, RT extends Service = Service>(serviceName: string, service: T): RT{
    const serviceOverriden = this.get(serviceName);
    this.remove(serviceName);
    this.set(serviceName, service);
    return serviceOverriden as RT;
  }

  isServicesReady(): boolean {
    return this.services.reduce<boolean>((ready: boolean, service: Service) => ready && service.ready, true);
  }

  log(): Record<string, { name: string, ready: boolean }>{
    return this.services.reduce<Record<string, { name: string, ready: boolean }>>((acc, service) => {
      acc[service.name] = {
        name: service.name,
        ready: service.ready
      };
      return acc;
    }, {});
  }
}


class HelpersContainer extends Container<Helper> {

  constructor(){
    super("helper");
  }

  get helpers(): Helper[]{
    return this.objects.map(({ object }) => object);
  }

  log(): Record<string, { name: string }>{
    return this.helpers.reduce<Record<string, { name: string }>>((acc, helper) => {
      acc[helper.name] = {
        name: helper.name
      };
      return acc;
    }, {});
  }
}


class Service {
  private _name: string;
  private _dependencies: string[];
  private _application: Application | null;
  private _ready: boolean;
  private _serviceUpdatedEvent: Event;
  private _serviceReadyEvent: Event;
  private _updated: boolean;
  private _initializing: boolean;

  constructor(name: string, dependencies: string[] = []){
    this._name                = name;
    this._dependencies        = dependencies;
    this._application         = null;
    this._ready               = false;
    this._serviceUpdatedEvent = new Event();
    this._serviceReadyEvent   = new Event();
    this._updated             = false;
    this._initializing        = false;
  }

  get updated(): boolean{
    return this._updated;
  }

  get name(): string {
    return this._name;
  }

  get onReady(): Event {
    return this._serviceReadyEvent;
  }

  get ready(): boolean {
    return this._ready;
  }

  get onServiceUpdated(): Event{
    return this._serviceUpdatedEvent;
  }

  triggerUpdate(...params: any[]){
    this._updated = true;
    this._serviceUpdatedEvent.trigger(this, ...params);
    this._updated = false;
  }

  get application(): Application {
    if(!this._application) {
      throw new Error(`Service "${this.name}" not registred in application`);
    }
    return this._application;
  }

  set application(application: Application){
    this._application = application;
  }

  async _dependenciesResolved(): Promise<void>{
    await Promise.all(this._dependencies.map(depency => {
      if(this.application.hasService(depency)){
        return Promise.resolve();
      }else{
        return Promise.reject(`"${this.name}" service depend on "${depency}" service and must be registred in the application`);
      }
    }));
  }

  async initialize(): Promise<void>{
    if (this._initializing) {
      throw new Error(`Service ${this.name} already initializing`)
    }
    this._initializing = true;
    await this._dependenciesResolved();

    const startTime = new Date();
    const timeoutId = window.setInterval(() => {
      Logger.log(`Service "${this.name}" starting since ${(new Date()).getTime() - startTime.getTime()}ms`);
    }, 1000);
    await this.start(this.application);

    window.clearInterval(timeoutId);
    this._ready = true;
    this._serviceReadyEvent.trigger(this);
    Logger.log(`Service "${this.name}" started`);
  }

  waitReady(serivcesName: string[]): Promise<Service[]>{
    return new Promise((resolve) => {
      const services = serivcesName.map(serviceName => this.application.getService(serviceName)).filter(service => !service.ready);
      if(!services.length){
        resolve(serivcesName.map(serviceName => this.application.getService(serviceName)));
      }else{
        Logger.info(`"${this.name}" service waiting "${services.map(service => service.name).join("\", \"")}" services`);
        let waitingServices = services.slice();
        services.forEach(service => {
          const listener = new Listener((serviceWaited) => {
            waitingServices = waitingServices.filter(s => s !== serviceWaited);
            Logger.log(`"${this.name}" service wait ${waitingServices.map(s => s.name).join(", ")} service(s)`);
            if(waitingServices.length === 0){
              services.forEach(service => service.onReady.removeListener(listener));
              resolve(serivcesName.map(serviceName => this.application.getService(serviceName)));
            }
          }, this);
          service.onReady.addListener(listener);
        });
      }
    });
  }

  async start(application: Application){}
  async stop(){}
  async restart(){}
}


class Helper {
  private _name: string;
  private _dependencies: string[];
  private _application: Application | null;

  constructor(name: string, dependencies: string[] = []){
    this._name                = name;
    this._dependencies        = dependencies;
    this._application         = null;
  }

  get name(): string{
    return this._name;
  }

  get application(): Application {
    if(!this._application) {
      throw new Error(`Helper "${this.name}" not registred in application`);
    }
    return this._application;
  }

  set application(application: Application){
    this._application = application;
  }

  validDepencies(): void {
    this._dependencies.forEach(dependency => {
      if(!this.application.hasService(dependency)){
        throw new Error(`Helper "${this.name}" depend from service "${dependency}" and it must be not registred in application`);
      }
    });
  }
}


class Application {
  
  private _servicesContainer: ServicesContainer;
  private _helpersContainer: HelpersContainer;
  private _serviceUpdatedEvent: Event;
  private _applicationReadyEvent: Event;

  constructor(){
    this._servicesContainer     = new ServicesContainer();
    this._helpersContainer      = new HelpersContainer()
    this._serviceUpdatedEvent   = new Event();
    this._applicationReadyEvent = new Event();
  }

  get onReady(): Event {
    return this._applicationReadyEvent;
  }

  get onServiceUpdated(): Event {
    return this._serviceUpdatedEvent;
  }

  get servicesContainer(): ServicesContainer {
    return this._servicesContainer;
  }

  get ready(): boolean {
    return this._servicesContainer.isServicesReady();
  }

  initializeServices(services: Service[]): void {
    services.map(service => {
      service.application = this;
      this._servicesContainer.set(service.name, service);
      service.onServiceUpdated.addListener({
        handleEvent: (service: Service, params: any) => {
          this._serviceUpdatedEvent.trigger(service, params);
        }
      });
      Logger.info(`Service "${service.name}" registred`);
      return service;
    });
  }

  addServiceAlias(alias: string, serviceName: string): void{
    this._servicesContainer.setAlias(alias, serviceName);
  }

  hasService(serviceName: string): boolean{
    return this._servicesContainer.has(serviceName);
  }

  getService<T = Service>(serviceName: string): T{
    return this._servicesContainer.get<T>(serviceName);
  }

  hasHelper(helperName: string): boolean{
    return this._helpersContainer.has(helperName);
  }

  getHelper<T = Helper>(helperName: string): T{
    return this._helpersContainer.get<T>(helperName);
  }

  overrideService<T extends Service = Service, RT extends Service = Service>(serviceName: string, service: T): RT {
    service.application = this;
    return this.servicesContainer.override<T, RT>(serviceName, service);
  }

  initializeHelpers(helpers: Helper[]): void{
    helpers.forEach(helper => {
      helper.application = this;
      this._helpersContainer.set(helper.name, helper);
      Logger.info(`Helper "${helper.name}" registred`);
    });
    this._helpersContainer.helpers.forEach(helper => helper.validDepencies());
  }

  start(): Promise<void> {
    const ps = this._servicesContainer.objects.filter(o => !o.alias)
      .map(o => o.object.initialize())
    return Promise.all(ps)
    .then(
      () => this._applicationReadyEvent.trigger(this),
      err => Logger.error(err)
    );
  }

  stop(): Promise<void> {
    return Promise.all(this._servicesContainer.services.map(service => service.stop())).then(() => {});
  }

  log(): { services: Record<string, { name: string, ready: boolean }>, helpers: Record<string, { name: string }>} {
    return {
      services: this._servicesContainer.log(),
      helpers: this._helpersContainer.log()
    };
  }
}

export default Application;

export { Service, Helper };