import React        from 'react';
import Application  from "@uBehaviour/application";
import Slot         from '@uComponents/slot';

const Then = Slot();
const Else = Slot();

const If = ({ children, ...props }) => {
  children = Array.isArray(children) ? children : [children];
  return React.createElement(Application.Service, { name: ["session", "currentTenant", "acl"] }, ([session, currentTenant, acl]) =>
    React.createElement(_If, Object.assign({ 
      user: session.user,
      tenant: currentTenant.currentId,
      acl: acl
    }, props), ...children)
  );
};
const _If = ({ user, tenant, resource, action , children, acl, not }) => {
  let _then   = Then.get({ props: { children }});
  const _else = Else.get({ props: { children }});
  if (!_then) _then = children;
  let is = acl.isAllow(resource, action, user, tenant);
  is = not ? !is : is;
  return is
    ? (_then instanceof Function ? _then() : _then)
    : (_else instanceof Function ? _else() : _else)      
}

const Case    = Slot();
const Default = Slot();

const Switch = (props) => {
  return React.createElement(Application.Service, { name: ["session", "currentTenant", "acl"] }, ([session, currentTenant, acl]) =>
    React.createElement(_Switch, Object.assign({ 
      user: session.user,
      tenant: currentTenant.currentId,
      acl: acl,
      _break: props.break
    }, props), props.children)
  );
};
const _Switch = ({children, acl, _break, user, tenant, resource}) => {
  const pCases      = Case.props({ props: { children }}, true);
  const defaultCase = Default.get({ props: { children }});
  
  if (_break) {
    const pCaseSelected = pCases.find(pCase => acl.isAllow(resource, pCase.action, user, tenant));
    if (pCaseSelected) {
      return pCaseSelected.children instanceof Function
        ? pCaseSelected.children()
        : pCaseSelected.children;
    }
    if (defaultCase) {
      return defaultCase instanceof Function
        ? defaultCase()
        : defaultCase;
    }
    return null;
  } else {
    return pCases.filter(pCase => acl.isAllow(resource, pCase.action, user, tenant))
                 .map(pCase => pCase.children instanceof Function 
                    ? pCase.children()
                    : pCase.children
                  );
  }
}


export default {
  If,
  Then,
  Else,
  Switch,
  Case,
  Default
};