import { Service }  from "@uLib/application";
import StorageService, { GetHandler, HasHandler, RemoveHandler, SetHandler } from "./types/storage";

class DefaultStorageService extends Service implements StorageService{
  private _currentStorage: (Service & StorageService) | null;

  constructor() {
    super("storage", ["persistentStorage", "temporaryStorage"]);
    this._currentStorage = null;
  }

  async start() {
    const [persistentStorage] = await this.waitReady(["persistentStorage", "temporaryStorage"]) as [Service & StorageService];
          
    const lastStorage = await persistentStorage.get("lastStorage");
    
    if(lastStorage){
      await this.setCurrentStorage(this.application.getService(lastStorage));
    } else {
      await this.setCurrentStorage(persistentStorage);
    }
  }

  isPersistent(): boolean {
    return this.currentStorage.name === "persistentStorage";
  }

  isTemporary(): boolean {
    return this.currentStorage.name === "temporaryStorage";
  }

  private get currentStorage(): Service & StorageService {
    if(!this._currentStorage){
      throw new Error("Current storage is not set");
    }
    return this._currentStorage;
  }

  async switchStorage(): Promise<void> {
    await this.setCurrentStorage(this.getInversedStorage());
  }

  private getInversedStorage(): Service & StorageService {
    if(this.isPersistent()){
      return this.application.getService("temporaryStorage");
    }
    return this.application.getService("persistentStorage");
  }

  private async setCurrentStorage(storage: Service & StorageService ) {
    this._currentStorage = storage;
    await this.updateLastStorage();
  }

  private async updateLastStorage(){
    await this.application.getService<StorageService>("persistentStorage").set("lastStorage", this.currentStorage.name);
  }

  has: HasHandler = (key: string) => this.currentStorage.has(key);

  set: SetHandler = (key: string, value: string | unknown, stringify = false) => this.currentStorage.set(key, value, stringify);

  get: GetHandler = <T = unknown>(key: string, parse = false) => this.currentStorage.get<T>(key, parse);

  remove: RemoveHandler = (key: string) => this.currentStorage.remove(key);
}

export default DefaultStorageService;
