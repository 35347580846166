import Event from "./event";

class Keyboard{
    constructor(){
        this._onKeyDown = new Event();
        document.body.addEventListener("keydown", (event) => {
            this._onKeyDown.trigger("onKeyDown", {
                altKey: event.altKey,
                ctrlKey: event.ctrlKey,
                shiftKey: event.shiftKey,
                key: event.key,
                code: event.code,
            })
        });
    }
    get onKeyDown(){
        return this._onKeyDown;
    }
}

export default new Keyboard();