import Application, { Service }  from "@uLib/application";

import StorageService, { SetHandler, GetHandler, HasHandler, RemoveHandler } from "./types/storage";
import SessionService from "./session";

class UserStorageStrategyService extends Service implements StorageService {
  private storageServiceName: string;

  constructor(name: string, storageServiceName: string) {
    super(name, [storageServiceName]);
    this.storageServiceName = storageServiceName;
  };

  async start(){
    await this.waitReady([this.storageServiceName]);
  }

  private getUserKey(key: string): string {
    const session = this.application.getService<SessionService>("session");
    if(!session.isLogged()){
      return `0000000000000000_${key}`;
    }
    return `${session.userId}_${key}`;
  }

  has: HasHandler = async (key: string) => {
    const storageService = this.application.getService<StorageService>(this.storageServiceName);
    return storageService.has(this.getUserKey(key));
  };

  get: GetHandler = async <T = unknown>(key: string, parse = false) => {
    const storageService = this.application.getService<StorageService>(this.storageServiceName);
    return storageService.get<T>(this.getUserKey(key), parse);
  };

  set: SetHandler = async <T = unknown>(key: string, value: string | T, stringify = false) => {
    const storageService = this.application.getService<StorageService>(this.storageServiceName);
    return storageService.set<T>(this.getUserKey(key), value, stringify);
  };

  remove: RemoveHandler = async (key: string) => {
    const storageService = this.application.getService<StorageService>(this.storageServiceName);
    return storageService.remove(this.getUserKey(key));
  };
}

export default  UserStorageStrategyService;