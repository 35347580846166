import React from 'react';
import PropTypes from 'prop-types';
import Display from './displayIf';

import './title.css';

export default class Title extends React.Component{
  static propTypes = {
    dataId: PropTypes.string
  }

  render() {
    const { dataId } = this.props;
    return (
      <div className="bs-itemTitle">
        {
          <Display.If condition={ dataId }>
             <span className="bs-data-id">{dataId}</span>
          </Display.If>
        }
        {this.props.children}
      </div>
    )
  }
}