const isClassNamesBuilder = (value: any): value is ClassNamesBuilder => {
  return value instanceof ClassNamesBuilder;
}

export class ClassNamesBuilder {
  private classNames: string[] = [];

  constructor(){
    this.classNames = [];
  }

  add(className: string | ClassNames): ClassNames  {
    if(isClassNamesBuilder(className)){
      this.classNames = this.classNames.concat(className.classNames);
    } else {
      this.classNames.push(className);
    }
    return this as unknown as ClassNames;
  }

  addIf(className: string | ClassNames, condition: boolean): ClassNames {
    if(condition){
      this.add(className);
    }
    return this as unknown as ClassNames;
  }
  addNotEmpty(className: string | ClassNames | null | undefined): ClassNames {
    if(className){
      this.add(className);
    }
    return this as unknown as ClassNames;
  }
  clone(): ClassNames {
    const builder = new ClassNamesBuilder();
    builder.classNames = this.classNames.slice();
    return builder as unknown as ClassNames;
  }
  build(): string {
    return this.classNames.join(' ');
  }
  toString(): string {
    return this.build();
  }
};

export type ClassNames = ClassNamesBuilder & string;

const classNames: (className?: string) => ClassNames = (className) => {
  const builder = new ClassNamesBuilder();
  builder.addNotEmpty(className);
  return builder as ClassNames;
}

export default classNames;