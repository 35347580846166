import React        from "react";
import Input        from "./input";
import Form         from '@cBehaviour/form';
import Slot         from "@cComponents/slot";
import _                      from "lodash";

class Simple extends React.Component{
    static Display      = Slot();
    static Form         = Slot();

    constructor(props){
        super(props);
        this._inputArray    = React.createRef();
        this._form          = React.createRef();
    }
    get inputArray(){
        return this._inputArray.current;
    }
    add(){
      if(this._inputArray.current.isEdited()) {
        _.defer(() => this.add());
        this._cancel();
        return;
      }
      this._inputArray.current.add();
    }
    edit(idx){
       if(this._inputArray.current.isEdited()) {
        _.defer(() => this.edit(idx))
        this._cancel();
        return;
      }
      this._inputArray.current.edit(idx);
    }
    isEdited(){
        return this._inputArray.current.isEdited() && !!this._form.current;
    }
    delete(idx){
        this._inputArray.current.delete(idx);
    }
    closeForm(){
        if(this.isEdited()){
            this._cancel();
        }
    }
    _submit(){
        this._form.current.submit();
    }
    _cancel(){
        this._inputArray.current.cancelEdit();
    }
    render(){
        const form = Simple.Form.get(this);
        const formProps = Simple.Form.props(this);
        return (
        <InputArray ref={ this._inputArray } name={ this.props.name } in={ this.props.in } out={ this.props.out } default={ this.props.default }>
            <InputArray.Display>
                { Simple.Display.get(this) }
            </InputArray.Display>
            <InputArray.Form>
                {(storeCallback, object, idx) => {
                    const id    = this.props.key ? this.props.key(object) : idx;
                    const key   = `${this.props.name}#${id ? id : "new"}`;
                    return React.createElement(
                        Form.Simple, { 
                            ref: this._form,
                            name: key,
                            key: key,
                            value: object,
                            validate:(data, errors) => this.props.validate ? this.props.validate(data, errors) : null,
                            submit: value => storeCallback(value),
                            preload: value => this.props.preload ? this.props.preload(value) : value,
                            presubmit: value => this.props.presubmit ? this.props.presubmit(value) : value,
                            onChange: formProps.onChange
                        },
                        (value) => form(
                            value,
                            this._submit.bind(this), 
                            this._cancel.bind(this),
                            idx
                        )
                    );
                }}
            </InputArray.Form>
        </InputArray>
        )
    }
}

export default class InputArray extends Input {
    static Simple       = Simple;
    static Display      = Slot();
    static Form         = Slot();

    state = { 
        editIdx: null
    };
   
    get value(){
        return super.value.slice();
    }

    get default(){
        return this.props.default ? JSON.parse(JSON.stringify(this.props.default)) : null;
    }
    add = () => {
        this._new = true;
        this._value.push(this.default);
        this.edit(this._value.length - 1);
        this._triggerChange();
    }
    delete = (idx) => {
        if(idx < 0 && idx >= this._value.length){
            throw new Error("Index out of bound");
        }
        this._value.splice(idx, 1);
        this._triggerChange();
    }
    edit = (idx) => {
        if(idx < 0 && idx >= this._value.length){
            throw new Error("Index out of bound");
        }
        this.setState({ editIdx: idx });
    }
    cancelEdit = () => {
        if(this._new){
            this._value.pop();
            this._new = false;
        }
        this.setState({ editIdx: null });
    }
    isEdited(){
        return this.state.editIdx !== null;
    }

    getErrors() {
        return [];
    }

    handleEvent(){
        this._triggerChange();
    }
    __render(){
        if(!this._value){
            this._value = [];
        }
        const display       = InputArray.Display.get(this);
        let form            = InputArray.Form.get(this);
        const storeCallback = (idx) => (value) => { 
            this._value[idx] = value; 
            this._new        = false;
            this.setState({ editIdx: null });
            this._triggerChange();
            return Promise.resolve(value);
        };
       
        return this._value.map((value, idx) => {
            if(this.state.editIdx === idx) {
                return form(storeCallback(idx), value, idx, this.cancelEdit)
            } else {                
                return display(value, idx, () => this.edit(idx), this.delete);
            }
        });
    }
}