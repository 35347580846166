class AbstractAsync{
  constructor(discriminator, handler = ((context, object) => object)){
    this._discriminator = discriminator;
    this._queue         = [];
    this._startHandler  = handler;
  }
  validate(object){
    return Promise.resolve(object);
  }
  next(handlers){
    if(handlers instanceof Function){
      this._queue.push((context, object) => this.validate(object)
        .then(object => handlers(context, object))
      );
    }else{
      handlers = Object.assign({}, handlers);
      handlers.default = ((context, object) => object)
      this._queue.push((context, object) => this.validate(object)
        .then(object => {
          let discriminator = object instanceof Object ? object[this._discriminator] : "default";
          if(!discriminator) discriminator = "default";
          if(!handlers[discriminator]) discriminator = "default";
          return handlers[discriminator](context, object);
        })
      );
    }
    return this;
  }
  build(execContext = null){
    const oThis = this;
    return function(){
      const args    = Array.prototype.slice.call(arguments);
      const context = {};
      return Promise.resolve()
        .then(() => oThis._startHandler.apply(execContext, [context].concat(args)))
        .then(object => oThis.exec(context, object));
    }
  }
  exec(context, object){
    let p = Promise.resolve(object);
    for(let i = 0; i < this._queue.length; ++i)((queue => {
      p = p.then(object => queue(context, object));
    })(this._queue[i]));
    return p;
  }
}

class AbstractSync{
  constructor(discriminator, handler = ((context, object) => object)){
    this._discriminator = discriminator;
    this._queue         = [];
    this._startHandler  = handler;
  }
  validate(object){
    return object;
  }
  next(handlers){
    if(handlers instanceof Function){
      this._queue.push((context, object) => {
        this.validate(object);
        return handlers(context, object);
      });
    }else{
      handlers = Object.assign({}, handlers);
      handlers.default = ((context, object) => object)
      this._queue.push((context, object) => {
        this.validate(object);
        let discriminator = object instanceof Object ? object[this._discriminator] : "default";
        if(!discriminator) discriminator = "default";
        if(!handlers[discriminator]) discriminator = "default";
        return handlers[discriminator](context, object);
      });
    }
    return this;
  }
  build(execContext = null){
    const oThis = this;
    return function(){
      const args    = Array.prototype.slice.call(arguments);
      const context = {};
      const object = oThis._startHandler.apply(execContext, [context].concat(args));
      return oThis.exec(context, object);
    }
  }
  exec(context, object){
    for(let i = 0; i < this._queue.length; ++i)((queue => {
      object = queue(context, object);
    })(this._queue[i]));
    return object;
  }
}
export default {
  async: (discriminator, handler) => {
    return new AbstractAsync(discriminator, handler);
  },
  sync: (discriminator, handler) => {
    return new AbstractSync(discriminator, handler);
  }
}