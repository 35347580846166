import React      from 'react';
import Filter     from "@cComponents/filter";
import Display    from "@cComponents/displayIf";
import T          from "@uBehaviour/i18n";
import Text       from "@cComponents/input/text";
import Scrollbar  from "@cComponents/scrollBar";
import Data       from "@uBehaviour/data";
import _          from 'lodash';
import Slot       from "@uComponents/slot";
import { escapeRegExp } from "@uLib/tool";


import './selectableList.css';

const FilterSlot =  Slot();
const ItemSlot = Slot();

export class SelectableList extends React.Component {
  constructor(props) {
    super(props);
    this._filterTextInputRef = React.createRef();
    this.state = {
      selected: []
    };
  }

  componentDidMount() {
    this._filterTextInputRef.current?.focus();
  }
  
  add(values){
    if(!Array.isArray(values)){
      values = [values];
    }
    if(this.props.limit){
      values = values.slice(0, this.props.limit - this.state.selected.length);
    }
    this.setState({ selected: this.state.selected.concat(values) });
  }

  remove(values){
    if(!Array.isArray(values)){
      values = [values];
    }
    this.setState({ selected: this.state.selected.filter(value => values.indexOf(value) === -1) });
  }

  get selectedValues() {
    return this.state.selected.slice();
  }
  isSelected(value){
    return this.state.selected.indexOf(value) !== -1;
  }

  switchSelect(data, ev){
    ev.stopPropagation();
    if(this.isSelected(data)){
      this.remove(data);
    } else {
      this.add(data);
    }
  }

  _textSearchBuildQuery = (value) => {
    return this.props.filterQuery(escapeRegExp(value));
  }

  render() {
    const {
      filterQuery,
      model,
      query,
      sort,
      load,
      source,
      pageSize,
      children
    } = this.props;
    
    const ComponentList = model ? Data.List : DataSourceList;
    const filter = FilterSlot.get(this);
    const item = ItemSlot.get(this, false, children);

    const fn = item instanceof Function
      ? data => item(data)
      : data => React.cloneElement(item, { data });

    return(
      <Filter.Aggregator default={ this.props.defaultFilter } >
        <div className='bs-selectable-list'>        
          {
            filter
              ? (
                <div className='bs-selectable-list-filter'>
                {
                  filter
                }
                </div>
              )
              : null
          }
          <div className='bs-selectable-list-body'>
            <Display.If condition={ filterQuery }>
              <div className="bs-selectable-list-search-container">
                <Filter.Generic name="text" buildQuery={ this._textSearchBuildQuery }>
                  {(value, set, clear) => (
                    <Text ref={this._filterTextInputRef} value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                      <T>freesearch</T>
                    </Text>
                  )}
                </Filter.Generic>
              </div>
            </Display.If>
            <div className='bs-selectable-list-content'>
              <Filter.Subject>
              {(compose) => (
                <Scrollbar.ListController>
                  <ComponentList model={ model } source={ source } query={ compose(query) } sort={ sort } load={ load } pageSize={ pageSize }>
                  {(data) => (
                    <div className={ `bs-selectable-item ${ this.isSelected(data) ? "bs-selectable-item-selected" : ""}` } onClick={ this.switchSelect.bind(this, data) }>
                    {
                      fn(data)
                    }
                    </div>
                  )}
                  </ComponentList>
                </Scrollbar.ListController>
              )}
              </Filter.Subject>
            </div>
          </div>
        </div>
      </Filter.Aggregator>
    );
  } 
};

const DataSourceList = ({ children, ...props }) => {
  const fn = children instanceof Function
    ? data => children(data)
    : data => React.cloneElement(children, { data });

  return (
    <Data.SourceList { ...props }>
    { 
      datas => datas.map(fn)
    }
    </Data.SourceList>
  )
};

SelectableList.Filter = FilterSlot;
SelectableList.Item = ItemSlot;

export default SelectableList;
