import React from "react";

class Container {
  constructor(onUpdate){
    this._value     = null;
    this._onUpdate  = onUpdate;
  }
  has(){
    return !!this._value;
  }
  get(){
    return this._value;
  }
  set(value){
    if(this._value !== value){
      if(this._onUpdate){
        this._onUpdate(this._value, value);
      }
      this._value = value;
    }
  }
}

const createBackReference = () => {
  const Context = React.createContext();

  class Consumer extends React.Component{
    constructor(props){
      super(props);
      this._container = new Container(this._onUpdate);
    }
    _onUpdate = (_old, _new) =>  {
      if(this.props.onUpdate){
        this.props.onUpdate(_old, _new);
      }
    }
    has(){
      return this._container.has();
    }
    value(){
      return this._container.get();
    }
    render(){
      return React.createElement(Context.Provider, { value: this._container }, this.props.children);
    }
  }
  const Provider = ({ value, children }) => {
    const container = React.useContext(Context);
    if(container){
      container.set(value);
    }
    return children;
  }
  
  return {
    Consumer,
    Provider
  };
};

export default {
  create: createBackReference
};