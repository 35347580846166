import React        from "react";
import md5          from "js-md5";
import Slot         from "@cComponents/slot";
import Geometry     from "@cLib/geometry";
import AsyncDisplay from "@cComponents/asyncDisplay";

export default class Map extends React.Component{
  static hash(component){
    const shapes  = Map.Shape.props(component, true);
    const props   = Object.assign({}, component.props);
    props.type    = component.props.type.name;
    delete props.children;
    return md5(JSON.stringify(props) + JSON.stringify(shapes));
  }
  static Shape = Slot();
  constructor(props){
    super(props);
    this.onZoomChanged  = this.onZoomChanged.bind(this);
    this.onDragEnd      = this.onDragEnd.bind(this);
    this.onShapeDragged = this.onShapeDragged.bind(this);
    this._mapRef        = React.createRef();
  }
  onZoomChanged(){
    if(this.props.onZoomChanged){
      this.props.onZoomChanged(...arguments);
    }
  }  
  onDragEnd(){
    if(this.props.onDragEnd){
      this.props.onDragEnd(...arguments);
    }
  }
  onShapeDragged(shape){
    if(this.props.onShapeDragged){
      this.props.onShapeDragged(...arguments);
    }
  }
  _getComponent(){
    return this.props.type.mapComponent;
  }
  _getProps(){
    return this.props.type.getMapProps({ ...this.props, defaultcenter: this.props.defaultcenter ? this.props.defaultcenter : this.center }, this.onZoomChanged, this.onDragEnd);
  }
  _shapeFactory(shape){
    return React.createElement(
      this.props.type.getShapeComponent(shape.type),
      this.props.type.getShapeProps(shape, this.onShapeDragged)
    );
  }
  get center() {
    const shapes  = Map.Shape.props(this, true);
    return new Geometry.Collection(shapes.map(shape => shape.geometry)).barycentre;
  }
  hash(){
    return Map.hash(this);
  }
  shouldComponentUpdate(nextProps, nextState){
    return Map.hash({ props: nextProps }) !== this.hash;
  }
  render(){
    const shapes    = Map.Shape.props(this, true);
    this._hashCache = this.hash;
    return React.createElement(AsyncDisplay, { whenReady: this.props.onLoaded }, React.createElement(
      this._getComponent(),
      this._getProps(),
      shapes.map(shape => this._shapeFactory(shape))
    ));
  }
};