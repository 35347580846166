import _ from "lodash";
export default class AutoDrop{
  constructor(onDeleteHandler = () => null){
    this._objects         = {};
    this._onDeleteHandler = onDeleteHandler;
    this._toDrop          = [];
    this._drop            = _.debounce(this._drop.bind(this), 0);
  }
  get datas(){
    return Object.values(this._objects).map(o => o.object);
  }
  has(id){
    return this._objects[id] !== undefined;
  }
  register(id){
    if(!this.has(id)) throw new Error(`Unknow Object("${id}")`);
    this._objects[id].nbrRegistred++;
    return this._objects[id].object;
  }
  unregister(id){
    if(!this.has(id)) throw new Error(`Unknow Object("${id}")`);
    this._objects[id].nbrRegistred--;
    if(this._objects[id].nbrRegistred === 0){
      this._toDrop.push(id);
      this._drop();
    }
  }
  _drop(){
    this._toDrop.forEach((id) => {
      if(this._objects[id].nbrRegistred === 0){
        this._onDeleteHandler(this._objects[id].object, id);
        delete this._objects[id];
      }
    });
    this._toDrop = [];
  }
  create(id, object){
    if(this.has(id))  throw new Error(`Object("${id}") already defined`);
    this._objects[id] = {
      nbrRegistred: 0,
      object: object
    };
  }

  mutateId(oldId, newId){
    if(this.has(newId))   throw new Error(`Object("${newId}") already defined`);
    if(!this.has(oldId))  throw new Error(`Unknow Object("${oldId}")`);
  }
};