import React        from "react";
import Synchronizer from "./synchronizer";
import Application  from "@uBehaviour/application";

class One extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      entity: null
    };
  }
  load() {
    this.props.model.repository.findOne(this.props.id, this.props.load)
      .then(entity => {
          if(entity){
              this.setState({ entity });
          }
      });
}

  componentDidMount(){
    this.load();
  }

  componentDidUpdate() {
    if (this.state.entity && this.state.entity._id !== this.props.id) {
        this.load();
    }else{
      if(this.props.onLoad){
        this.props.onLoad();
      }
    }
          
  }
  render(){
    return React.createElement(Synchronizer, { entity: this.state.entity }, this.props.children);
  }
}

export default (props) => React.createElement(Application.Service, { name: "repository" }, repository => 
  React.createElement(One, Object.assign({}, props, { model: repository.get(props.model) }), props.children)
);