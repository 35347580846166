export default {
  description: {
    type:"string",
    required:true,
    empty: false
  },
  files:{
    type:"array",
    required: false
  },
  requestor: {
    type: "object",
    optional: true,
    props: {
      firstname: { type: "string" },
      lastname: { type: "string" },
      type: { type: "string", enum: ["citizen", "internal", "userPro"] },
      email: { type: "email", optional: true },
      phone: { type: "string", optional: true },
    }
  }
}