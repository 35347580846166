import React                from "react";
import md5                  from "md5";
import Slot                 from "@uComponents/slot";
import Display              from "@uComponents/displayIf";
import Leaflet              from '@cComponents/leaflet';
import Geometry             from "@uLib/geometry";


import openstreetmappng     from "@cAssets/images/osm.png";
import googlemapspng        from "@cAssets/images/googlemap.png";
import satelittejpeg        from "@cAssets/images/satelitte.jpeg";

import ReactDOM             from "react-dom";

import './leafletMap.css';

const layerFactory = (type, params) => {
  switch(type){
    case "xyz": return Leaflet.tileLayer(params.url, params.option);
    case "mvt": return Leaflet.vectorGrid.protobuf(params.url, params.option);
    default:
      throw new Error(`Unknow layer type "${type}"`);
  }
}

const LayerItem = (props) => {
  const onClick = () => {
    if(props.onClick){
      props.onClick(props.layer, !props.selected);
    }
  }
  return (
    <div className={ `bs-map-leaflet-layerItem${ props.selected ? " bs-map-leaflet-layerItem-selected" : ""}`} onClick={ onClick }>
    { 
      props.layer.icon
    }
    </div>
  )
}

class BasemapLayerController extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isHover: false
    };
  }
  render(){
    const selected            = this.props.selected ? this.props.selected : this.props.default;
    const selectedLayer       = this.props.layers.find(el => el.id === selected.id);
    const unSelectedLayers    = this.props.layers.filter(el => el.id !== selected.id);
    const allLayers           = [selectedLayer].concat(unSelectedLayers);
    return (
      <div className="bs-map-leaflet-baseMapLayerController" onMouseEnter={() => this.setState({ isHover: true })} onMouseLeave={() => this.setState({ isHover: false })}>
        <Display.If condition={ !this.state.isHover }>
          <Display.Then>
            <div><LayerItem selected layer={ selectedLayer } /></div>
          </Display.Then>
          <Display.Else>
          {
            allLayers.map((layer, idx) => (<div><LayerItem key={ layer.id } layer={ layer } onClick={ idx !== 0 ? this.props.onChange : null } selected={ idx === 0 } /></div>))
          }
          </Display.Else>
        </Display.If>
      </div>
    )
  }
}

const TransparentLayerController = (props) => {
  const layers = props.selected.concat(props.layers.filter(layer => !props.selected.some(selectedLayer => selectedLayer.id === layer.id)));
  return (
    <div className="bs-map-leaflet-transparentLayerController">
    {
      layers.map(layer => (<div><LayerItem key={ layer.id } layer={ layer } onClick={ props.onChange } selected={props.selected.indexOf(layer) !== -1 } /></div>))
    }
    </div>
  );
}

class LeafletMap extends React.Component{
  static Layer    = Slot();
  static GeoJSON  = Slot();

  state = { 
    currentLayer: null,
    currentTransparentLayer: [],
    tooltip: null
  }

  constructor(props) {
    super(props);
    this._container = React.createRef();
    this._geoJSONs  = null;
    this._buildBaseMapLayer();
    this._buildTransparentLayer();
    this.setDefaultBaseMapLayer = this.setDefaultBaseMapLayer.bind(this);
    this.setTransparentLayer    = this.setTransparentLayer.bind(this);
    this._onClick               = this._onClick.bind(this);
  }

  _buildBaseMapLayer(layers = []){
    this._baseMapLayers = [{
        id: "google",
        name: "Google Maps",
        icon: (<img src={ googlemapspng } alt="Google maps icon" />),
        layer: Leaflet.gridLayer.googleMutant({ type: "terrain" })
      },{
        id: "osm",
        name: "Open Street Map",
        icon: (<img src={ openstreetmappng } alt="Open street map icon" />),
        layer: Leaflet.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }),
      },{
        id: "googleSatellite",
        name: "Google Maps Satellite",
        icon: (<img src={ satelittejpeg } alt="Google maps satelitte icon" />),
        layer: Leaflet.gridLayer.googleMutant({ type: "hybrid" })
      }].concat(layers.filter(layer => !layer.transparent).map(layer => {
        return {
          id: layer.id,
          name: layer.name,
          icon: layer.children,
          layer: layerFactory(layer.type, layer)
        };
      }));

  }

  _buildTransparentLayer(layers = []){
    this._transparentLayers = layers.filter(layer => layer.transparent).map(layer => {
      return {
        id: layer.id,
        name: layer.name,
        icon: layer.children,
        layer: layerFactory(layer.type, layer)
      };
    })
  }

  _getDefaultBasemapLayer(){
    if(!this.props.defaultBasemap){
      return this._baseMapLayers[0];
    }
    return this._baseMapLayers.find(layer => layer.id === this.props.defaultBasemap);
  }

  setDefaultBaseMapLayer(layer){
    if(this.state.currentLayer){
      this.state.currentLayer.layer.removeFrom(this._map);
    }
    this.setState({ currentLayer: layer });
  }

  setTransparentLayer(layer, add){
    const idx = this.state.currentTransparentLayer.indexOf(layer);
    if(add && idx === -1){
      this.setState({ currentTransparentLayer: this.state.currentTransparentLayer.concat([layer])});
    }else if(!add && idx !== -1){
      const currentTransparentLayer = this.state.currentTransparentLayer.slice();
      currentTransparentLayer.splice(idx, 1);
      this.setState({ currentTransparentLayer })
    }
  }

  updateGeoJSON(){
    this._featureGroup.clearLayers();
    LeafletMap.GeoJSON.props(this, true).forEach(geoJson => {
      const opts = {
        style: (feature) => {
          return {};
        }
      };
      if(geoJson.className || geoJson.children){
        const div = document.createElement("div");

        opts.pointToLayer = (geoJsonPoint, latlng) => {
          let marker = null;
          if(geoJson.className){
            const span =  document.createElement("span");
            span.className = geoJson.className + " bs-leaflet-mapMarker-content";
            marker = Leaflet.marker(latlng, {
              icon: Leaflet.divIcon({  iconSize:[22.86, 40], html: span, className: "bs-leaflet-mapMarker" })
            })
          }else{
            marker = Leaflet.marker(latlng);
          }
          if(geoJson.children){
            marker.bindPopup(() => {
              if(!this.state.tooltip || this.state.tooltip.marker !== marker){
                this.setState({ tooltip: {
                  parent: div,
                  content: geoJson.children,
                  marker: marker
                }});
              }
              return div;
            }).on("mouseover", (ev) => {
              ev.target.openPopup();
            });
          }
          return marker;
        };
      }

      const marker = Leaflet.geoJSON(geoJson.position, opts);      
      if(geoJson.onClick){
        marker.on('click' , geoJson.onClick);
      }
      marker.addTo(this._featureGroup);
    });
    this._updateGeoJSON = false;
  }

  addFullscreen(){
    if(!this._fullscreenControl){
      this._fullscreenControl = new Leaflet.Control.Fullscreen({
        position: 'topright',
          title: {
              'false': 'Voir en plein écran',
              'true': 'Sortir du mode plein écran'
          }
      });
      this._map.addControl(this._fullscreenControl);
    }
  }

  removeFullscreen(){
    if(this._fullscreenControl){
      this._map.removeControl(this._fullscreenControl);
      this._fullscreenControl = null;
    }
  }

  componentDidMount() {
    const position = this.props.position ? Geometry.buildFromGeoJSON(this.props.position) : new Geometry.Point(0, 0);
    this._map = Leaflet.map(this._container.current, { 
      zoomControl: false,
      maxZoom: 21
    }).setView([position.latitude, position.longitude], this.props.zoom);
    this._map.on("click", this._onClick);
    
    Leaflet.control.zoom({ position: "topright" }).addTo(this._map);
    if(this.props.allowFullscreen){
      this.addFullscreen();
    }
    this.setDefaultBaseMapLayer(this._getDefaultBasemapLayer());
    this._featureGroup = (this.props.cluster ? Leaflet.markerClusterGroup() : Leaflet.geoJSON()).addTo(this._map);
    this.updateGeoJSON();
    if(this.props.visitorDidMount){
      this.props.visitorDidMount(this._map, this._featureGroup);
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.currentLayer !== this.state.currentLayer || prevState.currentTransparentLayer !== this.state.currentTransparentLayer){
      this._map.eachLayer((layer) => {
        this._map.removeLayer(layer);
      });
      [this.state.currentLayer].concat(this.state.currentTransparentLayer).forEach(layer => {
        layer.layer.addTo(this._map);
      });
      this._featureGroup.addTo(this._map);
    }
    if(this.props.allowFullscreen){
      this.addFullscreen();
    }else{
      this.removeFullscreen();
    }
    if(md5(JSON.stringify(this.props.position ? this.props.position : "")) !== md5(JSON.stringify(prevProps.position ? prevProps.position : ""))){
      const position = this.props.position ? Geometry.buildFromGeoJSON(this.props.position) : new Geometry.Point(0, 0);
      this._map.setView([position.latitude, position.longitude], this._map.getZoom());
    }
    const updateGeoJSON = this._updateGeoJSON;
    if(updateGeoJSON){
      this.updateGeoJSON();
    }
    if(this.props.visitorDidUpdate){
      this.props.visitorDidUpdate(this._map, this._featureGroup, updateGeoJSON);
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    const currentGeoJSONProps = LeafletMap.GeoJSON.props(this, true);
    const nextGeoJSONProps    = LeafletMap.GeoJSON.props({ props: nextProps }, true);

    const currentPositions    = currentGeoJSONProps.map(geoJSON => geoJSON.position);
    const nextPositions       = nextGeoJSONProps.map(geoJSON => geoJSON.position); 

    const current = md5(JSON.stringify(currentPositions));
    const next    = md5(JSON.stringify(nextPositions));
    
    if(current !== next){
      this._updateGeoJSON = true;
    }
    const currentLayers = LeafletMap.Layer.props(this, true);
    const nextLayers = LeafletMap.Layer.props({ props: nextProps }, true);
    if (currentLayers.length !== nextLayers.length) {
      this._buildTransparentLayer(nextLayers);
      this._buildBaseMapLayer(nextLayers);
    }
    return true;
  }
  _getMapState(){
    const mapState = {
      zoom: this._map.getZoom(),
      center: this._buildGeoJSONFromLatLng(this._map.getCenter())
    };
    return mapState;
  }
  _buildGeoJSONFromLatLng(latlng){
    return { 
      type: "Point",
      coordinates: [latlng.lng, latlng.lat]
    };
  }
  _onClick(ev){ 
    if(this.props.onClick){
      this.props.onClick(this._getMapState(), this._buildGeoJSONFromLatLng(ev.latlng))
    }
  }

  render(){
    return (
      <>
        <div className="bs-map-leaflet">
          <div className="bs-map-leaflet-container" ref={ this._container } />
          <BasemapLayerController 
            layers={ this._baseMapLayers }
            default={ this._getDefaultBasemapLayer() }
            selected={ this.state.currentLayer }
            onChange={ this.setDefaultBaseMapLayer }
          />
          <Display.If condition={ this._transparentLayers.length }>
            <TransparentLayerController
              layers={ this._transparentLayers }
              selected={ this.state.currentTransparentLayer }
              onChange={ this.setTransparentLayer }
            />
          </Display.If>
        </div>
        {
          this.state.tooltip ? ReactDOM.createPortal(React.cloneElement(this.state.tooltip.content, { onReady: () => this.state.tooltip.marker.getPopup().update() }), this.state.tooltip.parent) : null
        }
      </>
    );
  }
}

export default LeafletMap;