import React       from "react";
import InputBase   from "./inputBase";
import T           from "@cBehaviour/i18n";
import Data        from "@uBehaviour/data";
import Error       from "@cComponents/error";
import Slot        from "@cComponents/slot";
import Modal       from "@cComponents/modal";
import Scrollbar   from "@cComponents/scrollBar";
import Button      from "@cComponents/button";
import Filter      from "@cComponents/filter";
import Application from "@uBehaviour/application";

import "./selectable.css";

class _ListSelector extends React.Component {
  static Filter = Slot();
  static Item = Slot();
  state = {
    selected: [],
    errors: []
  }
  constructor(props) {
    super(props);
    this._list = React.createRef();
  }
  onScroll(component) {
    if (component.position > 0.8) {
      this._list.current.loadNext();
    }
  }
  _has(data) {
    return this.state.selected.findIndex(v => this.props.model.key.equals(data, v)) !== -1;
  }
  _switchSelection(data) {
    let selected = this.state.selected;
    let errors = [];
    const idx = this.state.selected.findIndex(v => this.props.model.key.equals(data, v));
    if (idx !== -1) {
      selected.splice(idx, 1);
    } else if (!this.props.limit || this.state.selected.length < this.props.limit) {
      selected = [data].concat(selected);
    } else {
      errors = [{ error: "limit_reached" }];
    }
    this.setState({ selected: selected, errors: errors });
  }
  _validate() {
    if (this.props.min && this.state.selected.length < this.props.min) {
      this.setState({ selected: this.state.selected, errors: [{ error: "not_enough_selected" }] });
    } else {
      this.props.add(this.state.selected);
    }
  }
  _getQuery() {
    return this.props.query;
  }
  render() {
    const filter  = ListSelector.Filter.get(this);
    const item    = ListSelector.Item.get(this);
    return (
      <Filter.Aggregator>
        <div className="bs-selectable-list-old">
          <div className="bs-selectable-list-old-header">
            { this.props.title }
          </div>
          <div className="bs-selectable-list-old-body">
            { filter }
            <div>
              <Scrollbar  onScroll={(c) => this.onScroll(c)}>
                <Filter.Subject>
                {compose => (
                  <Data.List model={this.props.model.name} query={compose(this._getQuery())} sort={this.props.sort} load={this.props.load} limit={this.props.limit} ref={this._list}>
                  {(data) => React.createElement(
                    "div", {
                      onMouseUp: () => this._switchSelection(data),
                      className: `bs-selectable-list-old-item${this._has(data) ? " bs-item-selected" : ""}`
                    },
                    React.cloneElement(item, { data: data })
                  )}
                  </Data.List>
                )}
                </Filter.Subject>
              </Scrollbar>
            </div>
            {
              this.state.errors.length
                ? React.createElement(Error, { errors: this.state.errors })
                : null
            }
          </div>
          <div className="bs-selectable-list-old-footer">
            <Button.Text onClick={() => this._validate()}><T>validate</T></Button.Text>
          </div>
        </div>
      </Filter.Aggregator>
    );
  }
}
const ListSelector = React.forwardRef((props, ref) => React.createElement(Application.Service, { name: "repository" }, repository => 
  React.createElement(_ListSelector, Object.assign({}, props, { model: repository.get(props.model), ref: ref}), props.children)
));
ListSelector.Filter = _ListSelector.Filter;
ListSelector.Item   = _ListSelector.Item;

class InputSelectable extends InputBase {
  static Render = Slot();
  static Open = Slot();
  static Means = Slot();

  state = {
    initialized: false
  };
  _open = false;
  get value() {
    return this._out(this._value.map(value => this.props.model.key.extract(value)));
  }
  get limit() {
    return this.props.limit;
  }
  getErrors(){
    const errors = super.getErrors();
    if(this.props.min && (!this.value || this.value.length < this.props.min)){
      errors.push({ path: this.name, error: "input_selectable_error_min" });
    }
    return errors;
  }
  _add(values) {
    if (!Array.isArray(values)) {
      values = [values];
    }
    if (this._removed) {
      this._removed = this._removed.filter(value => values.findIndex(toRemove => this.props.model.key.equals(value, toRemove)) === -1);
    }
    this._value = this._value.concat(values);
    this._triggerChange();
  }
  _remove(values) {
    if (!Array.isArray(values)) {
      values = [values];
    }
    if (!this._removed) {
      this._removed = [];
    }
    this._removed = this._removed.concat(values);
    this._value = this._value.filter(value => values.findIndex(toRemove => this.props.model.key.equals(value, toRemove)) === -1);
    this._triggerChange();
  }
  open() {
    this._open = true;
    this.forceUpdate();
  }
  close() {
    this._open = false;
    this.forceUpdate();
  }
  _getValueRenderQuery() {
    const renderProps = Selectable.Render.props(this);
    return renderProps.query
      ? renderProps.query
      : (values) => ({ $or: values.map(value => this.props.model.key.extract(value)) });
  }
  _getValueRender() {
    if (!this._value || !this._value.length) return null;
    let render = Selectable.Render.get(this);
    let renderProps = Selectable.Render.props(this);
    if (!render && renderProps.textify) {
      render = (data, key, removeHandler) => {
        return (
          <span className="bs-old-input-selectable-render-item" key={key}>
            <span>{renderProps.textify(data)}</span>
            <span className="fa fa-close bs-button" onMouseUp={(ev) => { ev.nativeEvent.preventDefault(); removeHandler(); }} />
          </span>
        );
      }
    }
    const query = this._getValueRenderQuery()(this._value);

    return React.createElement(
      Data.Query, {
      key: "bs-old-input-selectable-render",
      model: this.props.model.name,
      load: this.props.load,
      query: query,
      size: this._value.length
    }, (datas) => {
      return React.createElement("div", {
        className: `bs-old-input-selectable-render${this.props.className ? " " + this.props.className : ""}`
      }, datas.map((data, key) => { return render(data, key, () => this._remove(data)) }))
    });
  }
  _getOpenSelectableRender() {
    const open = Selectable.Open.get(this);
    return React.createElement("label", {}, React.cloneElement(open(() => this.open()), {
      key: "bs-old-input-selectable-open"
    }));
  }
  _render() {
    const means = Selectable.Means.get(this);
    return React.createElement(
      "div", {
      className: "bs-old-input-selectable"
    },
      React.createElement("div", {}, this._getValueRender()),
      !this.limit || this._value.length < this.limit
        ? this._getOpenSelectableRender()
        : undefined,
      this._open
        ? means((values => {
          this._value = this._value.concat(values);
          this._open = false;
          this._triggerChange();
        }), (() => { this.close(); }), this._value.slice(), this.props.model)
        : null
    );
  }
}

const Selectable = (props) => React.createElement(Application.Service, { name: ["repository"] }, ([repository]) => 
  React.createElement(InputSelectable, Object.assign({}, props, { model: repository.get(props.model) }), props.children)
);
Selectable.Render = InputSelectable.Render;
Selectable.Open   = InputSelectable.Open;
Selectable.Means  = InputSelectable.Means;

Selectable.List = class List extends React.Component {
  static Render = Slot();
  static Open = Slot();
  static ListTitle = Slot();
  static Item = Slot();
  static Filter = Slot();
  get limit() {
    return this.props.limit;
  }
  _in(values) {
    if (this.props.in) {
      return this.props.in(values);
    }
    return values;
  }
  _out(values) {
    if (this.props.out) {
      return this.props.out(values);
    }
    return values;
  }
  render() {
    const title   = List.ListTitle.get(this);
    const item    = List.Item.get(this);
    const filter  = List.Filter.get(this);
    const render  = List.Render.props(this);
    const open    = List.Open.get(this);
    return (
      <Selectable {...this.props} in={(v) => this._in(v)} out={v => this._out(v)} limit={this.limit} >
        <Selectable.Render {...render}>
          {render.children}
        </Selectable.Render>
        <Selectable.Open>
          {open}
        </Selectable.Open>
        <Selectable.Means>
          {(validHandler, closeHandler, currentValues, model) => {
            let query = this.props.query;
            if (currentValues && currentValues.length) {
              let nor = ({ $or: currentValues.map(value => model.key.extract(value)) });
              query = { $and: [query, { $nor: [nor] }] };
            }
            return React.createElement(
              Modal.Show, {
              close: closeHandler,
              key: "bs-old-input-selectable-modal",
              style: { width: "40vw", 'height': "90vh" }
            },
              React.createElement(
                ListSelector, {
                model: model.name,
                sort: this.props.sort,
                load: this.props.load,
                query: query,
                title: title,
                limit: this.limit ? this.limit - currentValues.length : null,
                min: Math.max((this.props.min ? this.props.min : 0) - currentValues.length, 0),
                close: () => closeHandler(),
                add: (values) => { validHandler(values); closeHandler(); }
              },
                React.createElement(ListSelector.Filter, {}, filter),
                React.createElement(ListSelector.Item, {}, item)
              )
            )
          }}
        </Selectable.Means>
      </Selectable>
    );
  }
}
Selectable.One = class One extends Selectable.List {
  get limit() {
    return 1;
  }
  _out(values) {
    if (!values.length) {
      return null;
    }
    return super._out(values[0]);
  }
  _in(value) {
    if (value === null || value === undefined) return [];
    return [super._in(value)];
  }
}
Selectable.ListSelector = ListSelector;


export default Selectable;