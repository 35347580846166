import React from 'react';

function useForceUpdate(): () => void {
  const [, setState] = React.useState(0);
  
  return React.useCallback(() => {
    setState(n => ++n);
  }, [setState]);
};

export default useForceUpdate;