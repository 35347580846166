import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames, { ClassNames } from '@uLib/classNames';
import './text.css';


class StyleImpl {
  private classNames: ClassNames;

  constructor(){
    this.classNames = classNames();
  }

  private add(value: string): StyleImpl {
    const clone = this.clone();
    clone.classNames.add(value);
    return clone;
  }

  get tiny(): StyleImpl {
    return this.add('bs-text-tiny');
  }
  get small(): StyleImpl {
    return this.add('bs-text-small');
  }
  get standard(): StyleImpl {
    return this.add('bs-text-standard');
  }
  get large(): StyleImpl {
    return this.add('bs-text-large');
  }
  get huge(): StyleImpl {
    return this.add('bs-text-huge');
  }

  get bold(): StyleImpl {
    return this.add('bs-text-bold');
  }
  get italic(): StyleImpl {
    return this.add('bs-text-italic');
  }
  get underline(): StyleImpl {
    return this.add('bs-text-underline');
  }
  get capitalize(): StyleImpl {
    return this.add('bs-text-capitalize');
  }
  get super(): StyleImpl {
    return this.add('bs-text-super');
  }

  get left(): StyleImpl {
    return this.add('bs-text-left');
  }
  get center(): StyleImpl {
    return this.add('bs-text-center');
  }
  get right(): StyleImpl {
    return this.add('bs-text-right');
  }
  get justify(): StyleImpl {
    return this.add('bs-text-justify');
  }

  get black(): StyleImpl {
    return this.add('bs-text-black');
  }
  get orange(): StyleImpl {
    return this.add('bs-text-orange');
  }
  get white(): StyleImpl {
    return this.add('bs-text-white');
  }
  get lightGray(): StyleImpl {
    return this.add('bs-text-light-gray');
  }
  get gray(): StyleImpl {
    return this.add('bs-text-gray');
  }
  get darkGray(): StyleImpl {
    return this.add('bs-text-dark-gray');
  }
  get green(): StyleImpl {
    return this.add('bs-text-green');
  }
  get red(): StyleImpl {
    return this.add('bs-text-red');
  }
  get stdColor(): StyleImpl {
    return this.add('bs-text-stdColor');
  }
  get colorError(): StyleImpl {    
    return this.red;
  }

  clone(): StyleImpl {
    const style = new StyleImpl();
    style.classNames = this.classNames.clone();
    return style;
  }

  getClassNames(): ClassNames {
    return this.classNames.clone();
  }
}

type StaticStyle = Omit<typeof StyleImpl.prototype, 'clone' | 'getClassNames' | 'constructor'>;
type KeyOfStaticStyle = keyof StaticStyle;
const keysStaticStyle: KeyOfStaticStyle[] = Object
  .keys(Object.getOwnPropertyDescriptors(StyleImpl.prototype))
  .filter(propertyName => !['clone', 'getClassNames', 'constructor'].includes(propertyName)) as KeyOfStaticStyle[];

export const Style: StyleImpl & StaticStyle = keysStaticStyle.reduce<StyleImpl & StaticStyle>((object, key) => {
  Object.defineProperty(object, key, {
    get: function() {
      const style = new StyleImpl();
      return style[key];
    }
  });
  return object;
}, StyleImpl as unknown as StyleImpl & StaticStyle);


interface TextProps {
  style?: StyleImpl;
  onClick?: () => void;
  ['data-testid']?: string;
}

const withTextBase = (balise: string) => ({ style, onClick, 'data-testid': dataTestId, children }: PropsWithChildren<TextProps>) => React.createElement(
  balise, {
    onClick,
    className: classNames('bs-text').addNotEmpty(style?.getClassNames()).addIf('bs-text-clickable', !!onClick),
    'data-testid': dataTestId
  },
  children
);

export const Paragraph: FunctionComponent<PropsWithChildren<TextProps>> = withTextBase('p');
export const Text: FunctionComponent<PropsWithChildren<TextProps>> & { Paragraph: typeof Paragraph } = Object.assign(
  withTextBase('span'),
  { Paragraph }
);

export default Text;
