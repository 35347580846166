import React    from "react";
import Slot     from "@uComponents/slot";
import ShowHide from "@cComponents/showHide";

import "./collapsable.css";

const Title   = Slot();
const Content = Slot();

class Collapsible extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      opened: this.props.alwaysOpen || this.props.defaultOpen || false
    }
  }
  
  getIcon() {
    if(this.props.lock)   return "fa-lock";
    if(this.state.opened) return "fa-caret-up";
    else                  return "fa-caret-down";
  }

  render(){
    return (
      <div className={`bs-filter-collapsible ${this.props.alwaysOpen ? "bs-filter-collapsible-alwaysOpen" : "bs-filter-collapsible-openable"}`}>
        <div className="bs-filter-collapsible-title" onClick={() => !this.props.alwaysOpen ? this.setState({ opened: !this.state.opened}) : null }>
          <span className="bs-filter-collapsible-title-text">{ Title.get(this) }</span>
          <span className={`fa ${ this.getIcon() }`} />
        </div>
        <ShowHide hide={!this.state.opened && !this.props.lock}>
          { Content.get(this) }
        </ShowHide>
      </div>
    )
  }
}

Collapsible.Title   = Title;
Collapsible.Content = Content;

export default Collapsible;