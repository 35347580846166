import React, {
  PropsWithChildren,
  RefObject
}            from 'react';
import InnerTextContent from "@cBehaviour/innerTextContent";

import "./textBase.css";

export interface TextBaseProps {
  onChange: (value: string) => void;
  value?: string;
  className?: string,
  focusOnMount?: boolean,
  onKeyPress?: () => void,
  onKeyDown?: () => void,
  onKeyUp?: () => void
}

abstract class TextBase<P = {}, S = {}> extends React.Component<PropsWithChildren<TextBaseProps & P>, S> {
  private _input: RefObject<HTMLInputElement>

  constructor(props: PropsWithChildren<TextBaseProps & P>){
    super(props);
    this._input           = React.createRef<HTMLInputElement>();
    this._onChange        = this._onChange.bind(this);
    this._setPlaceholder  = this._setPlaceholder.bind(this);
  }
  /**
   * @deprecated Ne plus utiliser, cette fonction sera supprimée dans une prochaine version
   */
  get input(){
    return this._input.current;
  }

  get value() : string {
    return this._input?.current?.value || "";
  }

  get selectionStart() : number {
    return this._input?.current?.selectionStart || 0;
  }

  get selectionEnd() : number {
    return this._input?.current?.selectionEnd || 0;
  }

  set value(value: string){
    if (this._input?.current && this.value !== value) {
      let start = this.selectionStart;
      let end   = this.selectionEnd
      this._input.current.value = value;
      if(this.value.length < start){
        start = this.value.length;
      }
      if(this.value.length < end){
        end = this.value.length;
      }
      this._input.current.setRangeText("", start, end, "end");
    }
  }

  focus() {
    if(!this._input.current) {
      return null;
    }
    this._input.current.focus();
    this._input.current.setSelectionRange(this.value.length, this.value.length);
  }

  _onChange(ev: InputEvent){
    const value = (ev.target as HTMLInputElement).value;
    if(this.props.onChange){
      this.props.onChange(value);
    }
  }

  _setPlaceholder(text: string){
    if (this._input?.current) {
      this._input.current.placeholder = text;
    }
  }

  componentDidMount(){
    if(this.props.value !== undefined){
      this.value = this.props.value;
    }
    if(this.props.focusOnMount){
      this.focus();
    }
  }

  componentDidUpdate(){
    this.value = this.props.value ? this.props.value : "";
  }

  _getType(): string {
    throw new Error("Must be override");
  }

  render() {
    return React.createElement(React.Fragment, {},
      React.createElement("input", {
        ref: this._input,
        type: this._getType(),
        className: "bs-input-textBase" + (this.props.className ? " " + this.props.className : ""),
        onChange: this._onChange,
        onKeyPress: this.props.onKeyPress,
        onKeyDown: this.props.onKeyDown,
        onKeyUp: this.props.onKeyUp,
        autoComplete: "off"
      }),
      React.createElement(InnerTextContent, { onReady: this._setPlaceholder }, this.props.children)
    ); 
  }
}

export default TextBase;