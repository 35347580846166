
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultfilterValue = {
  }

  return ModelBuilder.create("Filter", Key.create("_id"), stdRepositoryConnector(application, "filters"), defaultfilterValue)
    .add(p => p("_id", "string"))
    .add(p => p("default", "bool"))
    .add(p => p("type", "string"))
    .add(p => p("name", "string"))
    .add(p => p("value", "string"))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("user", "entity").type("User"))
    .add(p => p("createdAt", "date"))
    .build();
}