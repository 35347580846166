import React      from 'react';
import ReactDOM   from "react-dom";

export default class ChildToProps extends React.Component{
    constructor(props){
        super(props);
        this._ref = null;
    }
    componentDidMount(){
        this._ref[this.props.name] = this._d.innerText
    }
    componentDidUpdate(){
        this._ref[this.props.name] = this._d.innerText
    }
    render(){
        this._d = document.createElement("div");
        const child = this.props.children;
        return (
            <React.Fragment>
                { React.cloneElement(this.props.children, { ref: (node => { 
                    this._ref = node; 
                    const { ref } = child;
                    if (typeof ref === 'function') {
                        ref(node);
                      }
                })})}
                { ReactDOM.createPortal(
                    this.props.child,
                    this._d
                )}
            </React.Fragment>
        )
    }
}