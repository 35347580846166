import React        from "react";
import Application  from "@uBehaviour/application";
import Release      from "@cFeatures/release";
import Data         from "@uBehaviour/data";
import ScrollBar    from "@cComponents/scrollBar";
import Button       from "@cComponents/button";
import T            from "@uBehaviour/i18n";
import Text, { Style } from "@cComponents/text";

import "./list.css";

const ReleaseList = Application.forward(["release"], [], (props) => (
  <div className="bs-release-list">
    <div className="bs-release-list-title">
      <Text style={ Style.huge.bold }><T>release_note</T></Text>
    </div>
    <div className="bs-release-list-content">
      <ScrollBar.ListController>
        <Data.List pager={props.release.pager}>
          <Release.Item application={props.application}/>
        </Data.List>
      </ScrollBar.ListController>
    </div>
    <div className="bs-release-list-footer">
      <Button.Text onClick={() => props.close() }><T>release_seen</T></Button.Text>
    </div>
  </div>
));

export default ReleaseList;