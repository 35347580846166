import React, { FunctionComponent, PropsWithChildren } from "react";
import "./nudge.css";

const types = {
  info: "fa fa-question-circle",
  warning: "fa fa-exclamation-circle"
};

interface NudgeProps {
  type?: keyof typeof types;
}


const Nudge: FunctionComponent<PropsWithChildren<NudgeProps>> = ({ type = "info", children }) => {
  return (
    <div className={ `bs-nudge bs-nudge-${type}`}>
      <div className={ types[type] }/>
      <div>{ children }</div>
    </div>
  )
};

export default Nudge;