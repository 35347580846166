import React        from "react";
import Input        from "./input";

import Address      from "./address";
import InputMap     from "./map";

import Map          from "@cComponents/map"
import Display      from "@cComponents/displayIf";
import Modal        from "@cComponents/modal";
import Form         from "@cBehaviour/form";

import Button       from "@cComponents/button";
import T            from "@cBehaviour/i18n";

import Application  from "@uBehaviour/application";
import CError         from "@cComponents/error";

import "./location.css";
import Location from "@universal/lib/location";

class InputLocation extends Input {
  state = {
    opened: false
  }
  constructor(props){
    super(props);
    this._form        = React.createRef();
    this.open         = this.open.bind(this);
    this.close        = this.close.bind(this);
    this._formSubmit  = this._formSubmit.bind(this);
    this._formChange  = this._formChange.bind(this);
  }

  open(){
    if(!this.state.opened){
      this.setState({ opened: true });
    }
  }
  close(){
    if(this.state.opened){
      this.setState({ opened: false });
    }
  }
  _formSubmit(value){
    if(value.address && value.position){
      this._value = value;
      this.state.opened = false;
      this._triggerChange();
      return Promise.resolve();
    }
  }
  _formChange(value, origin){
    let p = Promise.resolve(value);
    let updated = false;
    if(origin === "address" && value.address){
      p = this.props.geolocation.getPositionFromAddress(value.address).then(position => {
        value.position = position;
        updated = true;
        return value;
      });
    } else if(origin === "position" && value.position){
      p = this.props.geolocation.getAddressFromPosition(value.position).then(address => {
        value.address = address;
        updated = true;
        return value;
      });
    }
    return p.then(value => {
      if(updated){
        this._form.current.update(value, origin);
      }
      return value;
    });
  }
  getErrors() {
    const errors = [];
    if (this.props.required && !this.value){
      errors.push({
        path:this.props.name,
        error: "required_input"
      });
    }
    return errors;
  }
  __render(){
    const value = this._value ? this._value : { position: this.props.geolocation.current, address: null };
    const render = (
      <div className="bs-old-input-location" style={{ height: "300px" }} onClick={ this.open }>
        <Map default={ this.props.geolocation.current } zoomOnScroll={true} cursor="pointer">
          { value.position
              ? (<Map.Shape definition={ value.position } options={{ icon: this.props.icon }}/>)
              : null
          }
        </Map>
        <span className="bs-old-input-location-address">
          <span className="bs-old-input-location-address-marker fa fa-map-marker" />
          <span className="bs-old-input-location-address-text">{ Location.getLabelFromAddress(value.address ? value.address : {}) }</span>
        </span>
        <Display.If condition={ this.state.opened }>
          <Modal.Show close={ this.close } style={ {height: "90vh", width: "90vw" } }>
            <div className="bs-old-input-location-edit">
              <Form.Simple name={ this.fullName } submit={ this._formSubmit } value={ value } onChange={ this._formChange } ref={ this._form }>
                {(object, errors, form) => (
                  <>
                    <Address noError={ true } name="address"/>
                    <InputMap name="position" icon={this.props.icon}/>
                    <Button.Text onClick={ () => form.submit() }><T>update</T></Button.Text>
                  </>
                )}
              </Form.Simple>
            </div>
          </Modal.Show>
        </Display.If>
      </div>
    );

    if(this.props.noError){
      return render;
    }
    return (
      <CError errors={this._errors}>{ render }</CError>
    );
  }
}

export default Application.forward(["geolocation"], [], InputLocation);