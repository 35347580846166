import React          from 'react';
import PropTypes      from 'prop-types';
import Event          from "@cLib/event";
import InputObject    from "./objectContext";

import "./input.css";

export default class Input extends React.Component {
  static propTypes = {
    name: PropTypes.string
  }
  constructor(props) {
    super(props);
    this._in  = this.props.in  ? this.props.in   : (v => v);
    this._out = this.props.out ? this.props.out  : (v => v);
    this._onChange      = new Event();
    this._triggerChange = this._triggerChange.bind(this);
  }

  get onChange() {
    return this._onChange;
  }

  focus() {
    return new Error("focus() must be overriden");
  }

  getErrors() {
    return new Error("getErrors() must be overriden");
  }
  _triggerChange(origin){
    if(!this._inputObject){
      this._value = this.value;
      //this.props.noRendering : Utilisé et doit être utilisé uniquement par le FormSimple
      //Ne pas utiliser cette props!!!!!
      if (!this.props.noRendering) {
        this.forceUpdate();
      }      
    }
    if(!origin) origin = this;
    this._onChange.trigger("onChange", this, origin);
    if(this.props.onChange){
      this.props.onChange(this, origin);
    }
  }

  clear(){
    this._value = null;
    this._triggerChange();
  }

  get name() {
    return this.props.name;
  }
  get fullName(){
    if(this._inputObject){
      let parentName = this._inputObject.fullName;
      if(parentName) parentName += ".";
      return `${parentName}${this.name}`;
    }
    return this.name;
  }

  get value() {
    return this._out(this._value);
  }

  set value(val) {
    return new Error("value::set must be overriden");
  }
  
  componentDidMount(){
    if(this._inputObject){      
      this._inputObject.register(this);
    }
  }
  
  componentWillUnmount(){
    if(this._inputObject){
      this._inputObject.unregister(this);
    }
  }
  
  __render(){
    throw new Error("Must be override");
  }
  _localValue(v){
    return v;
  }

  render(){
    return (
      <InputObject.Context.Consumer>
        {(inputObject) => {          
          if(inputObject && this.props.name) {            
            this._inputObject = inputObject;
            this._value       = this._in(this._localValue(this._inputObject._getValue(this)));
            this._errors      = this._inputObject._getErrors(this);
          } else if(this.props.value) {
            if(this._lastValue !== this.props.value){
              const value     = this._in(this.props.value);
              this._lastValue = value;
              this._value     = value;
              this._errors    = [];
            }
          }
          if(this._value === undefined && this.props.default !== undefined){
            this._value = this.props.default;
          }
          return this.__render();
        }}
      </InputObject.Context.Consumer>
    )
  }
};