import React  from "react";
import md5    from "md5";
import Key    from "@uLib/model";

const getFnKey = (key) => {
  if(key + "" === key){
    return (object) => object[key];
  }
  if(key instanceof Function){
    return key;
  }
  if(key instanceof Key){
    return (object) => key.hash(object);
  }
  return (object) => md5(JSON.stringify(object));;
};

const List = ({ k, datas, children }) => {
  const fnKey = React.useMemo(() => getFnKey(k), [k]);

  const buildChildren = React.useCallback(
    children instanceof Function
      ? (data, idx, datas) => children(data, fnKey(data, idx), idx, datas.length)
      : (data, idx, datas) => React.cloneElement(children, { data, key: fnKey(data, idx), idx, length: datas.length})
  , [children, fnKey]);

  return (<>{ datas.map(buildChildren) }</>);
};

export default List;