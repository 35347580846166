// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-layout-columns {
  display: flex;
  flex-direction: row;
}
.bs-layout-column {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}`, "",{"version":3,"sources":["webpack://./../common/components/layout/columns.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".bs-layout-columns {\n  display: flex;\n  flex-direction: row;\n}\n.bs-layout-column {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
