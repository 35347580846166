import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {

  const defaultValorizationValue = {
    statements: [],
    statementOf: new Date()
  };

  return ModelBuilder.create("Valorization", Key.create("_id"), stdRepositoryConnector(application, "valorizations"), defaultValorizationValue)
    .add(p => p("_id", "string"))
    .add(p => p("issue", "string"))
    .add(p => p("assignment", "string"))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("updatedAt", "date"))
    .add(p => p("updatedBy", "entity").type("User"))
    .add(p => p("statementOf", "date"))
    .add(p => p("statements", "array")
      .of(t => t("object")
        .add(p => p("discriminator", "string"))
        .add(p => p("name", "string"))
        .add(p => p("externalReference", "string"))
        .add(p => p("description", "string"))
        .add(p => p("files",  "entity").type("File"))
        .add(p => p("agent",  "entity").type("User"))
        .add(p => p("team",  "entity").type("Team"))
        .add(p => p("equipment",  "entity").type("Equipment"))
        .add(p => p("supply",  "entity").type("Supply"))
        .add(p => p("duration", "int"))
        .add(p => p("cost", "int"))
        .add(p => p("files", "array").of(t => t("entity").type("File")))
        .add(p => p("unitCost", "int"))
        .add(p => p("unit", "string"))
        .add(p => p("quantity", "int"))        
      )
    )
    .build();
}