import React from "react";
import "./numeric.css";

const isSet   = value => value !== undefined && value !== null;
const integer = /[0-9]*/;
const decimal = /[0-9]*(\.[0-9]*)?/;

class InputNumeric extends React.Component{
  constructor(props){
    super(props);
    this._input     = React.createRef();
    this._onChange  = this._onChange.bind(this);
  }
  get value(){
    return this._out(this.props.numericType === "integer"
      ? parseInt(this._input.current.value)
      : parseFloat(this._input.current.value));
  }
  set value(value){
    value = this._in(value);
    if(this.valid(value)){
      this._input.current.value = !this.props.disabled ? value : null;
    }
  }
  focus(){
    this._input.current.focus();
  }
  select(){
    this._input.current.select();
  }
  valid(value){
    if (!this.props.nullable && value === "") {
      return false;
    }
    let v = value;
    if(v + "" === v){
      const regexp = this.props.numericType === "integer" ? integer : decimal;
      if(!regexp.test(value)) return false;
      v = this.props.numericType === "integer"
        ? parseInt(value)
        : parseFloat(value);
    }
    if(isSet(this.props.min) && v < this.props.min) return false;
    if(isSet(this.props.max) && v > this.props.max) return false;
    return true;
  }
  _onChange(){
    const value = this._input.current.value
    if(this.props.onChange){
      if(this.valid(value)){
        this.props.onChange(this.value, this);
      }else if(isSet(this.props.min) && value < this.props.min){
        this.props.onChange(this._out(this.props.min), this);
      }else if(isSet(this.props.max) && value > this.props.max){
        this.props.onChange(this._out(this.props.max), this);
      }
    }
  }
  componentDidMount(){
    const value = isSet(this.props.value)
      ? this.props.value
      : isSet(this.props.defaultValue)
        ? this.props.defaultValue
        : isSet(this.props.min)
          ? this.props.min
          : 0;
    
    this.value = value;
  }
  componentDidUpdate(){
    if(isSet(this.props.value)){
      this.value = this.props.value;
    }
  }
  _in(value){
    if(this.props.in){
      return this.props.in(value);
    }
    return value;
  }
  _out(value){
    if(this.props.out){
      return this.props.out(value);
    }
    return value;
  }
  render(){
    const props = {
      type:       "number",
      className:  `bs-input-numeric${ this.props.className ? ' ' + this.props.className : ''}`,
      ref:        this._input,
      disabled:   this.props.disabled,
      onClick:    (ev) => ev.stopPropagation(),
      onChange:   this._onChange,
    };
    if(this.props.min){
      props.min = this.props.min;
    }
    if(this.props.max){
      props.max = this.props.max;
    }
    if(this.props.step){
      props.step = this.props.step;
    }
    return React.createElement("input", props);
  }
}

export default InputNumeric;