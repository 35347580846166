
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultContactValue = {
    disabled:false,
    emails:[]
  };

  return ModelBuilder.create("Contact", Key.create("_id"), stdRepositoryConnector(application, "contacts"), defaultContactValue)
    .add(p => p("_id", "string"))
    .add(p => p("name", "string"))
    .add(p => p("emails", "array").of(t => t("string")))
    .add(p => p("description", "string"))
    .add(p => p("disabled", "bool"))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .build();
}