import React  from "react";
import Text   from "./text";
import T      from "@uBehaviour/i18n";

import "./url.css";

const onChange = (handler, value, type) => {
  return (v) => {
    value[type] = v;
    if(handler){
      handler(value);
    }
  }
};
export default (props) => {
  let value = props.value ? props.value : {};
  return (
    <div className="bs-input-url">
      <Text value={ value.label } onChange={ onChange(props.onChange, value, "label") }>
        <T>input_url_label</T>
      </Text>
      <Text value={ value.url   } onChange={ onChange(props.onChange, value, "url") }>
        <T>input_url_url</T>
      </Text>
    </div>
  );
}