import React       from "react";
import Slot        from "@uComponents/slot";
import Application from "@uBehaviour/application";
import Pager       from './pager';

class Query extends Pager {
  invalidCache(){
    this.forceReload();
  }
  render(){
    return this.props.children instanceof Function
            ? this.props.children(this._pager.loaded, this._firstLoad)
            : React.cloneElement(this.props.children, { datas: this._pager.loaded, firstLoad: this._firstLoad});
  }
}
class AggregatorWaiter extends React.Component{   
  constructor(props){
    super(props);
    this.state = {
      results: null
    };
  }
  render(){
    if(!this.state.results){
      return null;
    }
    return this.props.children(this.state.results);
  }
}
class Aggregator extends React.Component {
  constructor(props){
    super(props);
    this.waiter = React.createRef();
  }
  wait(){
    Promise.all(this.promises).then(results => {
      if(this.waiter.current){
        this.waiter.current.setState({ results: results });
      }
    });
  }
  componentDidMount(){
    this.wait();
  }
  componentDidUpdate(){
    this.wait();
  }
  render(){
    this.promises = [];
    const queries   = Aggregator.Queries.get(this, true);    
    const ret       = React.Children.map(queries, (query, index) => {
      let resolve;
      this.promises.push(new Promise(_resolve => resolve = _resolve));
      return React.cloneElement(query, { key: index }, result => {
        resolve(result);
        return null;
      });
    });
    ret.push(React.createElement(AggregatorWaiter, { key:"rendering", ref: this.waiter}, Aggregator.Children.get(this)));
    return ret;
  }
}
Aggregator.Queries  = Slot();
Aggregator.Children = Slot();

const ExportedQuery = Application.Service.forward([["pager", "pagerService"]], Query);

ExportedQuery.Aggregator = Aggregator;
export default ExportedQuery;