import React        from "react";
import AsyncDisplay from "@cComponents/asyncDisplay";
import { StaticGoogleMap, Marker }  from 'react-static-google-map';

const AsyncImage = (props) => React.createElement(
  AsyncDisplay.WaitMe, 
  {},
  ready => React.createElement("img", {
    src: props.src,
    onLoad: ready,
    onError: ready
  })
);
export default class StaticMap {
  static get name (){
    return "static";
  }
  static get mapComponent(){
    return StaticGoogleMap;
  }
  static getMapProps(props, onZoomChanged, onDragEnd, onLoad){
    return {
      as: AsyncImage,
      apiKey: window.google.apiKey,
      size: `${props.width}x${props.height}`,
      zoom: props.zoom ? props.zoom : 16,
      center: `${props.defaultcenter.latitude},${props.defaultcenter.longitude}`
    };
  }
  static getShapeComponent(shapeType){
    return Marker;
  }
  static getShapeProps(shape){
    return {
      location: {  lng: shape.geometry.longitude, lat: shape.geometry.latitude }
    };
  }
};