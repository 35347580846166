import React        from "react";
import Field        from "@cComponents/old/field";
import InputObject  from "@cComponents/old/input/object";
import Display      from '@cComponents/displayIf';

import "./standart.css";

export default class Standart extends React.Component {
    _threadChild(child, idx){
        if(child.type === InputObject) return React.cloneElement(child, {
                children: React.Children.map(child.props.children, (child, idx) => this._threadChild(child, idx))
            });
        if(child.type !== Field){
            return child;
        }

        let required = false;
        React.Children.forEach(child.props.children, c => {
            if (c.props.required) {
                required = true;
            }
        })

        return (
            <div key={ idx } className={`bs-form-layout-row${child.props.disabled ? " bs-disabled" : ""}${child.props.className ? " " + child.props.className : ""}`}>
                <div className="bs-form-layout-label"> 
                    <span>{Field.Label.get(child)}</span>
                    <Display.If condition = {required}> *</Display.If>
                </div>
                <div className="bs-form-layout-input"> {
                    required
                        ? React.cloneElement(Field.Input.get(child), {
                            required: true
                        })
                        : Field.Input.get(child)
                }</div>
            </div>
        )
    }
    render(){
        return (
            <div className={`bs-form-layout bs-form-standart-layout bs-dispose-${this.props.dispose}`}>
                {
                    React.Children.map(this.props.children, (child, idx) => this._threadChild(child, idx))
                }
            </div>
        )
    }
}