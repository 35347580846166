import React       from "react";
import FormSimple  from "./simple";
import Data        from "@uBehaviour/data";
import Event       from "@cLib/event";
import Application from "@uBehaviour/application";


/**
 * @deprecated
 */
class FormModel extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
    this._submitted = new Event();
    this._saved = new Event();
    this._submit = this._submit.bind(this);
    this._onSubmitted = this._onSubmitted.bind(this);
  }
  update(value, origin) {
    this._form.current.update(value, origin);
  }
  componentDidMount() {
    if (this._form.current)
      this._form.current.onSubmit.addListener(this);
  }
  componentWillUnmount() {
    if (this._form.current)
      this._form.current.onSubmit.removeListener(this);
  }
  handleEvent() {
    this._submitted.trigger(this);
  }
  get onSaved() {
    return this._saved;
  }
  get onSubmit() {
    return this._submitted;
  }
  submit(force) {
    return this._form.current.submit(force);
  }
  _validate(value, errors) {
    if (this.props.validate) {
      this.props.validate(value, errors);
    }
  }
  _submit(value) {
    const methodName =
      !this._entity
        ? "create"
        : "update";
    if (this._entity) {
      value._id = this._entity._id;
    }
    return this.props.model.repository[methodName](value)
      .then(value => {
        const extractedValue = this.props.extractValueAfterSubmit ? this.props.extractValueAfterSubmit(value) : value;
        this._onSubmitted(value);
        if (this._entity) {
          this._entity.update(extractedValue);
        } else {
          this._entity = this.props.model.create(extractedValue);
        }
        this._saved.trigger(this);
      });
  }
  _onSubmitted(value) {
    if (this.props.onSubmitted) {
      this.props.onSubmitted(value);
    }
  }
  render() {
    let val = this.props.value;
    if (val && val.toPlainText) {
      val = val.toPlainText();
    }
    const form = (data) => (
      <FormSimple name={this.props.name ? this.props.name : `Form::${this.props.model.name}#${data._id}`}
        value={data}
        onChange={this.props.onChange}
        presubmit={this.props.presubmit}
        preload={this.props.preload}
        ref={r => { this._form.current = r; this.componentDidMount(); return r; }}
        submit={this._submit}
        segment={this.props.segment ? true : false}
        observer={this.props.observer}
        validate={(value, errors) => this._validate(value, errors)}>
        { this.props.children}
      </FormSimple>
    );

    return this.props.id
      ? (
        <Data.One model={this.props.model.name} id={this.props.id}>
          {entity => {
            this._entity = entity;
            return form(entity.toPlainText())
          }}
        </Data.One>
      )
      : form(this.props.value ? this.props.value : this.props.model.default)
  }
}

export default React.forwardRef((props, ref) =>
  React.createElement(Application.Service, { name: ["repository"] }, ([repository]) =>
    React.createElement(FormModel, Object.assign({}, props, { model: repository.get(props.model), ref: ref }), props.children)
  ));