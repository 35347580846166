import React from 'react';

//import './list.css';

class List extends React.Component{
  render() {
    return(
      <button className={`bs-button bs-button-list${this.props.pushed ? " pushed" : ""}`} onClick={(ev) => { ev.stopPropagation(); this.props.onClick(ev); }}>
        { this.props.children }
      </button>
    );
  }
}

export default List;