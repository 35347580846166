import AclService from '../../../services/acl';
import GeolocationService from '@common/services/geolocation';

export const getDefaultIssue = (aclService: AclService, geolocation: GeolocationService) => {
  const defaultIssue: any = {
    requestor: {
      type: "userPro"
    },
    files: [],
    description: "",
    discriminator: "",
    category: null,
    tags: [],
  };

  if(aclService.connectedUserIsAllow("issues", "createOnPublicSpace")){
    defaultIssue.discriminator = "publicSpace";
    defaultIssue.location = {
      position: geolocation.current
    }
  } else if(aclService.connectedUserIsAllow("issues", "createOnBuilding")){
    defaultIssue.discriminator ="building";
  } else if(aclService.connectedUserIsAllow("issues", "createOnEquipment")){
    defaultIssue.discriminator = "equipment";
  } else {
    throw new Error("Can't create");
  }

  return defaultIssue;
}

export default {
  getDefaultIssue
};