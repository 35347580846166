import Checkbox             from "./checkbox";
import Text                 from "./text";
import Date                 from "./date";
import DateTime             from "./datetime";
import Time                 from "./time";
import TimeDuration         from "./timeDuration";
import File                 from "./file";
import FileGallery          from './file/gallery.jsx';
import Radio                from "./radio";
import Array                from "./array";
import Url                  from "./url";
import TextArea             from "./textarea";
import Editor               from "./editor";
import Select               from "./select";
import SelectModel          from "./select/model";
import Autocomplete         from "./autocomplete";
import AutocompleteModel    from "./autocompleteModel";
import Hour                 from "./hour";
import Period               from "./period";
import Numeric              from "./numeric";
import Duration             from "./duration";
import Tag                  from "./tag";
import Selectable           from "./selectable";
import Address              from "./address";
import Textarea             from "./textarea";
import SimpleSelectable     from "./simpleSelectable";
import Map                  from "./map";
import Quantity             from './quantity';
import Password             from './password';
import I18N                 from "./i18n";
import AddressMap           from "./addressMap";

File.Gallery        = FileGallery;

Autocomplete.Model  = AutocompleteModel;
Select.Model        = SelectModel;

export default {
  I18N,
  Address,
  Array,
  Checkbox,
  Text,
  Date,
  DateTime,
  Time,
  TimeDuration,
  File,
  Radio,
  Url,
  TextArea,
  Editor,
  Select,
  Autocomplete,
  Hour,
  Period,
  Map,
  Numeric,
  Duration,
  Tag,
  Selectable,
  SimpleSelectable,
  Textarea,
  Quantity,
  Password,
  AddressMap
};