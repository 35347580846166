import React from 'react';

type UseOpenCloseToggleHandle = (defaultOpenState?: boolean) => [boolean, () => void, () => void, () => void];

const useOpenCloseToggle: UseOpenCloseToggleHandle = (defaultOpenState = false) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpenState);

  const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const toggle = React.useCallback(() => setIsOpen(isOpen => !isOpen), [setIsOpen]);

  return [isOpen, open, close, toggle];
}

export default useOpenCloseToggle;