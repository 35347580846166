import React      from 'react';
import Slot       from './slot';
import Decorator  from "./decorator";
import FocusBlur  from './focusBlur';

import './dropDown.css';
class DropDown extends React.Component {
  static Menu     = Slot();
  static Content  = Slot();
  state = {
    open: false
  }
  constructor(props){
    super(props);
    this._onClick       = this._onClick.bind(this);
    this._onMouseEnter  = this._onMouseEnter.bind(this);
    this._onMouseLeave  = this._onMouseLeave.bind(this);

  }
  open(){
    if(!this.state.open){
      this.setState({ open: true });
    }
  }
  close = () => {
    if(this.state.open){
      this.setState({ open: false });
    }
  }
  switch(){
    this.setState({ open: !this.state.open });
  }
  _onClick(e){
    e.stopPropagation();
    e.preventDefault();
    this.switch();
  }
  _onMouseEnter(){ this.open(); }
  _onMouseLeave(event){
    let e = event.toElement || event.relatedTarget;
    while(e){
      if(e === event.currentTarget){
        return;
      }
      e = e.parentElement;
    }
    this.close();
  }
  render(){
    if(this.props.alwaysOpen && this.props.alwaysClosed){
      throw new Error('Can not be alwaysOpen and alwaysClosed at the same time');
    }
    let open = this.state.open;
    if(this.props.alwaysOpen){
      open = true;
    } else if(this.props.alwaysClosed){
      open = false;
    }
    const menu = DropDown.Menu.get(this);
    const content = DropDown.Content.get(this);
    const containerProps = { className: this.props.className };
    let Component = null;
    switch(this.props.open){
      case "click":
        containerProps.onClick = this._onClick;
        containerProps.onBlur = this.close;
        Component = FocusBlur;
      break;
      case "hover":
      default:
        containerProps.onMouseEnter = this._onMouseEnter;
        containerProps.onMouseLeave = this._onMouseLeave;
        Component = "div"
    }
    return React.createElement(Decorator.Style.ClassName, { className: "bs-dropdown" },
      React.createElement(Component, containerProps, (
        <div className="bs-menu">
          { menu instanceof Function ? menu(open) : React.cloneElement(menu, { pushed: open }) }
        </div>
      ), (
        <div className={"bs-content" + (this.props.noMaxWidth ? '' : ' bs-content-maxWith') + (this.props.alignRight ? " bs-right" : " bs-left") + (open ? " bs-open" : " bs-close")}>
          { content instanceof Function ? content(this.close) : React.cloneElement(content, { close: this.close }) }
        </div>
      ))
    );
  }
}

DropDown.List = class List extends React.Component {
  static Main   = Slot();
  static List   = Slot();
  render() {
    const main        = List.Main.get(this);
    const list        = List.List.get(this);
    return(
      <Decorator.Style.ClassName className="bs-button-dropdown">
        <DropDown alignRight={ this.props.alignRight } className={ this.props.className } alwaysOpen={ this.props.alwaysOpen } alwaysClosed={ this.props.alwaysClosed } open={ this.props.open }>
          <DropDown.Menu>
            <Decorator.Style.ClassName className="bs-button">
              { main }
            </Decorator.Style.ClassName>
          </DropDown.Menu>
          <DropDown.Content>
            <Decorator.Structure.Listable>
              <div>
                { list }
              </div>
            </Decorator.Structure.Listable>
          </DropDown.Content>
        </DropDown>
      </Decorator.Style.ClassName>
    )
  }
}
export default DropDown;