import StringBuilder          from './stringBuilder';
const getLabelFromObject = (location) => {
  return `${location.building ? location.building.name + (location.address ? ", " : ""): ""}${location.address ? getLabelFromAddress(location.address) : ""}`
};
const getLabelFromAddress = (address) => {
  return StringBuilder.create()
    .addIfExist(address.streetNumber, " ", !!address.street)
    .addIfExist(address.street, ", ")
    .addIfExist(address.zip).add(" ")
    .addIfExist(address.locality).add(", ")
    .addIfExist(address.country)
    .build()
}
export default {
  getLabelFromObject,
  getLabelFromAddress
}