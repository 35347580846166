export default class StringBuilder {
	static create(join){
		return new StringBuilder(join);
	}
	constructor(join = ""){
		this.strings = [];
    this.join    = join;
	}
	add(subject, condition = true){
        if(condition) this.strings.push("" + subject);
		return this;
	}
	addIfExist(subject, completeWith = "", condition = true){
		if(subject){
			this.add(subject + completeWith, condition);
		}
		return this;
	}
	build(join){
		return this.strings.join(join || this.join).trim();
	}
	toString(){
		return this.build();
	}
};