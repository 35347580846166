import React      from "react";
import deepDiff   from "deep-diff";
import _          from "lodash";
import md5        from "md5";
import deepClone  from "@uLib/deepClone";
import validate   from '@uLib/validator';

const FormContext = React.createContext(null);

const get = (name, object) => name.split(".").reduce((object, property) => {
  if(!object) return undefined;
  return object[property];
}, object);

const set = (name, object, v) => {
  const path = name.split(".");
  if(path.length > 1){
    object = path.slice(0, -1).reduce((object, property) => {
      if(!object[property]){
        object[property] = {};
      }
      return object[property];
    }, object);
  }
  object[path[path.length -1]] = v;
};

class Form extends React.Component {
  static toRelayError(errors, from) {
    return errors.map(error => {
      return {
        ...error,
        path: error.path.replace(from + ".", "")
      }
    });
  }
  constructor(props){
    super(props);
    this.state = {
      object: this.props.value ? this.props.value : this.props.default ? this.props.default : {},
      ctx: {},
      diffs: {
        forward: [],
        backward: []
      },
      errors: {
        global:[],
        properties:[]
      }
    };
    if(this.props.preload){
      this.state.object = this.props.preload(this, this.state.object);
    }
    this._lastHash = this.hash;
    this._ctx = null;

    this._changes = [];
    this._applyChange = _.debounce(this._applyChange.bind(this), 0);

    this._updatedObject     = null;
    this._lastUpdatedObject = null;
  }

  get ctx(){
    let ctx;
    if(this._ctx){
      ctx = this._ctx;
    } else {
      ctx = this.state.ctx;
    }
    return Object.assign({}, ctx);
  }
  set ctx(ctx){
    if(this._ctx){
      this._ctx = ctx;
    } else {
      const state   = this._cloneState();
      state.ctx = ctx;
      this.setState(state);
    }
  }

  get hash(){
    return md5(JSON.stringify(this.state.object));
  }

  _cloneState(){
    const ctx       = this.state.ctx;
    delete this.state.ctx;
    const state     = deepClone(this.state);
    this.state.ctx  = ctx;
    state.ctx       = this.state.ctx;
    return state;
  }
  _updateValue(object){
    const diffs         = deepDiff.diff(this._lastUpdatedObject, object);
    this._changes       = this._changes.concat(diffs);
    this._applyChange();
  }
  _applyChange(){
    const { object }    = this._cloneState();
    this._changes.forEach(diff => {
      deepDiff.applyChange(object, diff);
    });

    this._updatedObject     = null;

    this._changes       = [];


    const state         = this._cloneState();
    this._ctx           = state.ctx;

    const diffs         = deepDiff.diff(state.object, object);

    if(!diffs) {
      this.setState(state);
      this._ctx       = null;  
      return;
    }

    state.object        = this.onChange(object, JSON.parse(JSON.stringify(diffs)), "update");
    state.diffs.backward.push(diffs);
    state.diffs.forward = [];

    state.ctx       = this._ctx;
    this._ctx       = null;  

    state.errors = { global:[], properties:[] };

    this.setState(state);
  }
  forward(){
    if(this.props.hasDependency || !this.state.diffs.forward.length) return false;

    const state         = this._cloneState();
    this._ctx           = state.ctx;

    const diffs         = state.diffs.forward.pop();
    const object        = this.value;

    diffs.forEach(diff => {
      deepDiff.applyChange(object, true, diff);
    });

    state.object = this.onChange(object, JSON.parse(JSON.stringify(diffs)), "forward");
    state.diffs.backward.push(diffs);

    state.ctx = this._ctx;
    this._ctx = null;  

    this.setState(state);
  }
  backward(){
    if(this.props.hasDependency || !this.state.diffs.backward.length) return false;

    const state         = this._cloneState();
    this._ctx           = state.ctx;

    const diffs         = state.diffs.backward.pop();
    const object        = this.value;

    diffs.forEach(diff => {
      deepDiff.revertChange(object, true, diff);
    });
    state.object = this.onChange(object, deepDiff.diff(state.object, object), "backward");
    state.diffs.forward.push(diffs);

    state.ctx = this._ctx;
    this._ctx = null;  

    this.setState(state);
  }
  hasBeenUpdated() {
    return this._lastHash !== this.hash;
  }
  submit = (force = false) => {
    let errors = { global:[], properties:[] };
    if (!force && !this.hasBeenUpdated()) {
      errors.global.push({error:"any_changes_form"})
      this.setState({errors:errors});
    }
    if ((!this._submitting && this.hasBeenUpdated()) || force) {
      this._submitting = true;   
      return Promise.resolve().then(async ()=> {
        if (this.props.validator) {
          const errs = await validate(this.props.validator, this.value);
          errs.forEach(err => err.path ? errors.properties.push(err) : errors.global.push(err))
        }
        this.setState({
          errors: errors
        });
        if (!this._hasErrors()) {
          return Promise.resolve(JSON.parse(JSON.stringify(this.state.object)))
            .then(value => this.props.presubmit ? this.props.presubmit(this, value) : value)
            .then(value => this.props.submit(this, value))
            .then(()=>{ this._lastHash = this.hash; }, error => {
              if (!error) {
                return;
              }
              if (error.code !== 500 && error.code !== 503) {
                this._lastHash = this.hash;
              }
              let errors = null;
              if(!error.path){
                errors = { global: [{ code: error.code, error: error.message}], properties: [] };
              }else{
                errors = { global: [], properties: [{ code: error.code, error: error.message, path: error.path }] };
              }
              this.setState({
                errors:errors
              })
            }).catch(err => {
              console.error(err)
            });
        }else{
          return Promise.reject(errors);
        }
      }).then(() => {
        this._submitting = false;
      }, err => {
        this._submitting = false;
        return Promise.reject(err);
      })
    }
  }
  onChange(object, diff, type){
    this._submitting = false;
    if(this.props.onChange){
      return this.props.onChange(this, object, diff, type);
    }
    return object;
  }
  get value(){
    return deepClone(this.state.object);
  }

  get errors() {
    return deepClone(
      this.props.hasDependency
      ?  { properties: (this.props.errors || []), global: [] }
      : this.state.errors
    );
  }

  _hasErrors() {
    const errors = this.errors;
    return errors.properties.length || errors.global.length;
  }

  _getUpdatedObject(){
    if(!this._updatedObject){
      this._updatedObject = this.value;
      this._lastUpdatedObject = this.value;
    }else{
      this._lastUpdatedObject = this._updatedObject;
      this._updatedObject = deepClone(this._updatedObject);
    }
    return this._updatedObject;
  }

  get(name){
    return get(name, this.value);
  }
  add(name, value){
    const object    = this._getUpdatedObject();
    const array     = get(name, object);
    if(!array){
      set(name, object, [value]);
    }else{
      array.push(value);
    }
    this._updateValue(object);
  }
  drop(name, index){
    const object  = this._getUpdatedObject();
    const array   = get(name, object);
    if(!array || array.length <= index){
      throw new Error(`Undefined index(${index}) in "${name}"`);
    }
    array.splice(index, 1);
    this._updateValue(object);
  }
  set(name, index, value){
    if(value === undefined){
      value = index;
      index = undefined;
    }
    if(index !== undefined){
      name += "." + index;
    }
    const object = this._getUpdatedObject();
    set(name, object, value);
    this._updateValue(object);
  }
  clear(name){
    if(!name){
      this._updateValue(this.props.default ? this.props.default : {});
      return;
    }
    const mainObject  = this._getUpdatedObject();
    let object        = mainObject;
    const path        = name.split(".");
    if(path.length > 1){
      object    = get(path.slice(0, -1).join("."), object);
    }
    if(object && object[path[path.length - 1]]){
      delete object[path[path.length - 1]];
      this._updateValue(mainObject);
    }
  }
  componentDidUpdate(prevProps){
    if(this.props.hasDependency && prevProps.value !== this.props.value){
      this.setState({ object: this.props.value });
    }
  }

  getErrors(name){
    const errors = this.errors;
    return errors.properties.filter(e => e.path.startsWith(name));
  }

  render(){
    return React.createElement(FormContext.Provider, { value: { form: this }},
      this.props.children instanceof Function
        ? this.props.children(this.ctx, this.value, this.errors, this, this.submit)
        : this.props.children
    );
  }
}
const InputAdapter = ({ name, multiple = false, children }) => React.createElement(FormContext.Consumer, null,
({ form }) => {
  const value = form.get(name);
  if(multiple){
    return children(value, (value => form.add(name, value)), (index => form.drop(name, index)), ((index, value) => form.set(name, index, value)), (() => form.clear(name)));
  }else{
    return children(value, (value => form.set(name, value)), (() => form.clear(name)));
  }
});
InputAdapter.get = (component, name, multiple = false) => React.createElement(InputAdapter, { name, multiple }, (value, onChange) => React.createElement(component, { value, onChange}));

const ErrorAdapter = (props) =>  React.createElement(
  FormContext.Consumer,
  null,
  ({ form }) => props.name ? props.children(form.getErrors(props.name)) : props.children(form.errors.global)
);

const Adapter = ({ name, multiple = false, children }) => (
  <ErrorAdapter name={ name }>
  {(errors) => (
    <InputAdapter name={ name } multiple={ multiple }>
    {
      (...args) => children(errors, ...args)
    }
    </InputAdapter>
  )}
  </ErrorAdapter>
);


Form.InputAdapter   = InputAdapter;
Form.ErrorAdapter   = ErrorAdapter;
Form.Adapter        = Adapter;

export default Form;