export const get = (path, value) => path.split(".").reduce((value, property) => value ? value[property] : undefined, value);



export const deepReplace = (obj, oldKey, newKey) => {
  if(obj === null || typeof obj !== "object"){
    return obj;
  }
  if(Array.isArray(obj)){
    return obj.map(item => deepReplace(item, oldKey, newKey));
  }
  const newObj = {};
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if(key.includes(oldKey)){
      key = key.replace(oldKey, newKey);
    }
    newObj[key] = deepReplace(value, oldKey, newKey);
  });
  return newObj;
};

export default {
  get,
  deepReplace
}
