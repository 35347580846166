import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultTeamValue = {
    type:"internal",
    disabled:false
  }

  return ModelBuilder.create("Team", Key.create("_id"), stdRepositoryConnector(application, "teams"), defaultTeamValue)
    .add(p => p("_id", "string"))
    .add(p => p("name", "string"))
    .add(p => p("avatar", "entity").type("File"))
    .add(p => p("code", "string"))
    .add(p => p("disabled", "bool"))
    .add(p => p("type", "string"))
    .add(p => p("$hasValorization", "bool"))
    .add(p => p("valorization", "int"))
    .add(p => p("members", "array").of(t => t("entity").type("User")))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .build();
}