import React from "react";
import DataSource from "@uLib/dataSource";

export default class SourceList extends React.Component {
  constructor(props) {
    super(props);
    this._dataSource = new DataSource(this.props.source);
    this.state = {
      results: undefined
    };
  }
  
  invalidateCache(){
    this._dataSource.clear();
    this._invalidateCache = true;
  }
  shouldComponentUpdate(nextProps, nextState){
    if((nextProps.hash !== this.props.hash) || (JSON.stringify(this.props.query) !== JSON.stringify(nextProps.query)) ){
      this.invalidateCache();
    }
    return true;
  }
  isFinishedLoading() {
    return this._dataSource.isFinishedLoading();
  }
  loadNext() {
    return this._dataSource.next(this.props.query, this.props.sort, this.props.pageSize)
    .then(() => {
      this._invalidateCache = false;
      this.setState({ results: this._dataSource.loaded });
    });
  }
  render(){
    if(this.state.results === undefined || this.props.noCache || this._invalidateCache){
      this.loadNext();
      if(this.state.results === undefined){
        return null;
      }
    }
    return this.props.children instanceof Function
      ? this.props.children(this.state.results)
      : React.cloneElement(this.props.children, { data: this.state.results });
  }
}