import React, { FunctionComponent, MouseEvent } from 'react';
import FocusBlur from '../focusBlur';
import Numeric from './numeric';
import _ from 'lodash';

import './timeDuration.css';

const valueToTupleHourMinute = (value: number) => {
  if(!value){
    value = 0;
  }
  return [Math.floor(value), Math.round((value % 1) * 60)];
};

const valueToNdigit = (value: number, n: number = 2, digitCompletion: string = '0') =>  {
  let strValue = '' + value;
  while(strValue.length < n){
    strValue = digitCompletion + strValue;
  }
  return strValue;
}

interface TimeDurationProps {
  value: number;
  onChange: (value: number) => void;
  gap?: number;
};

const max = 100 - (1 / 60);

const TimeDuration: FunctionComponent<TimeDurationProps> = ({ value, onChange, gap = 15 }) => {

  const [editMode, setEditModeBase] = React.useState<'minute'|'hour'|null>(null);

  const setEditMode = React.useMemo(() => _.debounce(setEditModeBase, 50), [setEditModeBase]);

  const stopEventAndSetEditHourMode = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setEditMode('hour');
  }, [setEditMode]);

  const stopEventAndSetEditMinuteMode = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setEditMode('minute');
  }, [setEditMode]);

  const stopEditMode = React.useCallback(() => setEditMode(null), [setEditMode]);


  const [hour, minute] = React.useMemo(() => valueToTupleHourMinute(value), [value]);

  const decreaseMinuteByGap = React.useCallback((e) => {
    e.stopPropagation();
    const v = minute - gap;
    onChange(Math.max(hour + (v + (v % gap === 0 ? 0 : (v < 0 ? Math.abs(v % gap) : (gap - (v % gap))))) / 60, 0));
  }, [onChange, hour, minute, gap]);

  const increaseMinuteByGap = React.useCallback((e) => {
    e.stopPropagation();
    const v = minute + gap;
    onChange(Math.min(Math.max(hour + (v - v % gap) / 60, 0), max));
  }, [onChange, hour, minute, gap]);

  const updateHour = React.useCallback((value) => {
    if(isNaN(value)){
      value = 0;
    }
    onChange(Math.max(value + (minute / 60), 0));
  }, [minute, onChange]);

  const updateMinute = React.useCallback((value) => {
    if(isNaN(value)){
      value = 0;
    }
    onChange(Math.max(hour + (value / 60), 0));
  }, [hour, onChange]);


  const hourInput = React.useRef<Numeric>();
  const minuteInput = React.useRef<Numeric>();
  React.useEffect(() => {
    if(editMode === 'hour' && hourInput.current){
      hourInput.current.focus();
      hourInput.current.select();
    }else if(editMode === 'minute' && minuteInput.current){
      minuteInput.current.focus();
      minuteInput.current.select();
    }
  }, [editMode]);

  return (
    <div className='bs-timeDuration'>
      <span onClick={ decreaseMinuteByGap } className='bs-timeDuration-control fa fa-minus' />
      { 
        editMode === 'hour'
          ? <FocusBlur el='span' onBlur={ stopEditMode } hasFocus>
              <Numeric className="bs-timeDuration-hour" ref={ hourInput } value={ hour } onChange={ updateHour } min={ 0 } max={ 99 }/>
            </FocusBlur>
          : <div className='bs-timeDuration-display bs-timeDuration-hour' onClick={ stopEventAndSetEditHourMode }>{ valueToNdigit(hour) }</div>
      }
      <div className='bs-timeDuration-separator'>:</div>
      {
        editMode === 'minute'
          ? <FocusBlur el='span' onBlur={ stopEditMode } hasFocus>
              <Numeric className="bs-timeDuration-minute" ref={ minuteInput } value={ minute } onChange={ updateMinute } min={ 0 } max={ 59 }/>
            </FocusBlur>
          : <div className='bs-timeDuration-display bs-timeDuration-minute' onClick={ stopEventAndSetEditMinuteMode }>{ valueToNdigit(minute) }</div>
      }
      <span onClick={ increaseMinuteByGap } className='bs-timeDuration-control fa fa-plus' />
    </div>
  );
};

export default TimeDuration;