
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  return ModelBuilder.create("UserCitizen", Key.create("_id"), stdRepositoryConnector(application, "users"))
    .add(p => p("_id", "string"))
    .add(p => p("disabled", "bool"))
    .add(p => p("anonymized", "bool"))
    .add(p => p("emailValidated", "bool"))
    .add(p => p("fullname", "string"))
    .add(p => p("lastname", "string"))
    .add(p => p("firstname", "string"))
    .add(p => p("discriminator", "string"))
    .add(p => p("email", "string"))
    .add(p => p("authentificationProviders", "array")
      .of(t => t("object")
        .add(p => p("discriminator", "string"))
        .add(p => p("username", "string"))
      )
    )
    .build();
}