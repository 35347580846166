import React                          from 'react';
import md5                            from "md5";
import { Map as GoogleMap, Marker }   from 'google-maps-react';
import Slot                           from "@cComponents/slot";
import Geometry                       from "@cLib/geometry";
import Application                    from "@uBehaviour/application";

const markerFactory = (geoJson, options) => {
  switch(geoJson.type){
    case "Point":
      return React.createElement(Marker, {
        key: md5(JSON.stringify(geoJson)),
        position: { lat: geoJson.coordinates[1], lng: geoJson.coordinates[0] },
        draggable: !!options.draggable,
        onDragend: options.onDragEnd,
        icon: options.icon
      });
    default:
      throw new Error(`markerFactory::unknow type (${geoJson.type})`)
  }
};

export default class Map extends React.Component {
  static Shape = Slot();

  constructor(props){
    super(props);
    this._lastHash      = this.hash();
    this._map           = React.createRef();
    this._center        = null;
    this._boudingBox    = [];
    this._onZoomChanged = this._onZoomChanged.bind(this);  
    this._onMapReady    = this._onMapReady.bind(this);
    this._onDragEnd     = this._onDragEnd.bind(this);  
    this._onClick       = this._onClick.bind(this);
  }
  _usedCenter(){
    let center = this.shapesCenter;
    if(!center){
      center = this.props.default;
    }
    return center;
  }

  get shapesCenter(){
    const shapes = Map.Shape.props(this, true);
    if(shapes.length) {      
      const collection = new Geometry.Collection();
      shapes.forEach(shape => {
        collection.add(Geometry.buildFromGeoJSON(shape.definition));
      });
      return collection.barycentre.toGeoJSON();
    } 
    return null;
  }

  get center(){
    if(!this._map.current) return null;
    const map = this._map.current.map;
    return new Geometry.Point(map.center.lng(), map.center.lat()).toGeoJSON();
  }

  get boundingBox(){
    if(!this._map.current) return null;
    const map = this._map.current.map;
    return new Geometry.Polygon([
      new Geometry.Point(map.getBounds().getNorhEast().lng(), map.getBounds().getNorhEast().lat()),
      new Geometry.Point(map.getBounds().getNorhEast().lng(), map.getBounds().getSouthWest().lat()),
      new Geometry.Point(map.getBounds().getSouthWest().lng(), map.getBounds().getSouthWest().lat()),
      new Geometry.Point(map.getBounds().getSouthWest().lng(), map.getBounds().getNorhEast().lat()),
    ]).toGeoJSON();
  }

  get zoom(){    
    if(!this._map.current) return null;
    return this._map.current.map.zoom;
  }

  hash(props){
    if(!props) props = this.props;
    return md5(JSON.stringify({
      shapes: Map.Shape.props({ props: props }, true),
      center: props.default.coordinates
    }));
  }

  shouldComponentUpdate(nextProps){
    const hash = this.hash(nextProps);
    if(this._lastHash === hash){
      return false;
    }
    this._lastHash = hash;
    return true;
  }

  _onMapReady(mapProps, map) {
    if (this.props.cursor) {
      map.setOptions({draggableCursor: this.props.cursor})
    }
  }

  _onZoomChanged(mapProps, map) {
    this.props.onZoomChanged(this);
  }

  _onDragEnd(mapProps, map) {
    this.props.onDragEnd(this);
  }

  _onClick(mapProps, map, clickEvent) {
    const point = new Geometry.Point(clickEvent.latLng.lng(), clickEvent.latLng.lat()).toGeoJSON();
    this.props.onClick(this, point);
  }

  render() {
    const { zoom, zoomOnScroll, onZoomChanged, onDragEnd, onClick } = this.props;
    const center  = (center => center && ({ lat: center.coordinates[1], lng: center.coordinates[0] }))(this._usedCenter());
    const shapes = Map.Shape.props(this, true);
    const defaultShapeOptions = this.props.shapeOptions ? this.props.shapeOptions : {};
    return React.createElement(Application.Service, { name: "google-map" }, googleMapService =>
      React.createElement(
        GoogleMap, {
          ref: this._map,
          google: googleMapService.lib,
          zoom: zoom ? zoom : 16,
          containerStyle: {height: '100%', width: "100%", position: 'relative'},
          initialCenter: center,
          center: center,
          zoomControl: false,
          gestureHandling: zoomOnScroll ? "cooperative" : "auto",
          draggable: true,
          mapTypeControl: true,
          streetViewControl: false,
          fullscreenControl: false,
          onReady: this._onMapReady,
          onZoomChanged: onZoomChanged ? this._onZoomChanged : null,
          onDragend: onDragEnd ? this._onDragEnd : null,
          onClick: onClick ? this._onClick : null
        },
        shapes.map(shape => markerFactory(shape.definition, Object.assign({}, defaultShapeOptions, shape.options)))
      )
    );
  }
}
