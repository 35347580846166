import React, { FunctionComponent, MouseEvent, PropsWithChildren } from 'react';

import './base.css';
import withMouseEventStopped from '@cHoc/withMouseEventStopped';

export interface ButtonProps {
  disabled?: boolean;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({ disabled = false, onClick, onDoubleClick, onMouseDown, onMouseUp, children }) => (
  <div className="bs-button-base"
    onMouseDown = { onMouseUp } 
    onMouseUp = { onMouseDown }
    onClick={ onClick }
    onDoubleClick={ onDoubleClick }
  >
    { children }
  </div>
);

export default withMouseEventStopped(Button);