import React        from 'react';
import PropTypes    from 'prop-types';
import InputBase    from './inputBase';
import ChildToProps from '@cBehaviour/childToProps';  

import './textarea.css';

export default class Textarea extends InputBase {
  static propTypes = {
    placeholder: PropTypes.string
  }

  constructor(props){
    super(props);
    this._input = React.createRef();
    this._pos   = null;
  }

  getErrors() {
    const errors = super.getErrors();
    
    return errors;
  }

  componentDidUpdate(){
    if(this._pos){
      this._input.current.setSelectionRange(this._pos.start, this._pos.end);
      this._pos = null;
    }
  }

  _render() {
    const value = this._value ? this._value : "";
    return(
      <textarea 
        ref={ this._input }
        rows={4}
        type="text" 
        className="bs-old-input-textarea" 
        placeholder={this.props.placeholder}
        value={value}
        onChange={() => {
          this._pos = { start: this._input.current.selectionStart, end: this._input.current.selectionEnd };
          this._value = this._input.current.value;
          this._triggerChange(); 
        }}
        maxLength={ this.props.limit }
      /> 
      
    )      
  }
}