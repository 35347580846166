import React          from "react";
import md5            from "md5";
import _              from "lodash";
import Data           from "@uBehaviour/data";
import Slot           from "@uComponents/slot";
import Display        from "@cComponents/displayIf";
import Modal          from "@cComponents/modal";
import Button         from "@cComponents/button";
import T              from "@uBehaviour/i18n";
import { Key }        from "@uLib/model";
import SelectableList from "@cComponents/selectableList";
import Application    from "@uBehaviour/application";

import "./selectable.css";

const Textify = (props) => (
  <span className="bs-input-selectable-textify">
    <span>{ props.handler(props.value) }</span>
    <span onClick={ () => props.delete([props.value]) } className="fa fa-times" />
  </span>
);
export class SelectableBase extends React.Component {
  static Means = Slot();
  static SelectedItem = Slot();
  static SelectButton = Slot();

  state = {
    open: false
  };
  constructor(props){
    super(props);
    this._open            = this._open.bind(this);
    this._close           = this._close.bind(this);
    this._add             = this._add.bind(this);
    this._remove          = this._remove.bind(this);
    this._retrieveValues  = this._retrieveValues.bind(this);
    this._cache           = {};
    this._async           = React.createRef();
  }
  _open(){
    this.setState({ open: true });
  }
  _close(){
    this.setState({ open: false });
  }
  _retrieveValues(){
    const values = (this.props.value ? this.props.value : []);
    return Promise.resolve().then(() => {
      const toRetrieves = this.props.noCache ? values : values.filter(value => !this._cache[this.props.objectKey.hash(value)]);
      if(toRetrieves.length){
        return this.props.retrieve(toRetrieves).then(value => {
          this._cache = value.reduce((cache, value) => {
            cache[this.props.objectKey.hash(value)] = value;
            return cache;
          }, this._cache);
        });
      }
    }).then(() => values.map(value => this._cache[this.props.objectKey.hash(value)]));
  }
  _add(values){
    const newIds  = values.map(value => this.props.objectKey.extract(value));
    const ids     = (this.props.value || []).concat(newIds);
    if(this.props.onChange){
      this.props.onChange(ids, values, true);
    }
  }
  _remove(values){
    const ids =  (this.props.value || []).filter(id => !values.find(value => this.props.objectKey.equals(value, id)));
    if(this.props.onChange){
      this.props.onChange(ids, values, false);
    }
  }
  shouldComponentUpdate(nextProps, nextState){
    if((this.props.value !== nextProps.value && (!this.props.value || !nextProps.value)) || (this.props.value && nextProps.value && md5(JSON.stringify(this.props.value)) !== md5(JSON.stringify(nextProps.value)))){
      this._async.current.invalidateCache();
    }
    return true;
  }
  render(){
    const Means = SelectableBase.Means.get(this);
    if(!Means instanceof Function){
      throw new Error("Selectable.Means must be a function")
    }
    const SelectedItem = SelectableBase.SelectedItem.get(this);
    if(!SelectedItem instanceof Function){
      throw new Error("Selectable.SelectedItem must be a function")
    }
    const SelectButton = SelectableBase.SelectButton.get(this);
    if(SelectButton && !SelectButton instanceof Function){
      throw new Error("Selectable.SelectButton must be a function")
    }
    
    return (
    <>
      <Data.Async ref={ this._async } async={ this._retrieveValues }>
      { values => {
        return (
        <span className={ this.props.inline ? "bs-input-selectable-readValues-inline" : "bs-input-selectable-readValues" }>
          {
            values.map(value => !!SelectedItem
              ? SelectedItem(value, this._remove, this.props.objectKey.hash(value))
              : (<Textify key={ this.props.objectKey.hash(value) } handler={ this.props.textify } delete={ this._remove } value={ value } />))
          }
          <Display.If condition={ !this.props.limit || values.length < this.props.limit }>
            {SelectButton 
              ? SelectButton(this._open)
              : <span className="bs-input-selectable-select" onClick={ this._open }><span className="fa fa-plus" /><span><T>input_selectable_select</T></span></span>
            }
          </Display.If>
        </span>
      )}}
      </Data.Async>
      <Display.If condition={ this.state.open }>
      {() => (
        <Modal.Show close={this._close} style={ this.props.modalStyle }>
        {
          Means(this.props.value, this._close, this._add, this._remove)
        }
        </Modal.Show>
      )}
      </Display.If>
    </>
    );
  }
}
const SelectedItem = Slot();
const SelectButton = Slot();
class Selectable extends React.Component{
  constructor(props){
    super(props);
    this._selectableList = React.createRef();
    this._onSelect       = this._onSelect.bind(this);
  }
  _onSelect(add, close){
    const values = this.value;
    add(values);
    close();
  }
  get value(){
    return this._selectableList.current.selectedValues;
  }

  get model() {
    return this.props.model ? this.props.repository.get(this.props.model) : null;
  }

  get key(){
    return this.props.objectKey ? this.props.objectKey : this.model ? this.model.key : new Key(["_id"]);
  }

  render(){
    const model = this.model;

    const retrieve = model 
      ? (value) => model.repository.find({ _id: { $in: value.map(v => v._id) }}, null, null, value.length, this.props.load)
      : (value) => Promise.resolve(value);

    const noCache = model ? false : true;
    const withFilter = !!SelectableList.Filter.get(this);
    const selectedItem = SelectedItem.get(this);
    const selectButton = SelectButton.get(this);
    return (
      <SelectableBase
        limit={ this.props.limit }
        value={ this.props.value }
        textify={ this.props.textify }
        objectKey={ this.key }
        retrieve={ retrieve }
        noCache={ noCache }
        onChange={ this.props.onChange }
        modalStyle={ withFilter ? { width: '65vw' } : { width: '50vw' }}
        inline={ this.props.inline }
      >
        <SelectableBase.Means>
        {(alreadySelected, close, add, remove) => {
          if(this.key.properties.length !== 1){
            throw new Error('TODO');
          }
          const nin = alreadySelected && alreadySelected.length
            ? { [this.key.properties[0]]: { $nin: alreadySelected.map(selected => selected[this.key.properties[0]]) }}
            : null;


          const query = nin ? (this.props.query ? { $and: [this.props.query, nin] } : nin) : this.props.query;
          const limit = this.props.limit - (this.props.value?.length ? this.props.value.length : 0);

          return (
            <div className="bs-selectable-modal">
              <div className="bs-selectable-modal-content">
                <SelectableList
                  ref={ this._selectableList }
                  filterQuery={ this.props.filterQuery }
                  limit={ limit }
                  query={ query }
                  sort={ this.props.sort }
                  load={ this.props.load }
                  pageSize={ this.props.pageSize }
                  model={ model }
                  source={ this.props.source }
                  defaultFilter={ this.props.defaultFilter }
                >
                { this.props.children }
                </SelectableList>
              </div>            
              <div className="bs-selectable-modal-buttons">
                <Button.Text onClick={ this._onSelect.bind(this, add, close) } ><T>validate</T></Button.Text>
                <Button.Text onClick={ close }><T>cancel</T></Button.Text>
              </div>
            </div>       
          );
        }}
        </SelectableBase.Means>
        <SelectableBase.SelectedItem>
          {selectedItem}
        </SelectableBase.SelectedItem>
        <SelectableBase.SelectButton>
          {selectButton}
        </SelectableBase.SelectButton>
      </SelectableBase>
    );
  }
}

const ConnectedSelectable = Application.Service.forward(["repository"], Selectable);

ConnectedSelectable.Filter = SelectableList.Filter;
//Ne plus utiliser le slot Item dans les prochains développement. Utiliser le slot SelectableItem
ConnectedSelectable.Item = SelectableList.Item;
ConnectedSelectable.SelectableItem = SelectableList.Item;

ConnectedSelectable.SelectedItem = SelectedItem;
ConnectedSelectable.SelectButton = SelectButton;

export default ConnectedSelectable;