import React from "react";
import "./decorator.css";

const merge = {
  string: (maybe, add) => {
    return `${maybe ? maybe + " " : ""}${add}`;
  },
  function: (maybe, add) => {
    if(!maybe){
      return add;
    }
    return function(){
      maybe(...arguments);
      add(...arguments);
    };
  }
}
export default {
  Mouse: {
    Clickable: (props) => {
      return React.Children.map(props.children, (child) => React.cloneElement(child, {
        className: merge.string(child.props.className, "bs-clickable"),
        onClick: merge.function(child.props.onClick, props.onClick)
      }));
    },
    Hoverable: (props) => {
      return React.Children.map(props.children, (child) => React.cloneElement(child, {
        className:  merge.string(child.props.className, "bs-hoverable"),
        onMouseEnter: merge.function(child.props.onMouseEnter, props.onMouseEnter),
        onMouseLeave: merge.function(child.props.onMouseLeave, props.onMouseLeave)
      }));
    }
  },
  Structure: {
    Listable: (props) => {
      return React.Children.map(
        props.children, 
        (child) => { 
          if(!child){  
            return null; 
          }
          return React.cloneElement(
            child, 
            {
              className: merge.string(child.props.className, "bs-listable")
            }, 
            React.Children.map(             
              child.props.children, 
              (child) => { 
                if(!child){  
                  return null; 
                }
                return React.cloneElement(
                  child, {
                    className: merge.string(child.props.className, "bs-listable-item")
                  }
                );
              }
            )
          );
        }
      );
    }
  },
  Style: {
    ClassName: (props) => {
      return React.Children.map(
        props.children, 
        (child) => {
          if(!child){  
            return null; 
          }
          return React.cloneElement(child, {
            className: merge.string(child.props.className, props.className)
          });
        }
      );
    }
  }
};