import React from "react";


/**
 * @deprecated
 */
export default class Observer extends React.Component{
  constructor(props){
    super(props);
    this._observe = this._observe.bind(this);
  }
  state = {
    value: null
  };
  _observe(value){
    this.setState({ value: value });
  }
  componentDidMount(){
    this.props.observer.observe(this._observe);
  }
  componentWillUnmount(){
    this.props.observer.stopObserve(this._observe);
  }
  render(){
    if(!this.props.observer){
      throw new Error("Form.Observer specify observer");
    }
    return this.state.value ? this.props.children(this.state.value) : null;
  }
}