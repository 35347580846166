import React    from "react";
import { v4 as uuid }   from "uuid";
import Slot     from "@cComponents/slot";
import BtnRadio from "@cComponents/button/radio";
import BtnRadioFilter from "@cComponents/button/radioFilter";

import "./radio.css";

const Value = Slot();

class Radio extends React.Component {
  constructor(props) {
    super(props);
    this._id = uuid();
    this._values = [];
    this._onChange = this._onChange.bind(this);
  }

  _onChange(idx) {
    this._value = this._values[idx];
    if(this.props.onChange){
      this.props.onChange(this._value);
    }
  }

  render() {
    const radios = Value.props(this, true);
    this._values = [];
    return (
      <div className={`bs-input-radio${this.props.noFrame ? "" : " bs-input-radio-withFrame"}${ this.props.className ? " " + this.props.className : ""}`}>
        <div className={ `bs-input-radio-display bs-input-radio-${ this.props.inline ? "inline" : "column"}` }>
        {
          radios.map((radio, idx) => {
            this._values.push(radio.value);
            return (
              <label key={idx} onClick={this._onChange.bind(this, idx)}>
              {
                radio.children instanceof Function
                  ? radio.children(this.props.value === radio.value)
                  : React.cloneElement(radio.children, { selected: this.props.value === radio.value })
              }
              </label>
            )
          })
        }
        </div>
      </div>
    )
  }
};

Radio.Value = Value;

export const withButton = (Button) => {
  return React.forwardRef((props, ref) => (
    <Radio ref={ref} { ...props }>
    {
      Radio.Value.props({ props }, true).map(({ value, children }) => React.createElement(Radio.Value, { value: value}, selected => (
        <Button pushed={ selected }>
          { children instanceof Function ? children(selected) : React.cloneElement(children, { selected }) }
        </Button>
      )))
    }
    </Radio>
  ));
}

Radio.withButton = withButton;

Radio.Btn = withButton(BtnRadio);
Radio.BtnFilter = withButton(BtnRadioFilter);

export default Radio;