import React  from "react";
import { v4 as uuid }   from "uuid";
import Slot   from "@uComponents/slot";
import BtnRadio from "@cComponents/button/radio";
import BtnRadioFilter from "@cComponents/button/radioFilter";

import "./checkbox.css";
import classNames from "@universal/lib/classNames";


const Layout = {
  Inline: ({ children, noFrame, className }) => (
    <div className={`bs-input-checkbox${noFrame ? "" : " bs-input-checkbox-withFrame"} ${className}`}>
      <div className="bs-input-checkbox-inline">
        { children }
      </div>
    </div>
  ),
  Column: ({ children, noFrame, className }) => (
    <div className={`bs-input-checkbox${noFrame ? "" : " bs-input-checkbox-withFrame"} ${className}`}>
      <div className="bs-input-checkbox-column">
        { children }
      </div>
    </div>
  ),
  Table: ({ children, column, className }) => {
    let els = [];
    return (
      <div className={`bs-input-checkbox ${className}`}>
        <div className="bs-input-checkbox-table">
        {
          children.reduce((acc, child, index) => {
            els.push(child);
            if(els.length === column || children.length === index + 1){
              acc.push(<div className="bs-input-checkbox-table-row" key={acc.length + 1}>{ els }</div>);
              els = [];
            }
            return acc;
          }, [])
        }
        </div>
      </div>
    );
  }
};

class Checkbox extends React.Component {
  static Value = Slot();
  constructor(props){
    super(props);
    this._id        = uuid();
    this._onChange  = this._onChange.bind(this);
    this._inputs    = [];
  }
  get value() {
    const valueComponents = Checkbox.Value.props(this, true);
    const values = [];
    this._inputs.map(i => i.current).forEach((input, idx) => {
      if(input.checked){
        values.push(valueComponents[idx].value);
      }
    });
    return values;
  }
  _onChange(value, input){
    if(this.props.onChange){
      let values = this.value.slice();
      if(!input.checked){
        values = values.concat([value]);
      }else{
        const idx = values.indexOf(value);
        if(idx !== -1){
          values.splice(idx, 1);
        }
      }
      this.props.onChange(values, value, !input.checked);
    }
  }
  _equals(el1, el2){
    if(this.props.equals){
      return this.props.equals(el1, el2);
    }
    return el1 === el2;
  }
  _getLayout(){
    if(this.props.layout){
      return this.props.layout;
    }
    if(this.props.inline){
      return React.createElement(Layout.Inline, { noFrame: this.props.noFrame });
    }
    return React.createElement(Layout.Column, { noFrame: this.props.noFrame });
  }
  render() {
    const values = Checkbox.Value.props(this, true);
    const Layout = this._getLayout();
    this._inputs = [];
    return React.cloneElement(Layout, { className: this.props.className }, values.map(({ value: checkboxValue, children, disabled }, idx) => {
      const ref = React.createRef();
      this._inputs.push(ref);
      const checked = this.props.value && this.props.value.some(value => this._equals(value, checkboxValue));
      const onDivClick = () => {
        this._onChange(checkboxValue, ref.current);
      }
      const className = classNames("bs-input-checkbox-value")
        .addIf("bs-input-checkbox-value-checked", checked)
        .addIf("bs-input-checkbox-value-disabled", disabled);
        
      return (
        <div key={idx} className={ className } onClick={ disabled ? undefined : onDivClick }>
          <input ref={ref} type="checkbox" value={ idx } name={this._id} idx={idx} checked={checked} onChange={()=>null}/>
          {
            children instanceof Function
              ? children(checked, disabled)
              : React.cloneElement(children, { selected: checked, disabled })
          }
        </div>
      );
    }));
  }
}

Checkbox.Layout = Layout;

export const withButton = (Button) => {
  return React.forwardRef((props, ref) => (
    <Checkbox ref={ref} { ...props }>
    {
      Checkbox.Value.props({ props }, true).map(({ value, children, ["data-testid"]: dataTestId, disabled, ...rest }, idx) => React.createElement(Checkbox.Value, { key: idx, value, disabled}, (selected, disabled) => (
        <Button { ...rest }pushed={ selected } data-testid={dataTestId} disabled={ disabled }>
        { !children ? null : children instanceof Function ? children(selected, disabled, dataTestId, rest) : React.cloneElement(children, { ...rest, selected, disabled, "data-testid": dataTestId }) }
        </Button>
      )))
    }
    </Checkbox>
  ));
}
Checkbox.withButton = withButton;

Checkbox.Btn = withButton(BtnRadio);
Checkbox.BtnFilter = withButton(BtnRadioFilter);

export default Checkbox;