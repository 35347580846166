
import Store from "./store";
import _ from "lodash";

class LifeCycleManager extends Store{
    constructor(name){
        super(`StoreLifeCycleManager#${name}`, {}, {
            remove: function(datas, payload){
                const id = payload.id;
                if(datas[id].registredNbr === 0){
                    if(datas[id].object._destroy){            
                        datas[id].object._destroy();
                    }
                    delete datas[id];
                }
            },
            register: function(datas, payload){
                datas[payload.id].registredNbr++;
            },
            unregister: function(datas, payload){
                datas[payload.id].registredNbr--;
            },
            forceRemove: function(datas, payload){
                datas[payload.id].registredNbr = 0;
            },
            create: function(datas, payload){
                datas[payload.id] = {
                    registredNbr: 0,
                    object: payload.object
                };
            },
            mutateId: function(datas, payload){
                datas[payload.newId] = datas[payload.oldId];
                delete datas[payload.oldId];
            }
        });
        this.handleEvent    = _.debounce(() => this._handleEvent());
        this.onStoreUpdated.addListener(this);
        this._counted = {};
    }
    get datas(){
        const datas = this._store.getState();
        return Object.keys(datas).reduce((acc, key) => {
            acc[key] = datas[key].object;
            return acc;
        }, {});
    }
    _handleEvent(){
        const objects = this._store.getState();
        for(let id in objects){
            if(objects[id].registredNbr === 0){
                this._store.dispatch({ type: "remove", id: id });
            }
        }
    }
    has(id){
        return this.datas[id] !== undefined;
    }
    register(id){
        if(!this.has(id)){
            throw new Error(`store unknowed => ${id}`);
        }
        this._store.dispatch({ type: "register", id: id });
        return this.datas[id];
    }
    unregister(id){
        if(!this.has(id)){
            throw new Error(`Store unknowed => ${id}`);
        }
        this._store.dispatch({ type: "unregister", id: id});
    }
    create(id, object){
        if(this.has(id)) throw new Error(`Store already defined => ${id}`);
        this._store.dispatch({ type: "create", id: id, object: object });
    }

    mutateId(oldId, newId){
        if(this.has(newId)) throw new Error(`Can't mutate id. New id already exists => ${newId}`);
        this._store.dispatch({ type: "mutateId", oldId: oldId, newId: newId });
    }
}

export default LifeCycleManager;