import React, { FunctionComponent, PropsWithChildren, ReactElement, useContext } from 'react';
import Route, { RouteProps } from './route';
import { useMatchers } from '../hooks/useMatcher';
import RouteContext from '../context/route';

const Switch: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const routeComponents = React.useMemo<ReactElement<RouteProps, typeof Route>[]>( 
    () => (React.Children.toArray(children).filter(child => typeof child === "object" && (child as unknown as { type: any }).type  === Route)) as ReactElement<RouteProps, typeof Route>[],
    [children]
  );

  const pathSb = useContext(RouteContext);

  const routes = React.useMemo(
    () => routeComponents.map((routeComponent) => ({ path: pathSb.add(routeComponent.props.path).build(), strict: !!routeComponent.props.strict })),
    [routeComponents, pathSb]
  );

  const matchs = useMatchers(routes);

  const index = matchs.findIndex(m => m.match);
  if(index === -1){
    return null;
  }
  return routeComponents[index];
};

export default Switch;