import React, { useState }  from "react";
import Application          from "@uBehaviour/application";
import Display              from "@cComponents/displayIf";
import T                    from "@uBehaviour/i18n";
import dayjs                from "dayjs";

import "./next.css";

const Next = (props) => {
  const [display, setDisplay] = useState(true);
  return (
    <Display.If condition={props.release.hasAReleaseSoon() && display}>
    {() => {
      const nextRelease = props.release.next;
      return (
        <div className="bs-release-next">
          <span>
            <T bind={{ 
              date: dayjs(nextRelease.releaseDate).format("DD/MM/YYYY"),
              time: dayjs(nextRelease.releaseDate).format("HH:mm"),
              duration: dayjs.duration(nextRelease.serviceDowntime, "hours").format("H[h]mm")
            }}>release_next_service_downtime</T>
          </span>
          <span className="fa fa-times" onClick={() => { setDisplay(false); if(props.close) props.close(); }}/>
        </div>
      )
    }}
    </Display.If>
  );
};

export default Application.forward(["release", "i18n"], [], Next);