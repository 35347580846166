import React from 'react';
import Application from '@uBehaviour/application';
import Async from './async';
import { Listener } from "@uLib/event";

class Count extends React.Component {
  constructor(props){
    super(props);
    this._pushClientListener  = new Listener(this._onMessageReceived, this);
    this.state = {
      nbr: 1
    };
  }
  _onMessageReceived(type){
    if(this.props.repository.has(type) && this.props.repository.get(this.props.model) === this.props.repository.get(type)){
      this.setState({ nbr: this.state.nbr + 1});
    }
  }
  componentDidMount(){
    this.props.push.client.onReceipt.addListener(this._pushClientListener);
  }
  componentWillUnmount(){
    this.props.push.client.onReceipt.removeListener(this._pushClientListener);
  }
  render(){
    return React.createElement(Async, {
      async: () => this.props.repository.get(this.props.model).repository.count(this.props.query),
      hash: JSON.stringify(this.props.query)+"_"+this.state.nbr
    },
    this.props.children);
  }
}

export default Application.Service.forward(["repository", "push"], Count);