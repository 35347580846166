
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaulAbsenceValue = {
    
  };
  return ModelBuilder.create("Absence", Key.create("_id"), stdRepositoryConnector(application, "absences"), defaulAbsenceValue)
    .add(p => p("_id", "string"))
    .add(p => p("user", "entity").type("User"))
    .add(p => p("start", "date"))
    .add(p => p("end", "date"))
    .add(p => p("reason", "string"))
    .add(p => p("nature", "string"))
    .add(p => p("comment", "string"))
    .build();
}