import { isThisHour } from "date-fns";
import React                      from "react";
import Event                      from "@uLib/event";
import _                          from 'lodash';

import "./editor.css";
class Capture{
  constructor(editor, el, triggerLength, start, startHandler, updateHandler, disposeHandler){
    this._editor         = editor;
    this._el             = el;
    this._triggerLength  = triggerLength;
    this._start          = start;
    this._end            = start;
    this._startHandler   = startHandler;
    this._updateHandler  = updateHandler;
    this._disposeHandler = disposeHandler;
    this.start();
  }
  get editor(){
    return this._editor;
  }
  get element(){
    return this._el;
  }
  get text(){
    return this.element.textContent.substring(this._start + this._triggerLength, this._end);
  }
  replace(html, indivisible, className, data){
    const span = document.createElement("span");
    span.innerHTML =  html;
    if(className){
      span.className = className;
    }
    if(indivisible){
      span.setAttribute("indivisible", true);
    }
    if(data){
      span.setAttribute("data", JSON.stringify(data));
    }
    
    const selection = window.document.getSelection();
    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.setStart(this._el, this._start);
    newRange.setEnd(this._el, this._end);
    selection.addRange(newRange);
    newRange.deleteContents();
    newRange.insertNode(document.createTextNode(String.fromCharCode(160)));
    newRange.insertNode(span);
    const textNode = document.createTextNode(String.fromCharCode(160));
    if(this._start > 0){
      newRange.insertNode(textNode);
    }

    selection.collapseToEnd()

    this.release();
    this.editor.triggerChange();
  }
  setText(newText){
    const selection       = window.document.getSelection();
    if(!selection.rangeCount) return;
    const range           = selection.getRangeAt(0);
    const text            = this._el.textContent;
    this._el.textContent  = text.substring(0, this._start) + newText + text.substring(this._start + this.text.length + this._triggerLength);
    const newRange = document.createRange();
    newRange.setStart(this._el, this._start + newText.length);
    newRange.setEnd(this._el, this._start + newText.length);
    selection.removeAllRanges();
    selection.addRange(newRange);
    this.editor.triggerChange();
  }
  persist(){
    this._editor.currentCapture = this;
  }
  release(){
    this._editor.currentCapture = null;
    if(this._disposeHandler)
      this._disposeHandler();
  }
  start(){
    this._startHandler(this);
  }
  triggerUpdate(ev){
    const selection = window.document.getSelection();
    if(!selection.rangeCount) return "";
    const range     = selection.getRangeAt(0);
    if(range.endContainer !== this.element){
      return null;
    }
    this._end = range.endOffset;
    if(this._start === this._end){
      this.release();
    }
    this._updateHandler(this, ev);
  }
}
class Editor{
  constructor(element, config = {}){
    this._element        = element;
    this._config         = config;
    this._onChange       = new Event();

    this._currentCapture = null;

    this._onKeyDown      = this._onKeyDown.bind(this);
    this._onKeyUp        = this._onKeyUp.bind(this);
    this._onFocus        = this._onFocus.bind(this);
    this._onBlur         = this._onBlur.bind(this);

    this.init();
  }
  get onChange(){
    return this._onChange;
  }
  get element(){
    return this._element;
  }
  dispose(){
    if(this._currentCapture){
      this._currentCapture.release();
    }
    this._element.removeEventListener("keydown",     this._onKeyDown);
    this._element.removeEventListener("keyup",       this._onKeyUp);
    this._element.removeEventListener("focus",       this._onFocus);
    this._element.removeEventListener("blur",        this._onBlur);
  }
  init(){
    this._element.contentEditable = "true";

    this._element.addEventListener("keydown",     this._onKeyDown);
    this._element.addEventListener("keyup",       this._onKeyUp);
    this._element.addEventListener("focus",       this._onFocus);
    this._element.addEventListener("blur",        this._onBlur);

    this._triggers = Object.keys(this._config.triggers || {}).reduce((triggers, key) => {
      let trigger = {};
      if(this._config.triggers[key] instanceof Function){
        trigger.start = this._config.triggers[key];
        trigger.update = () => null;
      } else {
        trigger = this._config.triggers[key];
      }
      triggers[key] = trigger;
      return triggers;
    }, {});
  }
  set currentCapture(capture){
    this._currentCapture = capture;
  }
  getCurrentLast(length){
    const selection = window.document.getSelection();
    if(!selection.rangeCount) return "";
    const range     = selection.getRangeAt(0);
    return range.startContainer.textContent.substring(range.startOffset - length, range.startOffset);
  }
  createCapture(length, startHandler, updateHandler, disposeHandler){
    const selection = window.document.getSelection();
    if(!selection.rangeCount) return null;
    const range     = selection.getRangeAt(0);
    return new Capture(this, range.startContainer, length, range.startOffset - length, startHandler, updateHandler, disposeHandler);
  }
  _onKeyDown(ev){
    if(["Backspace", "Delete"].indexOf(ev.code) !== -1){
      const selection = window.document.getSelection();
      if(!selection.rangeCount) return;
      const range     = selection.getRangeAt(0);
      if(range.startContainer.parentElement.getAttribute("indivisible")){
        range.startContainer.parentElement.removeChild(range.startContainer);
      }
      if(range.endContainer !== range.startContainer && range.endContainer.parentElement.getAttribute("indivisible")){
        range.endContainer.parentElement.removeChild(range.endContainer);
      }
    }
    this.triggerChange();
  }
  _onKeyUp(ev){ 
    if(ev.key.length === 1 || ["Backspace", "Space"].indexOf(ev.key) !== -1){
      if(this._currentCapture){
        this._currentCapture.triggerUpdate(ev);
      }else{
        const values  = {};
        const keys    = Object.keys(this._triggers);
        let found     = false;
        for(let i = 0; i < keys.length && !found; ++i){
          const key = keys[i];
          if(!values[key.length]){
            values[key.length] = this.getCurrentLast(key.length);
          }
          if(values[key.length] === key){
            found = true;
            this.createCapture(key.length, this._triggers[key].start, this._triggers[key].update, this._triggers[key].dispose);
          }
        }
      }
    }
    this.triggerChange();
  }
  triggerChange(){
    if(this._last !== this.element.innerHTML){
      this._last = this.element.innerHTML;
      this._onChange.trigger(this.element.innerHTML);
    }
  }
  _onFocus(ev){

  }
  _onBlur(ev){

  }
}
class EditorComponent extends React.Component{
  constructor(props){
    super(props);
    this._editorElement = React.createRef();
    // this.handleEvent = _.debounce(this.handleEvent.bind(this), 400);
  }
  get datas(){
    const els = this._editorElement.current.querySelectorAll("*[data]");
    const ret = [];
    els.forEach(el => {
      ret.push(JSON.parse(el.getAttribute("data")))
    });
    return ret;
  }
  get value(){
    return this._editorElement.current.innerHTML;
  }
  set value(value){
    this._editorElement.current.innerHTML = value;
  }
  handleEvent(value){
    if(this.props.onChange){
      this.props.onChange(value);
    }
  }
  componentDidMount(){
    if(this.props.value){
      this.value = this.props.value;
    }
    this._editor = new Editor(this._editorElement.current, this.props.config);
    this._editor.onChange.addListener(this);
  }
  // componentDidUpdate(prevProps){
  //   console.log("componentDidUpdate : " + this.props.value);
  //   if(prevProps.value !== this.props.value && this.props.value !== this.value){
  //     this.value = this.props.value;
  //   }
  // }
  componentWillUnmount(){
    this._editor.onChange.removeListener(this);
    this._editor.dispose();
  }
  render(){
    return React.createElement("div", { className: "bs-input-editor"},
      React.createElement("div", {
        ref: this._editorElement,
        style: { width: "300px", height: "100px"}
      })
    );
  }
}
export default EditorComponent;