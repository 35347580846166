import { Map as DynamicGoogleMap, Marker }  from 'google-maps-react';
import md5                                  from "js-md5";
import Geometry                             from "@cLib/geometry";
const lngLatToGeometryPoint = (point) => {
  return new Geometry.Point(point.lng, point.lat);
}
export default class DynamicMap {
  static get type (){
    return "name";
  }
  static get mapComponent(){
    return DynamicGoogleMap;
  }
  static getMapProps(props, onZoomChanged, onDragEnd){
    return {
      key: props.key,
      google: window.google,
      zoom: props.zoom ? props.zoom : 16,
      containerStyle: { height: `100%`, width: `100%`, position: 'relative'},
      initialCenter: { lng: props.defaultcenter.longitude, lat: props.defaultcenter.latitude },
      zoomControl: !props.readOnly, 
      gestureHandling: "auto",
      draggable: !props.readOnly,
      mapTypeControl: !props.readOnly,
      streetViewControl: false,
      fullscreenControl: !props.readOnly,
      onZoom_changed: (mapPros, map) => onZoomChanged(lngLatToGeometryPoint(map.getBounds().getSouthWest().toJSON()), lngLatToGeometryPoint(map.getBounds().getNorthEast().toJSON()), lngLatToGeometryPoint(map.getCenter().toJSON()), map.getZoom()),
      onDragend: (mapPros, map) => onDragEnd(lngLatToGeometryPoint(map.getBounds().getSouthWest().toJSON()), lngLatToGeometryPoint(map.getBounds().getNorthEast().toJSON()), lngLatToGeometryPoint(map.getCenter().toJSON()), map.getZoom())
    };
  }
  static getShapeComponent(shapeType){
    return Marker;
  }
  static getShapeProps(shape, onShapeDragged){
    return {
      key: md5(JSON.stringify(shape)),
      position: {  lng: shape.geometry.longitude, lat: shape.geometry.latitude },
      draggable: !shape.readOnly,
      icon: { url: shape.icon },
      onClick: (t, map, coord) => shape.onClick ? shape.onClick(shape) : null,
      onDragend: (t, map, coord) => {
        onShapeDragged(shape, lngLatToGeometryPoint(coord));
      }
    };
  }
}