
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  return ModelBuilder.create("Log", Key.create("_id"), stdRepositoryConnector(application, "logs"),  {})
    .add(p => p("_id", "string"))
    .add(p => p("version", "int"))
    .add(p => p("subject", "object")
      .add(p => p("resource", "string"))
      .add(p => p("id", "string"))
    )
    .add(p => p("diff", "mixed"))
    .add(p => p("data", "var"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("createdAt", "date"))
    .add(p => p("type", "string"))
    .build();
}