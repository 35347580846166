
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultSupplyValue = {
    disabled:false,
    type: "internal"
  }

  return ModelBuilder.create("Supply", Key.create("_id"), stdRepositoryConnector(application, "supplies"), defaultSupplyValue)
    .add(p => p("_id", "string"))
    .add(p => p("disabled", "bool"))
    .add(p => p("name", "string"))
    .add(p => p("description", "string"))
    .add(p => p("sku", "string"))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("$hasValorization", "bool"))
    .add(p => p("valorization", "object")
      .add(p => p("type", "string"))
      .add(p => p("cost", "float"))
    )
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .build();
}