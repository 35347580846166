
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode
} from 'react';

import Slot from '@uComponents/slot2';
import "./coreff.css";

const Left  = Slot<ReactNode>();
const Right = Slot<ReactNode>();



const Coreff: FunctionComponent<PropsWithChildren> & { Left: typeof Left, Right: typeof Right } = ({ children }) => {  

  const left  = Left.get(children);
  const right = Right.get(children);

  const getOptionalClassName = (className?: string) => className || "";
  
  return (
    <div className="bs-layout-coreff-container">
      <div className={"bs-layout-coreff-left "  + getOptionalClassName(Coreff.Left.props(children).className)}>{ left }</div>
      <div className={"bs-layout-coreff-right " + getOptionalClassName(Coreff.Right.props(children).className)}>{ right }</div>
    </div>
  );
}

Coreff.Left = Left;
Coreff.Right = Right;

export default Coreff;
