import React, { FunctionComponent }        from "react";
import Display      from "@cComponents/displayIf";
import List         from "./list";
import useService from "@uBehaviour/hooks/useService";
import ReleaseService from "@universal/services/release";

type AutoListProps = {
  application: "citizen" | "admin"
}

const AutoList: FunctionComponent<AutoListProps> = ({ application }) => {
  const release = useService<ReleaseService>('release');
  return (
    <Display.If condition={release.hasAReleaseNotRead()}>
    {() => (
      <List application={application} close={() => release.updateLastSeen()}/>
    )}
    </Display.If>
  );
}

export default AutoList;