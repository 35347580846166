import React  from "react";
import Slot   from "@uComponents/slot";
import Modal  from "@cComponents/modal";

import "./simpleSelectable.css";

class Selectable extends React.Component{
  state = {
    values: [],
    modal: false
  }
  constructor(props) {
    super(props);
    this._onClick = this._onClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this._ready   = false;
  }
  _onClick(){
    if(!this.props.readOnly){
      this.openModal();
    }
  }
  openModal(){
    this.setState({ modal: true });
  }
  closeModal(){
    this.setState({ modal: false });
  }
  componentDidMount(){
    this._load();
  }
  componentDidUpdate(){
    this._load();
  }
  _load(){
    if(!this._ready){
      this._ready = true;
      return (this.props.value.length ? this.props.getDatas(this.props.value) : Promise.resolve([]))
              .then(values => this.setState({ values }));
    }
  }
  shouldComponentUpdate(nextProps, nextState){
    if(JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)){
      this._ready = false;
    }
    return true;
  }
  render(){
    const Means = Selectable.Means.get(this);
    return (
      <>
        <div className={`bs-selectable-wa${ !this.props.readOnly ? " bs-selectable-wa-openable" : ""}`} onClick={ this._onClick }>
        { 
          this.state.values.length
            ? <span>{ this.state.values.map(value => (<span>{ this.props.textify(value) }</span>)) }</span>
            : <span>{ this.props.label }</span>
        }
        </div>
        {
          this.state.modal
            ? (<Modal.Show style={{ height: "80vh", width: "80vw" }} close={ this.closeModal }>{ React.cloneElement(Means instanceof Function ? Means(this.props.onChange, this.closeModal) : Means, { onChange: this.props.onChange, close: this.closeModal })}</Modal.Show>)
            : null
        }
      </>
    )
  }
}
Selectable.Means = Slot();

export default Selectable;