// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-section-header {
  background-color: rgb(245, 245, 245);
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 10px;
}

.bs-section-header-text {
  color: rgb(44, 62, 80);
  font-size: 1.2em;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./../common/components/section/header.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,uCAAuC;EACvC,0BAA0B;EAC1B,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".bs-section-header {\n  background-color: rgb(245, 245, 245);\n  border-bottom-color: rgb(221, 221, 221);\n  border-bottom-style: solid;\n  border-bottom-width: 1px;\n  padding: 10px;\n}\n\n.bs-section-header-text {\n  color: rgb(44, 62, 80);\n  font-size: 1.2em;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
