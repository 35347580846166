export class Role{
	constructor(name, constraint = []){
		this._name 				= name;
		this._constraints 	= Array.isArray(constraint) ? constraint : [constraint];
		this._inheritance = [];
	}
	get name(){
		return this._name;
	}
	inheritFrom(role){
		this._inheritance.push(role);
		return this;
	}
  isInheritedFrom(role){
		return this._inheritance.some(r => {
			if(r === role) return true;
			return r.isInheritedFrom(role);
		});
	}
	conextConstraint(constraint = []){
		if(!constraint){
			constraint = [];
		}
		this._constraints 	= Array.isArray(constraint) ? constraint : [constraint];
	}
	is(role){
		let is = this === role;		
		if(!is){
		 	is = this._inheritance.reduce((acc, r) => acc || r.is(role), false);
		}
		return is;
	}

	getRolesOn(userContext, context){
		if(this.isOn(userContext, context)){
			return [this];
		}
		return this._inheritance.reduce((roles, role) => {
			return roles.concat(role.getRolesOn(userContext, context));
		}, []);
	}
	
	isOn(userContext, context){
		if(!context){
			return true;
		}
		return this._constraints.length === 0 || this._constraints.some(constraint => {
			if(userContext && userContext[constraint] && context[constraint]){
				let last = null;
				const userConstraint = userContext[constraint].slice().sort().filter(o => {
					if(last === o) return false;
					last = o;
					return true;
				});
				last = null;
				const contextConstraint = context[constraint].slice().sort().filter(o => {
					if(last === o) return false;
					last = o;
					return true;
				});
				last = null;
				return userConstraint.concat(contextConstraint).sort().some(o => {
					if(last === o) return true;
					last = o;
					return false;
				});
			}else {
				return true;
			}
		});
	}
	get parent(){
		return this._inheritance.slice();
	}
	toJson(){
		return {
			name: this._name,
			constraints: this._constraints,
			inheritFrom: this._inheritance.map(role => role._name)
		};
	}
}
class Resource{
	constructor(name, actions = [], contextMapper = {}){
		this._name    			= name;
		this._actions 			= {};
		this._contextMapper = contextMapper;
		actions.forEach(action => {
			this.addAction(action);
		});
	}
	_extract(object, propertiesPath){
		if(!propertiesPath.length || !object){
			return object;
		}
		let value = !Array.isArray(object) ? [object] : object;
		let ret = [];
		value.forEach(object => {
			ret = ret.concat(this._extract(object[propertiesPath[0]], propertiesPath.slice(1)));
		});
		return ret.filter(r => !!r).map(r => r.toString());
	}
	getContext(object){
		const context = Object.keys(this._contextMapper).reduce((context, propertyName) => {
			context[propertyName] = this._extract(object, this._contextMapper[propertyName].split("."));
			return context;
		}, {});
		return Object.keys(context).reduce((context, property) => {
			let last = null;
			context[property] = context[property].sort().filter(o => {
				if(o === last) return false;
				last = o;
				return true;
			});
			return context;
		}, context);
	}
	defineAction(name){
		if(this._actions[name]) throw new Error("Action \"" + name +"\" already exist");
		this._actions[name] = new Action(name, this);
		return this._actions[name];
	}
	getActionByName(name){
		return this._actions[name];
	}
	toJson(){
		return {
			name: this._name,
			contextMapper: this._contextMapper,
			actions: Object.keys(this._actions).map(actionName => this._actions[actionName].toJson())
		};
	}
}

class Action{
  constructor(name, resource){
    this._name      = name;
    this._resource  = resource;
    this._allowed   = [];
    this._forbidden = []; 
  }
  get resource(){
    return this._resource;
  }
  debug(){
    this._debug = true;
    return this;
  }
  allow(roles){
    return this.allowAndForbid(roles, []);
  }
  allowAndForbid(roles, forbids){
    if(!Array.isArray(roles)){
      roles = [roles];
    }
    if(!Array.isArray(forbids)){
      forbids = [forbids];
    }
    forbids.forEach(role => {
      if(this._forbidden.indexOf(role) === -1){
        this._forbidden.push(role);
      }
    });
    roles.forEach(role => {
      if(this._allowed.indexOf(role) === -1){
        this._allowed.push(role);
      }
    });
    return this.resource;
  }
  isForbid(role) {
    if(this._forbidden.indexOf(role) !== -1) return true;
    return role.parent.some(parent => this.isForbid(parent));
  }
  isAllow(role){
    if(this._debug){
      debugger;
    }
    if(this._allowed.indexOf(role) !== -1) return true;
    return role.parent.some(parent => this.isAllow(parent));
  }
  toJson(){
    return {
      name: this._name,
      allow: this._allowed.map(allowed => allowed._name),
      forbidden: this._forbidden.map(forbidden => forbidden._name)
    };
  }
}

class ProxyManagerResource{
	constructor(aclManager){
		this._aclManager = aclManager;
	}
	get(resource, actionName){
		const action = resource.getActionByName(actionName);
		if(action){
			const oThis = this;
			return {
				isAllow: function(){
          const roles = oThis._aclManager.roles(...arguments);
          const forbid = roles.some(role => action.isForbid(role));
          if (forbid) return false;
          const allowed = roles.some(role => action.isAllow(role));
          return allowed;
        },
        isAllowOn(user, businessObject){
          const context = resource.getContext(businessObject);
          const rolesOn = oThis._aclManager.rolesOn(user, context);
          const forbid = rolesOn.some(role => action.isForbid(role));
          if (forbid) return false;
          const allowed = rolesOn.some(role => action.isAllow(role));
          return allowed;
        }
			};
		}
		throw new Error("Unknow action \"" + actionName + "\"");
	}
}
class ProxyManagerACl{
	constructor(userContextHandler, userObjectHandler, acl){
    this._userContextHandler  = userContextHandler;
    this._userObjectHandler   = userObjectHandler;
		this._acl                 = acl;
	}
	
	get(acl, prop){
		if(prop === "toJson"){
			return function(){ return acl.toJson(); };
    }
    if(prop === "getRole"){
      return acl.getRole.bind(acl);
    }
		
		if(acl.hasResource(prop)){
			return new Proxy(acl.getResource(prop), new ProxyManagerResource(this));
		}
		throw new Error("Unknow resource \"" + prop + "\"");
	}

	userIsOneOf(roles){
		if(!Array.isArray(roles)) roles = [roles];
		roles = roles.map(r => this._acl.getRole(r));
		return this.roles(Array.prototype.slice.call(arguments, 1)).some(r1 =>roles.some(r2 => r2.is(r1)));
	}

	roles(){
		return this._userContextHandler(...[this._acl].concat(Array.prototype.slice.call(arguments)));
  }
  rolesOn(){
    return this._userObjectHandler(...[this._acl].concat(Array.prototype.slice.call(arguments)));
  }
}

export default class Acl{
	static createFromJson(json){
    const acl = new Acl();
    const dicRoles = {};
    json.roles.forEach(jRole => {
      dicRoles[jRole.name] = acl.defineRole(jRole.name);
    });
    json.roles.forEach(jRole => {
      if(jRole.inheritFrom){
        jRole.inheritFrom.forEach(inherit => {
          dicRoles[jRole.name].inheritFrom(dicRoles[inherit]);
        });
      }
    });
    json.resources.forEach(jResource => {
      const resource = acl.defineResource(jResource.name);
      jResource.actions.forEach(jAction => {
        resource.defineAction(jAction.name)
          .allowAndForbid(jAction.allow.map(roleName => dicRoles[roleName]), jAction.forbidden.map(roleName => dicRoles[roleName]));
      });
    });
    return acl;
  }
	constructor(){
		this._roles			= {};
		this._resources	= {};
		const fallback 	= this.defineResource("fallback");
		fallback.defineAction("forbidden");
	}
	defineRole(name, constraint){
		if(this.hasRole(name)) throw new Error("Role \"" + name + "\" already exist");
		this._roles[name] = new Role(name, constraint);
		return this._roles[name];
	}
	hasRole(name){
		return !!this._roles[name];
	}
	getRole(name){
		if(!this.hasRole(name)) throw new Error("Unknow Role \"" + name + "\"");
		return this._roles[name];
	}
	defineResource(name, mapper){
		if(this.hasResource(name)) throw new Error("Resource \"" + name + "\" already exist");
		this._resources[name] = new Resource(name, [], mapper);
		return this._resources[name];		
	}
	hasResource(name){
		return !!this._resources[name];
	}
	getResource(name){
		if(!this.hasResource(name)) throw new Error("Unknow Resource \"" + name + "\"");
		return this._resources[name];
	}
	export(userContextHandler, userObjectHandler){
		return new Proxy(this, new ProxyManagerACl(userContextHandler, userObjectHandler, this));
	}
	toJson(){
		return {
			roles: Object.keys(this._roles).map(roleName => this._roles[roleName].toJson()),
			resources: Object.keys(this._resources).filter(rName => rName !== "fallback").map(resourceName => this._resources[resourceName].toJson()),
		};
	}
};


