// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-citizen-updateCgu-header {
  border-bottom: 2px solid #d9480f; 
}


.bs-citizen-updateCgu-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bs-citizen-updateCgu-footer {
  display: flex;
  justify-content:space-around;
  align-items: center;
  flex-wrap: wrap;
}

.bs-citizen-updateCgu-check{
  margin-right: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/applications/views/sessions/updateCgu.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-citizen-updateCgu-header {\n  border-bottom: 2px solid #d9480f; \n}\n\n\n.bs-citizen-updateCgu-content {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n.bs-citizen-updateCgu-footer {\n  display: flex;\n  justify-content:space-around;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.bs-citizen-updateCgu-check{\n  margin-right: 1vw;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
