import Display                                    from "@cComponents/displayIf";
import useService                                 from "@uBehaviour/hooks/useService";
import React, { useState }                        from "react";
import Modal                                      from "@cComponents/modal";
import Button                                     from "@cComponents/button";
import T                                          from '@cBehaviour/i18n';
import Layout                                     from '@common/components/layout';
import Section                                    from "@cComponents/section";
import Citizen                                    from "@universal/helpers/citizen";
import useHelper                                  from "@universal/behaviour/hooks/useHelper";
import ConfigurationService                       from "@universal/services/configuration";
import I18nService                                from "@universal/services/i18n";
import MessageService                             from "@common/services/message";
import                                                 "./updateCgu.css";

const updateCgu = () => {
  const citizen = useHelper<Citizen>("citizen");
  const i18n = useService<I18nService>("i18n");
  const message = useService<MessageService>("message");
  const [userMustValidateLastCgu, setUserMustValidateCgu] = useState(citizen.mustValidateCgu()); 
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const configuration = useService<ConfigurationService>("configuration");

  React.useEffect(() => {
    if (citizen.mustValidateCgu() !== userMustValidateLastCgu) {
      setUserMustValidateCgu(citizen.mustValidateCgu());
    }
  }, [ citizen.mustValidateCgu() ]);

  const validateUpdateCgu = () => {
    citizen.updateCgu().then(() => {
      setUserMustValidateCgu(false);  
    }).catch(error => {
      message.send("error", i18n.translate("updateCgu_citizen_error_update"));
    });
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <Display.If condition={userMustValidateLastCgu}> 
      <Modal.Show style={{ width: '70vw', height: '80vh' }} userCanClose={false}>
        <Layout.Standart stretch>
          <Layout.Standart.Header className="bs-citizen-updateCgu-header">
            <Section.Header>
              <b><T>src_applications_view_session_Title</T></b>
            </Section.Header>
          </Layout.Standart.Header>
          <Layout.Standart.Content className="bs-citizen-updateCgu-content">
            <iframe
              title="Betterstreet"
              src= {configuration.get("conditionUrl")}
              style={{
                width: '98%',
                height: 'calc(96%)',
                border: '2px solid #ccc',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                overflow: 'auto',
              }}
              allowFullScreen
            />
          </Layout.Standart.Content>
          <Layout.Standart.Footer>
            <Section.Footer className="bs-citizen-updateCgu-footer">
              <label>
                <input 
                  type="checkbox" 
                  onChange={handleCheckboxChange} 
                  className="bs-citizen-updateCgu-check"
                />
                <T>src_applications_view_session_validateUpdateGpuCheckbox</T>
              </label>
              <Button.Text onClick={validateUpdateCgu} disabled={!isCheckboxChecked}>
                <T>src_applications_view_session_validateUpdateGpuButton</T>
              </Button.Text>
            </Section.Footer>
          </Layout.Standart.Footer>
        </Layout.Standart>
      </Modal.Show>
    </Display.If>
  );
};

export default updateCgu;
