import React, { useContext } from 'react';

import RouterContext from '../context/router';
import Path from '../types/Path';
import { matcher, pathToRegexp } from '../lib/matcher';

type MatcherResult = {
  match: boolean,
  params: Record<string, string>
};

type UseMatcherType = (path: Path, strict?: boolean) => MatcherResult;

const useMatcher: UseMatcherType = (path, strict = false) => {
  const { current } = useContext(RouterContext);

  const regExpPath = React.useMemo(() => pathToRegexp(path, strict), [path, strict]);
  
  return React.useMemo<MatcherResult>(
    () => matcher(regExpPath, current || '/'),
    [regExpPath, current]
  );
}

type UseMatchersType = (paths: { path: Path, strict: boolean }[]) => MatcherResult[];

export const useMatchers: UseMatchersType = (paths) => {
  const { current } = useContext(RouterContext);
  const regExpPaths = React.useMemo(
    () => paths.map(el => pathToRegexp(el.path, el.strict)),
    [paths]
  );
  return React.useMemo<MatcherResult[]>(
    () => regExpPaths.map(regExpPath => matcher(regExpPath, current || '/')),
    [regExpPaths, current]
  );
}

export default useMatcher;