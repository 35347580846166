import { Service }  from "@uLib/application";
import Abstract     from "@uLib/abstract";
import Acl          from "@uLib/acl";
import { UserCollaboratorRoles, UserProAclAllowedRoles } from '../types/business/User';
import ObjectId from "@universal/types/technic/ObjectId";



const proRoles = Object.values(UserProAclAllowedRoles);

const collaboratorRoles = Object.values(UserCollaboratorRoles);

const virtualRoles            = [
  { role: "issueFollower",  props: "followers" },
  { role: "issueManager",   props: "manager" },
  { role: "creator",        props: "creator" }
];
const dicCommercialOfferToRoles = {
  "expert": "expertOffer",
  "standard": "standardOffer",
  "starter": "starterOffer",
  [null + '']: "notClient",
};
const commercialOfferToRoles = (commercialOffer) => {
  if(commercialOffer === undefined){
    return [];
  }
  return [dicCommercialOfferToRoles[commercialOffer + '']];
}
const extendWithVirtualRoles  = (user, tenant) => {
  tenant = JSON.parse(JSON.stringify(tenant));
  virtualRoles.forEach(virtual => {
    if(tenant.roles.indexOf(virtual.role) === -1){
      tenant.roles = tenant.roles.concat([virtual.role]);
      tenant[virtual.props] = [user._id];
    }
  });
  tenant.roles = tenant.roles.concat(commercialOfferToRoles(tenant.tenantCommercialOffer));
  return tenant;
};

const getGlobalRoles = Abstract.sync("discriminator", (context, acl, user, tenant, notConnected) => {
  context.acl           = acl;
  context.tenant        = tenant;
  context.notConnected  = notConnected;
  if(!user) return [acl.getRole("guest")];
  return user;
})
.next({
  "requestorValidation": (context, user) => {
    const { acl } = context;
    return [acl.getRole("requestorValidation")];
  },
  "starterActivator": (context, user) => {
    const { acl } = context;
    return [acl.getRole("starterActivator")];
  },
  "starterActivationConfirmEmail": (context, user) => {
    const { acl } = context;
    return [acl.getRole("starterActivationConfirmEmail")];
  },
  "technical": (context, user) => {
    const { acl } = context;
    switch(user.application){
    case "jvs-ac": return [acl.getRole("attractiveCity")];
    case "jvs-gs": return [acl.getRole("gestionDeSalleJvs")];
    case "jvs-pp": return [acl.getRole("panneauPocket")];
    }
  },
  "citizen": (context, user) => {
    const { acl } = context;
    if(user.mustValidateEmail){
			return [acl.getRole("citizenNotValidated")];
		} else {
			return [acl.getRole("citizen")];
		}
  },
  "pro": (context, user) => {
    const { acl } = context;
    const tenant = user.tenants.find(t => t.tenant === context.tenant);
    if(!tenant){
      return [acl.getRole("userproGuest")];
    }
    return tenant.roles
                  .filter(r => !["issueManager", "creator"].includes(r))
                  .filter(r => proRoles.includes(r))
                  .concat(commercialOfferToRoles(tenant.tenantCommercialOffer))
                  .map(role => {
                    if(role === "agent" && tenant.canValorizeForOthers){
                      return acl.getRole("superAgent");
                    }
                    return acl.getRole(role);
                  });
  },
  "starterActivator": (context, user) => [context.acl.getRole("starterActivator")],
  "external": (context, user) => [context.acl.getRole("external")],
  "citizenExternal": (context, user) => [context.acl.getRole("citizenExternal")],
  "collaborator": (context, user) => user.roles
                                          .filter(r => collaboratorRoles.includes(r))
                                          .map(role => context.acl.getRole(role)),
  "tablet": (context, user) => [context.acl.getRole("tablet")]
})
.next((context, roles) => {
  return roles;
})
.build();

const getRolesOnData = Abstract.sync("discriminator", (context, acl, user, data, notConnected) => {
  context.acl           = acl;
  context.data          = data;
  context.notConnected  = notConnected;
  if(!user) return [acl.getRole("guest")];
  return user;
})
.next({
  "requestorValidation": (context, user) => {
    const { acl } = context;
    return [acl.getRole("requestorValidation")];
  },
  "starterActivator": (context, user) => {
    const { acl } = context;
    return [acl.getRole("starterActivator")];
  },
  "starterActivationConfirmEmail": (context, user) => {
    const { acl } = context;
    return [acl.getRole("starterActivationConfirmEmail")];
  },
  "technical": (context, user) => {
    const { acl } = context;
    switch(user.application){
    case "jvs-ac": return [acl.getRole("attractiveCity")];
    case "jvs-gs": return [acl.getRole("gestionDeSalleJvs")];
    }
  },
  "citizen": (context, user) => {
    const { acl } = context;
    if(user.mustValidateEmail){
			return [acl.getRole("citizenNotValidated")];
		} else {
			return [acl.getRole("citizen")];
		}
  },
  "pro": (context, user) => {   
    let last = null;
    const { acl } = context;
    if(!context.data.tenants || !context.data.tenants.length){
      return [acl.getRole("userpro")];
    }
    //Création d'une structure virtuel de définition de rôle pour chaque tenants sur lequel on travail
    let tenantsRoles = context.data.tenants.map(t => ({ tenant: t.toString(), roles: []}));
    //Ajout des définition de rôle de l'utilisateur
    tenantsRoles = tenantsRoles.concat(user.tenants.filter(t => t.roles && t.roles.length).map(t => Object.assign(t, {tenant: t.tenant.toString()})));
    //Suppression des définition de rôle en double (on garde celui qui a des rôles de définis)
    tenantsRoles = tenantsRoles.sort((a, b) => {
      const cmp = a.tenant.localeCompare(b.tenant);
      if(cmp) return cmp;
      return a.roles.length - b.roles.length;
    }).filter(t => {
      if(last === t.tenant){
        return true;
      }
      last = t.tenant;
      return false;
    });
    //Ajout des rôles "virtuelles" à chaque définition de rôles
    tenantsRoles = tenantsRoles.map(tenant => extendWithVirtualRoles(user, tenant));

    //Récupération des rôles
    let roles = tenantsRoles.reduce((roles, tenant) => {
      const allowedTenantRoles = tenant.roles.filter(r => proRoles.includes(r)).map(role => {
        if(role === "agent" && tenant.canValorizeForOthers){
          return acl.getRole("superAgent");
        }
        return acl.getRole(role);
      });
      const rolesOnData = allowedTenantRoles.reduce((allowedRoles, role) => allowedRoles.concat(role.getRolesOn(tenant, context.data)), []);
      return roles.concat(rolesOnData);
    }, []);
    //Suprresion des rôles en doubles
    roles = roles
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(role => {
        if(role.name === last){
          return false;
        }
        last = role.name;
        return true;
      });
    if(!roles.length){
      return [acl.getRole("userproGuest")];
    }
    return roles;
  },
  "starterActivator": (context, user) => [context.acl.getRole("starterActivator")],
  "external":     (context, user) => context.acl.getRole("external"),
  "citizenExternal":     (context, user) => context.acl.getRole("citizenExternal"),
  "collaborator": (context, user) => user.roles.filter(r => collaboratorRoles.includes(r)).map(role => context.acl.getRole(role)),
  "tablet": (context, user) => [context.acl.getRole("tablet")]
})
.build();



class AclService extends Service{
  protected _acl: Acl;
  constructor(){
    super("acl", ["api", "session"]);
  }

  get acl(): Acl{
    return this._acl as Acl;
  }

  protected _updateFromJson(jsonAcl) {
    this._acl = Acl.createFromJson(jsonAcl).export(getGlobalRoles, getRolesOnData);
  }
  start(){
    return this.waitReady(["api"]).then(([api]) => {
      return api.service("sessions", "acl").execute().then(jsonAcl => {
        this._updateFromJson(jsonAcl);
      });
    });
  }
  connectedUserIsAllow(ressourceName: string, actionName: string, tenant: ObjectId | null = null){
    const user = this.application.getService("session").userToken;
    if(!tenant && this.application.hasService("currentTenant") && this.application.getService("currentTenant").isSelected()){
      tenant = this.application.getService("currentTenant").currentId;
    }
    return this.isAllow(ressourceName, actionName, user, tenant);
  }
  connectedUserIsAllowOn(ressourceName, actionName, object){
    const user = this.application.getService("session").userToken;
    return this.isAllowOn(ressourceName, actionName, user, object);
  }
  isAllow(resourceName, actionName, user, tenant){
    return this._acl[resourceName][actionName].isAllow(user, tenant);
  }
  isAllowOn(resourceName, actionName, user, object){
    return this._acl[resourceName][actionName].isAllowOn(user, object);
  }
  isInheritedFrom(parent, child){
    return this._acl.getRole(parent).isInheritedFrom(this._acl.getRole(child));
  }
}

export default AclService;
