import { Service }        from "@uLib/application";
import LifeCycleManager   from "@uLib/lifeCycleManager";
export default class ReduxStoreService extends Service{
  constructor(){
    super("redux-store");
  }
  start(){
    this._lifeCycleManager = new LifeCycleManager("Global");
  }
  has(name){
    return this._lifeCycleManager.has(name);
  }
  create(name, store){
   return this._lifeCycleManager.create(name, store);
  }
  register(name){
    return this._lifeCycleManager.register(name);
  }
  unregister(name){
    return this._lifeCycleManager.unregister(name);
  }
}