import React        from 'react';
import _            from 'lodash'
import ChildToProps from '@cBehaviour/childToProps';  

import './textarea.css';

class Textarea extends React.Component {

  constructor(props){
    super(props);
    this._input = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState){
    const propsNoValue = _.omit(this.props, "value");
    const nextPropsNoValue = _.omit(this.props, "value");

    if (!_.isEqual(propsNoValue, nextPropsNoValue) || nextProps.value !== this._input.current.value){
      return true;
    }
    return false;
  }

  componentDidMount(){
    if(this.props.value){
      this._input.current.value = this.props.value
    }
  }

  componentDidUpdate(){
    if(!_.isNil(this.props.value) && this._input.current.value !== this.props.value){
      const pos = { start: this._input.current.selectionStart, end: this._input.current.selectionEnd };

      this._input.current.value = this.props.value;
      this._input.current.setSelectionRange(pos.start, pos.end);
    }
  }

  _onChange = () => {
    if(this.props.onChange){
      this.props.onChange(this._input.current.value)
    }
  }

  render() {
    return(
      <textarea
        ref={this._input}
        rows={4}
        type="text" 
        className="bs-input-textarea" 
        placeholder={this.props.children}
        maxLength={this.props.maxLength}
        onChange={this._onChange}
      />
    );
  }
}

export default Textarea;