import { Helper }   from '@uLib/application';

export default class File extends Helper {
  constructor() {
    super('file', ["configuration"]);
  }

  makeStorageUrl(file, params = null) { 
    let paramsString = "";
    if (params){
      if (params.fit) {
        paramsString = paramsString + `f_${params.fit}`;
      }
      if (params.width) {
        paramsString = paramsString + `${paramsString.length ? "," : ""}w_${params.width}`;
      }
      if (params.height) {
        paramsString = paramsString + `${paramsString.length ? "," : ""}h_${params.height}`;
      }
      if (file.meta && file.meta.rotate) {
        paramsString = paramsString + `${paramsString.length ? "," : ""}r_${file.meta.rotate}`;
      }
    }    
    return `${this.application.getService("configuration").get("file_storage_read_url")}/${paramsString}${ paramsString.length ? "/" : ""}${file.storageId}${params && params.format ? "." + params.format : ""}`;
  }
}