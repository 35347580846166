import React from "react";

class Synchronizer extends React.Component {
  constructor(props) {
    super(props);
    if (props.entity) {
      this._entity = props.entity;
      this._entity.addListener(this);
    }
  }
  handleEvent() {
    this.forceUpdate();
  }
  _updateStore(entity) {
    if (this._entity !== entity) {
      if (this._entity) this._entity.removeListener(this);
      this._entity = entity;
      if (this._entity) this._entity.addListener(this);
    }
  }
  componentWillUnmount() {
    if (this._entity) {
      this._entity.removeListener(this);
    }
  }
  render() {
    this._updateStore(this.props.entity);
    if (!this._entity) return null;

    const props = {
      [this.props.propertyName ? this.props.propertyName : "data"]: this.props.entity
    };

    return React.createElement(Synchronizer.Context.Provider, { value: this.props.entity },
      this.props.children instanceof Function
        ? this.props.children(this.props.entity, this.props)
        : React.cloneElement(this.props.children, props)
    );
  }
}

Synchronizer.Context = React.createContext();

export default Synchronizer;