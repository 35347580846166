import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';

import RouteContext from '../context/route';
import Path from '../types/Path';

interface JunctionProps {
  path: Path
};

const Junction: FunctionComponent<PropsWithChildren<JunctionProps>> = ({ path, children }) => {
  const stringBuilder = useContext(RouteContext);

  const junctionSb = React.useMemo(() => stringBuilder.add(path), [stringBuilder, path]);

  return (
    <RouteContext.Provider value={ junctionSb }>
    { children }
    </RouteContext.Provider>
  );
}

export default Junction;