
import { createStore as _createStore, applyMiddleware, compose } from 'redux/lib/redux';
import Event from "./event";

const createReduxStore = function(name, initialState, reducer){
    // const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
    //     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    //         name: name
    //     })
    //     : compose;
    const composeEnhancers = compose;
    const enhancer = composeEnhancers(
        applyMiddleware(),
    );
    return _createStore(reducer, initialState, enhancer);
};

export default class Store {
  constructor(id, initialState, reducer = {}, cloneStateHander = (v => v)){
    this._id                = id;
    this._reducer           = reducer;
    this._reducer["@@INIT"] = function(state, payload){}
    this._reducer["set"]    = function(state, payload){
      state[payload.propertyName] = payload.value;
    };
    this._reducer["delete"] = function(state, payload){
      delete state[payload.propertyName];
    };
    this._store = createReduxStore(id, initialState, (state, payload) => {
      if(this._reducer[payload.type]){
        state = cloneStateHander(state);
        this._reducer[payload.type](state, payload);
      }
      return state;
    });
    this._storeUpdatedEvent = new Event();
    this._store.subscribe(() =>  {
        this._storeUpdatedEvent.trigger("storeUpdated", this);
    });
  }
  get datas(){
    return this._store.getState();
  }
  get id(){
      return this._id;
  }
  get onStoreUpdated(){
      return this._storeUpdatedEvent;
  }
  _set(propertyName, value){
    this._store.dispatch({
      type: "set",
      propertyName: propertyName,
      value: value
    });
  }
  _delete(propertyName){
    this._store.dispatch({
      type: "delete",
      propertyName: propertyName
    });
  }
  execute(actionName){
    if(!this[actionName]){
      throw new Error(`Unknow action : ${this.id}.${actionName}`)
    }
    const params = Array.prototype.slice.call(arguments, 1);
    return this[actionName].apply(this, params);
  }
};