import React      from "react";
import ScrollBar  from "./scrollBar";
import Slot       from '@uComponents/slot';

export default class MultiListsController extends React.Component{
  static List = Slot();
  constructor(props){
    super(props);
    this._onScroll  = this._onScroll.bind(this);
  }
  get triggerPosition(){
    return this.props.triggerPosition ? this.props.triggerPosition : 0.8;
  }
  _getFocusedList(scrollBarPosition){
    const listsProps = MultiListsController.List.props(this, true);
    let height = 0;
    return listsProps.reduce((acc, list) => {
      if (list.isLoadable()) {
        acc.push({
          startHeight: height,
          endHeight: height + list.getHeight(),
          load: list.load
        });
      }
      height = height + list.getHeight();
      return acc;
    }, []).find(list => list.startHeight < scrollBarPosition && list.endHeight > scrollBarPosition);

  }
  _onScroll(scrollBar){
    const scrollBarPosition = scrollBar.viewPortHeight * scrollBar.position;
    const focusedList = this._getFocusedList(scrollBarPosition);
    if(focusedList && scrollBarPosition > focusedList.endHeight * this.triggerPosition){
      focusedList.load();
    }
  }
  render(){
    const lists = MultiListsController.List.get(this, true);
    return (
      <ScrollBar
        onScroll= {this._onScroll}
        viewPortClassName={this.props.viewPortClassName}
      >
        {lists}
      </ScrollBar>
    )
  }
}