const cloneAndExtract = (object) => {
  object    = JSON.parse(JSON.stringify(object));
  const id  = object._id;
  delete object._id;
  return { id: id, data: object };
}
export default (application, resourceName) => {
  return {
    find(query, sort, offset, limit){
      return application.getService("api").service(resourceName, "get").execute(query, sort, offset, limit);
    },
    count(query){
      return application.getService("api").service(resourceName, "count").execute(query);
    },
    findOne(id){
      return application.getService("api").service(resourceName, "getOne").execute(id);
    },
    create(object){
      return application.getService("api").service(resourceName, "post").execute(object);
    },
    update(object){
      object = cloneAndExtract(object);
      return application.getService("api").service(resourceName, "put").execute(object.id, object.data);
    },
    partial(object){
      object = cloneAndExtract(object);
      return application.getService("api").service(resourceName, "patch").execute(object.id, object.data);
    },
    remove(object){
      return application.getService("api").service(resourceName, "delete").execute(object._id);
    }
  };
}