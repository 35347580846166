export default class FileCollection {
  constructor(files) {
    this._files = files;
  }

  length() {
    return this._files.length;
  }

  get(index) {
    return this._files[index];
  }

}