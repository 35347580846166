
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";
import { getDefaultIssue }        from "@uBusiness/lib/issues/getDefault";
import dayjs                      from "dayjs";

export default (application) => {
  const defaultValue = () => {
    return {
      eventHorizonInDay: 7,
      schedulerRule: {
        discriminator: "weekly",
        dayOfWeek: []
      },
      history: {
        start: dayjs().toISOString(),
        lastPlannedDate: null,
        nbrAlreadyPlanned: 0
      },
      type: "deadline",
      template: getDefaultIssue(application.getService("acl"), application.getService("geolocation"))
    };
  };

  return ModelBuilder.create("Recurrence", Key.create("_id"), stdRepositoryConnector(application, "recurrences"),  defaultValue)
    .add(p => p("_id", "string"))
    .add(p => p("history", "object")
      .add(p => p("start", "date"))
      .add(p => p("lastPlannedDate", "date"))
      .add(p => p("nbrAlreadyPlanned", "number"))
    )
    .add(p => p("endRule", "object")
      .add(p => p("discriminator", "string"))
      .add(p => p("number", "number"))   
      .add(p => p("date", "date")) 
    )
    .add(p => p("schedulerRule", "any"))
    .add(p => p("eventHorizonInDay", "number"))
    .add(p => p("ended", "bool"))
    .add(p => p("deleted", "bool"))
    .add(p => p("nextExecution", "date"))
    .add(p => p("template", "object")
      .add(p => p("category", "entity").type("Category"))
      .add(p => p("description", "string"))
      .add(p => p("files", "array").of(t => t("entity").type("File")))
      .add(p => p("location", "object")
        .add(p => p("address", "object")
          .add(p => p("streetNumber", "string"))
          .add(p => p("street", "string"))
          .add(p => p("locality", "string"))
          .add(p => p("zip", "string"))
          .add(p => p("country", "string"))
        )
        .add(p => p("building", "entity").type("Building"))
        .add(p => p("place", "string"))
        .add(p => p("locationInfo", "string"))
        .add(p => p("position", "geometry"))
        .add(p => p("road", "object")
          .add(p => p("code", "string"))
          .add(p => p("km", "string"))
        )
      )
      .add(p => p("requestor", "object")
        .add(p => p("_id", "string"))
        .add(p => p("firstname", "string"))
        .add(p => p("lastname", "string"))
        .add(p => p("type", "string"))
        .add(p => p("email", "string"))
        .add(p => p("phone", "string"))
      )
      .add(p => p("equipment", "entity").type("Equipment"))
    )
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add( p => p("tags", "array").of("string"))
    .add( p => p("issues", "array").of(t => t("entity").type("Issue").link("_id", "recurrence", )))
    .build();
}