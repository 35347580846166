import React, { FunctionComponent, PropsWithChildren }     from 'react';
import ButtonStyle from './stylized';

type RadioButtonProps = {
  pushed: boolean,
  "data-testid": string
}

const ButtonPushed = ButtonStyle.orangeTransparent.fluid.small.alignLeft;
const ButtonUnpushed = ButtonStyle.transparent.fluid.small.alignLeft;

const Radio: FunctionComponent<PropsWithChildren<RadioButtonProps>> = ({ pushed, "data-testid": dataTestId, children }) => {
  const Button = pushed ? ButtonPushed : ButtonUnpushed;
  return (
    <Button data-testid={ dataTestId }>
    { children }
    </Button>
  );
}



export default Radio;