
import React, { ComponentType, FunctionComponent, PropsWithChildren} from 'react';
import List           from "./list";
import Header         from './header';
import Radio          from './radio';
import Display        from './display';
import Icon           from './icon';
import Stylized  from './stylized'
import Button, { ButtonProps } from './base';


function withStyle <StyleProps>(Style: ComponentType<StyleProps>): FunctionComponent<PropsWithChildren<StyleProps & ButtonProps>> {
  return ({ disabled, onMouseDown, onMouseUp, onClick, onDoubleClick, children, ...props }) => {
    return (
      <Button disabled={ disabled } onMouseDown={ onMouseDown } onMouseUp={ onMouseUp } onClick={ onClick } onDoubleClick={ onDoubleClick }>
        <Style {...props} disabled={ disabled }>
          { children }
        </Style>
      </Button>
    );
  };
};

export default Object.assign(Button, {
  withStyle,
  Text: withStyle(Stylized.Text),
  Header,
  Radio,
  List,
  Icon,
  Display,
  Stylized
});