import React from 'react';
import { ApplicationContext } from '../application';
import useForceUpdate from './useForceUpdate';
import Application, { Service } from '@universal/lib/application';

export type ServiceEvent<Service> = {
  [Property in keyof Service as Extract<Property, `${'on'}${string}`>]: Service[Property];
}
export type EventName<Service> = keyof ServiceEvent<Service>;

const isApplication = (application: null | Application): application is Application => {
  return application !== null;
}

function useServiceListenener<T extends Service>(serviceName: string, listens: EventName<T>[]): void {
  const application = React.useContext(ApplicationContext);

  if(!isApplication(application)) {
    throw new Error('useServiceListener must be used within an ApplicationContext');
  }

  const service = React.useMemo(() => application.getService(serviceName), [serviceName, application]);

  listens.forEach(listen => {
    if(!service[listen]) {
      throw new Error(`Service ${serviceName} does not have event ${listen as string}`);
    }
  });
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    const listener = { handleEvent: () => forceUpdate() };
    listens.forEach(listen => {
      service[listen].addListener(listener);
    });
    return () => {
      listens.forEach(listen => {
        service[listen].removeListener(listener);
      });
    };
  }, [service, ...listens]);
};

export default useServiceListenener;