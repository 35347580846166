import React, { ComponentType, FunctionComponent, MouseEvent, PropsWithChildren } from 'react';

export interface ButtonProps {
  disabled?: boolean;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

const stopEventAndExecuteAction = (action) => (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  if(action) {
    action();
  }
}

const withMouseEventStopped = <P extends ButtonProps>(Component: ComponentType<PropsWithChildren<P>>): FunctionComponent<PropsWithChildren<P>> => {
  return (props: PropsWithChildren<P>) => {
    const { 
      onClick,
      onDoubleClick,
      onMouseDown,
      onMouseUp,
      disabled,
      children
    } = props;

    const onClickHandler = React.useCallback(stopEventAndExecuteAction(onClick), [onClick]);
    const onDoubleClickHandler = React.useCallback(stopEventAndExecuteAction(onDoubleClick), [onDoubleClick]);
    const onMouseDownHandler = React.useCallback(stopEventAndExecuteAction(onMouseDown), [onMouseDown]);
    const onMouseUpHandler = React.useCallback(stopEventAndExecuteAction(onMouseUp), [onMouseUp]);

    return (
      <Component
        { ...props }
        disabled={ disabled }
        onMouseDown={ disabled ? undefined : onMouseDownHandler }
        onMouseUp={ disabled ? undefined : onMouseUpHandler }
        onClick={ disabled ? undefined : onClickHandler }
        onDoubleClick={ disabled ? undefined : onDoubleClickHandler }
      >
      { children }
      </Component>
    );
  };
};

export default withMouseEventStopped;