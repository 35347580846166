import React        from "react";
import Application  from "@uBehaviour/application";
import Release      from "@cFeatures/release";
import Display      from "@cComponents/displayIf";

const Next = Application.forward(["release"], [], (props) => (
  <Display.If condition={props.release.hasAReleaseSoon()}>
    <Release.Next next={props.release.next} />
  </Display.If>
));
export default Next;