import { Service }        from "@uLib/application";
import Event        from "@uLib/event";

export default class UrlService extends Service{
  private _params: Record<string, string>;

  private _onParamsUpdated: Event;

  constructor(){
    super("url", ["navigator"]);
    this._params = {};
    this._onParamsUpdated = new Event();
  }

  start(){
    return this.waitReady(["navigator"]).then(([navigator]) => {
      const s1 = window.location.search.split('?');
      if(s1.length === 2){
        const s2 = s1[1].split("&");
        this._params = s2.reduce((acc, s) => {
          const idx = s.indexOf("=");
          if(idx === -1) return acc;
          acc[s.substr(0, idx)] = decodeURIComponent(s.substr(idx + 1));
          return acc;
        }, {});
      }else{
        this._params = navigator.state;
      }
      if(!this._params){
        this._params = {};
      }
      this.syncParamsWithNavigatorStateAndTriggerUpdate();
    });
  }

  get onParamsUpdated(): Event {
    return this._onParamsUpdated;
  }

  get params(): Record<string, string>{
    return JSON.parse(JSON.stringify(this._params));
  }

  has(name: string): boolean {
    return this.params[name] !== undefined;
  }

  get(name: string): string {
    return this.params[name];
  }

  private syncParamsWithNavigatorStateAndTriggerUpdate(): void {
    this.application.getService("navigator").replace(window.location.pathname, this.params);
    this._onParamsUpdated.trigger(this.params);
  }

  set(name: string, value: string): void {
    this._params = { ...this.params, [name]: value };
    this.syncParamsWithNavigatorStateAndTriggerUpdate();
  }

  remove(name: string): void {
    if(!this.has(name)){
      throw new Error(`Unknow url params "${name}"`)
    }
    delete this._params[name];
    this.syncParamsWithNavigatorStateAndTriggerUpdate();
  }
};