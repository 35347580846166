import { Service }        from "@uLib/application";
import RequestBuilder     from "@cLib/http";
import Event from "@universal/lib/event";
import _ from "lodash";


class HttpService extends Service {
  private _onRequestStart: Event;
  private _onRequestEnd: Event;

  private _nbrRequestInProgress: number;

  constructor() {
    super("http");
    this._onRequestStart = new Event();
    this._onRequestEnd = new Event();
    this._nbrRequestInProgress = 0;
  }

  private _triggerStart = () => {
    ++this._nbrRequestInProgress;
    this._onRequestStart.trigger(this._nbrRequestInProgress);
    this.triggerUpdate();
  }

  private _triggerEnd = () => {
    --this._nbrRequestInProgress;
    this._onRequestEnd.trigger(this._nbrRequestInProgress);
    _.defer(() => this.triggerUpdate(), 1000);
  }

  hasRequestInProgress = (): boolean => this._nbrRequestInProgress > 0;

  create = (httpContext: any, path: any) => {
    return RequestBuilder.create(httpContext, path, { start: this._triggerStart, end: this._triggerEnd });
  }
  get onRequestStart() { return this._onRequestStart; }
  get onRequestEnd() { return this._onRequestEnd; }
}

export default HttpService;