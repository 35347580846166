import React, { FunctionComponent, MouseEvent } from 'react';
import FocusBlur from '../focusBlur';
import Numeric from './numeric';

import './quantity.css';

interface QuantityProps {
  value: number;
  onChange: (value: number) => void;
  gap?: number;
  textify?: (value: number) => string;
  numericType?: 'integer' | 'decimal';
  min?: number;
  max?: number;
};

const Quantity: FunctionComponent<QuantityProps> = ({ value, onChange, gap = 1, textify = (value) => ('' + value), numericType, min = 0, max }) => {

  const [editMode, setEditMode] = React.useState<boolean>(false);

  const stopEventAndBeginEditMode = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setEditMode(true);
  }, [setEditMode]);

  const stopEditMode = React.useCallback(() => setEditMode(false), [setEditMode]);

  const changeValue = React.useCallback((value: number) => {
    if(isNaN(value)){
      value = 0;
    }
    if (value < min) {
      value = min;
    }
    if (max && value > max) {
      value = max;
    }
    onChange(value);
  }, [onChange]);

  const decreaseByGap = React.useCallback((e) => {
    e.stopPropagation();
    changeValue(Math.max(value - gap, 0));
  }, [onChange, value, gap]);

  const increaseByGap = React.useCallback((e) => {
    e.stopPropagation();
    changeValue(Math.max(value + gap, 0));
  }, [onChange, value, gap]);


  const input = React.useRef<Numeric>();

  React.useEffect(() => {
    if(editMode && input.current){
      input.current.focus();
      input.current.select();
    }
  }, [editMode]);

  const subtractBtn = React.useMemo(() => {
    if (value <= min) {
      return null;
    }
    return <span onClick={ decreaseByGap } className='bs-quantity-control fa fa-minus' />;
  },[ value ]);
  
  const addBtn = React.useMemo(() => {
    if (max && value >= max) {
      return null;
    }
    return <span onClick={ increaseByGap } className='bs-quantity-control fa fa-plus' />;
  },[ value ]);

  return (
    <div className='bs-quantity'>
      { subtractBtn }
      {
        editMode
          ? <FocusBlur el='span' onBlur={ stopEditMode } hasFocus>
              <Numeric ref={ input } value={ value } onChange={ changeValue } min={ min } max={ max } numericType={ numericType }/>
            </FocusBlur>
          : <div className='bs-quantity-display' onClick={ stopEventAndBeginEditMode }>{ textify(value) }</div>
      }
      { addBtn }
    </div>
  );
};

export default Quantity;