import { Service }    from "@uLib/application";
import Event          from "@uLib/event";
import { Model }      from "@uLib/model";

export default class RepositoryService extends Service{
  constructor(loaders){
    super("repository", ["api"]);
    this._loaders         = loaders;
    this._onEntityUpdated = new Event();
    this._repositories    = {};
  }
  get onEntityUpdated(){
    return this._onEntityUpdated;
  }
  start(){
    return this.waitReady(["api"]).then(() => {
      this._loaders.map(loader => {
        this.load(loader);
      });
    });
  }
  load(loader){
    const repository = loader(this.application);
    this._repositories[repository.name] = repository;
  }
  has(name){
    return !!this._repositories[name];
  }
  get(name){
    if(name instanceof Model){
      return name;
    }
    if(!this.has(name)){
      throw new Error(`Unknow model ${name}`);
    }
    return this._repositories[name];
  }
  update(type, action, datas){
    if(this.has(type)){
      const repository  = this.get(type);
      datas.forEach(data => {
        let entity = repository.updateSet(action, data);
        if(entity){
          this._onEntityUpdated.trigger(repository, entity, action);
          entity.dispose();
        }
      });
    }
  }
}