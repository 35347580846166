import React, { CSSProperties, FunctionComponent, ReactNode }    from "react";
import useService from "@universal/behaviour/hooks/useService";
import MessageService, { Message as MessageData, PositionType } from "@common/services/message";

import "./message.css";
import classNames from "@universal/lib/classNames";

const DisplayMessage: FunctionComponent<MessageData> = ({ type, content, delay, position, close }) => {
  const icon = React.useMemo(() => {
    switch(type){
      case "warning":
        return "fa-exclamation";
      case "error":
        return "fa-exclamation-triangle";
      case "success":
        return "fa-check";
      case "info":
        return "fa-info";
      default:
        return "fa-info";
    }
  }, [type]);

  return (
    <div
      className={ classNames("bs-message").add(`bs-message-${ type }`).addIf("bs-message-vanish", !!delay) }
      style={ delay ? { "--bs-message-duration": delay + "ms"} as CSSProperties : undefined }
    >
      <div className="bs-message-body">
        <div className="bs-message-icon">
          <i className={`fa ${ icon }`}></i>
        </div>
        <div className="bs-message-content">
          { content instanceof Function ? content() : content }
        </div>
      </div>
      <div className="bs-message-close" onClick={ close }>
        <i className="fa fa-times"></i>
      </div>
    </div>
  );
};

const Viewer: FunctionComponent = ({}) => {
  const messageService = useService<MessageService>("message", ["onServiceUpdated"]);
  
  if(!messageService.messages.length) return null;

  const positions = messageService.messages.reduce<Record<PositionType, ReactNode[]>>((acc, message) => {
    if(!acc[message.position]){
      acc[message.position] = [];
    }
    acc[message.position].push(<DisplayMessage { ...message } key={ message.id } />);
    return acc;
  }, {} as Record<PositionType, ReactNode[]>);

  return (
    <>
    {
      Object.entries(positions).map(([position, messages]) => (
        <div key={ position } className={`bs-message-viewer bs-message-viewer-${ position }`}>
        { messages }
        </div>
      ))
    }
    </>
  );
}


export default Viewer;