import { Service }  from "@uLib/application";
import Configuration from "@universal/types/business/Configuration";
import ApiService from "./api";

export default class RemoteConfigurationService extends Service{

  private _configuration: Configuration;

  constructor(){
   super("remoteConfiguration",["api"]);
   this._configuration = [];
  }
  start() {
    return this.waitReady(["api"])
      .then(async ([api]: [ApiService]) => {
        this._configuration = await api.service("configuration", "get").execute();
      });
  }

  has(key){
    return !!this._configuration[key];
  }


  get(key){
    if(!this.has(key)){
      throw new Error(`Unknow property "${key}"`);
    }
    return this._configuration[key];
  }

}