import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import Navigator from "../types/Navigator";
import RouterContext from "../context/router";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { Listener } from "../../../lib/event";

interface RouterProps {
  navigator: Navigator;
}

const Router: FunctionComponent<PropsWithChildren<RouterProps>> = ({ navigator, children }) => {
  const forceUpdate = useForceUpdate();
  const [ready, setReady] = useState(false);

  React.useEffect(() => {
    const listener = new Listener(forceUpdate);
    navigator.onChange.addListener(listener);
    setReady(true);
    return () => {
      navigator.onChange.removeListener(listener);
      setReady(false);
    };
  }, [navigator, forceUpdate]);

  return (
    <RouterContext.Provider value={{ current: navigator.current, navigator }}>
    { ready ? children : null }
    </RouterContext.Provider>
  );
}

export default Router;