import React, { FunctionComponent, PropsWithChildren } from 'react';
import Slot from '@universal/components/slot2';

import './section.css';

const SectionLevelContext = React.createContext<number>(1);

const Title = Slot();
const Content = Slot();

interface SectionProps {
  level?: number;
}

const Section: FunctionComponent<PropsWithChildren<SectionProps>> & { Title, Content } = ({ level, children }) => {
  const usedLevel = level || React.useContext<number>(SectionLevelContext);

  const title = Title.get(children);
  if(!title){
    throw new Error("Section.Title must be provided");
  }

  const content = Content.get(children, false, Title.not(children));

  return (
    <SectionLevelContext.Provider value={ usedLevel + 1 }>
      <div className={`bs-section bs-section-level-${usedLevel}`}>
        <div className="bs-section-title">{ title }</div>
        <div className="bs-section-content">{ content }</div>
      </div>
    </SectionLevelContext.Provider>
  );
}

Section.Title = Title;
Section.Content = Content;

export default Section;