import { Helper }                 from "@uLib/application";
import RemoteConfigurationService from "../services/remoteConfiguration";
import SessionService             from "../services/session";
import ApiService                 from "../services/api";

class Citizen extends Helper {

  constructor() {
    super('citizen', ["remoteConfiguration", "api", "session"]);
  }

  mustValidateCgu(){
    const configuration = this.application.getService<RemoteConfigurationService>("remoteConfiguration");
    const session = this.application.getService<SessionService>("session");

    return session.isLogged() && session.user.discriminator === "citizen" && session.user.cgu.version !== configuration.get("cguVersion")
  }
  
  updateCgu(){
    const api = this.application.getService<ApiService>("api");
    const session = this.application.getService<SessionService>("session");
    const configuration = this.application.getService<RemoteConfigurationService>("remoteConfiguration");
    
    return api.service('users', 'updateCgu').execute(session.user._id, configuration.get("cguVersion"));
  }
}

export default Citizen;