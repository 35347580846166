class DataSource {
  constructor(dataSource){
    this._dataSourceHandler = dataSource;
    this._offset = 0;
    this._finishLoading = false;
    this._waitLoadingPromise = Promise.resolve(true);
    this._loading = false;
    this._datas = [];
  };

  get loaded() {
    return this._datas;
  }

  isFinishedLoading() {
    return this._finishLoading;
  }

  isLoading() {
    return this._loading;
  }

  clear() {
    this._datas = [];
    this._finishLoading = false;
    this._loading = false;
    this._offset = 0;
  }

  next(query = null, sort = null, pageSize = 10){
    if (this.isFinishedLoading() || this._loading) {
      return this._waitLoadingPromise;
    }      
    this._loading = true;
    this._waitLoadingPromise = this._dataSourceHandler(query, sort, this._offset, pageSize).then(results => {
      if (results.length !== pageSize) {
        this._finishLoading = true;
        this._offset = this._offset + results.length;
      } else {
        this._offset = this._offset + pageSize;
      }
      this._datas = this._datas.concat(results);
      this._loading = false;
    });    
    return this._waitLoadingPromise;
  }
}

export default DataSource;