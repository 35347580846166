import React  from 'react';
import Input  from "./input";
import My     from "./objectContext";

export default class InputObject extends Input { 
  static Disable = (props) => {
    return React.createElement(InputObject.Context.Provider, { value: null }, props.children);
  } 
  static Context = My.Context;
  constructor(props) {
    super(props)
    this._inputs      = [];
    this._value = this.props.value;
  }

  forceUpdate(){
    super.forceUpdate();
    this._inputs.forEach(input => input.forceUpdate());
  }
  
  get value() {
    const val = {};
    this._inputs.forEach(input => { 
      const value = input.value;        
      val[input.name] = value;
    });
    return this._out(val);
  }

  register(input){
    if(this._inputs.indexOf(input) !== -1) return false;
    this._inputs.push(input);
    input.onChange.addListener(this);
    return true;
  }

  unregister(input){
    const idx = this._inputs.indexOf(input);
    if(idx === -1) return false;
    this._inputs.splice(idx, 1);
    input.onChange.removeListener(this);
    return true;
  }
  _getValue(component){
    const value = this.props.value ? this.props.value : this._value;
    if(value){
      return value[component.name];
    }
  }

  getErrors() {
    let errors = [];
    this._inputs.forEach(i => {
      errors = errors.concat(i.getErrors());
    });
    if(this._inputObject){
      errors.forEach(e => {
        e.path = this.name + "." + e.path;
      });
    }
    return errors;
  }

  _getErrors(component){
    const errors = this.props.errors ? this.props.errors : this._errors;
    if(this._bckErrors !== errors){
      this._computed_errors = errors.reduce((acc, error) => {
        const paths = error.path.split(".");
        if(paths.length > 1){
          const property = paths[0];
          if(!acc[property]){
            acc[property] = [];
          }
          acc[property].push({
            path: paths.slice(1).join("."),
            error: error.error
          });
        }else{
          const property = paths[0];
          if(!acc[property]){
            acc[property] = [];
          }
          acc[property].push(error);
        }
        return acc;
       }, {});
       this._bckErrors = errors;
    }

    if(this._computed_errors[component.name]){
      return this._computed_errors[component.name];
    }
    return [];
  }

  get fullName(){
    if(this._inputObject){
      return super.fullName;
    }
    return "";
  }

  componentWillUnmount() {
    this._inputs.slice().forEach(input => {
      this.unregister(input)
    });
  }

  handleEvent(event, input, origin) {
    if (event === "onChange") {
      this._triggerChange(origin);
    }      
  }
  
  __render() {
    return React.createElement(InputObject.Context.Provider, { value: this }, this.props.children);
  }
}  
