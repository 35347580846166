import React      from "react";
import ScrollBar  from "./scrollBar";
import Data       from "@uBehaviour/data";

export default class ListController extends React.Component{
  constructor(props){
    super(props);
    this._list      = null;
    this._onScroll  = this._onScroll.bind(this);
  }
  get triggerPosition(){
    return this.props.triggerPosition ? this.props.triggerPosition : 0.8;
  }
  get list(){
    if(this.props.list){
      return this.props.list.current;
    }
    return this._list;
  }
  _onScroll(scrollBar){
    if(scrollBar.position > this.triggerPosition && this.list && !this.list.isFinishedLoading()){
      this.list.loadNext();
    }
  }
  render(){
    const children = this.props.list
      ? this.props.children
      : React.createElement(Data.List.BackReference.Consumer, { onUpdate: (_old, _new) => this._list = _new }, this.props.children);
    return React.createElement(ScrollBar, { onScroll: this._onScroll, viewPortClassName: this.props.viewPortClassName }, children)
  }
}