import React      from 'react';
import InputBase  from './inputBase';

const hasWritableInPrototypeChain = (object, name) => {
  const descriptor = Object.getOwnPropertyDescriptor(object, name);
  if(descriptor){
    return descriptor.writable || !!descriptor.set;
  }
  const proto = Object.getPrototypeOf(object);
  if(object === Object.prototype){
    return false;
  }
  hasWritableInPrototypeChain(proto, name);
}

class NewToOld extends InputBase {
  constructor(props){
    super(props);
    this._input = React.createRef();
    this._value = null;
  }
  get value(){
    return this._value;
  }
  set value(value){
    this._value = value;
    if(this._input.current && hasWritableInPrototypeChain(this._input.current, "value")){
      this._input.current.value = value;
    }
  }
  _onInputChange = (value) => {
    this._value = value;
    this._triggerChange();
  };

  _render(){
    return this.props.children instanceof Function
    ? this.props.children(this._onInputChange, this._value)
    : React.cloneElement(this.props.children, {
      onChange: this._onInputChange,
      value: this._value,
      ref: Object.getPrototypeOf(this.props.children) === Function.prototype ? this._input : undefined
    })
  }
}
class NewToOldMultiple extends InputBase {
  constructor(props){
    super(props);
    this._input = React.createRef();
    this._value = [];
  }
  get value(){
    return this._value;
  }
  set value(value){
    this._value = value;
    if(this._input.current && hasWritableInPrototypeChain(this._input.current, "value")){
      this._input.current.value = value;
    }
  }
  _onInputChange = (value) => {
    this._value = value;
    this._triggerChange();
  };
  
  _render(){
    return this.props.children instanceof Function
    ? this.props.children(this._onInputChange, this._value)
    : React.cloneElement(this.props.children, {
      onChange: this._onInputChange,
      value: this._value,
      ref: Object.getPrototypeOf(this.props.children) === Function.prototype ? this._input : undefined
    })
  }
}
NewToOld.Multiple = NewToOldMultiple;

export default NewToOld;