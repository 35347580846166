// Usage: import i18n from 'common/config/i18n';
const i18n = {
  "all": "Tous",
  "assignment_state_finished": "Terminé",
  "assignment_state_ongoing": "En cours",
  "assignment_state_to_do": "À faire",
  "bs_input_period_multiDay": {
      "fr-FR": "%nbrDay% jours",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "%nbrDay% Tage"
  },
  "bs_input_period_oneDay": {
      "fr-FR": "1 jour",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "1 Tag"
  },
  "buildings": "Bâtiments",
  "cancel": {
      "fr-FR": "Annuler",
      "en-GB": "Cancel",
      "nl-BE": "Annuleren",
      "de-DE": "Stornieren"
  },
  "client": "Client",
  "commune": "Commune",
  "contains": {
      "fr-FR": "Contient",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Inhalt"
  },
  "copy_team_calendar_link": "Copier le lien de l'équipe pour paramétrer mon calendrier",
  "copy_user_calendar_link": "Copier le lien de l'utilisateur pour paramétrer mon calendrier",
  "default_email": "Email par défaut",
  "define_password": {
      "fr-FR": "Mise à jour du mot de passe",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Aktualisierung des Passworts"
  },
  "user_updatePassword_define_password": {
    "fr-FR": "Définir un mot de passe",
    "en-GB": "Set a password",
    "nl-BE": "Stel een wachtwoord in",
    "de-DE": "Legen Sie ein Passwort fest"
  },
  "user_updatePassword_password": {
    "fr-FR": "Mot de passe",
    "en-GB": "Password",
    "nl-BE": "Wachtwoord",
    "de-DE": "Passwort"
  },
  "user_updatePassword_password_verification": {
    "fr-FR": "Vérification du mot de passe",
    "en-GB": "Password check",
    "nl-BE": "Wachtwoordcontrole",
    "de-DE": "Passwortüberprüfung"
  },
  "user_updatePassword_send": {
    "fr-FR": "Enregistrer",
    "en-GB": "Save",
    "nl-BE": "Opnemen",
    "de-DE": "Speichern"
  },
  "user_updatePassword_define_password_info": {
      "fr-FR": "Le mot de passe doit avoir une taille d'au moins 8 caractères et comporter au moins une majuscule (A-Z), une minuscule (a-z) et un caractère numérique (0-9).\nVeuillez le saisir deux fois dans les cases ci-dessous.",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben (A-Z), einen Kleinbuchstaben (a-z) und ein numerisches Zeichen (0-9) enthalten.\nBitte geben Sie das Passwort zweimal in die Felder unten ein."
  },
  "documentation": {
      "fr-FR": "Documentation",
      "en-GB": "Documentation",
      "nl-BE": "Documentatie",
      "de-DE": "Dokumentation"
  },
  "email_already_used": {
      "fr-FR": "Profil déjà existant dans la base Betterstreet",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Ein Konto mit dieser E-Mail-Adresse existiert bereits"
  },
  "entity": "Structure",
  "filter_bulk_active_filter": {
      "fr-FR": "Filtre(s) actif(s)",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Aktive(r) Filter"
  },
  "filter_form_fieldGroup_global_infos": "Informations générales",
  "filter_form_fieldGroup_selected_filters": "Filtre(s) selectionné(s)",
  "filter_form_header": "Nom du filtre : %name%",
  "filter_form_label_default": "Filtre par défaut",
  "filter_form_label_name": "Nom",
  "filter_form_remove": "Supprimer",
  "filter_form_remove_confirmation": "Êtes-vous sûr de vouloir supprimer ce filtre ?",
  "filter_form_submit": "Sauver",
  "filter_manager_default": "Voir tout",
  "filter_manager_errorEquivalentFilter_create": "Le filtre \"%filter%\" est équivalent à celui que vous souhaitez créer",
  "filter_manager_errorEquivalentFilter_update": "Le filtre \"%filter%\" est équivalent à celui que vous souhaitez modifier",
  "freesearch": {
      "fr-FR": "Recherche libre",
      "en-GB": "Free search",
      "nl-BE": "Gratis zoeken",
      "de-DE": "Forschung"
  },
  "help_starterDemo": {
    "fr-FR": "Guide d'utilisation",
    "en-GB": "User guide",
    "nl-BE": "Gebruikershandleiding", //Google Translate
    "de-DE": "Benutzerhandbuch" //Google Translate
  },
  "input_duration_day": {
      "fr-FR": "Jour(s)",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Tag(e)"
  },
  "input_duration_month": {
      "fr-FR": "Mois",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Monate"
  },
  "input_duration_undefined": {
      "fr-FR": "Non défini",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": ""
  },
  "input_duration_year": {
      "fr-FR": "Année(s)",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Jahr(e)"
  },
  "input_email_format_error": {
      "fr-FR": "Le format de l'adresse e-mail n'est pas valide",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Das Format der E-Mail-Adresse ist ungültig"
  },
  "input_file_dropArea_diplay_label": "Glissez et déposez vos fichiers ici",
  "input_period_allday": {
      "fr-FR": "Toute la journée",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Den ganzen Tag"
  },
  "input_selectable_error_min": {
      "fr-FR": "Nombre minimum d'éléments sélectionnés non atteint",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Mindestanzahl an ausgewählten Elementen nicht erreicht"
  },
  "input_selectable_select": {
      "fr-FR": "Sélectionner",
      "en-GB": "Select",
      "nl-BE": "Selecteren",
      "de-DE": "Auswählen"
  },
  "input_url_format_error": {
      "fr-FR": "Le format de l'url n'est pas valide",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Das Format der URL ist ungültig"
  },
  "input_url_label": {
      "fr-FR": "Libellé",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Bezeichnung"
  },
  "input_url_url": {
      "fr-FR": "URL",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "URL"
  },
  "issue_state_deleted": "Supprimé",
  "issue_state_in_progress": "En traitement",
  "issue_state_merged": "Fusionné",
  "issue_state_open": "Ouvert",
  "issue_state_planned": "Planifié",
  "issue_state_rejected": "Rejeté",
  "issue_state_resolved": "Résolu",
  "issue_state_transferred": "Transféré",
  "item_actions_tooltip": "Actions",
  "limit_reached": {
      "fr-FR": "Limite atteinte",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Maximale Anzahl erreicht"
  },
  "loginAs_filter_tenant": "Structure",
  "loginAs_filter_tenants": "Structures",
  "no": "Non",
  "no_tooltip_manager": "Tooltip : pas de manager",
  "password_format_mismatch": {
      "fr-FR": "Le mot de passe choisi doit contenir huit caractères minimum, une lettre majuscule, une lettre minuscule et un chiffre",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Das gewählte Passwort muss mindestens acht Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten."
  },
  "password_mismatch": {
      "fr-FR": "Les mots de passe ne correspondent pas",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Die Passwörter stimmen nicht überein"
  },
  "password_mismatch2": {
    "fr-FR": "Bliblablou",
    "en-GB": "",
    "nl-BE": "",
    "de-DE": "Die Passwörter stimmen nicht überein"
},
  "period_allday": {
      "fr-FR": "Du %start% au %end%",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Von %start% bis %end%"
  },
  "period_sameday_allday": {
      "fr-FR": "Le %date%",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Am %date%"
  },
  "period_sameday_withhour": {
      "fr-FR": "Le %date% de %start% à %end%",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Am %date% von %start% bis %end%"
  },
  "period_withhour": {
      "fr-FR": "Du %start_date% à %start_hour% au %end_date% à %end_hour%",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Vom %start_date% um %start_hour% bis %end_date% um %end_hour%"
  },
  "release_item_bugsFixed": {
      "fr-FR": "Bogues corrigés",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Behobene Fehler"
  },
  "release_item_newFeatures": {
      "fr-FR": "Nouvelles fonctionnalités",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Neue Funktionen"
  },
  "release_next_service_downtime": {
      "fr-FR": "Nous vous informons qu'une mise à jour aura lieu le %date% à %time%. Le service BetterStreet ne sera pas disponible pour une durée de %duration%.",
      "en-GB": "We inform you that an update will take place on %date% at %time%. The BetterStreet service will not be available for %duration%.",
      "nl-BE": "We informeren u dat er een update zal plaatsvinden op %date% om %time%. De BetterStreet-service zal %duration% niet beschikbaar zijn.",
      "de-DE": "Wir möchten Sie davon in Kenntnis setzen, dass am %date% um %time% eine Aktualisierung stattfindet. Der BetterStreet-Dienst wird für einen Zeitraum von %duration% nicht verfügbar sein."
  },
  "release_note": {
      "fr-FR": "Note de mise à jour",
      "en-GB": "Patch notes",
      "nl-BE": "Patch-opmerkingen", // Google Translate
      "de-DE": "Aktualisierungshinweis"
  },
  "release_publish_at": {
      "fr-FR": "Date de publication : %date%",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Veröffentlichungsdatum: %date%"
  },
  "release_publish_on": {
      "fr-FR": "Publié le %date%",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Veröffentlicht am %date%"
  },
  "release_seen": {
      "fr-FR": "Vu",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Ansicht"
  },
  "renew_password": {
      "fr-FR": "Renouvellement de mon mot de passe",
      "en-GB": "Renew my password",
      "nl-BE": "Vernieuw mijn wachtwoord",
      "de-DE": "Mein Passwort erneuern"
  },
  "renew_password_info": {
      "fr-FR": "Cliquez sur le bouton ci-dessous pour renouveller votre mot de passe. Un email sera envoyé à l'adresse e-mail de votre compte.",
      "en-GB": "Click on the button below to renew your password. An email will be sent to your account email address.",
      "nl-BE": "Klik op onderstaande knop om uw wachtwoord te vernieuwen. Er wordt een e-mail verzonden naar het e-mailadres van uw account.",
      "de-DE": "Klicken Sie auf die Schaltfläche unten, um Ihr Passwort zu erneuern. Eine E-Mail wird an die E-Mail-Adresse Ihres Kontos gesendet."
  },
  "required_input": {
      "fr-FR": "Ce champ est requis",
      "en-GB": "This field is required",
      "nl-BE": "Dit veld is verplicht",
      "de-DE": "Dieses Feld ist erforderlich"
  },
  "role": "Rôle",
  "roles": "Rôles",
  "send": {
      "fr-FR": "Envoyer",
      "en-GB": "Send",
      "nl-BE": "Doorsturen",
      "de-DE": "Senden"
  },
  "send_message": {
      "fr-FR": "Contacter le support",
      "en-GB": "Contact support",  //Traduction copilot
      "nl-BE": "Contact opnemen met ondersteuning",  //Traduction google translate
      "de-DE": "Support kontaktieren" //Traduction copilot
  },
  "subscription_channelSetterHeader_email": "Email",
  "subscription_channelSetterHeader_push": "Notification",
  "subscription_info": "Choisissez les sujets qui vous intéressent et la façon dont vous souhaitez en être informé.",
  "subscription_parameters": "Paramètres de notifications",
  "subscription_subscriptionForm_submit": "Sauvegarder les préférences",
  "tenant_country_be": "Belgique",
  "tenant_country_fr": "France",
  "tenant_country_lu": "Luxembourg",
  "type": "Type",
  "unsubscribe_explanation": {
      "fr-FR": "Vous avez été désabonné de l'ensemble des mails envoyés par Betterstreet.",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Sie wurden von allen von BetterStreet versandten E-Mails abgemeldet."
  },
  "unsubscribe_explanation_citizen": {
      "fr-FR": "Pour information, vous avez la possibilité de inscrir à nouveau depuis le site web ou l'application mobile dans le menu \"Mon Profil\"",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Zur Information: Sie haben die Möglichkeit, sich von der Webseite oder der mobilen Anwendung aus im Menü „Mein Profil“ erneut für E-Mails anzumelden."
  },
  "unsubscribe_explanation_pro": {
      "fr-FR": "Pour information, vous avez la possibilité de personnaliser les mails que vous souhaitez recevoir depuis le site web dans le menu \"Paramètres de notifications\"",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Zur Information: Sie haben die Möglichkeit, die E-Mails, die Sie erhalten möchten, auf der Website im Menü „Benachrichtigungseinstellungen“ anzupassen."
  },
  "unsubscribe_footer": {
      "fr-FR": "Si vous avez des questions concernant ce courrier, vous pouvez envoyer un message à l'adresse e-mail support@betterstreet.org.",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Wenn Sie Fragen zu dieser Mitteilung haben, können Sie eine Nachricht an support@betterstreet.org senden."
  },
  "unsubscribe_succes": {
      "fr-FR": "Désinscription effecuée avec succès",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Abmeldung erfolgreich"
  },
  "update": {
      "fr-FR": "Modifier",
      "en-GB": "",
      "nl-BE": "",
      "de-DE": "Ändern"
  },
  "user_item_pro_role_admin": "Administrateur",
  "user_item_pro_role_agent": "Agent",
  "user_item_pro_role_buildingIssuer": "Bâtiment",
  "user_item_pro_role_buildingViewer": "Consultant bâtiment",
  "user_item_pro_role_categoryManager": "Responsable de catégorie",
  "user_item_pro_role_equipmentIssuer": "Créateur sur équipement",
  "user_item_pro_role_equipmentViewer": "Consultant équipement",
  "user_item_pro_role_manager": "Responsable",
  "user_item_pro_role_publicSpaceIssuer": "Espace public",
  "user_item_pro_role_publicSpaceViewer": "Consultant espace public",
  "user_item_pro_role_statistician": "Accès statistique",
  "user_item_pro_role_teller": "Guichet",
  "user_item_pro_role_viewer": "Consultant",
  "users_filter_collaborator_role": "Rôles de collaborateur",
  "users_filter_collaborator_role_appAdmin": "Administrateur de l'application",
  "users_filter_collaborator_role_issueProvider": "Fournisseur de signalements",
  "users_filter_collaborator_role_superAdmin": "Super administrateur",
  "users_filter_discriminator": "Type",
  "users_filter_pro_role_admin": "Administrateur",
  "users_filter_pro_role_agent": "Agent",
  "users_filter_pro_role_buildingIssuer": "Créateur bâtiment",
  "users_filter_pro_role_buildingViewer": "Consultant bâtiment",
  "users_filter_pro_role_categoryManager": "Responsable de catégorie",
  "users_filter_pro_role_equipmentIssuer": "Créateur équipement",
  "users_filter_pro_role_equipmentViewer": "Consultant équipement",
  "users_filter_pro_role_manager": "Responsable",
  "users_filter_pro_role_publicSpaceIssuer": "Créateur espace public",
  "users_filter_pro_role_publicSpaceViewer": "Consultant espace public",
  "users_filter_pro_role_statistician": "Accès statistique",
  "validate": {
      "fr-FR": "Valider",
      "en-GB": "Validate",
      "nl-BE": "Bevestigen",
      "de-DE": "Bestätigen"
  },
  "yes": "Oui",
  "common_issue_detail_follow": {
    "fr-FR": "Suivre",
    "en-GB": "Follow",
    "nl-BE": "Volgen",
    "de-DE": "Verfolgen"
  },
  "common_issue_detail_unfollow": {
    "fr-FR": "Ne plus suivre",
    "en-GB": "No longer follow",
    "nl-BE": "Niet langer volgen",
    "de-DE": "Nicht mehr verfolgen"
  },
  "common_issue_detail_write_comment": {
    "fr-FR": "Écrire un commentaire",
    "en-GB": "Write a comment",
    "nl-BE": "Schrijf een reactie",
    "de-DE": "Einen Kommentar schreiben"
  },
  "common_issue_detail_send": {
    "fr-FR": "Envoyer",
    "en-GB": "Send",
    "nl-BE": "Doorsturen",
    "de-DE": "Senden"
  },
  "common_issue_detail_comment_title": {
    "fr-FR": "Commentaire",
    "en-GB": "Comment",
    "nl-BE": "Commentaar",
    "de-DE": "Kommentar"
  },
  "common_issue_detail_state_change": {
    "fr-FR": "Changement de statut",
    "en-GB": "Status change",
    "nl-BE": "Status wijziging",
    "de-DE": "Statusänderung"
  },
  "common_issue_detail_issue_created": {
    "fr-FR": "Signalement",
    "en-GB": "Report",
    "nl-BE": "Melding",
    "de-DE": "Meldung"
  },
  "common_issue_state_open": {
    "fr-FR": "Ouvert",
    "en-GB": "Open",
    "nl-BE": "Open",
    "de-DE": "Offen"
  },
  "common_issue_state_in_progress": {
    "fr-FR": "En traitement",
    "en-GB": "In treatment",
    "nl-BE": "In behandeling",
    "de-DE": "In Bearbeitung"
  },
  "common_issue_state_planned": {
    "fr-FR": "Planifié",
    "en-GB": "Planned",
    "nl-BE": "Gepland",
    "de-DE": "Geplant"
  },
  "common_issue_state_resolved": {
    "fr-FR": "Résolu",
    "en-GB": "Resolved",
    "nl-BE": "Opgelost",
    "de-DE": "Gelöst"
  },
  "common_issue_state_transferred": {
    "fr-FR": "Transféré",
    "en-GB": "Transferred",
    "nl-BE": "Overgebracht",
    "de-DE": "Weitergeleitet"
  },
  "common_issue_state_deleted": {
    "fr-FR": "Supprimé",
    "en-GB": "Deleted",
    "nl-BE": "Verwijderd",
    "de-DE": "Gelöscht"
  },
  "common_issue_state_rejected": {
    "fr-FR": "Rejeté",
    "en-GB": "Rejected",
    "nl-BE": "Geweigerd",
    "de-DE": "Abgelehnt"
  },
  "common_issue_state_merged": {
    "fr-FR": "Fusionné",
    "en-GB": "Merged",
    "nl-BE": "Samengevoegd",
    "de-DE": "Zusammengeführt"
  },
};

export default i18n;