import moment from 'moment';

const toEscape = /[.*+?^${}()|[\]\\]/g;
export const escapeRegExp     = (string) => string.replace(toEscape, '\\$&');
export const objectGetPath    = (path, value) => path.split(".").reduce((value, property) => value ? value[property] : undefined, value);
export const numericGenerator = function*(min, max, step = 1){
  if(max === undefined){ 
    max = min;
    min = 0;
  }
  for(let i = min; i < max; i += step){
    yield i;
  }
};

export const range = (min, max, step) => {
  return [...numericGenerator(min, max, step)];
}
export const clone          = object => JSON.parse(JSON.stringify(object));

//Calcul de la valeur H de la couleur HSL à appliquer en style sur la date d'échéance
const timeBoundary = 15 * 24 * 60 * 60;
export const calculateHDeadlineColor = (issueDeadline) => {
  const now = moment();
  let diffFromNow = moment(issueDeadline).diff(now) / 1000;
  if (diffFromNow > timeBoundary) {
    diffFromNow = timeBoundary;
  } else if(diffFromNow < 0) {
    diffFromNow = 0;
  }
  
  return Math.round(120 * diffFromNow / timeBoundary);

  // const now = moment(new Date());
  // const deadline = moment(issueDeadline);
  // const duration = moment.duration(deadline.diff(now));
  // let seconds = duration.asSeconds();
  // if (seconds > 1296000) {
  //   seconds = 1296000;
  // } else if(seconds < 1) {
  //   seconds = 1;
  // }
  // return Math.round(120 * seconds / 1296000);    
}

export const strFirstCharUpperCase = (str: string): string => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}

export const strFirstCharLowerCase = (str: string): string => {
  return str.substring(0, 1).toLowerCase() + str.substring(1);
}

export const strToUpperCamelCase = (str: string): string => {
  return str.split(" ").map((str) => {
    return strFirstCharUpperCase(str);
  }).join("");
};

export const strToLowerCamelCase = (str: string): string => {
  return str.split(" ").map((str, idx) => {
    if(idx === 0){
      return strFirstCharLowerCase(str);
    }
    return strFirstCharUpperCase(str);
  }).join("");
};

export default {
  escapeRegExp,
  objectGetPath,
  numericGenerator,
  range,
  clone,
  calculateHDeadlineColor,
  strFirstCharUpperCase,
  strFirstCharLowerCase,
  strToLowerCamelCase,
  strToUpperCamelCase
};