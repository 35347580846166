import React, { FunctionComponent, PropsWithChildren } from 'react';

import './title.css';


interface SubTitleProps {

}

type SubTitleType = FunctionComponent<PropsWithChildren<SubTitleProps>>;


interface TextProps {

}

type TextType = FunctionComponent<PropsWithChildren<TextProps>> & {
  SubTitle: SubTitleType
};

interface TitleProps {

}

type TitleType = FunctionComponent<PropsWithChildren<TitleProps>> & { 
  Text: TextType;
};

const Title: TitleType = ({ children }) => (
  <div className='bs-old-section-title'>
    { children }
  </div>
);

Title.Text = (({ children }) => (
  <div className='bs-old-section-title-text'>
    { children }
  </div>
)) as TextType;

Title.Text.SubTitle = ({ children }) => (
  <div className='bs-old-section-title-text-subtitle'>
    { children }
  </div>
);

export default Title;