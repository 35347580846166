import React        from 'react';
import InputBase    from './inputBase';
import ChildToProps from "@cBehaviour/childToProps";

export default class Url extends InputBase {
  getErrors() {
    const errors = super.getErrors();
    const urlRegex = RegExp("^(?:http(s)?://)");
    if(this._value && !urlRegex.test(this._value)) {
      errors.push({
        path: this.props.name,
        error: "input_url_format_error"
      })
    }
    return errors;
  }

  _render() {
    return (
      <ChildToProps child={ this.props.children } name="placeholder">
        <input 
          ref={ref=>this._input = ref}
          type="text" 
          className="bs-old-input-url bs-old-input-text-like"
          onChange={() => {
            this._value = this._input.value;
            this._triggerChange(); 
          }}
          value={ this._value ? this._value : "" }
        />
      </ChildToProps>
    )      
  }
}