import React        from 'react';
import InputBase    from './inputBase';
import ChildToProps from "@cBehaviour/childToProps";

export default class Email extends InputBase {

  constructor(props){
    super(props);
    this._input = React.createRef();
    this._pos   = null;
  }

  getErrors() {
    const errors = super.getErrors();
    const emailSchema = new RegExp("^.+@.+\\..+$");

    if(!emailSchema.test(this._input.current.value)) {
      errors.push({
        path: this.props.name,
        error: "input_email_format_error"
      })
    }
    return errors;
  }

  componentDidUpdate(){
    if(this._pos){
      this._input.current.setSelectionRange(this._pos.start, this._pos.end);
      this._pos = null;
    }
  }

  _render() {
    return (
      <input 
        ref={ this._input }
        type="text" 
        className="bs-old-input-email bs-old-input-text-like"
        onChange={() => {
          this._pos = { start: this._input.current.selectionStart, end: this._input.current.selectionEnd };
          this._value = this._input.current.value.trim();
          this._triggerChange();
        }}
        placeholder={ this.props.children }
        value={ this._value ? this._value : "" }
      />
    )      
  }
}