import React          from 'react';
import Input          from "./input";
import CError         from "@cComponents/error";

export default class InputBase extends Input {
  getErrors(){
    if (this.props.required && !this.value){
      return [{
        path:this.props.name,
        error: "required_input"
      }];
    }
    return [];
  }
  _render(){
    throw new Error("Must be override");
  }
  __render(){
    let render = this._render();
    if(this.props.noError){
      return render;
    }
    return (
      <CError errors={this._errors}>{ render }</CError>
    );
  }
};