import React        from "react";
import { v4 as uuid }   from "uuid";
import InputBase    from "./inputBase";
import Slot         from "@cComponents/slot";

import "./radio.css";

const Value = Slot();

class Group extends InputBase{
    constructor(props){
        super(props);
        this._id = uuid();
    }
    
    _triggerChange(ev){
        this._value = this._values[parseInt(ev.target.value)];
        super._triggerChange();
    }

    getErrors() {
        const errors = super.getErrors();
        
        return errors;
    }

    _render(){
        this._values = [];
        const radios = Value.props(this, true);
        return (
            <div className="bs-old-input-old-radio">
                <input type="radio" />
                <div>
                    {
                        radios.map((radio, idx) => {
                            this._values.push(radio.value);
                            return (
                                <label key={idx} onClick={() => this._triggerChange({ target: { value: idx }})}>
                                    <input type="radio" value={idx} name={this._id} onChange={ this._triggerChange }/>
                                    { 
                                        radio.children instanceof Function
                                            ? radio.children(this._value === radio.value)
                                            : React.cloneElement(radio.children, { selected: this._value === radio.value })
                                    }
                                </label>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
};

export default {
    Group,
    Value
};