import React          from "react";
import _              from 'lodash';
import Event          from '@uLib/event';
import List           from './list';

class RequestLoader {
  constructor(request) {
    this._request = request;
    this._onLoadingFinished = new Event();
  }

  get onLoadingFinished() {
    return this._onLoadingFinished;
  }

  load(offset, length) {
    return this._request(offset, length).then((results) => {
      this._onLoadingFinished.trigger(results);
    })
  }
}

class Paginator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
    }
    this._isLoading = false;
    this._loadingFinished = false;
    this._requestLoaderListener = {
      handleEvent: (results) => {
        if (results.length === 0 || results.length < this.props.length) {
          this._loadingFinished = true;
        }
        _.defer(() => this.setState({ results: this.state.results.concat(results) }));
      }
    }
    this._initializeRequestLoader();
    this.load = _.debounce(this.load.bind(this), 1000); 
  }

  _initializeRequestLoader() {
    this._requestLoader = new RequestLoader(this.props.load);
    this._requestLoader.onLoadingFinished.addListener(this._requestLoaderListener)
  }

  load(){
    if (!this.isLoading()) {
      if (this.isFinishedLoading()) {
        return;
      }
      this._isLoading = true;
      this._requestLoader.load(this.state.results.length, this.props.length)
      .finally(() => this._isLoading = false);
    }
  }

  isLoading() {
    return this._isLoading;
  }

  isFinishedLoading() {
    return this._loadingFinished;
  }
  
  componentDidMount(){
    this.load();
  }
  
  componentDidUpdate(lastProps) {
    if (this.props.id !== lastProps.id) {
      this.forceReload();
    }
  }

  forceReload() {
    this._loadingFinished = false;
    this._requestLoader.onLoadingFinished.removeListener(this._requestLoaderListener);
    this._isLoading = false;
    this.setState({ results: [] });
    this._initializeRequestLoader();
    this.load();
  }

  loadNext() {
    this.load();
  }

  get loadedLength() {
    return this.state.results.length;
  }

  render(){
    if(!this.state.results) return null;
    return React.createElement(List.BackReference.Provider, { value: this }, this.props.children instanceof Function
            ? this.props.children(this.state.results, this._isLoading, this._loadingFinished)
            : React.cloneElement(this.props.children, { datas: this.state.results }));
  }
}

export default Paginator;