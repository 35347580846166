import React from "react";

import InputFile from "../file";
import File      from "@cComponents/file";
import Display   from "@uComponents/displayIf";
import useService from '@universal/behaviour/hooks/useService';

import "./gallery.css";

const InputFileGallery = ({ value, onChange, onFileClick, isAllowedToDelete, limit, column = 4 }) => {
  const fileService = useService("file")
  if(isAllowedToDelete && !(isAllowedToDelete instanceof Function)){
    const viatd = isAllowedToDelete;
    isAllowedToDelete = () => viatd;
  }
  return (
    <InputFile value={ value } onChange={ onChange } limit={ limit }>
    {(files, add, isAllowedToAdd) => {
      const transform = (file, index, component) => {
        if(isAllowedToDelete && isAllowedToDelete(file)){
          component = (
            <div className="bs-input-file-gallery-inputFile-deletor">
              { component }
              <div className="bs-input-file-gallery-inputFile-deletor-button" onClick={() => files[index].remove() }>
                <span className="fa fa-times" />
              </div>
            </div>
          );
        }
        return component;
      };
      return (
        <File.Gallery files={ files.map(f => f.entity) } column={ column } onClick={ onFileClick } transform={ transform }>
        {(height, width) => (
          <Display.If condition={ isAllowedToAdd }>
            <label className="bs-input-file-gallery-inputFile">
              <File.Frame file={ null } height={ height } width={ width } fit="cover"/>
              <input className="bs-input-file-gallery-inputFile-input" type="file" onChange={(ev) => add(ev.currentTarget.files)} accept={ fileService.uploadableMimeTypes.join(", ") } />
            </label>
          </Display.If>
        )}
        </File.Gallery>
      );
    }}
    </InputFile>
  );
}

export default InputFileGallery;