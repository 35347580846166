const combinate = (op, ...queries) => {
  queries = queries.filter(query => !!query);
  if(!queries.length) return {};
  if(queries.length === 1) return queries[0];
  return { [op]: queries };
};

const stackOrCombinate = (op, ...queries) => {
  queries = queries.filter(query => !!query).reduce((acc, query) => {
    if(query[op]) {
      return acc.concat(query[op]);
    }
    acc.push(query);
    return acc;
  }, []);
  return combinate("$or", ...queries);
};

const joinWithOptimizer = (main, optimizer) => {
  return { main, optimizer };
};

const getQueryIfOptimized = (query) => {
  if(!query){
    return query;
  }
  if(query.main || query.optimizer) {
    return combinate("$and", query.main, query.optimizer);
  }
  return query;
};

export {
  combinate,
  stackOrCombinate,
  joinWithOptimizer,
  getQueryIfOptimized
};

export default {
  combinate,
  stackOrCombinate,
  joinWithOptimizer,
  getQueryIfOptimized
};
