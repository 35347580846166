import React from 'react';

import './icon.css';

class Icon extends React.Component{
  render() {
    const className = `bs-button bs-button-icon${this.props.color ? ' bs-color-' + this.props.color : ''} ${this.props.shape ? 'bs-button-icon-' + this.props.shape : 'bs-button-icon-circle'}${ this.props.pushed ? ' bs-button-pushed' : '' }`;
    const faClass = this.props.icon ? this.props.icon : this.props.faClass;
    return(
      <div className={ className } onClick={(ev) => { ev.stopPropagation(); this.props.onClick(ev); }}>
        <div>
          <span className={`fa fa-${faClass}`}></span>
        </div>
      </div>
    );
  }
}

export default Icon;