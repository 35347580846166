import React, {
  FunctionComponent,
  PropsWithChildren
}                   from "react";
import Form         from '@uBehaviour/form';
import T            from '@cBehaviour/i18n';
import cButton       from '@cComponents/button';
import Nudge        from "@cComponents/nudge";
import Title        from "@cComponents/title";
import Input        from '@cComponents/input';
import Validator    from '@uBusiness/validator';
import CError       from '@cComponents/error';

import './updatePassword.css';

interface UpdatePassword {
  updatePassword: (password: string) => void;
}

const rules = [
  (value) => value.password !== value.password_verification ? { error: "password_mismatch" } : null
];

const Button = cButton.withStyle(cButton.Stylized.orange.round);

const UpdatePassword: FunctionComponent<PropsWithChildren<UpdatePassword>> = ({ updatePassword }) => {
  const submit = React.useCallback((form: any, value: { password: string }) => {
    updatePassword(value.password);
  }, [updatePassword]);

  return (
    <div>
      <div className="bs-update-password-title">
        <Title><T>user_updatePassword_define_password</T></Title>
      </div>
      <Form.Simple
        submit={ submit }
        validator={{
          schema: Validator.User,
          keys:["password"],
          rules:rules
        }}
      >
        {(ctx, value, errors, form, submit) => (
          <div className="bs-update-password">
            <Nudge type="info"><T>user_updatePassword_define_password_info</T></Nudge>
            <div className="bs-update-password-form">
              <Form.Simple.ErrorAdapter name="password">
              {(errors) => (
                <CError errors={ errors }>
                  <Form.Simple.InputAdapter name="password">
                  {(value, set) => (
                    <Input.Password value={value} onChange={ set }>
                      <T>user_updatePassword_password</T>
                    </Input.Password>
                  )}
                  </Form.Simple.InputAdapter>
                </CError>
              )}
              </Form.Simple.ErrorAdapter>
              <div className="bs-update-password-confirm-password-cerror-like">
                <Form.Simple.InputAdapter name="password_verification">
                {(value, set) => (
                  <Input.Password value={value} onChange={ set }>
                    <T>user_updatePassword_password_verification</T>
                  </Input.Password>
                )}
                </Form.Simple.InputAdapter>
              </div>
            </div>
            <CError errors={ errors.global } />         
            <Button onClick={ submit }>
              <T>send</T>
            </Button>  
          </div>
        )}
      </Form.Simple>
    </div>
  );
}

export default UpdatePassword;