export default {
  firstname: {
    type:"string",
    required:true,
    empty: false
  },
  lastname: {
    type:"string",
    required:true,
    empty: false
  },
  email: {
    type:"email",
    required:true
  },
  password: { type: "string", pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/, required: true, empty: false}
}