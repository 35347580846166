import React      from 'react';
import InputBase  from './inputBase';
import Button     from '@cComponents/button'; 

import './number.css';

export default class Number extends InputBase {

  static defaultProps = {
    decimals : 0
  }

  constructor(props) {
    super(props);
    this._divisor = Math.pow(10, this.props.decimals);
    this._changeValue = this._changeValue.bind(this);
  }

  getErrors() {
    const errors = super.getErrors();
    
    return errors;
  }

  componentDidMount() {
    if (!this._value) {
      this._value = this.props.min ? this.props.min * this._divisor : 0;
    }
    super.componentDidMount();
  }

  _getValue() {
    return this._value 
      ? this._value / this._divisor 
      : this.props.min 
        ? this.props.min 
        : 0;
  }

  _changeValue(direction) {
    let value = this._value ? this._value : this.props.min ? this.props.min : 0;
    if (direction === "down") {
      if (this.props.min || this.props.min === 0) {
        if (value > this.props.min) {
          value -= 1;  
        } 
      } else {
        value -=1
      }   
    } else {
      if (this.props.max || this.props.max === 0) {
        if (value < this.props.max * this._divisor) {
          value += 1;  
        } 
      } else {
        value +=1
      }
    }
    this._value = value;
    this._input.value = this._getValue();
    this._triggerChange("button");    
  }

  _triggerChange(type) {
    this._value = 
      type === "button"
        ? this._value
        : this._input.value * this._divisor;
    super._triggerChange();
  }

  _render() {
    const { min, max } = this.props;
    const value = this._getValue();

    return(
      <div className="bs-old-input-number">
        <Button.Display onClick={()=>this._changeValue("down")}>
          <span className="bs-old-input-number-button">-</span>            
        </Button.Display>
        <input 
          ref={ref=>this._input = ref}
          type="number"
          min={min}
          max={max}
          step={value/this._divisor} 
          onChange={()=>{
            this._triggerChange("manual");
          }}
          value={ value }
        />     
        <Button.Display onClick={()=>this._changeValue("up")}>
          <span className="bs-old-input-number-button">+</span>
        </Button.Display>
      </div>      
      
    )      
  }
}