import React  from 'react';
import ReactDOM from 'react-dom';
import Slot   from  './slot';
import { usePopperTooltip } from 'react-popper-tooltip';
import T from "@uBehaviour/i18n";
import "react-popper-tooltip/dist/styles.css";

import './tooltip.css';

class Manager extends React.Component {
  static Context = React.createContext();
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  get container() {
    return this._ref.current;
  }

  render() {
    return (
      <Manager.Context.Provider value={this}>
        { this.props.children }
        <div ref={this._ref} className="bs-tooltip" />
      </Manager.Context.Provider>
    );    
  }  
}

const Subject = Slot();
const Info = Slot();

const Tooltip = React.forwardRef(({ defaultVisible = false, placement = "top-start", interactive = false, trigger = "hover", ...props }, ref) => {
  const [displayTootltip, setDisplayTooltip] = React.useState(false);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    defaultVisible,
    placement,
    interactive,
    offset: [0, 0],
    trigger,
    visible: displayTootltip,
    onVisibleChange: (state => setDisplayTooltip(state))
  });

  React.useImperativeHandle(ref, () => ({
    close() {
      setDisplayTooltip(false);
    }
  }));

  const subject = Subject.get({ props });
  const { style, className = "", children: info } = Info.props({ props });

  return (
    <Manager.Context.Consumer>
    {managerNode => managerNode 
      ? (
        <>
          <div className='bs-tooltip-subject' ref={setTriggerRef}>
            { subject }
          </div>     
          {visible && ReactDOM.createPortal(
            <div
              ref={setTooltipRef}
              {...getTooltipProps(style ? { style } : { className: `${ className } tooltip-container` })}
            >
              { info }
              <div {...getArrowProps(style ? {} : { className: `${ className } tooltip-arrow` })} />
            </div>,
            managerNode.container 
          )}
        </>
      )
      : (<div><T>no_tooltip_manager</T></div>)
    }
    </Manager.Context.Consumer>
  );
});

Tooltip.Subject = Subject;
Tooltip.Info = Info;
Tooltip.Manager = Manager;

export default Tooltip;