// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-section-title {
  padding: 5px;
  border-bottom: 2px solid #cecbc8;
  margin-bottom: 15px;
}

.bs-old-section-title-text {
  font-weight: 600;
  font-size: 1.2em;
}

.bs-old-section-title-text-subtitle {
  font-size:  0.8em;
}

.bs-old-section-title-text,
.bs-old-section-title-text-subtitle {
  color: #5a6266;
}`, "",{"version":3,"sources":["webpack://./../common/components/section/title.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,cAAc;AAChB","sourcesContent":[".bs-old-section-title {\n  padding: 5px;\n  border-bottom: 2px solid #cecbc8;\n  margin-bottom: 15px;\n}\n\n.bs-old-section-title-text {\n  font-weight: 600;\n  font-size: 1.2em;\n}\n\n.bs-old-section-title-text-subtitle {\n  font-size:  0.8em;\n}\n\n.bs-old-section-title-text,\n.bs-old-section-title-text-subtitle {\n  color: #5a6266;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
