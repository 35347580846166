import Building     from "./building";
import Category     from "./category";
import Comment      from "./comment";
import Contact      from "./contact";
import Equipment    from "./equipment";
import File         from "./file";
import Issue        from "./issue";
import Log          from "./log";
import Supply       from "./supply";
import MapLayer     from "./mapLayer";
import Team         from "./team";
import Tenant       from "./tenant";
import User         from "./user";
import Release      from "./release";
import Assignment   from "./assignment";
import Recurrence   from "./recurrence";
import Filter       from "./filter";
import UserCitizen  from "./userCitizen";
import Campaign     from "./campaign";
import Absence      from "./absence";
import Valorization from "./valorization";
import Notification from "./notification";
import IssueGroup   from "./issueGroup";


export default [
    Building,
    Comment,
    User,
    Team,
    Equipment,
    Tenant,
    Category,
    Contact,
    Supply,
    MapLayer,
    File,
    Log,
    Issue,
    Release,
    Assignment,
    Recurrence,
    Filter,
    UserCitizen,
    Campaign,
    Absence,
    Valorization,
    Notification,
    IssueGroup
];
