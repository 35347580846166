import React        from "react";
import { v4 as uuid }   from "uuid";
import InputBase    from "./inputBase";
import Slot         from "@cComponents/slot";

import "./checkbox.css";

const Value = Slot();

class Group extends InputBase{
    constructor(props){
        super(props);
        this._id = uuid();
    }
    get value(){
        const checkboxes = Value.props(this, true);
        this._value = this._value.filter(v => !!checkboxes.find(c => c.value === v));
        return super.value;
    }

    getErrors() {
        let errors = [];
        if (this.props.required && this.value.length === 0) {
            errors.push({
              path:this.props.name,
              error: "required_input"
            });
        }
        return errors;
    }

    _triggerChange(cbValue){
        if (!this._value) {
            this._value= [] ;
        }
        this._value.includes(cbValue)
            ? this._value = this._value.filter(val => val !== cbValue)
            : this._value.push(cbValue);
        
        super._triggerChange();
    }

    _render(){
        const checkboxes = Value.props(this, true);
        this._value = (this._value || []).filter(v => !!checkboxes.find(c => c.value === v));
        return (
            <div className="bs-old-input-checkbox">
                <div className={`${this.props.inline ? "bs-old-input-checkbox-inline" : "bs-old-input-checkbox-column"}`}>
                    {
                        checkboxes.map((checkbox, idx) => {  
                            const checked = this._value && this._value.includes(checkbox.value);                      
                            return (
                                <label key={idx} className={"bs-old-input-checkbox-value" + (checked ? " bs-old-input-checkbox-value-checked" : "")}>
                                    <input type="checkbox" value={idx} name={this._id} onChange={ ()=>this._triggerChange(checkbox.value) } checked={checked}/>
                                    { 
                                        React.cloneElement(checkbox.children)
                                    }
                                </label>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
};

export default {
    Group,
    Value
};