
const clone = (data) => {
  if(Array.isArray(data))     return cloneArray(data);
  if(data instanceof Object)  return cloneObject(data);
  return data;
};

const cloneArray  = array   => array.map(data => clone(data));
const cloneObject = object  => {
  if(object.clone instanceof Function)  return object.clone();
  if(object       instanceof Date)      return new Date(object);
  return Object.keys(object).reduce((_clone, property) => {
    _clone[property] = clone(object[property]);
    return _clone;
  }, {});
}

export default clone;