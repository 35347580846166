class UrlBuilder{
  static create(baseUrl){
    return new UrlBuilder(baseUrl);
  }
  constructor(baseUrl){
    this._baseUrl = baseUrl;
    this._path    = [];
    this._params  = [];
  }
  addPath(path, condition = true){
    if(condition){
      this._path.push(path);
    }
    return this;
  }
  addParam(key, value, condition = true){
    if(condition){
      this._params.push({ key, value });
    }
    return this;
  }
  build(){
    return `${this._baseUrl}${this._path.join("/")}${this._params.length ? "?" : ""}${this._params.map(({ key, value}) => `${key}=${encodeURIComponent(value)}`).join("&")}`;
  }
}

export default UrlBuilder;