import React from 'react';
import useService   from "./useService";

export const useI18n = (key, bind = {}, allowUnknow = false, transform: Function | false = false) => {
  const i18n = useService("i18n");
  return React.useMemo(() => {
    let traduction = i18n.translate(key, bind);
    if (traduction === null) {
      if (allowUnknow) {
        return key;
      } 
      throw new Error(`React.Component::@cBehaviour:I18n(Unknow word : "${key + "" === key ? key : JSON.stringify(key)}")`);
    }
    if(transform){
      traduction = transform(traduction);
    }
    return traduction;
  }, [key, bind, allowUnknow, transform]);
};

export default useI18n;