import React from "react";
import md5   from "md5";
import _ from "lodash";

const Context                 = React.createContext(null);

const composer = (aggregator) => (query) => aggregator.compose(query);

class Aggregator extends React.Component{
  constructor(props){
    super(props);
    this.state = { values: []};
    this._filters = {};
    if(props.default){
      this.state = { values: props.default.map(v => {
        return {
          name: v.name,
          value: v.value,
          excludes: v.excludes ? v.excludes.slice() : []
        };
      })}; 
    }
    this.debounceForceUpdate = _.debounce(this.forceUpdate.bind(this), 0);
  }
  get values(){
    return this.state.values.map(v => ({
      value:      () => JSON.parse(JSON.stringify(v.value)),
      stringify:  () => this._filters[v.name].stringify(v.value),
      key:        () => md5(JSON.stringify(v)),
      drop:       () => this._filters[v.name].drop(v.value)
    }));
  }

  deshydrate() {
    return this.state.values
      .filter(v => this._filters[v.name].isDeshydratable())
      .map(v => ({
        name: v.name,
        value: this._filters[v.name].deshydrate(v.value),
        excludes: v.excludes ? v.excludes.slice() : []
      }));
  }

  hydrate(values) {
    const temporyValues = values.reduce((acc, v) => {
      if (!acc[v.name]) {
        acc[v.name] = [];
      }
      acc[v.name].push(v.value);
      return acc;
    }, {});
    Promise.all(Object.keys(temporyValues).map(name => {
      return Promise.resolve(this._filters[name].hydrate(temporyValues[name]))
      .then(values => {
        if(values instanceof Map){
          return values;
        }
        const map = new Map();
        Object.keys(values).forEach(key => {
          map.set(key, values[key]);
        });
        return map;
      })
      .then(values => ({
        name, values
      }))
    }))
    .then(results => {
      return results.reduce((acc, result) => {
        acc[result.name] = result.values;
        return acc;
      }, {})
    })
    .then(dic => {
      this.setState({values: values.map(v => ({
        name: v.name,
        value: dic[v.name].get(v.value),
        excludes: v.excludes ? v.excludes.slice() : []
      }))})
    });
  }

  isReady(){
    return this.state.values.reduce((ready, v) => ready && !!this._filters[v.name], true);
  }

  register(filter){
    if(!this._filters[filter.name]){
      this.debounceForceUpdate();
    }
    this._filters[filter.name] = filter;
  }
  set(filter, value, excludes = []){
    const idx = this.state.values.findIndex(v => v.name === filter.name);
    if(idx === -1){
      this.add(filter, value, excludes);
    }else{
      const state = Object.assign({}, this.state);
      state.values[idx].value    = value;
      state.values[idx].excludes = excludes;
      this.setState(state);
    }
  }
  add(filter, values, excludes = []){
    if(!Array.isArray(values)){
      values = [values];
    }
    this.setState({
      values: this.state.values.concat(values.map(value => ({ name: filter.name, value, excludes })))
    });
  }
  drop(filter, values){
    if(!Array.isArray(values)){
      values = [values];
    }
    let newValues = this.state.values;
    values.forEach(value => {
      const idx = newValues.findIndex(v => v.name === filter.name && (value.equals ? value.equals(v.value) : v.value === value));
      if(idx !== -1){
        newValues = newValues.slice(0, idx).concat(this.state.values.slice(idx + 1))
      }
    })    
    this.setState({
      values: newValues
    });
  }
  isExclude(filter){
    return this.state.values.reduce((excludes, v) => excludes.concat(v.excludes), [])
                            .includes(filter.name);
  }
  collect(filter){
    return this.state.values.filter(v => v.name === filter.name).map(v => v.value);
  }
  clear(filter){
    if(!filter){
      this.setState({ values: []});
    } else {
      this.setState({ values: this.state.values.filter(v => v.name !== filter.name) })
    }
  }
  compose(query){
    let last = null;
    const queries = this.state.values
      .map(value => value.name)
      .sort()
      .filter(name => {
        if(name === last) return false;
        last = name;
        return true;
      }).reduce((queries, name) => {
        const query = [];
        this._filters[name].buildQuery(this.collect(this._filters[name]), query);
        return queries.concat(query);
      }, []);
    if(!queries.length) return query;
    if(query) queries.push(query);
    return { $and: queries };
  }
  render(){
    const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
    return React.createElement(Context.Provider, { value: { aggregator: this }}, ...children);
  }
}

const Subject = (props) => {
  return React.createElement(Context.Consumer, {}, context => {
    const values = context.aggregator.values
    return context.aggregator.isReady()
      ? (
        props.children instanceof Function
          ? props.children(composer(context.aggregator), values.length)
          : React.cloneElement(props.children, { compose: composer(context.aggregator), hasFilters: values.length })
      ): null
  }
  );
};

class AbstractAdapter extends React.Component {
  constructor(props){
    super(props);
    this.clear        = this.clear.bind(this);
    this.add          = this.add.bind(this);
    this.drop         = this.drop.bind(this);
    this.buildQuery   = this.buildQuery.bind(this);
    this.stringify    = this.stringify.bind(this);
    this.isExclude    = this.isExclude.bind(this);
  }
  get name(){
    return this.props.name;
  }
  get values(){
    return this.context.aggregator.collect(this);
  }
  isDeshydratable(){
    return !this.props.notDeshydratable;
  }
  deshydrate(value) {
    return value;
  }
  hydrate(values) {
    return values.reduce((acc, value) => {
      acc.set(value, value);
      return acc;
    }, new Map());
  }
  clear(){
    this.context.aggregator.clear(this);
  }
  add(value, excludes = []){
    this.context.aggregator.add(this, value, excludes);
  }
  drop(value) {
    this.context.aggregator.drop(this, value);
  }
  set(value, excludes = []){
    this.context.aggregator.set(this, value, excludes);
  }
  isExclude(){
    return this.context.aggregator.isExclude(this);
  }
  render(){
    this.context.aggregator.register(this);
    if(this.isExclude()){
      return null;
    }
    return this.renderFilter(this.values);
  }
  renderFilter(values){
    throw new Error("Must be Override");
  }
  buildQuery(values, queryResults){
    throw new Error("Must be Override");
  }
  stringify(value){
    throw new Error("Must be Override");
  }
}

AbstractAdapter.contextType = Context;

class Generic extends AbstractAdapter{
  renderFilter(values){
    return this.props.multiple
      ? this.props.children(values, (value, excludes) => this.add(value, excludes), (value) => this.drop(value), () => this.clear())
      : this.props.children(values && values.length ? values[0] : undefined, (value, excludes) => this.set(value, excludes), () => this.clear());
  }
  buildQuery(values, queryResults){
    let queries = this.props.buildQuery(this.props.multiple ? values : values[0]);
    if(!Array.isArray(queries)){
      queries = [queries];
    }
    queries.forEach(query => queryResults.push(query));
  }
  deshydrate(value) {
    if (this.props.deshydrate) {
      return this.props.deshydrate(value);
    }
    return super.deshydrate(value);
  }
  hydrate(values) {
    if (this.props.hydrate) {
      return this.props.hydrate(values);
    }
    return super.hydrate(values);
  }
  stringify(value){
    return this.props.stringify(value);
  }
}

class Setter extends React.Component {
  constructor(props){
    super(props);
    this.clear        = this.clear.bind(this);
    this.add          = this.add.bind(this);
    this.drop         = this.drop.bind(this);
    this.isExclude    = this.isExclude.bind(this);
  }
  get name(){
    return this.props.name;
  }
  get values(){
    return this.context.aggregator.collect(this);
  }
  clear(){
    this.context.aggregator.clear(this);
  }
  add(value, excludes = []){
    this.context.aggregator.add(this, value, excludes);
  }
  drop(value) {
    this.context.aggregator.drop(this, value);
  }
  set(value, excludes = []){
    this.context.aggregator.set(this, value, excludes);
  }
  isExclude(){
    return this.context.aggregator.isExclude(this);
  }
  render(){
    if(this.isExclude()){
      return null;
    }
    return this.renderFilter(this.values);
  }
  renderFilter(values){
    return this.props.multiple
      ? this.props.children(values, (value, excludes) => this.add(value, excludes), (value) => this.drop(value), () => this.clear())
      : this.props.children(values && values.length ? values[0] : undefined, (value, excludes) => this.set(value, excludes), () => this.clear());
  }
}

Setter.contextType = Context;

export default {
  Context,
  Aggregator,
  Subject,
  AbstractAdapter,
  Generic,
  Setter
}