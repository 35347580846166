
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultCampaignValue = {
    type: "typeform"
  };
  return ModelBuilder.create("Campaign", Key.create("_id"), stdRepositoryConnector(application, "campaigns"), defaultCampaignValue)
    .add(p => p("_id", "string"))
    .add(p => p("title", "string"))
    .add(p => p("start", "date"))
    .add(p => p("end", "date"))
    .add(p => p("type", "string"))
    .add(p => p("options", "object")
      .add(p => p("id", "string"))
    )
    .add(p => p("country", "string"))
    .add(p => p("roles", "array").of(t => t("string")))
    .build();
}