import React, { MutableRefObject } from 'react';

type UseResizeObserverType = (onResize: (element: Element) => void) => MutableRefObject<Element | undefined>;

const useResizeObserver: UseResizeObserverType = (onResize) => {
  const ref = React.useRef<Element | undefined>();
  
  const resizeObserver = React.useMemo(() => new ResizeObserver((elements) => {
    onResize(elements[0].target);
  }), [onResize]);

  React.useEffect(() => {
    const currentRef = ref;
    if(currentRef.current){
      resizeObserver.observe(currentRef.current);
    }
    return () => {
      if(currentRef.current){
        resizeObserver.unobserve(currentRef.current);
      }
    }
  }, [ref.current, resizeObserver]);

  return ref;
}

export default useResizeObserver;