import React, { FunctionComponent } from 'react';
import useLocation from '../hooks/useLocation';
import Path from '../types/Path';
import useRoute from '../hooks/useRoute';

interface RedirectProps {
  to: Path,
  fromRoot?: boolean;
};

const Redirect: FunctionComponent<RedirectProps> = ({ to, fromRoot = false }) => {
  const [, setLocation] = useLocation();
  const path = fromRoot ? to : useRoute(to);
  React.useEffect(() => {
    setLocation(path);
  }, [path, setLocation]);
  return null;
}

export default Redirect;