import React          from "react";
import _              from "lodash";
import Application    from "@uBehaviour/application";
import Synchronizer   from "./synchronizer";
import BackReference  from "../backReference";
import Slot           from "@uComponents/slot";
import Pager          from './pager';

const Data = {
  Synchronizer
};

const ListBackReference = BackReference.create();

const Separator = Slot();
const Item      = Slot();
const IfEmpty   = Slot();

class List extends Pager {
  render() {
    const { has: hasSeparator, children: Separator } = ExportedList.Separator.props(this, false, { has: () => false, children: null });
    const Item = ExportedList.Item.get(this, false, this.props.children);
    const ifEmpty = IfEmpty.get(this, false, null);
    
    if(this.isEmpty()){
      return ifEmpty;
    }

    let lastEntity = null;
    return React.createElement(ListBackReference.Provider, { value: this } ,
      this._pager.mapLoaded((entity, index) => {
        const els = [];
        if(hasSeparator(lastEntity, entity)){
          const separatorKey = `separator_${entity.id}`;
          els.push(Separator instanceof Function 
            ? React.cloneElement(Separator(entity, index), { key: separatorKey })
            : React.cloneElement(Separator, { key: separatorKey, data: entity, index })
          );
        }
        lastEntity = entity;
        const itemKey =  `item_${entity.id}`;
        els.push(Item instanceof Function 
          ? React.cloneElement(Item(entity, { index, key: entity.id }), { key: itemKey })
          : React.cloneElement(Item, { index, key: itemKey, data: entity })
        );
        if(els.length === 1){
          return els[0];
        }
        return React.createElement(React.Fragment, { key: entity.id }, ...els);
      })
    );
  }
}


const ExportedList = Application.Service.forward([["pager", "pagerService"]], List);

ExportedList.BackReference = ListBackReference;
ExportedList.Separator     = Separator;
ExportedList.Item          = Item;
ExportedList.IfEmpty       = IfEmpty;

export default ExportedList;