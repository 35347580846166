import React          from 'react';
import Slot           from "@cComponents/slot";
import Display        from '@cComponents/displayIf';

import './field.css';

export default class Field extends React.Component {
  static Label = Slot();
  static Input = Slot();

  render() {
    const inputs      = Field.Input.get(this, true);
    const required    = inputs.reduce((required, input) => required || input ? input.props.required : false, false);
    const disabled    = inputs.reduce((disabled, input) => disabled || input ? input.props.disabled : false, false);
    const labelProps  = Field.Label.props(this);
    return (
      <div className={`bs-old-field${ disabled ? " bs-disabled" : ""} ${this.props.className ? " " + this.props.className : ""}`}>
        <div className={`${labelProps.className ? " " + labelProps.className : "bs-old-field-label"}`}> 
          <span>{ Field.Label.get(this) }</span>
          <Display.If condition={required}><span> *</span></Display.If> 
        </div> 
        <div className="bs-old-field-input">
          { inputs }
        </div>
      </div>
    );
  }
}  