import deepDiff from "deep-diff";
import dayjs from "dayjs";

import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import ObjectId from "@universal/types/technic/ObjectId";
import BsDate from "@universal/types/technic/Date";
import Log, { isLog, isLogV2,  } from "@uTypes/business/Log";
import Comment, { isComment } from "@uTypes/business/Comment";
import User from "@universal/types/business/User";

class BackToThePast<Type> {

  private _currentValue: BusinessEntity<Type>;
  private _afterChangeValue: Type | null;
  private _beforeChangeValue: Type | null;
  private _currentIndex: number;
  private _logsAndComment: (BusinessEntity<Log, { createdBy: Loader}> | BusinessEntity<Comment, { createdBy: Loader}>)[];

  constructor(current: BusinessEntity<Type>, logs: BusinessEntity<Log, { createdBy: Loader}>[], comments: BusinessEntity<Comment, { createdBy: Loader}>[] = []) {
    this._currentValue = current;
    this._logsAndComment = [...logs, ...comments].sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf());
    this._currentIndex = -1;
    this._afterChangeValue = null;
    this._beforeChangeValue = current.toPlainText();
  }

  toStart(): void {
    this._currentIndex = -1;
    this._afterChangeValue = null;
    this._beforeChangeValue = this._currentValue.toPlainText();
  }

  hasNextIteration(): boolean {
    return this._currentIndex > 0 && this._logsAndComment.length > 0;
  }

  nextIteration(): void {
    if(this.hasNextIteration()) {
      this._currentIndex--;
      const currentLog = this._logsAndComment[this._currentIndex].toPlainText();
      this._beforeChangeValue = JSON.parse(JSON.stringify(this._afterChangeValue));
      if(isLog(currentLog) && isLogV2(currentLog)) {
        currentLog.diff.forEach(diff => {
          deepDiff.applyChange(this._afterChangeValue, true, diff)
        });
      }
    }
  }

  hasPreviousIteration(): boolean {
    return this._currentIndex < this._logsAndComment.length - 1;;
  }

  previousIteration(): void {
    if(this.hasPreviousIteration()) {
      this._currentIndex++;
      const currentLog = this._logsAndComment[this._currentIndex].toPlainText();
      this._afterChangeValue = JSON.parse(JSON.stringify(this._beforeChangeValue));
      if(isLog(currentLog) && isLogV2(currentLog)) {
        currentLog.diff.forEach(diff => {
          deepDiff.revertChange(this._beforeChangeValue, true, diff)
        });
      }
    }
  }

  get currentValue(): BusinessEntity<Type> {
    return this._currentValue;
  }

  isCurrentComment(): boolean {
    return isComment(this._logsAndComment[this._currentIndex]);
  }

  get currentComment(): BusinessEntity<Comment, { createdBy: Loader}> {
    const current = this._logsAndComment[this._currentIndex];
    if(!isComment(current)) {
      throw new Error("Current element is not a comment");
    }
    return current;
  }

  isCurrentLog(): boolean {
    return isLog(this._logsAndComment[this._currentIndex]);
  }

  get currentLog(): BusinessEntity<Log, { createdBy: Loader}> {
    const current = this._logsAndComment[this._currentIndex];
    if(!isLog(current)) {
      throw new Error("Current element is not a log");
    }
    return current;
  }

  get beforeChangeValue(): Type {
    if(this._beforeChangeValue === null) {
      throw new Error("No before change value or not initialized");
    }
    return this._beforeChangeValue;
  }

  get afterChangeValue(): Type {
    if(this._afterChangeValue === null) {
      throw new Error("No after change value or not initialized");
    }
    return this._afterChangeValue;
  }

  get baseData(): { _id: ObjectId, createdBy: User, createdAt: BsDate } {
    return this._logsAndComment[this._currentIndex];
  }
}

export default BackToThePast;