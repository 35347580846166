import React from 'react';

import './display.css';

class Display extends React.Component{
  render() {
    return(
      <div className={`bs-button bs-button-display${this.props.pushed ? " pushed" : ""}`} onClick={(ev) => { ev.stopPropagation(); this.props.onClick(ev); }}>
        { this.props.children }
      </div>
    );
  }
}

export default Display;