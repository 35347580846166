import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

const defaultReleaseValue = {
  
}
const handlerReleaseItem = (name) => 
  p => p(name, "array").of(t => t("object")
    .add(p => p("_id", "string"))
    .add(p => p("title", "string"))
    .add(p => p("description", "string"))
    .add(p => p("files", "array").of(t => t("entity").type("File")))
    .add(p => p("links", "array").of(t => t("object")
      .add(p => p("label", "string"))
      .add(p => p("url", "url"))
    ))
    .add(p => p("target", "object")
      .add(p => p("platform", "string"))
      .add(p => p("applications", "array").of(t => t("string")))
    ));
export default (application) => {
  return ModelBuilder.create("Release", Key.create("_id"), stdRepositoryConnector(application, "releases"), defaultReleaseValue)
    .add(p => p("_id", "string"))
    .add(p => p("description", "string"))
    .add(p => p("releaseDate", "date"))
    .add(p => p("serviceDowntime", "number"))
    .add(handlerReleaseItem("newFeatures"))
    .add(handlerReleaseItem("bugsFixed"))
    .add(p => p("openToPublic", "bool"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("updatedAt", "date"))
    .add(p => p("updatedBy", "entity").type("User"))
    .build();
}