import React            from "react";
import moment           from "moment";
import T                from "@uBehaviour/i18n";
import Display          from "@cComponents/displayIf";
import File             from "@cComponents/file";
import Paragraph        from "@cComponents/paragraph";
import List             from "@cComponents/list";
import Url              from "@cComponents/url";
import { filterItems }  from "@uLib/release";
import Text, { Style }  from '@cComponents/text';

import "./item.css";

const ReleaseItem = props => (
  <li>
    <div className="bs-item-release-item">
      <Text style={ Style.standard.bold }>{ props.data.title }</Text>
      <Paragraph>{props.data.description}</Paragraph>
      <div className="bs-item-release-item-links">
      {
        props.data.links.map((link, idx) => (
          <Url key={idx} label={link.label} url={link.url} />
        ))
      }
      </div>
      <div className="bs-item-release-item-files">
      {
        props.data.files.map(file => (
          <File key={file._id} file={file} height={ 100 } width={ 100 } openable/>
        ))
      }
      </div>
    </div>
  </li>
)

const Release = (props) => {
  const filteredFeatures  = filterItems(props.data.newFeatures, props.application, "web");
  const filteredBugsFixed = filterItems(props.data.bugsFixed, props.application, "web");
  return (
    <div className="bs-item-release">
      <div><Text style={ Style.large.bold }><T bind={{ date: moment(props.data.releaseDate).format("DD/MM/YYYY")}}>release_publish_on</T></Text></div>
      <div><Paragraph>{props.data.description}</Paragraph></div>
      <Display.If condition={filteredFeatures.length}>
        <div>
        <div className="bs-item-release-list-title"><Text style={ Style.large.bold }><T>release_item_newFeatures</T></Text></div>
          <div>
            <ul>
              <List datas={filteredFeatures} k="_id">
                <ReleaseItem />
              </List>
            </ul>
          </div>
        </div>
      </Display.If>
      <Display.If condition={filteredBugsFixed.length}>
        <div>
          <div className="bs-item-release-list-title"><Text style={ Style.large.bold }><T>release_item_bugsFixed</T></Text></div>
          <div>
            <ul>
              <List datas={filteredBugsFixed} k="_id">
                <ReleaseItem />
              </List>
            </ul>
          </div>
        </div>
      </Display.If>
    </div>
  )
};

export default Release;