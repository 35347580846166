import React from "react";
import File from "@cComponents/file";

import "./frame.css";

const Frame = ({ file, height, width, fit, onClick, className, noConstraint }) => (
  <div className={ `bs-file-frame${ className && className.length ? " " + className : ""}` } onClick={ onClick }>
    <File file={ file } height={ height } width={ width } fit={ fit } noConstraint={ noConstraint }/>
  </div>
);

Frame.border  = 1;
Frame.padding = 4;

export default Frame;