import React    from 'react';
import TextBase from './textBase';

import './password.css'

interface PasswordState {
  type: string 
}

class Password extends TextBase<{}, PasswordState> {
  constructor(props) {
    super(props);
    this.state= {
      type:"password"
    }
  }

  private changeType = () => {
    if(this.state.type === "password") {
      this.setState({
        type:"text"
      })
    } else {
      this.setState({
        type:"password"
      })
    }
  }

  _getType = () => this.state.type;


  render() {
    const TextInputBase = super.render(); 
    return (
      <div className="bs-input-password">
        {TextInputBase}
        <span onClick={ this.changeType } className={`bs-icon fa ${this.state.type === "password" ? "fa-eye" : "fa-eye-slash"}`}/>
      </div>
    )      
  }
}

export default Password;