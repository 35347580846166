import React from "react";
import Form from '@uBehaviour/form';
import Slot from "@uComponents/slot";

class Simple extends React.Component {
  static Display  = Slot();
  static Form     = Slot();

  constructor(props) {
    super(props);
    this._inputArray = React.createRef();
    this._form = React.createRef();
  }
  get inputArray() {
    return this._inputArray.current;
  }
  add() {
    this._inputArray.current.add();
  }
  edit(idx) {
    this._inputArray.current.edit(idx);
  }
  isEdited() {
    return this._inputArray.current.isEdited() && !!this._form.current;
  }
  delete(idx) {
    this._inputArray.current.delete(idx);
  }
  closeForm() {
    if (this.isEdited()) {
      this._cancel();
    }
  }
  _submit() {
    this._form.current.submit();
  }
  _cancel() {
    this._inputArray.current.cancelEdit();
  }
  render() {
    const form      = Simple.Form.get(this);
    const formProps = Simple.Form.props(this);
    return (
      <InputArray ref={this._inputArray} name={this.props.name} in={this.props.in} out={this.props.out} default={this.props.default} value={this.props.value} onChange={this.props.onChange}>
        <InputArray.Display>
          {Simple.Display.get(this)}
        </InputArray.Display>
        <InputArray.Form>
          {(storeCallback, cancelCallback, object, idx) => {
            return React.createElement(
              Form.Simple, {
                ref: this._form,
                key: idx,
                value: object,
                validate: this.props.validate,
                submit: (form, value) => storeCallback(value),
                presubmit: this.props.presubmit,
                onChange: formProps.onChange
              },
              form(
                this._submit.bind(this),
                cancelCallback,
                idx
              )
            );
          }}
        </InputArray.Form>
      </InputArray>
    )
  }
}

export default class InputArray extends React.Component {
  static Simple = Simple;
  static Display = Slot();
  static Form     = Slot();

  constructor(props) {
    super(props);
    this._value = [];
  }

  state = {
    editIdx: null
  };

  get value() {
    return this._value.slice();
  }

  get default() {
    return this.props.default ? JSON.parse(JSON.stringify(this.props.default)) : null;
  }
  delete(idx) {
    if (idx < 0 && idx >= this._value.length) {
      throw new Error("Index out of bound");
    }
    const element = this._value[idx];
    this._triggerChange(this._value, element, false);
    this._value.splice(idx, 1);
  }
  edit(idx) {
    if (idx < 0 && idx >= this._value.length) {
      throw new Error("Index out of bound");
    }
    this.setState({ editIdx: idx });
  }
  cancelEdit() {
    this.setState({ editIdx: null });
  }
  _triggerChange(values, value, added){
    if(this.props.onChange){
      this.props.onChange(values, value, added)
    }
  }
  isEdited() {
    return this.state.editIdx !== null;
  }
  render() {
    this._value   = this.props.value ? this.props.value : this._value;
    const display = InputArray.Display.get(this);
    let form      = InputArray.Form.get(this);
    
    const storeCallback = (idx) => (value) => {
      if(this._value.length > idx){
        this._value[idx] = value;
        this._triggerChange(this._value, value, idx);
      }else{
        this._value.push(value);
        this._triggerChange(this._value, value, true);
      }
      this.setState({editIdx: null})
    };

    return React.createElement(React.Fragment, {}, this._value.map((value, idx) => {
      if (this.state.editIdx === idx) {
        return form(storeCallback(idx), () => this.setState({editIdx: null}), value, idx)
      } else {
        return display(value, () => this.setState({editIdx: idx}), () => this.delete(idx), idx);
      }
    }).concat(form(storeCallback(), () => null, this.default, this._value.length, true)));
  }
}