
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {

  return ModelBuilder.create("Issue", Key.create("_id"), stdRepositoryConnector(application, "issues"),  {})
    .add(p => p("_id", "string"))
    .add(p => p("description", "string"))
    .add(p => p("state", "string"))
    .add(p => p("bsIdNumber", "number"))
    .add(p => p("bsId", "string"))
    .add(p => p("distance", "number"))
    .add(p => p("files", "array").of(t => t("entity").type("File")))
    .add(p => p("$followed", "bool"))
    .add(p => p("followers", "array").of(t => t("entity").type("User")))
    .add(p => p("manager", "entity").type("User"))
    .add(p => p("priority", "number"))
    .add(p => p("category", "entity").type("Category"))
    .add(p => p("equipment", "entity").type("Equipment"))
    .add(p => p("recurrence", "entity").type("Recurrence"))
    .add(p => p("mustBeValorized", "boolean"))
    .add(p => p("withValorization", "boolean"))
    .add(p => p("$valorizationsTotal", "number"))
    .add(p => p("group", "entity").type("IssueGroup"))
    .add(p => p("requestor", "object")
      .add(p => p("_id", "string"))
      .add(p => p("firstname", "string"))
      .add(p => p("lastname", "string"))
      .add(p => p("type", "string"))
      .add(p => p("email", "string"))
      .add(p => p("phone", "string"))
    )
    .add(p => p("location", "object")
      .add(p => p("address", "object")
        .add(p => p("streetNumber", "string"))
        .add(p => p("street", "string"))
        .add(p => p("locality", "string"))
        .add(p => p("zip", "string"))
        .add(p => p("country", "string"))
      )
      .add(p => p("building", "entity").type("Building"))
      .add(p => p("place", "string"))
      .add(p => p("locationInfo", "string"))
      .add(p => p("position", "geometry"))
      .add(p => p("road", "object")
        .add(p => p("code", "string"))
        .add(p => p("km", "string"))
      )
    )
    .add(p => p("deadline", "date"))
    .add(p => p("assignments", "array").of(t => t("object")
      .add(p => p("_id", "string"))
      .add(p => p("bsIdNumber", "number"))
      .add(p => p("scheduledFrom", "date"))
      .add(p => p("scheduledTo", "date"))
      .add(p =>  p("team", "array").of(t => t("entity").type("Team")))
      .add(p =>  p("agents", "array").of(t => t("entity").type("User")))
      .add(p =>  p("necessariesEquipments", "array").of(t => t("entity").type("Equipment")))
      .add(p =>  p("necessariesSupplies", "array").of(t => t("object")
        .add(p => p("supply", "entity").type("Supply"))
        .add(p => p("quantity", "number"))
      ))
      .add(p => p("workInstructions", "string"))
    ))
    .add( p => p("logs", "array").of(t => t("entity").type("Log").link("_id", "subject.id", )))
    .add( p => p("comments", "array").of(t => t("entity").type("Comment").link("_id", "subject.id", )))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("$creator", "boolean"))
    .add(p => p("updatedAt", "date"))
    .add(p => p("updatedBy", "entity").type("User"))
    .add(p => p("valorizations", "array").of(t => t("object")
      .add(p => p("elements", "array").of(t => t("object")
        .add(p => p("cost", "integer"))
        .add(p => p("quantity", "integer"))
      ))
    ))
    .add( p => p("tags", "array").of("string"))
    .add(p => p("public", "boolean"))
    .add(p => p("$deadlineReminder", "date"))
    .build();
}