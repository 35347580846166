import React  from "react";
import Map    from "./map";
import _      from "lodash";
export default class AutoSize extends React.Component {
  constructor(props){
    super(props);
    this._container = React.createRef();
    this.state      = {
      height: this.props.height,
      width:  this.props.width
    };
    this._listen    = false;
    if(!this.state.height || !this.state.width){
      this._onResize  = _.debounce(this._onResize.bind(this), 0);
      window.addEventListener("resize", this._onResize);
      this._listen    = true;
    }
  }
  onComponentWillUnmount(){
    if(this._listen){
      window.removeEventListener("resize", this._onResize);
    }
  }
  _onResize(){
    this.forceUpdate();
  }
  render(){
    
    if(!this.state.height || !this.state.width){
      this.setState()
      return React.createElement(
        "div", {
          className: "bs-autosize",
          ref: this._container
        }
      );
    }else{
      const props   = Object.assign({}, this.props);
      props.height  = this.state.height;
      props.width   = this.state.width;
      return React.createElement(
        Map,
        props,
        this.props.children
      );
    }
  }
}