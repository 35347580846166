import React        from "react";
import Field        from "@cComponents/old/field";
import InputObject  from "@cComponents/old/input/object";
import Display      from "@cComponents/displayIf";


import "./inline.css";

export default class Inline extends React.Component {
    _threadChild(child, idx){
        if(child.type === InputObject) return React.cloneElement(child, {
                children: React.Children.map(child.props.children, (child, idx) => this._threadChild(child, idx))
            });
        if(child.type !== Field){
            return child;
        }

        let required = false;
        React.Children.forEach(child.props.children, c => {
            if (c.props.required) {
                required = true;
            }
        })

        return (
            <label key={ idx } className={`bs-form-layout-row${child.props.disabled ? " disabled" : ""}`}>
                <div className="bs-form-layout-cell bs-form-layout-cell-label"> 
                    <span>{Field.Label.get(child)}</span>
                    <Display.If condition = {required}> *</Display.If>
                </div>
                <div className={`bs-form-layout-cell bs-form-layout-cell-input bs-dispose-${this.props.dispose}`}> {
                    Field.Input.get(child)
                }</div>
            </label>
        )
    }
    render(){
        return (
            <div className="bs-form-layout bs-form-inline-layout">
                {
                    React.Children.map(this.props.children, (child, idx) => this._threadChild(child, idx))
                }
            </div>
        )
    }
}