import React                    from 'react';
import Input                    from "@cComponents/input";
import useService               from "@uBehaviour/hooks/useService";
import GeolocationService from '@cServices/geolocation';

import "./addressMap.css"
type Position = {
  type: "Point",
  coordinates: [number, number]
};

type Address = {
  locality: string,
  street: string,
  streetNumber?: string,
  zip: string
};

interface AddressMapProps {
  value: {
    position?: Position,
    address?: Address
  },
  onChange: ({ address, position }: { address: Address, position: Position }) => void,
}
const AddressMap : React.FunctionComponent<AddressMapProps> = ({ value, onChange, }) => {
  const geolocation = useService<GeolocationService>("geolocation");
  
  const onAddressChange = React.useCallback(( address: Address ) => {
    geolocation.getPositionFromAddress(address)
      .then((position: Position) => {
        onChange({
          address,
          position
        });
      });
  }, [value, onChange, geolocation]);

  const onMapChange = React.useCallback(( position: Position ) => {
    geolocation.getAddressFromPosition(position)
      .then((address: Address) => {
        onChange({
          address,
          position
        });
      });
  }, [value, onChange, geolocation]);
  return (
    <div className="bs-input-addressmap-container">
      <Input.Address value={ value?.address  } onChange={ onAddressChange } />
      <Input.Map.One value={ value?.position } onChange={ onMapChange } />
    </div>
  );
};

export default AddressMap;
