import React from "react";

export default class Async extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: undefined
    };
  }
  invalidateCache(){
    this._invalidateCache = true;
  }
  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.hash !== this.props.hash){
      this.invalidateCache();
    }
    return true;
  }
  render(){
    if(this.state.result === undefined || this.props.noCache || this._invalidateCache){
      this._invalidateCache = false;
      this.props.async().then(result => {
        this.setState({ result });
      });
      if(this.state.result === undefined){
        return null;
      }
    }
    return this.props.children instanceof Function
      ? this.props.children(this.state.result)
      : React.cloneElement(this.props.children, { data: this.state.result });
  }
}