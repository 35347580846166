import React from "react";
import FileFrame from "./frame";
import ScrollBar from "@cComponents/scrollBar";
import _ from "lodash";

import "./viewer.css";

const ControlButton = ({ onClick, direction }) => (
  <div className={ `bs-fileViewer-controlButton bs-fileViewer-controlButton-${direction}` } onClick={ onClick }>
    <div>
      <span className={ `fa fa-chevron-${ direction }` } />
    </div>
  </div>
);

const padding                 = FileFrame.padding;
const margin                  = 0;
const border                  = FileFrame.border;
const paddingContainerSubject = 20;
const marginList              = 10;
class Viewer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      displayIndex: (this.props.display || 0) % this.props.files.length,
      size: null
    };
    this._list      = React.createRef();
    this._subject   = React.createRef();
    this._scrollbar = React.createRef();
    this._updateSize = _.debounce(this._updateSize, 1000);
  }
  get hasScrollbar(){
    return this.props.files.length * this._list.current.offsetWidth > this._list.current.offsetHeight;
  }
  _setDisplayIndex = (index) => {
    this._positionScrollbar(index % this.props.files.length);
    this.setState({ displayIndex: index % this.props.files.length });
  }
  _previous = (e) => {
    e.stopPropagation();
    const displayIndex = this.state.displayIndex;
    this._setDisplayIndex(displayIndex === 0 ? this.props.files.length - 1 : displayIndex - 1);
  }
  _next = (e) => {
    e.stopPropagation();
    const displayIndex = this.state.displayIndex;
    this._setDisplayIndex(displayIndex + 1);
  }
  _updateSize = () => {
    let listWidth = this._list.current.offsetWidth - 2 * margin - 2 * padding - 2 * border - 2 * marginList;
    if(this.hasScrollbar){
      listWidth -= 15;
    }
    this.setState({ size: {
      list: {
        width: listWidth
      },
      subject: {
        height: this._subject.current.offsetHeight - 2 * margin - 2 * padding - 2 * border - 2 * paddingContainerSubject,
        width: this._subject.current.offsetWidth - 2 * margin - 2 * padding - 2 * border - 2 * paddingContainerSubject
      }
    }});
  }
  _positionScrollbar = (index) => {
    if(this.hasScrollbar){
      this._scrollbar.current.scrollTop = index * (this._list.current.offsetWidth - marginList - 15);
    }
  }
  componentDidMount(){
    window.addEventListener("resize", this._updateSize);
    this._updateSize();
  }
  componentDidUpdate(oldProps, oldState){
    if(!oldState.size){
      this._positionScrollbar(this.state.displayIndex);
    }
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this._updateSize);
  }
  render(){
    const { files }               = this.props;
    const { size, displayIndex }  = this.state;
    return (
      <div className="bs-fileViewer">
        <div className="bs-fileViewer-action"></div>
        <div className="bs-fileViewer-content">
          <div className="bs-fileViewer-content-list" ref={ this._list }>
            <ScrollBar ref={ this._scrollbar }>
            {
              size
              ? files.map((file, index) => (
                  <FileFrame file={ file } height={ size.list.width } width={ size.list.width } fit="cover" className={ `bs-fileViewer-content-list-item${ index === displayIndex ? " bs-fileViewer-content-list-item-selected" : ""}` } onClick={ () => this._setDisplayIndex(index) }/>
                ))
              : null
            }
            </ScrollBar>
          </div>
          <div className="bs-fileViewer-content-subject" ref={ this._subject } onClick={ this._next }>
          {
            size
              ? (
                <>
                  <div className="bs-fileViewer-content-subject-container">
                    <FileFrame file={ files[displayIndex] } height={ size.subject.height } width={ size.subject.width } fit="inside" noConstraint/>
                  </div>
                  <ControlButton onClick={ this._previous } direction="left" />
                  <ControlButton onClick={ this._next } direction="right" />
                </>
              )
              : null
          }
          </div>
        </div>
      </div>
    )
  }
}

export default Viewer;