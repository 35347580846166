import React from "react";

import Autocomplete from "@cComponents/old/input/autocomplete";
import Application  from "@uBehaviour/application";

class AutocompleteModel extends React.Component{
    constructor(props){
        super(props);
        this._autoComplete = React.createRef();
    }
    get value(){
        return this._autoComplete.current.value;
    }

    set value(value){
        this._autoComplete.current.value = value;
    }
    clear(){
        this._autoComplete.current.clear();
    }
    _query(value){
       let query = {};
        if(this.props.querying){
            query = this.props.querying(value);
        } else if(this.props.property){
            query[this.props.property] = { '$regex': `.*${value}.*`, '$options' : 'i' };
        } else{
            throw new Error("Undefined query");
        }
        if(this.props.condition){
            query = { $and: [ this.props.condition, query ]};
        }
            
        return query
    }
    _sort(){
        if(this.props.sort) {
            return this.props.sort;
        }
        if(this.props.property){
            const sort = {};
            sort[this.props.property] = 1;
            return sort;
        }
        return {};
    }
    _limit(){
        return this.props.limit ? this.props.limit : 20;
    }
    _label(value){
        if(this.props.labelize){
            return this.props.labelize(value);
        }
        if(this.props.property){
            return this.props.property.split(".").reduce((v, p) => v[p] ? v[p] : "", value);
        }
        throw new Error("Undefined label");
    }
    _value(value){
        if(this.props.evaluate){
            return this.props.evaluate(value);
        }
        return value._id;
    }

    _initialize(id) {
        return this.props.model.repository.findOne(id)
        .then(result => ({ label: this._label(result), value: this._value(result)}));
    }

    _search(value){
        if(!value) return Promise.resolve([]);
        return this.props.model.repository.find(this._query(value), this._sort(), 0, this._limit())
            .then(results => results.map(result => ({ label: this._label(result), value: this._value(result)})));
    }
    render(){
        return React.createElement(Autocomplete, {
            noError: this.props.noError,
            ref: this._autoComplete,
            value: this.props.value,
            name: this.props.name,
            required: this.props.required,
            disabled: this.props.disabled,
            onChange: this.props.onChange,
            postSelect: this.props.postSelect ? this.props.postSelect : null,
            comparableValue: this.props.comparableValue ? this.props.comparableValue : null,
            search: this._search.bind(this),
            initialize: this._initialize.bind(this)
        }, this.props.children);
    }
}

export default (props) => React.createElement(Application.Service, { name: "repository" }, repository =>
  React.createElement(AutocompleteModel, Object.assign({}, props, { model: repository.get(props.model) }), props.children)
);