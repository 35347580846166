import moment from "moment";
import React from "react";
import Date from "./date";
import Hour from "./hour";
import "./datetime.css";
class DateTime extends React.Component {
  constructor(props){
    super(props);
    this._inputDate = React.createRef();
    this._inputHour = React.createRef();

    this._onDateChange = this._onDateChange.bind(this);
    this._onHourChange = this._onHourChange.bind(this);
  }
  _onDateChange(value){
    this._onChange(value, this._inputHour.current.value ? this._inputHour.current.value : '00:00');
  }
  _onHourChange(value){
    this._onChange(this._inputDate.current.value ? this._inputDate.current.value : new Date(), value);
  }
  _onChange(date, hour){
    const value = date && hour ? moment(date).format("YYYY-MM-DD") + "T" + hour + ":00.000" : null;
    if(this.props.onChange){
      this.props.onChange(value);
    }
  }
  render(){
    const dateValue = this.props.value ? moment(this.props.value).startOf("day").toDate() : null;
    const hourValue = this.props.value ? moment(this.props.value).format("HH:mm")         : null;
    return (
      <div className="bs-input-datetime">
        <Date ref={ this._inputDate } value={ dateValue } onChange={ this._onDateChange } />
        <Hour ref={ this._inputHour } value={ hourValue } onChange={ this._onHourChange } />
      </div>
    );
  }
}

export default DateTime;
