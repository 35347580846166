import react from "react";
import React from "react";
import Text  from "./text";
const durationRegexp = /^([0-9][0-9])(\:)([0-5][0-9])$/;
const durationToString = (value) => {
  if(isNaN(parseInt(value))) return "00:00";
  value = parseInt(value / 60000);
  const minutes = value % 60;
  let hours   = ((value - minutes) / 60);
  if(hours >= 24){
    hours = 23;
  }
  return `${(hours < 10 ? "0" : "") + hours}:${(minutes < 10 ? "0" : "") + minutes}`; 
};
const stringToDuration = (value) => {
  const [hours, minutes] = value.split(":");
  return parseInt(hours) * 3600000 + minutes * 60000;
};
export default (props) => (
  <Text
    value={ durationToString(props.value) }
    onChange={value => props.onChange ? props.onChange(stringToDuration(value)) : null }
    regexp={durationRegexp}
    mask="||:||"
  />
);