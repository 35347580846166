
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultBuildingValue = {
    disabled:false,
    tags: []
  };
  return ModelBuilder.create("Building", Key.create("_id"), stdRepositoryConnector(application, "buildings"), defaultBuildingValue)
    .add(p => p("_id", "string"))
    .add(p => p("name", "string"))
    .add(p => p("externalLink", "string"))
    .add(p => p("disabled", "bool"))
    .add(p => p("location", "object")
      .add(p => p("address", "object")
        .add(p => p("streetNumber", "string"))
        .add(p => p("street", "string"))
        .add(p => p("locality", "string"))
        .add(p => p("zip", "string"))
        .add(p => p("country", "string"))
      )
      .add(p => p("position", "object")
        .add(p => p("type", "string"))
        .add(p => p("coordinates", "array").of(t => t("float")))
      )
    )
    .add(p => p("places", "array").of(t => 
      t("object")
        .add("_id", "string")
        .add("name", "string")
        .add("externalLink", "string")
        .add("disabled", "bool")
    ))
    .add(p => p("files", "array").of(t => t("entity").type("File")))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("tags", "array").of(t => t("string")))
    .build();
}