import { Service }        from "@uLib/application";
export default class ReleaseService extends Service {
  constructor(apptarget, platform, otherDependencies = []){
    super("release", ["session", "api", "pager", "acl"].concat(otherDependencies));
    this._appTarget = apptarget;
    this._platform  = platform;
    this._onUserLogginOrLoggout  = this._onUserLogginOrLoggout.bind(this);
  }
  start(){
    return this.waitReady(["session", "acl", "pager", "api"]).then(([session, acl]) => {
      session.onServiceUpdated.addListener({
        handleEvent: this._onUserLogginOrLoggout
      });
      return this.prepare();
    });
  }

  prepare() {
    const session = this.application.getService("session");
    const pager = this.application.getService("pager");
    const api = this.application.getService("api");
    const ps = [];

    const query = { $and: [
      { openToPublic: true },
      { $or: [
        { newFeatures: { $elemMatch :{
          "target.applications": this._appTarget,
          "target.platform": { $in: [this._platform, "all" ] }
        }}},
        { bugsFixed: { $elemMatch :{
          "target.applications": this._appTarget,
          "target.platform": { $in: [this._platform, "all" ] }
        }}}
      ]}
    ]};

    this._pagerNext = pager.create("Release", { openToPublic: true, releaseDate: { $gt: new Date() }});
    ps.push(this._pagerNext.next(1));
    this._pagerNext.stateChange.addListener({ handleEvent: () => {
      this.triggerUpdate();
    }});
    this._pagerPrevious = pager.create("Release", { $and: query.$and.slice().concat([{ releaseDate: { $lte: new Date() }}])}, { releaseDate: -1 }, { "newFeatures.files": 1, "bugsFixed.files": 1});
    ps.push(this._pagerPrevious.next(20));
    this._pagerPrevious.stateChange.addListener({ handleEvent: () => {
      this.triggerUpdate();
    }});

    if(session.isLogged() && session.hasUser()){
      ps.push(this._prepare(api));
    }
    return Promise.all(ps);
  }  
  
  _prepare(api){
    return api.service("releases", "lastSeen").execute(this._appTarget, this._platform).then(release => {
      this._lastSeen = release;
    });
  }
  _onUserLogginOrLoggout(){
    const session = this.application.getService("session");
    if(session.isLogged() && session.hasUser()){
      return this._prepare( this.application.getService("api"))
        .then(() => {
          this.triggerUpdate(this._lastSeen);
        });
    }else{
      delete this._lastSeen;
      this.triggerUpdate(this._lastSeen);
    }
  }
  hasAReleaseNotRead(){
    return this._lastSeen !== undefined && this._pagerPrevious.loadedLength && this._pagerPrevious.getLoadedByIndex(0)._id !== this._lastSeen;
  }
  updateLastSeen(){
    const release = this._pagerPrevious.getLoadedByIndex(0)._id;
    return this.application.getService("api").service("releases", "updateLastSeen").execute(
      this._appTarget,
      this._platform,
      release
    ).then(() => {
      this._lastSeen = release;
      this.triggerUpdate();
    });
  }
  hasAReleaseSoon(){
    const now = new Date();
    let hasSoon = false;
    this._pagerNext?.forEachLoaded(release => {
      hasSoon = hasSoon || release.releaseDate > now;
    })
    return hasSoon;
  }
  get next(){
    const now = new Date();
    let next = null;
    this._pagerNext?.forEachLoaded(release => {
      if(release.releaseDate > now){
        next = release;
      }
    });
    return next;
  }
  get pager(){
    return this._pagerPrevious;
  }
}