import { Service }  from "@uLib/application";

import StorageService, { SetHandler, GetHandler, HasHandler, RemoveHandler } from "@universal/services/storage";

const isString = (value: any): value is string => typeof value === "string";

class TemporaryStorageService extends Service implements StorageService {
  constructor() {
    super("temporaryStorage");
  };

  has: HasHandler = async (key: string) => {
    return !!sessionStorage.getItem(key);
  };

  get: GetHandler = async <T = unknown>(key: string, parse = false) => {
    const storedItem = sessionStorage.getItem(key); 
    if(!isString(storedItem)){
      return null;
    }   
    return parse 
      ? JSON.parse(storedItem) as T
      : storedItem as string;
  };

  set: SetHandler = async <T = unknown>(key: string, value: string | T, stringify = false) => {
    if(stringify || !isString(value)){
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  };

  remove: RemoveHandler = async (key: string) => {
    sessionStorage.removeItem(key);
  };
}

export default  TemporaryStorageService;