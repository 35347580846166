import React, { ReactNode, FunctionComponent, ComponentType, PropsWithChildren } from "react";


const collectChildren = (componentChildren) => {
  if(!Array.isArray(componentChildren)){
    componentChildren = [componentChildren];
  }
  return componentChildren.reduce((children, child) => {
    if(Array.isArray(child)){
      child.forEach(child => children.push(child));
    }else{
      children.push(child);
    }
    return children;
  }, [])
}

type AnonymousSlotFunction = (...args: any[]) => ReactNode;
type DefaultProps<P> = P | Array<P> | undefined;

type SlotResult = ReactNode | AnonymousSlotFunction;


type PropsHandlerTypeMultiple<P> = (children: ReactNode, all: true, _default?: Array<P>) => Array<P>;
type PropsHandlerTypeSimple<P> = ((children: ReactNode, all: false, _default?: P) => P | null) & ((children: ReactNode) => P | null);
type PropsHandleType<P> = PropsHandlerTypeMultiple<P> & PropsHandlerTypeSimple<P>;

type GetHandlerTypeMultiple<F extends ReactNode | AnonymousSlotFunction = SlotResult> = (component: ReactNode, all: true, _default?: F[]) => F[];
type GetHandlerTypeSimple<F extends ReactNode | AnonymousSlotFunction = SlotResult> = ((component: ReactNode, all: false, _default?: F) => F | null) & ((component: ReactNode) => F | null);
type GetHandlerType<F extends ReactNode | AnonymousSlotFunction = SlotResult> = GetHandlerTypeMultiple<F> & GetHandlerTypeSimple<F>;



type NotHandlerType = (component: ReactNode | ReactNode[]) => ReactNode[];

interface SlotInterface<F extends ReactNode | AnonymousSlotFunction, P extends {} = {}> {
  props: PropsHandleType<PropsWithChildren<P>>;
  get: GetHandlerType<F>;
  not: NotHandlerType;
};

interface SlotChildProps<F extends ReactNode | AnonymousSlotFunction>{
  children: F;
}
type SlotPropsWithChild<F extends ReactNode | AnonymousSlotFunction, P extends {}> = P & SlotChildProps<F>;
type SlotPropsWithoutChild<P extends {}> = P;


type SlotHandlerWithChild = (<F extends (ReactNode | AnonymousSlotFunction) = ReactNode, P extends {} = {}>(memoize: boolean, withChild: true) => FunctionComponent<SlotPropsWithChild<F, P>> & SlotInterface<F, P>) 
                          & (<F extends (ReactNode | AnonymousSlotFunction) = ReactNode, P extends {} = {}>(memoize?: boolean) => FunctionComponent<SlotPropsWithChild<F, P>> & SlotInterface<F, P>);
type SlotHandlerWithoutChild = <F extends null = null, P extends {} = {}>(memoize: boolean, withChild: false) => FunctionComponent<SlotPropsWithoutChild<P>> & SlotInterface<null, P>;

type SlotHandler = SlotHandlerWithChild & SlotHandlerWithoutChild;

type CopyHandler<T extends (...args: any) => any> = (...args: Parameters<T>) => ReturnType<T>;

const memoizeHandler = <T extends (...args: any) => any>(fn: T, nbrParams: number): CopyHandler<T> => (...args) => {
  return React.useMemo(() => fn(...args as any), args.slice(0, nbrParams));
};

const Slot: SlotHandler = <F extends (ReactNode | AnonymousSlotFunction) = ReactNode, P extends {} = {}>(memoize = false, withChild = true) => {

  const fn = () => null;

  const props: PropsHandleType<P> = (children, all = false, _default = undefined) => {
    if(!_default){
      _default = all ? [] as Array<P> : {} as P;
    }
    if(!children){
      return _default;
    }

    const allChildren = collectChildren(children);
    
    if(all){
      const childrenComponent = allChildren.filter(child => child && child.type === fn);
      if(_default && ! childrenComponent.length){
        return _default;
      }
      return childrenComponent.map(childComponent => childComponent.props);
    }else{
      const childComponent = allChildren.find(child => child && child.type === fn);
      return childComponent ? childComponent.props : _default;
    }
  };

  const get: GetHandlerType<F> = (children, all = false, _default = null) => {
    const slotProps: DefaultProps<SlotChildProps<F>> = props(children, all) as DefaultProps<P & SlotChildProps<F>>;
    if(all){
      const components = (slotProps as SlotChildProps<F>[]).map(prop => prop.children).filter(c => !!c);
      if(_default && !components.length){
        return _default;
      }
      return components;
    }else{
      return (slotProps as SlotChildProps<F>).children ? (slotProps as SlotChildProps<F>).children : _default;
    }
  };

  const not: NotHandlerType = (children) => {
    const childrenComponents = collectChildren(children);
    return childrenComponents.filter(child => child.type !== fn);
  };

  return Object.assign(fn, { 
    props: memoize ? memoizeHandler(props, 3) : props,
    get: memoize ? memoizeHandler(get, 3) : get,
    not: memoize ? memoizeHandler(not, 1) : not,
  });
};

type DeepSlotReturn<F extends (ReactNode | AnonymousSlotFunction)> = [
  FunctionComponent<{ children: F }>, 
  () => F | null, 
  <P extends {} = {}>(Component: ComponentType<P>) => FunctionComponent<P>
];

export function DeepSlot<F extends (ReactNode | AnonymousSlotFunction) = ReactNode>() : DeepSlotReturn<F> {
  type ContextType = {
    get: () => F | null;
    set: (value: F | null) => void;
  };

  const Context = React.createContext<ContextType>({
    get: () => { throw new Error('No Deep Slot provider found'); },
    set: () => { throw new Error('No Deep Slot provider found'); }
  });

  const DeepSlot: FunctionComponent<{ children: F }> = ({ children }) => {
    const contextValue = React.useContext(Context);

    React.useEffect(() => {
      contextValue.set(children);
      return () => {
        contextValue.set(null);
      };
    }, [children]);

    return null;
  };

  const useDeepSlot = () : F | null => {
    const contextValue = React.useContext(Context);
    return contextValue.get();
  };
  
  function withDeepSlot<Props extends {} = {}>(Component: ComponentType<PropsWithChildren<Props>>): FunctionComponent<PropsWithChildren<Props>> {
    return (props: PropsWithChildren<Props>) => {

      const [value, setValue] = React.useState<F | null>(null);

      const contextValue = React.useMemo(() => ({
        get: () => value,
        set: setValue
      }), [value, setValue]);

      return (
        <Context.Provider value={ contextValue }>
          <Component { ...props }>
          { props.children }
          </Component>
        </Context.Provider>
      );
    };
  };

  return [
    DeepSlot,
    useDeepSlot,
    withDeepSlot
  ];
};

export default Slot;