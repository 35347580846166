import React        from 'react';
import InputBase    from './inputBase';
import ChildToProps from "@cBehaviour/childToProps";

import './password.css'

export default class Password extends InputBase {
  constructor(props) {
    super(props);
    this.state= {
      type:"password"
    }
    this._changeType = this._changeType.bind(this);
  }

  getErrors() {
    const errors = super.getErrors();
    if (this.props.pattern) {
      let regex = this.props.pattern;
      if(!(regex instanceof RegExp)){
        regex = new RegExp(this.props.pattern);
      }
      if(!regex.test(this.value)){
        errors.push({
            path:this.props.name,
            error: "password_format_mismatch"
        });
      }
    }
    return errors;
  }

  _changeType() {
    if(this.state.type === "password") {
      this.setState({
        type:"text"
      })
    } else {
      this.setState({
        type:"password"
      })
    }
  }

  _render() {
    return (
      <div className="bs-old-input-password">
        <ChildToProps child={ this.props.children } name="placeholder">
          <input 
            ref={ref=>this._input = ref}
            type={this.state.type} 
            className="bs-old-input bs-old-input-text-like"
            onChange={() => { 
              this._value = this._input.value;
              this._triggerChange(); 
            }}
            value={ this._value ? this._value : "" }
            data-testid={ this.props["data-testid"] }
          />
        </ChildToProps>
        <span onClick={ this._changeType } className={`bs-icon fa ${this.state.type === "password" ? "fa-eye" : "fa-eye-slash"}`}/>
      </div>
      
    )      
  }
}