
class Url{
    static get path(){
        return window.location.pathname;
    }
    static fromRoot(path){
        return `${window.location.origin}${path[0] === '/' ? '' : '/'}${path}`;
    }

    static getIssueIcon (issue) {
        return this.fromRoot(`/images/maps/${issue.category.key}_${issue.state}.png`);
    }
    static getBuildingIcon (state) {
        return this.fromRoot(`/images/maps/building_${state}.png`);
    }
    static getEquipmentIcon (state) {
        return this.fromRoot(`/images/maps/equipment_${state}.png`);
    }
}

export default Url;