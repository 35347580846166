import React, { ComponentType, FunctionComponent, ReactElement, ReactNode } from 'react';

import useMatcher from '../hooks/useMatcher';
import Path, { ExtractParameterFromPath, ValidatePath } from '../types/Path';
import Junction from './junction';
import useRoute from '../hooks/useRoute';
import useLocation from '../hooks/useLocation';

type ChildrenType<P extends object> = ((params: P, goToParentPath: () => void) => ReactNode) | ReactElement<{ params: P, goToParentPath: () => void }>;; 

export interface RouteProps<P extends Path> {
  path: ValidatePath<P>;
  strict?: boolean;
  hasJunction?: boolean;
  component?: ComponentType<{ params: ExtractParameterFromPath<P>, goToParentPath: () => void }>;
  children?: ChildrenType<ExtractParameterFromPath<P>>;
}

const getChildren = <P extends object>(component: ComponentType<{ params: P, goToParentPath: () => void }> | undefined, children: ChildrenType<P> | undefined, params: P, goToParentPath: () => void) => {
  if(component){
    return React.createElement(component, { params, goToParentPath });
  }
  if(children){
    return children instanceof Function
      ? children(params, goToParentPath)
      : React.cloneElement(children, { params });
  }
  return null;
}

function Route<P extends Path>(props: RouteProps<P>): ReactNode{
  const { path, component, strict = false, hasJunction = true, children } = props;

  type Params = ExtractParameterFromPath<P>;
  
  const parentPath = useRoute("");
  const [, setLocation] = useLocation();

  const goToParentPath = React.useCallback(() => {
    setLocation(parentPath || "/");
  }, [setLocation, parentPath]);

  const usedPath = useRoute(path);
  const result = useMatcher(usedPath, strict) as { match: boolean, params: Params };
  
  if(!result.match){
    return null;
  }
  
  return !hasJunction
    ? getChildren<Params> (component, children, result.params, goToParentPath)
    : (
      <Junction path={ path }>
      {
        getChildren(component, children, result.params, goToParentPath)
      }
      </Junction>
    );
}

export default Route;