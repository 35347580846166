import React        from "react";
import Data         from "@uBehaviour/data";
import Select       from "../select";
import { get }      from "@uLib/objectTool";
import md5          from "md5";

const SelectModel = (props) => {
  let { model, query, sort, limit, value, onChange, label, id, labelize, valuize, searchable, fluid } = props;
  if(!id){
    id = "_id";
  }
  if(!labelize && label){
    labelize  = v => get(label, v);
  }
  if(!valuize && id){
    valuize   = v => get(id, v);
  }
  if(!sort && label){
    sort = {[label]: 1 };
  }
  let child= [];
  const display = props.children ? Select.Display.get({ props: props }) : null;
  const values  = props.children ? Select.Value.get({ props: props }, true) : null;
  
  if(display){
    child.push(display);
  }
  if(values && values.length){
    child = child.concat(values);
  }
  return (
    <Data.Query model={ model } query={ query } sort={ sort } pageSize={ limit }>
    { results => {
      child = child.concat(results.map(result => { 
        const value = valuize(result);
        return (
          <Select.Value key={md5(JSON.stringify(value))} value={ value }>{ labelize(result) }</Select.Value>
        );
      }));
      return React.createElement(Select, { value: value, onChange: onChange, searchable: searchable, fluid }, ...child)
    }}
    </Data.Query>
  );
}

export default SelectModel;