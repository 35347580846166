import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import Slot from '@uComponents/slot2';
import classNames from '@universal/lib/classNames';

import './columns.css';

type ColumnsProps = {
  className?: string;
}

type ColumnProps = {
  className?: string;
}

const Column = Slot<ReactNode, PropsWithChildren<ColumnProps>>();

const Columns: FunctionComponent<PropsWithChildren<ColumnsProps>> & { Column: typeof Column }= ({ className, children }) => (
  <div className={ classNames("bs-layout-columns").addNotEmpty(className) }>
  {
    Column.props(children, true).map((column, index) => (
      <div className={ classNames("bs-layout-column").addNotEmpty(column.className) } key={index}>
      { column.children }
      </div>
    ))
  }
  </div>
);
Columns.Column = Column;

export default Columns;

