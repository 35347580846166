import React  from "react";
import T      from "@cBehaviour/i18n";
import "./error.css";

const CError = ({ errors = [], children = null }) => {
  const error = (
    <div className={`bs-error ${errors?.length ? "bs-display-error" : ""}`}>
      { errors?.map(e => (<li key={e.error}><T allowUnknow={true}>{ e.error }</T></li>)) }
    </div>
  )
  if(children){
    return (
      <div className={`bs-input-error ${errors?.length ? "bs-display-error" : ""}`}>
        { children }
        { error }
      </div>
    )
  }else{
    return error;
  }
}

export default CError;