import { Key, ModelBuilder }      from "@uLib/model";

const issueGroupsModel = (application) => {
  const connector = {
    find(query, sort, offset, limit){
      return application.getService("api").service('issueGroups', "get").execute(query, sort, offset, limit);
    },
    count(query){
      return application.getService("api").service('issueGroups', "count").execute(query);
    },
    findOne(query){
      return application.getService("api").service('issueGroups', "getOne").execute(query);
    },
    create(){ throw new Error('Not possible') },
    update(){ throw new Error('Not possible') },
    partial(){ throw new Error('Not possible') },
    remove(){ throw new Error('Not possible') }
  };
  return ModelBuilder.create("IssueGroup", Key.create("_id"), connector,  {})
    .add(p => p("_id", "string"))
    .add(p => p("issues", "array").of(t => t("entity").type('Issue')))
    .add(p => p("tenant", "entity").type("Tenant"))
    .build();
}

export default issueGroupsModel;