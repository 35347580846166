import axios from "axios";

class Request {
  constructor(request, response, trigger) {
    this._request = request;
    this._response = response;
    this._trigger = trigger;
  }
  _triggerStart() {
    if (this._trigger?.start) {
      this._trigger.start();
    }
  }
  _triggerEnd() {
    if (this._trigger?.end) {
      this._trigger.end();
    }
  }
  execute(...args) {
    this._triggerStart();
    return this._request(args)
      .then(opt => axios(opt))
      .then(response => {
        this._triggerEnd();
        if (response.status >= 200 && response.status < 300) {
          return this._response(response);
        } else {
          return Promise.reject(this._response(response));
        }
      }, e => {
        this._triggerEnd();
        try {
          return Promise.reject(this._response(e.response));
        } catch (o) {
          return Promise.reject(e);
        }
      });
  }
}



class RequestBuilder {
  static create(httpContext, path, trigger) {
    return new RequestBuilder(httpContext, path, trigger);
  }
  constructor(httpContext, path, trigger) {
    this._httpContext = httpContext;
    this._path = path;
    this._trigger = trigger;
    this._parser = {
      out: (_out => _out),
      in: (_in => _in)
    };
    this._methodHandler = (() => null);
    this._typeHandler = (() => null);
    this._paramsHandler = (() => null);
    this._bodyHandler = (() => null);
    this._callHandler = function () { return Array.prototype.slice.call(arguments) };
    this._pathHandler = (opt, params) => {
      if (!params) {
        params = {};
      }
      opt.url += Object.keys(params).reduce((path, key) => path.replace(`:${key}`, params[key]), this._path);
    };
    this._queryHandler = (opt, params) => {
      opt.url += Object.keys(params).reduce((query, key) => {
        if (!query.length) {
          query = "?";
        } else {
          query += "&";
        }
        return `${query}${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      }, "");
    };
  }
  buildContextParams(params) {
    this._buildContextParams = params;
    return this;
  }
  method(method) {
    this._methodHandler = (opt) => { opt.method = method };
    switch (method) {
      case "PUT":
      case "POST":
      case "PATCH":
        this._bodyHandler = (opt, params) => {
          opt.data = params;
        }
        break;
      default:
    }
    return this;
  }
  setParser(parser) {
    this._parser = Object.assign(this._parser, parser);
    return this;
  }
  type(type, parser = null) {
    if (type + "" === type) {
      type = {
        out: type,
        in: type
      };
    }
    this._typeHandler = ((opt) => opt.headers["content-type"] = type.out);
    if (!parser) {
      parser = {};
      if (type.out === "application/json") {
        parser.out = ((object) => JSON.stringify(object));
      } else {
        parser.out = v => v;
      }
      if (type.in === "application/json") {
        parser.in = ((object) => object === "" ? null : JSON.parse(object));
      } else {
        parser.in = v => v;
      }
    }
    this._parser = parser;
    return this;
  }
  call(handler) {
    this._callHandler = handler;
    return this;
  }
  build() {
    const requestHandler = (params) => {
      params = this._callHandler.apply(null, params);
      return this._httpContext(this._buildContextParams)
        .then(opt => {
          if (!opt.headers) opt.headers = {};

          this._pathHandler(opt, params.path);
          this._methodHandler(opt);
          this._typeHandler(opt);
          if (params.query) {
            this._queryHandler(opt, params.query);
          }
          if (params.body) {
            this._bodyHandler(opt, this._parser.out(params.body));
          }
          opt.transformResponse = [v => v];
          return opt;
        });
    };
    const reponseHandler = (response) => {
      return this._parser.in(response.data, response);
    };
    return new Request(requestHandler, reponseHandler, this._trigger);
  }
}
export default RequestBuilder;