import { Service }  from "@uLib/application";

export default class SessionService extends Service{
  constructor(type, tokenName){
    super("session", ["api", "storage", "url", "jwt", "message", "repository", "networking"]);
    this._token     = null;
    this._tokenName = tokenName;
    this._userToken = null;
    this._user      = null;
    this._type      = type;
  }
  start(){
    return this.waitReady(["api", "storage", "url", "networking"])
      .then(async ([api, storage, url, networking]) => {
        const [storageToken, urlToken] = await Promise.all([
          storage.get(this._tokenName),
          url.has("token") ? url.get("token") : null
        ]);
        
        if(urlToken){
          await this.application.getService("url").remove("token");
          try {
            await this._startWithToken(urlToken);
          }catch(err){
            this.setToken(null);
            await this._startWithToken(storageToken);
          }
        } else {
          await this._startWithToken(storageToken);
        }
        networking.onConnect.addListener({
          handleEvent:this._onNetworkConnect.bind(this)
        });
    });
  }
  async _startWithToken(token){
    if(!token){
      return null;
    }
    await this.setToken(token);
    if(this.application.getService("networking").isConnected()){
      await this.renewToken();
    }
  }

  _onNetworkConnect() {
    this.renewToken()
      .then(() => this.triggerUpdate());
  }

  async _loadUserFromToken(){
    if(!this._token){
      this._userToken = null;
      this._user      = null;
      return;
    }
    this._userToken = this.application.getService("jwt").decode(this._token);
    if(!this.application.getService("networking").isConnected()
        || ["starterActivationConfirmEmail", "starterActivator"].includes(this._userToken.discriminator)
    ){
      this._user = null;
      return;
    }
    if(this._userToken.virtual){
      this._user = {};
      return;
    }
    this._user = await this.application.getService("api").service("sessions", "me").execute();
  }

  renewToken(){
    return this.application.getService("api").service("sessions", "renewToken").execute()
      .then(token => this.setToken(token))
      .catch(err => {
        if (err.code === 403) {
          this.logout();
        } else {
          return Promise.reject(err);
        }
      });
  }

  isLogged(){
    return this._token !== null;
  }

  hasUser(){
    return !!this._user;
  }

  mustResetPassword(){
    if(this.userToken){
      return this.userToken.mustResetPassword;
    }
    return false;
  }

  get tokenName() {
    return this._tokenName;
  }

  get userId(){
    return this._userToken ? this._userToken._id : null;
  }

  get userBusiness(){
    return this._user;
  }

  get userToken(){
    return this._userToken;
  }

  get user(){
    if(!this._token) return null;
    return Object.assign({}, this._user, this._userToken);
  }

  getToken(){
    return Promise.resolve(this.token);
  }

  get token(){
    return this._token;
  }

  set token(token) {
    this.setToken(token);
  }

  async setToken(token){
    this._token = token;
    await this._loadUserFromToken();
    if(this.userToken && this.userToken.toStore !== false){
      await this.application.getService("storage").set(this._tokenName, token);
    }
    this.triggerUpdate(this.user);
  }

  login(username, password){    
    return this.application.getService("api").service("sessions", "login").execute(username, password, this._type)
      .then(token => this.setToken(token));
  }
  logout(){
    this._token = null;
    return this._loadUserFromToken()
      .then(() => this.application.getService("storage").remove(this._tokenName))
      .then(() => this.triggerUpdate(this.user));
  }


  updateMyPassword(password){
    return this.application.getService("api").service("sessions", "updateMyPassword").execute(password)
      .then(token => this.setToken(token));
  }

  updateMine(user) {
    const newMail = user.email;
    return this.application.getService('api').service('sessions', 'updateMine').execute(user) 
      .then(user => this._user = user)
      .then(user => this.application.getService("message").send("info", this.application.getService("i18n").translate(newMail === user.email ? "account_change_successfull" : "account_change_new_email")));
  }

  deleteAccount(){
    return this.application.getService("api").service("sessions", "deleteAccount").execute()
         .then(() => this.logout())
         .then(() => this.application.getService("message").send("info", this.application.getService("i18n").translate("account_deleted_confirmation")));
  }

  recoverPassword(username){
    return this.application.getService("api").service("sessions", "recoverPassword").execute(username, this._type)
      .then(() => this.application.getService("message").send("info", this.application.getService("i18n").translate("recover_password_email_send")));
  }

  renewPassword(){
    return this.application.getService("api").service("sessions", "renewPassword").execute()
    .then(() => this.application.getService("message").send("info", this.application.getService("i18n").translate("renew_password_email_send")));
  }

  updateSubscriptions(subscriptions){
    return this.application.getService("api").service("sessions", "updateSubscriptions").execute(subscriptions)
      .then((result) => {
        if(result){
          this._user.personalSettings.subscriptions = subscriptions;
          this.triggerUpdate(this.user);
        }
      });
  }

  isUserProWithoutTenant(){
    return this.user.discriminator === "pro" && (!this.user.tenants || !this.user.tenants.length);
  }
}