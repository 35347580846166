import React from "react";
import _      from "lodash";
import Display from "@cComponents/displayIf";
import Application  from "@uBehaviour/application";
import moment       from "moment";
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import FocusBlur from "@cComponents/focusBlur";
import classNames from "@uLib/classNames";
import Tooltip from "@cComponents/tooltip";
import "moment/locale/fr";
import "moment/locale/nl";

import 'react-day-picker/lib/style.css';
import "./date.css";

const keyRegEx    = /[0-9]/;

const emptyDate = "__/__/____"

class Date extends React.Component{
  constructor(props){
    super(props);
    this.state                = { cursor: 0 };
    this._input               = React.createRef();
    this._tooltipRef          = React.createRef();
    this._onKeyDown           = this._onKeyDown.bind(this);
    this._onChange            = this._onChange.bind(this);
    this.componentDidUpdate   = _.debounce(this.componentDidUpdate.bind(this), 0);
    this._onDayClick          = this._onDayClick.bind(this);
    this._updateValue(true);
  }
  get value(){
    return this._valueDate;
  }
  _updateValue(force){
    if(this._lastProps !== this.props || force){
      this._value               = this.props.value ? moment(this.props.value).format("DD/MM/YYYY") : emptyDate;
      this._valueDate           = this.props.value;
      this._lastProps           = this.props;
    }
  }
  _onKeyDown(ev){
    let cursor = ev.currentTarget.selectionStart;

    if(ev.key.length === 1 && !(keyRegEx.test(ev.key) && cursor < 10)){
      ev.preventDefault();
      return;
    }
    if(ev.key.length > 1 && ["Backspace", "Delete"].indexOf(ev.key) === -1){
      return;
    }

    let key = ev.key;

    if(key === "Backspace"){
      if(cursor === 0){
        return;
      }
      cursor -= 1;
      key = "_";
    } else if(key ===  "Delete"){
      key = "_";
    }
    if(cursor === 2 || cursor === 5){
      ++cursor;
    }
    
    this._value = this._value.substring(0, cursor) + key + this._value.substring(cursor + key.length);

    if(ev.key !== "Backspace"){
      ++cursor;
      if(cursor === 2 || cursor === 5){
        ++cursor;
      }
    } else if(cursor === 3 || cursor === 6){
      --cursor;
    }
    
    this._onChange(this._value);
    this.setState({ cursor })
  }
  _onChange(value){    
    if(this.props.onChange && (value === emptyDate || moment(value, "DD/MM/YYYY").format("DD/MM/YYYY") === value)){
      value = value === emptyDate ? null : moment(value + " 00:00:00.000", "DD/MM/YYYY HH:mm:ss.SSS").toDate();
      this._valueDate = value;
      if((!value && this.props.value) || (value && (!this.props.value || moment(value).toDate().getTime() !== moment(this.props.value).toDate().getTime()))){
        this.props.onChange(value);
      }
    }
  }
  _onDayClick(date, args, ev){
    ev.stopPropagation();
    this._value = moment(date).format("DD/MM/YYYY");
    this._tooltipRef.current.close();
    this._onChange(this._value);
  }
  componentDidUpdate(){
    if(this._input.current){
      this._input.current.setSelectionRange(this.state.cursor, this.state.cursor);
    }
  }

  render(){
    this._updateValue();
    return (
      <div className={classNames("bs-input-date").add(this.props.fluid ? "bs-fluid" : "bs-not-fluid")}>
        <input ref={ this._input } type="text" onKeyDown={ this._onKeyDown } value={ this._value }/>
        <Tooltip placement='right-end' style={{backgroundColor: "green"}} trigger="click" interactive={true} ref={ this._tooltipRef }>
          <Tooltip.Subject> 
            <div>
              <span className="fa fa-calendar" onClick={ (ev) => { ev.stopPropagation(); }}/>
            </div>
          </Tooltip.Subject>
          <Tooltip.Info >
            <DayPicker 
              localeUtils={ MomentLocaleUtils }
              locale={ this.props.i18n.currentLanguage.bs }
              onDayClick={ this._onDayClick }
              selectedDays={ this._valueDate }
              month={ this._valueDate ? moment(this._valueDate).startOf("month").toDate() : null }
            />
          </Tooltip.Info>
        </Tooltip>
      </div>
    );
  }
};


export default Application.Service.forward(["i18n"], Date);