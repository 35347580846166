import React          from "react";
import Data           from "@uBehaviour/data";
import DisplayFile    from '@cComponents/file';
import Application    from "@uBehaviour/application";
import FileCollection from '@cLib/fileCollection';
import File           from "@cComponents/file"; 
import Display        from "@uComponents/displayIf";
import T              from '@uBehaviour/i18n';
import _ from 'lodash';

import "./file.css";

const DropAreaContext = React.createContext();

class DropArea extends React.Component {
  constructor(props) {
    super(props);
    this._droparea        = React.createRef();
    this._listeners       = [];
    this._dropFilePrevented = false;
    this._in = false;
    this._lastIn = null;
    this._interpretLeaveAndEnter = _.debounce(this._interpretLeaveAndEnter.bind(this), 0);
    this._useChildHasHtmlElement = !Array.isArray(this.props.children) && (_.isString(this.props.children.type) || !!this.props.children.type.HtmlEventRelayer);
  }
  _preventDefault = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }
  addEventListener(event, listener){
    if(this._listeners.findIndex(l => l.event === event && l.listener === listener) !== -1){
      return null;
    }
    this._listeners.push({ event, listener });
  }
  removeEventListener(event, listener){
    const index = this._listeners.findIndex(l => l.event === event && l.listener === listener);
    if(index !== -1){
      this._listeners.splice(index, 1);
    }
  }

  _onDrop = (ev) => {
    if (!this._dropFilePrevented && ev.dataTransfer && ev.dataTransfer.files) {
      this._listeners.filter(l => l.event === 'onFile').forEach(l => {
        l.listener(ev.dataTransfer.files);
      });
    }
  }

  _onDragOverDocument = (ev) => {
    ev.stopPropagation();
    if (!this._dropFilePrevented && ev.dataTransfer && ev.dataTransfer.files) {
      this._lastIn = true;
      this._interpretLeaveAndEnter();
    }
  }

  _onDragLeaveDocument = (ev) => {
    ev.stopPropagation();
    if (!this._dropFilePrevented && ev.dataTransfer && ev.dataTransfer.files && this._lastIn === null) {
      this._lastIn = false;
      this._interpretLeaveAndEnter();
    }
  }

  _interpretLeaveAndEnter(){
    let event = null;
    if(this._in && !this._lastIn){
      this._in = false;
      event = 'onDragendDocument';
    }else if(!this._in && this._lastIn){
      this._in = true;
      event = 'onDragoverDocument';
    }
    this._lastIn = null;
    if(event){
      this._listeners.filter(l => l.event === event).forEach(l => {
        l.listener();
      });
    }
  }

  _forbidDrop = () => {
    this._dropFilePrevented = true;
  }

  _onDragEnd = (ev) => {
    if (!this._dropFilePrevented && ev.dataTransfer && ev.dataTransfer.files) {
      ev.preventDefault();
      this._listeners.filter(l => l.event === 'onDragendDocument').forEach(l => {
        l.listener();
      });
    } else {
      this._dropFilePrevented = false;
    }
  }

  componentDidMount() {
    window.document.addEventListener('dragstart', this._forbidDrop);
    window.document.addEventListener('dragenter', this._onDragOverDocument, true);
    window.document.addEventListener('dragleave', this._onDragLeaveDocument, true);
    window.document.addEventListener('drop', this._onDragEnd, true);
    this._droparea.current.addEventListener("drop", this._onDrop);
  }

  componentWillUnmount(){
    this._droparea.current.removeEventListener("drop", this._onDrop);
    window.document.removeEventListener('drop', this._onDragEnd, true);
    window.document.removeEventListener('dragleave', this._onDragLeaveDocument, true);
    window.document.removeEventListener('dragover', this._onDragOverDocument, true);
    window.document.removeEventListener('dragstart', this._forbidDrop);
  }

  render() {
    const usedChildren = this._useChildHasHtmlElement
      ? React.cloneElement(this.props.children, { ref: this._droparea })
      : (
        <div ref={ this._droparea } className='bs-input-file-droparea'>
          { this.props.children }
        </div>
      );
    return React.createElement(DropAreaContext.Provider, { value: this }, usedChildren);
  }
}
class DropAreaDisplayOnDrag extends React.Component {
  static contextType = DropAreaContext;
  static HtmlEventRelayer = true;
  constructor(props){
    super(props);
    this.state = {
      fileIsDraging: false,
      hover: false
    };
    this._ref = React.createRef();
  }
  onDragOver = () => {
    if(!this.state.fileIsDraging){
      this.setState({ fileIsDraging: true });
    }
  }
  onDragEnd = () => {
    if(this.state.fileIsDraging){
      this.setState({ fileIsDraging: false });
    }
  }
  componentDidMount(){
    this.context.addEventListener('onDragoverDocument', this.onDragOver);
    this.context.addEventListener('onDragendDocument', this.onDragEnd);
  }
  componentWillUnmount(){
    this.context.removeEventListener('onDragendDocument', this.onDragEnd);
    this.context.removeEventListener('onDragoverDocument', this.onDragOver);
  }

  addEventListener(...args){
    this._ref.current.addEventListener(...args);
  }

  removeEventListener(...args){
    this._ref.current.removeEventListener(...args);
  }

  onDragEnter = () => {
    this.setState({ hover: true });
  }
  onDragLeave = () => {
    this.setState({ hover: false });
  }

  render(){
    const { children }      = this.props;
    const { fileIsDraging, hover } = this.state;

    return (
      <div className='bs-input-file-dropAreaDisplayOnDrag' ref={ this._ref } onDragEnter={ this.onDragEnter } onDragLeave={ this.onDragLeave }>
        { children }
        <Display.If condition={ fileIsDraging }>
          <div className={ `bs-input-file-dropAreaDisplayOnDrag-overlay ${ hover ? 'bs-hover' : 'bs-not-hover' }` } >
            <div className='bs-input-file-dropAreaDisplayOnDrag-overlay-content'>
              <h2><T>input_file_dropArea_diplay_label</T></h2>
            </div>
          </div>
        </Display.If>
      </div>
    )
  }
}
DropArea.DisplayOnDrag = DropAreaDisplayOnDrag;

const DefaultDisplay = (props) => {
  const height = !props.height && !props.width ? 120 : props.height;
  const width =!props.height && !props.width ? 120 : props.width
  const style = {
    height: height,
    width: width
  };
  const inputOnChange = (ev) => { 
    if (ev.currentTarget && ev.currentTarget.files){
      props.addFiles(ev.currentTarget.files);
    }
  }
  return (
    <div className={"bs-input-file" + (props.inline ? " bs-input-file-inline" : " bs-input-file-incolumn") }>
      { 
        props.files.map((file, idx) => (
          <Data.Synchronizer key={file?.entity?.hash ? file.entity.hash : "new"} entity={file.entity}>
          { entityFile => {
            return (
              <div className="bs-input-file-thumb" id={entityFile?.hash ? entityFile.hash : "new"} style={style}>
                <div className="bs-input-file-remove" onClick={(e) => { e.preventDefault(); file.remove() }}><span className="fa fa-times" /></div>
                <DisplayFile file={ entityFile } height={height} width={width} fit={props.fit} />
              </div>
            )
          }}
          </Data.Synchronizer>
        ))
      }
      { props.isAllowedToAdd && (
        <label>
          <File width={ width } height={ height } />
          <input className="bs-input-file-input" type="file" onChange={inputOnChange} accept={ props.acceptedMimeType } />
        </label>
      )}

    </div>
  );
};
class _InputFile extends React.Component{
  constructor(props) {
    super(props)
    this.addFiles   = this.addFiles.bind(this);
    this._newFiles  = {};
    this._values    = [];
  }
  get values() {
    return this._values;
  }
  async addFiles(files) {
    if(this.props.limit && this.props.limit <= this.values.length){
      return;
    }
    try {
      const addedFiles = await this.props.fileService.highLevelAdd(new FileCollection(files));
      addedFiles.forEach(file => {
        const listener = {
          handleEvent: () => {
            file.removeListener(listener);
            this._newFiles[file._id] = file;
            this._values.push(file._id);              
            if(this.props.onChange){
              this.props.onChange(this._values, file._id, true);
            }
          }
        };
        file.addListener(listener);
      });
    } catch(err) {
      return;
    }
  }
  _remove(id) {
    if (this._newFiles[id]) {
      delete this._newFiles[id];
    }
    this._values = this._values.filter(i => i !== id);
    if(this.props.onChange){
      this.props.onChange(this._values, id, false);
    }
  }

  onFileHandler = (files) => {
    this.addFiles(files);
  }

  componentDidMount(){
    if(this.props.dropArea){
      this.props.dropArea.addEventListener('onFile', this.onFileHandler);
    }
  }

  componentWillUnmount(){
    if(this.props.dropArea){
      this.props.dropArea.removeEventListener('onFile', this.onFileHandler);
    }
  }

  render() {
    this._values = this.props.value ? this.props.value : this._values;
    const toLoad = this._values.filter(v => !this._newFiles[v]) ;
    const DisplayFile = (files) => {
      const props = {
        files: files.map(file => ({
          entity: file,
          remove: () => this._remove(file._id)
        })),
        addFiles: (files) => this.addFiles(files),
        isAllowedToAdd: !this.props.limit || this.props.limit > this.values.length
      };
      if(this.props.children) {
        return this.props.children instanceof Function 
          ? this.props.children(props.files, props.addFiles, props.isAllowedToAdd)
          : React.cloneElement(this.props.children, props);
      }
      return React.createElement(DefaultDisplay, Object.assign({ 
        inline: this.props.inline,
        acceptedMimeType: this.props.fileService.uploadableMimeTypes.join(', '),
        height: this.props.height,
        width: this.props.width,
        fit: this.props.fit
      }, props));
    }
    if(toLoad.length){
      return React.createElement(Data.Query, {
        key: "first_file",
        model: "File",
        query: { _id: { $in: toLoad } },
        limit: 200
      }, (files => {
        if (!files.length) {
          return null;
        }
       const dic = Object.assign({}, this._newFiles, files.reduce((dic, file) => {
        dic[file._id] = file;
        return dic;
       }, {}));
       return DisplayFile(this._values.map(fileId => dic[fileId]));
      }));
    }else{
      return DisplayFile(this._values.map(fileId => this._newFiles[fileId]));
    }
  }
}
const InputFile = Application.Service.forward([["file", "fileService"], "message"], _InputFile);

const ConnectedInputFile = (props) =>(
    <DropAreaContext.Consumer>
    {(dropArea) => (
      <InputFile { ...props } dropArea={ dropArea }>
        { props.children }
      </InputFile>
    )}
    </DropAreaContext.Consumer>
)

ConnectedInputFile.DropArea = DropArea;
ConnectedInputFile.Gallery = null;
export default ConnectedInputFile;