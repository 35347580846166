import React  from "react";
import _      from "lodash";

const AsyncDisplayContext = React.createContext(null);

class AsyncDisplay extends React.Component {
  constructor(props){
    super(props);
    this._promises = [];
  }
  _wait(){
    Promise.all(this._promises).then(() => {
      if(this.props.whenReady){
        this.props.whenReady()
      }
    });
  }
  waitMe(promise){
    this._promises.push(promise);
  }
  componentDidMount(){
    this._wait();
  }
  componentDidUpdate(){
    this._wait();
  }
  render(){
    return React.createElement(AsyncDisplayContext.Provider, { value: this }, this.props.children);
  }
}
class WaitMe extends React.Component {
  constructor(props){
    super(props);
    this._ready   = this._ready.bind(this);
  }
  _buildPromise(){
    this._resolve = null;
    this._promise = new Promise(resolve => {
      this._resolve = resolve;
    });
  }
  _ready(){
    this._resolve();
  }
  render(){
    return React.createElement(AsyncDisplayContext.Consumer, {}, value => {
      this._buildPromise();
      if(value){
        value.waitMe(this._promise);
      }
      return this.props.children(this._ready);
    });
  }
}
AsyncDisplay.WaitMe = WaitMe;
export default AsyncDisplay;