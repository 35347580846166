import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from '@uLib/classNames';
import './footer.css';

interface FooterProps {
  className?: string
}

type FooterType = FunctionComponent<PropsWithChildren<FooterProps>> & {

};

const Footer: FooterType = ({ children, className }) => (
  <div className={ classNames('bs-section-footer').addNotEmpty(className) }>
  { children }
  </div>
);

export default Footer;