import React            from 'react';
import Application      from "@uBehaviour/application";
import './file.css';
import useService from '@universal/behaviour/hooks/useService';
import useHelper from '@universal/behaviour/hooks/useHelper';

const stopPropagation = (ev) => ev.stopPropagation();
const defaultImageUrl = "/images/no_photo_little_all_tags.png";
const undisplayableImageUrl = "/images/undisplayable.png";

const isNumber = (value) => parseInt(value) == value;

class ImageDynamicImpl extends React.Component {
  onLoad = (...args) => {
    const { onLoad } = this.props;
    if(onLoad){
      onLoad(...args);
    }
  }
  onError = (...args) => {
    const { onError } = this.props;
    if(onError){
      onError(...args);
    }
  }
  render() {
    const { fileHelper, file, width, height, noConstraint = false, "data-testid": dataTestId, fit, format, openable: pOpenable } = this.props;

    const src = file?.storageId
    ? fileHelper.makeStorageUrl(file, {
        fit: fit,
        width: isNumber(width) ? width : null,
        height: isNumber(height) ? height : null,
        format: format
      })
    : defaultImageUrl;

    const openable = !!pOpenable && src !== defaultImageUrl;

    const props = {};

    if(!noConstraint){
      props.width = width;
      props.height = height;
    }

    const img = (
      <img src={ src } onLoad={ this.onLoad } onError={ this.onError } { ...props } data-testid={ dataTestId }/>
    );
    
    if(!openable){
      return (
        <div className="bs-file">
          { img }
        </div>
      );
    }
    
    return (
      <div className="bs-file" onClick={ stopPropagation }>
        <a href={ fileHelper.makeStorageUrl(file) } target="_blank">
        { img }
        </a>
      </div>
    );
  }
}

const ImageDynamic = Application.Helper.forward([['file', 'fileHelper']], ImageDynamicImpl);

const ImageURI = ({ file, width, height, onLoad, onError, noConstraint = false }) => {
  const props = {};
  if(!noConstraint){
    props.width  = width;
    props.height = height;
  }
  return (
    <div className="bs-file">
      <img src={ file.uri } onLoad={ onLoad } onError={ onError } { ...props } />
    </div>
  )
};

const ImageUndisplayable = ({ file, width, height, noConstraint = false, openable = false }) => {
  const fileHelper = useHelper("file");
  const props = {};
  if(!noConstraint){
    props.width  = width;
    props.height = height;
  }
  if (!openable) {
    return (
      <div className="bs-file">
        <img src={ undisplayableImageUrl } { ...props } />
      </div>
    )
  }
  return (
    <a onClick={ stopPropagation } href={ fileHelper.makeStorageUrl(file) } target="_blank" download={ file.name } className="bs-file">
      <img src={ undisplayableImageUrl } { ...props } />
    </a>
  );
}

const File = ({ file = null, fit = null, height = null, width = null, openable = null, noConstraint = null, onLoad = null, onError = null }) => {
  const fileService = useService("file");
  let Component = null;
  if(!file) {
    Component = ImageDynamic;
  } else if(file.type === "static") {
      const imgProps = {
        src: file.src,
        width,
        height
      };
      if(file.fit || fit){
        imgProps.style = {
          "object-fit": file.fit || fit
        };
      }
      return React.createElement("img", imgProps);
  } else {
    if (fileService.displayableMimeTypes.includes(file.mimeType)) {
      Component = ImageDynamic;
    } else if (file.mimeType === "image/uri") {
      Component = ImageURI;
    } else {
      Component = ImageUndisplayable
    }
  } 

  return React.createElement(Component, {
    file: file,
    fit: fit,
    height: height,
    width: width,
    openable: openable,
    noConstraint: noConstraint,
    onLoad: onLoad,
    onError: onError
  });
}

export default File;