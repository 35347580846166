import React  from 'react';
import T      from "@cBehaviour/i18n";

import './state.css'

const getColor = (state) => {
  switch(state){
    case "open": return 'orange';
    case "in_progress": return 'blue';
    case "planned": return 'azure';
    case "resolved": return 'green';
    case "transferred": return 'pink';
    case "deleted": return 'grey';
    case "rejected": return 'army';
    default: return 'purple';
  }
};
const State = ({ state }) => (
  <span className={"bs-state " + getColor(state) }><T>{"common_issue_state_" + state}</T></span>
);

const Wrapper = ({ state, children }) => (
  <div className="bs-state-wrapper">
    { children }
    <State state={ state } />
  </div>
);

export default {
  State,
  Wrapper
};