import React                from "react";
import Data                 from "@uBehaviour/data";
import InputObject          from "@cComponents/old/input/object";
import Store                from "@cLib/store";
import keyboard             from "@cLib/keyboard";
import Event                from "@cLib/event";


/**
 * @deprecated
 */
class FormStore extends Store{
    constructor(id, datas, context){
        const defaultValue = { value: datas, last: [], forwad: [], errors: { global: [], properties: [] }, lastOrigin: null };
        super(id, defaultValue, {
            initialize: (state, payload) => {
                state.value = payload.value;
                state.datas = payload.datas;
            },
            update: (state, payload) => {
                if(state.lastOrigin !== payload.origin){
                    state.last.push(JSON.parse(JSON.stringify(state.value)));
                    state.forwad     = [];
                    state.lastOrigin = payload.origin;
                }
                state.updated   = true;
                state.value     = payload.value;
            },
            rollback: (state) => {
                if(state.last.length){
                    state.forwad.push(state.value);
                    state.lastOrigin    = null;
                    state.value         = state.last.pop();
                    if(state.updated && !state.last.length){
                        state.updated = false;
                    }else if(!state.updated){
                        state.updated = true;
                    }
                }
            },
            backforward: (state) => {
                if(state.forwad.length){
                    state.last.push(state.value);
                    state.lastOrigin    = null;
                    state.value         = state.forwad.pop();
                    state.updated       = true;
                }
            },
            submited: (state) => {
                state.updated = false;
            }
        });
        this._context = context;
        this._initialize = this._preload(datas);
    }
    get value(){
        return this.datas.value;
    }
    get context(){
        return this._context;
    }
    set context(context){
        this._context = context;
    }
    initialize(){
        return this._initialize;
    }
    _preload(datas){
        return Promise.resolve(this.context.preload(datas))
            .then(value => {
                this._store.dispatch({
                    type: "initialize",
                    value: value,
                    datas: datas
                });
            });
    }
    update(value, origin, errors){
        if (errors && ((errors.global && errors.global.length) || (errors.properties && errors.properties.length))) {
            this._set("errors", errors);
        }
        this._store.dispatch({
            type: "update",
            value: value,
            origin: origin.fullName
        });
    }
    rollback(){
        this._store.dispatch({ type: "rollback" });
    }
    backforward(){
        this._store.dispatch({ type: "backforward" });
    }
    submit(force){
        return Promise.resolve().then(() => {
            if(!this.datas.updated && !force) return null;
            let errors = { global: [], properties: [] };
            let value = JSON.parse(JSON.stringify(this.datas.value));
            this.context.validate(value, errors);
            if(!errors.global.length && !errors.properties.length){
                return Promise.resolve(JSON.parse(JSON.stringify(this.datas.value)))
                    .then(value => this.context.presubmit(value))
                    .then(value => this.context.submit(value).then(() => value), err => Promise.reject(err))
                    .then(value => {
                        this._store.dispatch({ type: "submited" });
                        return value;
                    }, error => {
                        if(!error.path){
                            errors = { global: [{ code: error.code, error: error.message}], properties: [] };
                        }else{
                            errors = { global: [], properties: [{ code: error.code, error: error.message, path: error.path }] };
                        }
                        return Promise.reject(errors);
                    });   
            }
            return Promise.reject(JSON.parse(JSON.stringify(errors)));
        }).then(
          value => value,
          errors => {
            if(errors instanceof Error){
                console.error(errors);
            }else{
                this._set("errors", errors);
            }
            return Promise.reject(errors);
        });
    }
}

export default class Form extends React.Component{
    constructor(props){
        super(props);
        this._submitted     = new Event();
        this._inputObject   = React.createRef();
        this._onChange      = this._onChange.bind(this);
    }
    get onSubmit(){
        return this._submitted;
    }
    get value(){
        return this._inputObject.current.value;
    }
    componentDidMount(){
        keyboard.onKeyDown.addListener(this);
    }
    componentWillUnmount(){
        keyboard.onKeyDown.removeListener(this);
    }
    submit(force = false){
        return Promise.resolve()
            .then(() => this._store.submit(force))
            .then(value => {
                this._submitted.trigger(this);
                return value;
            }).then(value => value, error => {
                if(!(this.props.children  instanceof Function)){
                    this._inputObject.current.forceUpdate();
                }
                return Promise.reject(error);
            });
    }
    update(value, origin){
        this._store.update(value, origin);
    }
    async _onChange(input, origin){
        let value = this._inputObject.current.value;
        const handler = this.props.onChange
            ? this.props.onChange
            : v => v;
        const errors = this._store.datas.errors;

        errors.properties = errors.properties.filter(error => error.path !== origin.fullName);
        value = await handler(
            value,
            origin.fullName,
            this._store.value,
            (path, error) => {
                errors.properties.push({path, error});
            },
            (error) => {
                errors.global.push({error});
            }
        );
        this._store.update(value, origin, errors);
        this._inputObject.current.forceUpdate();
    }
    handleEvent(event, value, origin){
        switch(event){
            case "onKeyDown":
                if(value.ctrlKey){
                    switch(value.code){
                        case "KeyW": this.rollback();       this._inputObject.current.forceUpdate(); break;
                        case "KeyY": this.backforward();    this._inputObject.current.forceUpdate(); break;
                        default:
                    }
                }
                break;
            default:
        }
    }
    rollback(){
        this._store.rollback();
    }
    backforward(){
        this._store.backforward();
    }
    _getContext(){
        return {
            preload: this.props.preload ? this.props.preload : (val) => val,
            presubmit: this.props.presubmit ? this.props.presubmit : (val) => val,
            validate: (datas, errors) => {
                errors.properties = this._inputObject.current.getErrors();
                if(this.props.validate){
                    this.props.validate(datas, errors);
                }
            },
            submit: this.props.submit
        };
    }
    _buildStore(){
        const value = this.props.value ? JSON.parse(JSON.stringify(this.props.value)) : this.props.default ? this.props.default : {};
        const store = new FormStore(
            this.props.name,
            value,
            this._getContext()
        );
        return store.initialize().then(() => store);
    }

    render(){
        return (
            <InputObject.Context.Provider value={null}>
                <Data.Store.Provider name={ this.props.name } build={ () => this._buildStore() }>
                    <Data.Store.Accessor name={ this.props.name }>
                        { store => {                                  
                            this._store = store;
                            this._store.context = this._getContext();
                            const datas = this._store.datas;
                            if(this.props.observer){
                                this.props.observer.notify(JSON.parse(JSON.stringify(datas.value)));
                            }
                            const inputObject = React.createElement(InputObject, {
                                    value: JSON.parse(JSON.stringify(datas.value)),
                                    errors: datas.errors.properties,
                                    ref: this._inputObject,
                                    onChange: this._onChange,
                                    name: this.props.name,
                                    //Props utilisable uniquement par le FormSimple
                                    //TODO: trouver quelque chose de moins bidouille
                                    noRendering: true
                                },
                                this.props.children  instanceof Function
                                    ? this.props.children(JSON.parse(JSON.stringify(datas.value)), datas.errors.global, this)
                                    : React.Children.map(this.props.children, child => React.cloneElement(child, { errors: datas.errors.global && datas.errors.global.length ? datas.errors.global : [] }))
                            );
                            if(!this.props.segment){
                                return inputObject;
                            }
                            return (
                                <form  onSubmit={ (ev) => ev.preventDefault() }>
                                    { inputObject }
                                </form>
                            )
                        }}
                    </Data.Store.Accessor>
                </Data.Store.Provider>
            </InputObject.Context.Provider>
        )
    }
}