import React, { FunctionComponent, PropsWithChildren } from 'react';

import './header.css';

interface TextProps {

}

type TextType = FunctionComponent<PropsWithChildren<HeaderProps>> & {

};

interface HeaderProps {

}

type HeaderType = FunctionComponent<PropsWithChildren<HeaderProps>> & {
  Text: TextType;
};

const Header: HeaderType = ({ children }) => (
  <div className='bs-section-header'>
  { children }
  </div>
);

Header.Text = ({ children }) => (
  <div className='bs-section-header-text'>
  { children }
  </div>
);



export default Header;