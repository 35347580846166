import moment from "moment";

class Accessor {
  constructor(path) {
    this._path = path;
  }
  get path() {
    return this._path.join(".");
  }

  _getValues(values, property) {
    return values.reduce((acc, value) => {
      if(!(value instanceof Object)){
        return acc;
      }
      if(Array.isArray(value[property])){
        return acc.concat(value[property]);
      }
      acc.push(value[property]);
      return acc;
    }, []);
  }
  extract(object) {
    let ret;
    if (object.eval) {
      ret = object.eval(this._path);
      if (!Array.isArray(ret)) {
        ret = [ret];
      }
    } else {
      return this._path.reduce((values, property) => this._getValues(values, property), [object]);
    }
    
    return ret;
  }
}

export class Filter {
  toQuery() {
    throw new Error("Must be override");
  }
  match(object) {
    throw new Error("Must be override");
  }
  flatten(){
    return this;
  }
}

class NoOperationFilter extends Filter {
  toQuery() {
    return {};
  }
  match(object) {
    return true;
  }
}

class ValuableFilter extends Filter {
  constructor(operator, accessor, testedValue) {
    super();
    this._operator    = operator;
    this._accessor    = accessor;
    this._queryValue  = testedValue;
    if (operator.cache) {
      testedValue = operator.cache(testedValue);
    }
    this._testedValue = (Array.isArray(testedValue) ? testedValue : [testedValue]).map(value => this._convert(value));
  }

  get operator(){
    return this.operator;
  }

  get accessor(){
    return this._accessor;
  }

  get queryValue(){
    return this.queryValue;
  }

  toQuery() {
    const query                = {};
    query[this._accessor.path] = this._operator.toQuery(this._queryValue);
    return query;
  }
  _convert(value){
    if(value instanceof Date){
      value = moment(value).toISOString();
    }
    return value;
  }
  match(object) {
    return this._accessor.extract(object).reduce((test , value) => {
      value = this._convert(value);
      return test || this._testedValue.some(testedValue => this._operator.operate(value, testedValue));
    }, false);
  }
}

class CompositeFilter extends Filter {
  constructor(agglomerator) {
    super();
    this._filters      = [];
    this._agglomerator = agglomerator;
  }
  add(filter) {
    if (this._filters.indexOf(filter) !== -1) {
      return false;
    }
    this._filters.push(filter);
    return true;
  }
  remove(filter) {
    const idx = this._filters.indexOf(filter);
    if (idx === -1) {
      return false;
    }
    this._filters.splice(idx, 1);
    return true;
  }
  toQuery() {
    return this._agglomerator.toQuery(this._filters.map(filter => filter.toQuery()));
  }
  match(object) {
    return this._agglomerator.agglomerate(this._filters, object);
  }
  flatten(){
    if(!this._agglomerator === or){
      return this;
    }
    const filters = this._filters.map(filter => filter.flatten());
    if(!filters.length){
      return this;
    }
    let currentAccessor = null;
    let values = [];
    const flattenable = filters.reduce((flattenable, filter) => {
      if(!flattenable && !(filter instanceof ValuableFilter)){
        return false;
      }
      if(currentAccessor === null){
        currentAccessor = filter.accessor;
      }
      if(filter.accessor.path !== currentAccessor.path || (filter.operator !== _in && filter.operator !== eq)){
        return false;
      }

      values = values.concat(Array.isArray(filter.queryValue) ? filter.queryValue : [filter.queryValue]);

      return true;
    }, true);
    if(!flattenable){
      return this;
    }
    return new ValuableFilter(
      _in,
      currentAccessor,
      values
    );
  }
}

class NotFilter extends Filter {
  constructor(filter) {
    super();
    this._filter      = filter;
  }
  toQuery() {
    return { $ne: this._filter.toQuery() };
  }
  match(object) {
    return !this._filter.match(object);
  }
}

const or = {
  toQuery    : (queries) => (
    { $or: queries }
  ),
  agglomerate: (filters, object) => filters.reduce(
    (valid, filter) => valid || filter.match(object),
    false
  )
};
const nor = {
  toQuery    : (queries) => (
    { $nor: queries }
  ),
  agglomerate: (filters, object) => !filters.reduce(
    (valid, filter) => valid || filter.match(object),
    false
  )
};
const and = {
  toQuery    : (queries) => (
    {$and: queries}
  ),
  agglomerate: (filters, object) => filters.reduce(
    (valid, filter) => valid && filter.match(object),
    true
  )
};
const eq = {
  toQuery: (value) => value,
  operate: (value1, value2) => value1 === value2
};
const gte = {
  toQuery: (value) => (
    {$gte: value}
  ),
  operate: (value1, value2) => value1 >= value2
};
const gt = {
  toQuery: (value) => (
    {$gt: value}
  ),
  operate: (value1, value2) => value1 > value2
};
const lt = {
  toQuery: (value) => (
    {$lt: value}
  ),
  operate: (value1, value2) => value1<value2
};
const lte = { 
  toQuery: (value) =>({$lte: value}),
  operate: (value1, value2) => value1<=  value2
};
const regex = {
  toQuery: (value) => value,
  operate: (value1, regex) => regex.test(value1),
  cache  : (regex) => new RegExp(regex.$regex, regex.$options)
};
const elemMatch = {
  toQuery: (query) => (
    {$elemMatch: query}
  ),
  operate: (value, filter) => filter.match(value),
  cache  : (query) => Criterion.factory(null, query)
};
const _in = {
  toQuery: (query) => (
    {$in: query}
  ),
  operate: (value1, value2) => {
    return value1 === value2
  }
};
const exists = {
  toQuery: ($exists) => (
    { $exists }
  ),
  operate: (value, $exists) => {
    return $exists
      ? value !== undefined
      : value === undefined
  }
};

class Criterion {
  static factory(model, query) {
    if (query instanceof Filter) {
      return query;
    }
    return this._recursiveFactory(Criterion.create(model), query)
  }
  static _recursiveFactory(criterion, query) {
    const keys = Object.keys(query);
    if (! keys.length) {
      return new NoOperationFilter();
    }
    if (keys.length > 1) {
      return criterion.and(c => keys.map(key => {
        const tmpQuery = {};
        tmpQuery[key]  = query[key];
        return this._recursiveFactory(c, tmpQuery);
      }));
    }
    switch (keys[0]) {
      case "$and":
        return criterion.and(c => query[keys[0]].map(q => this._recursiveFactory(c, q)));
      case "$or":
        return criterion.or(c => query[keys[0]].map(q => this._recursiveFactory(c, q)));
      case "$nor":
        return criterion.nor(c => query[keys[0]].map(q => this._recursiveFactory(c, q)));
      default:
        const property = keys[0];
        const value    = query[property];
        if (value === null) {
          return criterion.eq(property, null);
        }
        if (value.$ne !== undefined) {
          return criterion.diff(property, value.$ne);
        } else if (value.$lte) {
          return criterion.lte(property, value.$lte);
        } else if (value.$lt) {
          return criterion.lt(property, value.$lt);
        } else if (value.$gt) {
          return criterion.gt(property, value.$gt);
        } else if (value.$gte) {
          return criterion.gte(property, value.$gte);
        } else if (value.$regex) {
          return criterion.regex(property, value);
        } else if (value.$elemMatch) {
          return criterion.elemMatch(property, value.$elemMatch);
        } else if (value.$in || Array.isArray(value)) {
          return criterion.in(property, value.$in ? value.$in : value);
        } else if (value.$nin) {
          return criterion.nin(property, value.$nin);
        } else if(value.$exists !== undefined){
          return criterion.exists(property, value.$exists);
        } else {
          return criterion.eq(property, value.$eq ? value.$eq : value);
        }
    }
  }
  static create() {
    return new Criterion();
  }
  constructor() {
  }
  _getPath(field) {
    if (field instanceof Function) {
      const path = [];
      let p;
      const spy  = {
        get(path, propertyName) {
          path.push(propertyName);
          return p;
        }
      }
      p = new Proxy(path, spy);
      field(p);
      return path;
    }
    if ("" + field === field) {
      return field.split(".");
    }
  }
  _buildComposite(aggregator, componentHandler) {
    const composite = new CompositeFilter(aggregator);
    componentHandler(this).forEach(filter => composite.add(filter));
    return composite;
  }
  or(componentHandler) {
    return this._buildComposite(or, componentHandler);
  }
  and(componentHandler) {
    return this._buildComposite(and, componentHandler);
  }
  nor(componentHandler) {
    return this._buildComposite(nor, componentHandler);
  }
  _buildValuable(premise, field, value) {
    const accessor = new Accessor(this._getPath(field));
    return new ValuableFilter(premise, accessor, value);
  }
  eq(field, value) {
    return this._buildValuable(eq, field, value);
  }
  diff(field, value) {
    return new NotFilter(this._buildValuable(eq, field, value));
  }
  gte(field, value) {
    return this._buildValuable(gte, field, value);
  }
  gt(field, value) {
    return this._buildValuable(gt, field, value);
  }
  lt(field, value) {
    return this._buildValuable(lt, field, value);
  }
  lte(field, value) {
    return this._buildValuable(lte, field, value);
  }
  regex(field, value) {
    return this._buildValuable(regex, field, value);
  }
  elemMatch(field, query) {
    return this._buildValuable(elemMatch, field, query);
  }
  in(field, query) {
    return this._buildValuable(_in, field, query);
  }
  nin(field, query) {
    return new NotFilter(this._buildValuable(_in, field, query));
  }
  exists(field, $exists) {
    return this._buildValuable(exists, field, $exists);
  }
}

export default Criterion;
