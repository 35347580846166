import React, { ComponentType, FunctionComponent, MouseEvent, PropsWithChildren, useContext } from 'react';
import Path from '../types/Path';
import useRoute from '../hooks/useRoute';
import useLocation from '../hooks/useLocation';

interface LinkedProps {
  to: Path;
  fromRoot?: boolean;
}

interface ToLinkWaitedProps {
  onClick: () => void;
  to: Path;
};

type LinkedComponentProps<ToLinkProps extends ToLinkWaitedProps> = Omit<ToLinkProps, keyof ToLinkWaitedProps> & LinkedProps;
type LinkedComponent<ToLinkProps extends ToLinkWaitedProps> = FunctionComponent<PropsWithChildren<LinkedComponentProps<ToLinkProps>>>;

const withLink = <ToLinkProps extends ToLinkWaitedProps>(ToLink: ComponentType<PropsWithChildren<ToLinkProps>>): LinkedComponent<PropsWithChildren<ToLinkProps>>  => {
  const Linked: LinkedComponent<PropsWithChildren<ToLinkProps>> = ({ to, children, fromRoot = false, ...rest }) => {
    const [, setLocation] = useLocation();

    const path = fromRoot ? to : useRoute(to);

    const changeLocation = React.useCallback(() => {
      setLocation(path);
    }, [setLocation, path]);

    return (
      <ToLink { ...rest as ToLinkProps } onClick={ changeLocation } to={ path }>
      { children }
      </ToLink>
    );
  }

  return Linked;
};

export default withLink;